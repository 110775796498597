







































import {Component, Prop, Watch} from "vue-property-decorator";
import AsiListTable from "@/components/common/AsiListTable.vue";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import AsiListTableHeader from "@/components/common/AsiListTableHeader";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
import AsiListTableLayout from "@/components/common/AsiListTableLayout.vue";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import {ICategoryAdminListEntry} from "@/models/category/CategoryAdminModels";
import CategoryListFilter from "@/models/category/CategoryListFilter";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import CategoryHelper from "@/models/category/CategoryHelper";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import AsiSelectSimple from "@/components/common/AsiSelectSimple";
import {AttachmentMediaSize} from "@/helpers/constants";
import Icon from "@/plugins/icons";
import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
import PathEntry from "@/models/PathEntry";
import CategoryStateChip from "@/components/category/CategoryStateChip.vue";

@Component({
	components: {
		CategoryStateChip,
		AsiBreadcrumbs,
		AsiSelectSimple,
		AsiAvatar,
		AsiContentContainer,
		AsiListTableLayout, AsiTextFieldSimple
	}
})
export default class CategoryList extends AsiListTable<ICategoryAdminListEntry, CategoryListFilter> implements IAsiListTableImplementation<ICategoryAdminListEntry, CategoryListFilter> {

	@Prop({type: Boolean, default: false})
	public hideToolbar!: boolean;

	private icons = Icon;
	private initialized: boolean = false;

	public created(): void {
		this.reload(false, false);
		this.$nextTick(() => this.initialized = true);
	}

	private get isRootOptions(): { text: string; value: boolean | null }[] {
		return [
			{text: this.$t('category.terms.allTypes').toString(), value: null},
			{text: this.$t('category.rootCategory.plural').toString(), value: true},
			{text: this.$t('category.subCategory.plural').toString(), value: false},
		];
	}

	public createFilterModel(): CategoryListFilter {
		return new CategoryListFilter();
	}

	public createHeaders(): AsiListTableHeader[] {
		return [
			new AsiListTableHeader('', 'avatar', false, false, 'center', '4rem'),
			new AsiListTableHeader(`${this.$t('category.name')} / ${this.$t('category.terms.path')}`, 'name', true, true, 'start'),
			new AsiListTableHeader(this.$t('category.state'), 'state', true, true, 'center', '10rem'),
		];
	}

	public fetchData(filterModel: CategoryListFilter | null, tableOptions: AsiListTableOptions | null): Promise<IPaginatedResponse<ICategoryAdminListEntry>> {
		return this.$categoryServiceAdmin.categories(filterModel, tableOptions);
	}

	public filterTextChanged(filterText: string): void {
		if (this.filterModel === null) return;
		this.filterModel.fulltext = filterText;
	}

	public openModelDetailPage(model: ICategoryAdminListEntry): void {
		this.$router.push(CategoryHelper.detailRouteAdmin(model.id));
	}

	public reload(goToFirstPage: boolean = false, debounce: boolean = true): void {
		this.reloadInternal(this, goToFirstPage, debounce);
	}

	protected prepareOptions(options: AsiListTableOptions): AsiListTableOptions {
		if (options.sortBy.length === 0 && this.defaultSortBy.length === 0) {
			options.sortBy = ['name'];
			options.sortDesc = [false];
		}
		return options;
	}

	@Watch('tableOptions', {deep: true})
	private onTableOptionsChanged(): void {
		if (!this.initialized) return;
		this.reload();
	}

	@Watch('filterModel', {deep: true})
	private onFilterModelChanged(): void {
		if (!this.initialized) return;
		this.reload(true);
	}

	private nameTranslated(category: ICategoryAdminListEntry): string | null {
		return TranslatedValueHelper.get(category.name, this.$i18n.locale);
	}

	// noinspection JSMethodCanBeStatic
	private color(category: ICategoryAdminListEntry): string | null {
		return CategoryHelper.colorHierarchical(category);
	}

	// noinspection JSMethodCanBeStatic
	private avatarUrl(category: ICategoryAdminListEntry): string | null {
		return CategoryHelper.avatarUrl(category, AttachmentMediaSize.s);
	}

	// noinspection JSMethodCanBeStatic
	private path(category: ICategoryAdminListEntry): PathEntry[] {
		return CategoryHelper.path(category, true);
	}

}
