






import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
@Component({
	components: {AsiContentContainer}
})
export default class AsiListTableAdvancedFilters extends Vue {

}
