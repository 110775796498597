

































import {Component, Prop} from "vue-property-decorator";
import PathEntry from "@/models/PathEntry";
import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

@Component({
	components: {AsiBreadcrumbs}
})
export default class ShopView extends mixins(ResponsiveChecks) {

	@Prop({type: Array, default: () => []})
	public path!: PathEntry[];

	@Prop({type: Boolean, default: false})
	public fullWidth!: boolean;

	@Prop({type: Boolean, default: null})
	public showAside!: boolean | null;

	@Prop({type: Boolean, default: false})
	public asideInverted!: boolean;

	private get showAsideFinal(): boolean {
		return this.hasAsideSlot && this.showAside !== false;
	}

	private get colsContent(): number {
		if (!this.showAsideFinal || this.sMobile) return 12;
		return this.sSemiMobile ? 8 : 9;
	}

	private get colsAside(): number {
		if (this.sMobile) return 12;
		return this.sSemiMobile ? 4 : 3;
	}

	private get classesAside(): object {
		if (!this.sMobile) return {};
		return {
			'mt-6': this.asideInverted,
			'mb-6': !this.asideInverted,
		};
	}

	private get hasBannerSlot(): boolean {
		return !!this.$slots['banner'] || !!this.$scopedSlots['banner'];
	}

	private get hasAsideSlot(): boolean {
		return !!this.$slots['aside'] || !!this.$scopedSlots['aside'];
	}

	private get hasPrependSlot(): boolean {
		return !!this.$slots['prepend'] || !!this.$scopedSlots['prepend'];
	}

	private get hasAppendSlot(): boolean {
		return !!this.$slots['append'] || !!this.$scopedSlots['append'];
	}
}
