
























































































import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AsiBtn from "@/components/common/AsiBtn.vue";
import {AttachmentMediaSize, Routes} from "@/helpers/constants";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import LogoHerzogDichtungen from "@/components/layout/LogoHerzogDichtungen.vue";
import Icon from "@/plugins/icons";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import {IOrderAdminListEntry} from "@/models/order/OrderAdminModels";
import Snackbar from "@/helpers/Snackbar";
import OrderHelper from "@/helpers/OrderHelper";
import {ICustomerAdminListEntry, ICustomerAdminSimple} from "@/models/customer/CustomerAdminModels";
import CustomerHelper from "@/models/customer/CustomerHelper";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import LogoMkDichtungen from "@/components/layout/LogoMkDichtungen.vue";
import {IUserAdminBlamable} from "@/models/user/UserAdminModels";
import {IAddressFields} from "@/models/address/AddressModels";

@Component({
	components: {LogoMkDichtungen, AsiAvatar, AsiCard, LogoHerzogDichtungen, AsiContentContainer, AsiBtn}
})
export default class Home extends Vue {

	private icons = Icon;

	private loadingOrders: boolean = false;
	private orders: IOrderAdminListEntry[] = [];
	private loadingCustomers: boolean = false;
	private customers: ICustomerAdminListEntry[] = [];

	public created(): void {
		this.loadNewestOrders();
		this.loadNewestCustomers();
	}

	private goToShopHome(): void {
		this.$router.push({name: Routes.home});
	}

	private customerAvatarUrl(customer: ICustomerAdminSimple): string | null {
		return CustomerHelper.avatarUrl(customer, AttachmentMediaSize.s);
	}

	private showOrder(order: IOrderAdminListEntry): void {
		this.$router.push(OrderHelper.detailRouteAdmin(order.id));
	}

	private showCustomer(customer: ICustomerAdminListEntry): void {
		this.$router.push(CustomerHelper.detailRouteAdmin(customer.id));
	}

	private loadNewestOrders(): void {
		const options = new AsiListTableOptions();
		options.itemsPerPage = 10;
		options.page = 1;
		options.sortBy = ['createdAt'];
		options.sortDesc = [true];

		this.loadingOrders = true;
		this.$orderServiceAdmin.orders(null, options)
			.then(data => this.orders = data.data)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loadingOrders = false);
	}

	private loadNewestCustomers(): void {
		const options = new AsiListTableOptions();
		options.itemsPerPage = 10;
		options.page = 1;
		options.sortBy = ['createdAt'];
		options.sortDesc = [true];

		this.loadingCustomers = true;
		this.$customerServiceAdmin.customers(null, options)
			.then(data => this.customers = data.data)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loadingCustomers = false);
	}

	private fullname(customer: IAddressFields): string {
		return customer.firstname + ' ' + customer.lastname;
	}

}
