


















































































































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AddressCreate from "@/models/address/AddressCreate";
import AsiTextField from "@/components/common/AsiTextField";
import AddressUpdate from "@/models/address/AddressUpdate";
import AsiCountryAutocompleteInput from "@/components/common/AsiCountryAutocompleteInput.vue";
import AsiRadioGroup from "@/components/common/AsiRadioGroup";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import AsiCheckbox from "@/components/common/AsiCheckbox";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import SalutationInput from "@/components/common/SalutationInput.vue";

@Component({
	components: {
		SalutationInput,
		AsiCheckbox,
		AsiBtn, AsiTextFieldSimple, AsiCard, AsiRadioGroup, AsiCountryAutocompleteInput, AsiTextField
	}
})
export default class AddressForm extends mixins(ResponsiveChecks) {

	@Prop({type: Boolean, default: true})
	public value!: boolean;

	@Prop({type: Object, required: true})
	public model!: AddressCreate | AddressUpdate;

	@Prop({type: Boolean, default: false})
	public business!: boolean;

	@Prop({type: Boolean, default: false})
	public allowDelete!: boolean;

	@Prop({type: Boolean, default: false})
	public flat!: boolean;

	@Prop({type: Boolean, default: false})
	public tile!: boolean;

	@Prop({type: Boolean, default: false})
	public noBottomMargin!: boolean;

	@Prop({type: Boolean, default: false})
	public allowTypeSelection!: boolean;

	@Prop({type: Boolean, default: false})
	public hideLabel!: boolean;

	@Prop({type: Boolean, default: false})
	public hideDefaultControls!: boolean;

	private icons = Icon;
	private valueInternal: boolean = true;
	private businessInternal: boolean = false;

	private get isCreateModel(): boolean {
		return this.model instanceof AddressCreate;
	}

	private get isDefaultShippingAddress(): boolean | null {
		return this.isCreateModel && (this.model as AddressCreate).isDefaultShippingAddress ? true : null;
	}

	private set isDefaultShippingAddress(value: boolean | null) {
		if (!this.isCreateModel) return;
		this.requestIsDefaultShippingAddress(value === true);
	}

	private get isDefaultBillingAddress(): boolean | null {
		return this.isCreateModel && (this.model as AddressCreate).isDefaultBillingAddress ? true : null;
	}

	private set isDefaultBillingAddress(value: boolean | null) {
		if (!this.isCreateModel) return;
		this.requestIsDefaultBillingAddress(value === true);
	}

	@Watch('value')
	private onValueChanged(value: boolean): void {
		this.valueInternal = value;
	}

	@Watch('valueInternal')
	private onValueInternalChanged(value: boolean): void {
		if (this.value !== value) this.input(value);
	}

	@Watch('business', {immediate: true})
	private onBusinessChanged(value: boolean): void {
		this.businessInternal = value;
	}

	@Watch('businessInternal')
	private onBusinessInternalChanged(value: boolean): void {
		if (this.business !== value) {
			this.$emit('update:business', value);
		}

		if (!value) {
			this.model.company = null;
			this.model.companySector = null;
		}
	}

	@Emit('input')
	public input(value: boolean): boolean {
		return value;
	}

	@Emit('requestDelete')
	public requestDelete(): void {
		return;
	}

	@Emit('requestDefaultShippingAddress')
	public requestIsDefaultShippingAddress(value: boolean | null): boolean {
		return value ?? false;
	}

	@Emit('requestDefaultBillingAddress')
	public requestIsDefaultBillingAddress(value: boolean | null): boolean {
		return value ?? false;
	}

}
