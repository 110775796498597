







import {Component} from "vue-property-decorator";
import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
import Icon from "@/plugins/icons";
import CustomerList from "@/components/customer/admin/CustomerList.vue";
import {mixins} from "vue-class-component";
import RightChecks from "@/mixins/RightChecks.vue";

@Component({
	components: {CustomerList, AsiPageTitle}
})
export default class Customers extends mixins(RightChecks) {

	private icons = Icon;

}
