



















































































































import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
import ShopView from "@/components/layout/ShopView.vue";
import Icon from "@/plugins/icons";
import SalutationInput from "@/components/common/SalutationInput.vue";
import AsiTextField from "@/components/common/AsiTextField";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import {mixins} from "vue-class-component";
import AsiCountryAutocompleteInput from "@/components/common/AsiCountryAutocompleteInput.vue";
import AsiTextarea from "@/components/common/AsiTextarea";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiCheckbox from "@/components/common/AsiCheckbox";
import DialogHandler from "@/components/common/DialogHandler";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import LegalTermsAndConditions from "@/components/legal/LegalTermsAndConditions.vue";
import AsiDialog from "@/components/common/AsiDialog.vue";
import EnumHelper from "@/helpers/EnumHelper";
import {Tenant} from "@/models/ui/Tenant";
import PrivacyContent from "@/views/shop/legal/PrivacyContent.vue";
import ContactFormCreate from "@/models/contact/ContactFormCreate";

@Component({
	components: {
		PrivacyContent,
		Privacy: PrivacyContent,
		AsiDialog, LegalTermsAndConditions,
		AsiTextFieldSimple,
		AsiCard,
		AsiCardDetail,
		AsiCheckbox,
		AsiBtn, AsiTextarea, AsiCountryAutocompleteInput, AsiTextField, SalutationInput, ShopView
	}
})
export default class ContactForm extends mixins(ResponsiveChecks) {
	@Prop({type: Object, required: true})
	public model!: ContactFormCreate;

	@Prop({type: Boolean, required: true})
	public valid!: boolean;

	private icons = Icon;
	private validInternal: boolean = true;
	private isPrivacyConditionsAccepted = false;
	private dpDialog: DialogHandler = new DialogHandler();

	private get tenantName(): string {
		return EnumHelper.textFromValue(Tenant, this.$isTenant, true) ?? '';
	}

	private sendContactForm(): void {
		if (this.valid && this.isPrivacyConditionsAccepted) {
			this.performContactForm();
		}
	}

	@Watch('validInternal')
	public onValidInternalChange(): void {
		this.change(this.validInternal);
	}

	@Emit('change')
	public change(valid: boolean): boolean {
		return valid;
	}

	@Emit('performContactForm')
	public performContactForm(): boolean {
		return true;
	}
}
