









import {Component} from "vue-property-decorator";
import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
import Icon from "@/plugins/icons";
import UserList from "@/components/user/admin/UserList.vue";
import UserCreateDialog from "@/components/user/UserCreateDialog.vue";
import DialogHandler from "@/components/common/DialogHandler";
import RightChecks from "@/mixins/RightChecks.vue";
import {mixins} from "vue-class-component";

@Component({
	components: {UserCreateDialog, UserList, AsiPageTitle}
})
export default class Users extends mixins(RightChecks) {

	private icons = Icon;
	private createDialog: DialogHandler = new DialogHandler();

	private userCreated(): void {
		this.createDialog.close();

		let list = this.$refs.userList;
		if (list !== undefined) {
			(this.$refs.userList as UserList).reload();
		}
	}

}
