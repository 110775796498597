































































































import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import {IAddressSimple} from "@/models/address/AddressModels";
import AsiCard from "@/components/common/AsiCard.vue";
import VueI18n from "vue-i18n";
import Icon from "@/plugins/icons";
import AddressHelper from "@/helpers/AddressHelper";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
import Snackbar from "@/helpers/Snackbar";
import AsiChip from "@/components/common/AsiChip.vue";
import AddressUpdateDialog from "@/components/address/AddressUpdateDialog.vue";
import DialogHandler from "@/components/common/DialogHandler";
import TranslateResult = VueI18n.TranslateResult;
import ICustomerServiceAdmin from "@/services/definition/ICustomerServiceAdmin";
import ICustomerServiceShop from "@/services/definition/ICustomerServiceShop";

@Component({
	components: {AddressUpdateDialog, AsiChip, AsiConfirmDialog, AsiBtn, AsiCard}
})
export default class AddressCard extends Vue {

	@Prop({type: Object, required: true})
	public address!: IAddressSimple;

	@Prop({type: String, required: true})
	public customerId!: string;

	@Prop({type: Boolean, required: true})
	public business!: boolean;

	@Prop({type: Boolean, required: true})
	public allowTypeSelection!: boolean;

	@Prop({type: Object, required: true})
	public service!: ICustomerServiceAdmin | ICustomerServiceShop;

	@Prop({type: Boolean, default: false})
	public isDefaultShippingAddress!: boolean;

	@Prop({type: Boolean, default: false})
	public isDefaultBillingAddress!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	@Prop({type: Boolean, default: false})
	public noBottomMargin!: boolean;

	private icons = Icon;
	private loading: boolean = false;
	private updateDialog: DialogHandler = new DialogHandler();

	private get labelFinal(): string | TranslateResult {
		return this.address.label ?? this.$t('address.terms.noLabel');
	}

	private get iconFinal(): string {
		return AddressHelper.hasBusinessFieldData(this.address) ? Icon.customerBusiness : Icon.customerPrivate;
	}

	private get iconsFinal(): string[] {
		const ret: string[] = [];
		if (this.isDefaultShippingAddress) ret.push(Icon.shipping);
		if (this.isDefaultBillingAddress) ret.push(Icon.currency);
		return ret.length === 0 ? [Icon.address] : ret;
	}

	private get lines(): string[] {
		return AddressHelper.addressLines(this.address);
	}

	@Emit('changed')
	public changed(): void {
		return;
	}

	@Emit('changedDefault')
	public changedDefault(): void {
		return;
	}

	@Emit('deleted')
	public deleted(id: string): string {
		return id;
	}

	private requestDefaultShippingAddress(): void {
		if (this.readonly || this.isDefaultShippingAddress) return;

		this.loading = true;
		this.service.setDefaultShippingAddress(this.customerId, this.address.id)
			.then(() => this.changedDefault())
			.catch(() => Snackbar.updateError())
			.finally(() => this.loading = false);
	}

	private requestDefaultBillingAddress(): void {
		if (this.readonly || this.isDefaultBillingAddress) return;

		this.loading = true;
		this.service.setDefaultBillingAddress(this.customerId, this.address.id)
			.then(() => this.changedDefault())
			.catch(() => Snackbar.updateError())
			.finally(() => this.loading = false);
	}

	private requestDelete(): void {
		if (this.readonly) return;
		const addressId = this.address.id;

		const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
		confirm.openDialog().then((res: boolean) => {
			if (!res) return;
			this.loading = true;
			this.service.deleteAddress(this.customerId, addressId)
				.then(() => this.deleted(addressId))
				.catch(() => Snackbar.deleteError())
				.finally(() => this.loading = false);
		});
	}

	private openMap(): void {
		const w = window.open(AddressHelper.googleMapsLink(this.address), '_blank');
		if (w !== null) w.focus();
	}

}
