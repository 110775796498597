import i18n from "@/plugins/i18n";
import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
import LocaleHelper from "@/helpers/LocaleHelper";

export default class TranslatedValueHelper {

	public static get(instance: ITranslatedValue, locale: string, fallback: boolean = true): string | null {
		if (locale.length < 2) throw new Error(`invalid locale length: ${locale}`);

		const map = this.toMap(instance);

		const requestedLang = LocaleHelper.extractLanguage(locale);
		let curValue = map.get(requestedLang);
		if (curValue !== undefined) return curValue;
		if (!fallback) return null;

		const fallbackLang = LocaleHelper.extractLanguage(i18n.fallbackLocale as string);
		curValue = map.get(fallbackLang);
		if (curValue !== undefined) return curValue;

		return map.size > 0 ? map.values().next().value : null;
	}

	private static toMap(instance: ITranslatedValue): Map<string, string> {
		const map = new Map<string, string>();
		if (instance.de !== null) map.set('de', instance.de);
		if (instance.fr !== null) map.set('fr', instance.fr);
		if (instance.en !== null) map.set('en', instance.en);
		return map;
	}

}
