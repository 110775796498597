
























import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import CategoryCard from "./CategoryCard.vue";
import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
import CategoryFeaturedCard from "@/components/category/shop/CategoryFeaturedCard.vue";
import Icon from "@/plugins/icons";

@Component({
	components: {CategoryFeaturedCard, CategoryCard}
})
export default class CategoryNavigation extends Vue {

	@Prop({type: Number, default: 5})
	public columns!: number;

	@Prop({type: Boolean, default: false})
	public small!: boolean;

	@Prop({type: String, default: null})
	public parentCategoryId!: string | null;

	private icons = Icon;

	private showAllCategories: boolean = true;

	public get categories(): ICategoryShopListEntry[] {
		if (this.parentCategoryId !== null) {
			return this.$store.getters['category/categoryChildren'](this.parentCategoryId);
		}

		const roots = this.$store.getters['category/rootCategories'];
		return roots.length === 1
			? this.$store.getters['category/categoryChildren'](roots[0].id)
			: roots;
	}

	public get topCategories(): ICategoryShopListEntry[] {
		return this.categories.filter(c => c.isTopCategory);
	}

	private toggleShowCategory(): void {
		if (this.topCategories.length > 0) {
			this.showAllCategories = !this.showAllCategories;
		}
	}
}
