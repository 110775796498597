
















	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import {IUserAdminBlamable} from "@/models/user/UserAdminModels";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import Icon from "@/plugins/icons";
	import {ICartAdmin} from "@/models/cart/CartAdminModels";
	import CustomerTypeChip from "@/components/customer/CustomerTypeChip.vue";
	@Component({
		components: {CustomerTypeChip, AsiCardDetailEntry, AsiCardDetail}
	})
	export default class CartCardBasicData extends Vue {
        @Prop({type: Object, required: true})
        public cart!: ICartAdmin;

		private icons = Icon;

		private fullname(blamable: IUserAdminBlamable): string {
			return blamable.firstname + ' ' + blamable.lastname;
		}
	}
