
































import {Component, Prop, Watch} from "vue-property-decorator";
import CategoryList from "./CategoryNavigation.vue";
import {ICategoryShop} from "@/models/category/CategoryShopModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import AsiCard from "@/components/common/AsiCard.vue";
import GalleryDetail from "@/components/gallery/GalleryDetail.vue";
import GalleryPreview from "@/components/gallery/GalleryPreview.vue";
import {IAttachment} from "@/models/attachment/AttachmentModels";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import AttachmentHelper from "@/models/attachment/AttachmentHelper";
import {AttachmentMediaSize} from "@/helpers/constants";
import CategoryHelper from "@/models/category/CategoryHelper";
import AsiBtn from "@/components/common/AsiBtn.vue";

@Component({
	components: {AsiBtn, GalleryPreview, GalleryDetail, AsiCard, CategoryList}
})
export default class CategoryHeader extends mixins(ResponsiveChecks) {

	@Prop({type: Object, default: null})
	public category!: ICategoryShop | null;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	private selectedImageId: string | null = null;
	private textCollapsed: boolean = true;

	private get nameTranslated(): string | null {
		return this.category === null
			? null
			: TranslatedValueHelper.get(this.category.name, this.$i18n.locale);
	}

	private get descriptionTranslated(): string | null {
		return this.category === null || this.category.description === null
			? null
			: TranslatedValueHelper.get(this.category.description, this.$i18n.locale);
	}

	private get descriptionTranslatedTooLong(): boolean {
		if (this.descriptionTranslated === null) return false;
		if (this.sMobile) {
			return this.descriptionTranslated.length > 150;
		} else if ((this.category?.galleryAttachments.length ?? 0) > 0) {
			return this.descriptionTranslated.length > 390;
		} else {
			return this.descriptionTranslated.length > 820;
		}
	}

	private get showGallery(): boolean {
		return !this.sMobile && (this.category?.galleryAttachments.length ?? 0) > 0;
	}

	private get selectedImage(): IAttachment | null {
		return this.category?.galleryAttachments.find(a => a.id === this.selectedImageId) ?? null;
	}

	private get selectedImageUrl(): string | null {
		return this.selectedImage !== null ? AttachmentHelper.getImageUrl(this.selectedImage, AttachmentMediaSize.l) : null;
	}

	private get categoryColor(): string {
		return this.category === null
			? 'primary'
			: CategoryHelper.colorHierarchical(this.category) ?? 'primary';
	}

	private get cardStyles(): object {
		if (!this.showGallery || this.selectedImageUrl === null) return {};
		return {
			'background-image': `linear-gradient(90deg, #fff 30%, transparent 75%), url(${this.selectedImageUrl}`,
		};
	}

	@Watch('category.bannerAttachmentId', {immediate: true})
	private onCategoryBannerAttachmentIdChanged(value: string | null): void {
		if (value !== null) {
			this.selectedImageId = value;
		} else if (this.category?.avatarAttachmentId !== null) {
			this.selectedImageId = this.category?.avatarAttachmentId ?? null;
		}
	}

}
