



































































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogForm from "../../common/AsiDialogForm.vue";
import {IItemAdmin} from "@/models/item/ItemAdminModels";
import ItemUpdateSalesData from "../../../models/item/ItemUpdateSalesData";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import AsiTextField from "@/components/common/AsiTextField";
import AsiSelect from "@/components/common/AsiSelect";
import AsiCheckbox from "@/components/common/AsiCheckbox";
import EnumHelper from "@/helpers/EnumHelper";
import {UnitOfMeasure} from "@/helpers/constants";

@Component({
	components: {AsiCheckbox, AsiSelect, AsiTextField, AsiDialogFormLayout}
})
export default class ItemUpdateDialogSalesData extends AsiDialogForm {
	@Prop({type: Object, required: true})
	public item!: IItemAdmin;

	private model: ItemUpdateSalesData | null = null;
	private stockNoInformation: boolean = false;

	// noinspection JSMethodCanBeStatic
	private get options(): { text: string, value: string | number }[] {
		return EnumHelper
			// eslint-disable-next-line no-undef
			.toSelectItems(UnitOfMeasure, true);
	}

	@Watch('item', {deep: true, immediate: true})
	private onItemChanged(): void {
		this.setItemInternal();
	}

	@Watch('stockNoInformation')
	private onStockNoInformationChanged(value: boolean): void {
		if (this.model === null) return;

	    if (value) {
			this.model.stockQuantity = null;
	    }
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.setItemInternal();
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private setItemInternal(): void {
		if (this.model === null) {
			this.model = new ItemUpdateSalesData(this.item);
			this.valid = true;
		} else {
			this.model.setDetailModel(this.item);
		}
	}

	private requestSave(): void {
		if (this.model === null) return;

		this.loading = true;
		this.$itemServiceAdmin.updateSalesData(this.item.id, this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}
}
