

















import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import AsiTextField from "@/components/common/AsiTextField";
import {ICartShopSimple} from "@/models/cart/CartShopModels";
import Snackbar from "@/helpers/Snackbar";
import CartHelper from "@/helpers/CartHelper";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiTextField, AsiDialogFormLayout, AsiDialogForm}
})
export default class CartUpdateDialogName extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public cart!: ICartShopSimple;

	private nameInternal: string | null = null;

	private get cartLabel(): string | TranslateResult {
		return CartHelper.label(this.cart);
	}

	@Watch('cart.name', {immediate: true})
	private onNameChanged(value: string | null): void {
		this.nameInternal = value;
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		this.nameInternal = this.cart.name;
		return event;
	}

	private requestSave(): void {
		this.loading = true;
		this.$store.dispatch('cart/updateName', {cartId: this.cart.id, name: this.nameInternal})
			.then(() => this.save())
			.catch(() => Snackbar.updateError())
			.finally(() => this.loading = false);
	}

}
