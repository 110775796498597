














































import Vue from 'vue';
import {Component, Prop, Watch} from "vue-property-decorator";
import ShopView from "@/components/layout/ShopView.vue";
import Icon from "@/plugins/icons";
import {IOrderShop} from "@/models/order/OrderShopModels";
import Snackbar from "@/helpers/Snackbar";
import VueI18n from "vue-i18n";
import OrderStateChip from "@/components/order/OrderShipmentStateChip.vue";
import OrderHelper from "@/helpers/OrderHelper";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import OrderCardPositions from "@/components/order/shop/OrderCardPositions.vue";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import {IAddress} from "@/models/address/AddressModels";
import AddressHelper from "@/helpers/AddressHelper";
import OrderCardPayment from "@/components/order/shop/OrderCardPayment.vue";
import TranslateResult = VueI18n.TranslateResult;
import OrderPaymentStateChip from "@/components/order/OrderPaymentStateChip.vue";
import OrderShipmentStateChip from "@/components/order/OrderShipmentStateChip.vue";

@Component({
	components: {
		OrderShipmentStateChip,
		OrderPaymentStateChip,
		AsiCardDetailEntry,
		AsiCardDetail,
		OrderCardPositions,
		OrderCardPayment,
		AsiContentContainer,
		OrderStateChip, ShopView
	}
})
export default class Order extends Vue {

	@Prop({type: String, required: true})
	public id!: string;

	@Prop({type: String, default: null})
	public paymentUrl!: string | null;

	private icons = Icon;
	private loading: boolean = false;
	private order: IOrderShop | null = null;

	private get title(): string | TranslateResult {
		if (this.order === null) {
			return this.$t('order.singular');
		}
		return `${this.$t('order.singular')}: ${this.order.orderNumber}`;
	}

	private get subtitle(): string | TranslateResult | null {
		return this.order === null || this.order.createdAt === null
			? null
			: this.$d(new Date(this.order.createdAt), 'long');
	}

	private get paramPaymentState(): string | null {
		return this.$route.query['payment-state'] as string | undefined ?? null;
	}

	private get paramPaymentId(): string | null {
		return this.$route.query['payment-id'] as string | undefined ?? null;
	}

	@Watch('id', {immediate: true})
	private onIdChanged(): void {
		this.loadOrder();
	}

	private loadOrder(): void {
		this.loading = true;
		this.$orderServiceShop.order(this.id)
			.then(model => this.order = model)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loading = false);
	}

	private paymentChanged(): void {
		this.$router
			.replace(OrderHelper.detailRoute(this.id))
			.finally(() => this.loadOrder());
	}

	// noinspection JSMethodCanBeStatic
	private addressLines(address: IAddress): string[] {
		return AddressHelper.addressLines(address);
	}

}
