














































import Vue from 'vue';
import {Component, Prop, Watch} from "vue-property-decorator";
import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
import Icon from "@/plugins/icons";
import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import Snackbar from "@/helpers/Snackbar";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import DetailCardTranslatedValue from "@/components/translated-value/DetailCardTranslatedValue.vue";
import AsiTabs from "@/components/common/AsiTabs";
import ItemList from "@/components/item/admin/ItemList.vue";
import CategoryHelper from "@/models/category/CategoryHelper";
import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
import PathEntry from "@/models/PathEntry";
import CategoryTabDetails from "@/components/category/admin/CategoryTabDetails.vue";
import CategoryTabImages from "@/components/category/admin/CategoryTabImages.vue";
import CategoryTabAttributes from "@/components/category/admin/CategoryTabAttributes.vue";

@Component({
	components: {
		CategoryTabAttributes,
		CategoryTabImages,
		CategoryTabDetails,
		AsiBreadcrumbs, ItemList, AsiTabs, DetailCardTranslatedValue, AsiContentContainer, AsiPageTitle}
})
export default class Category extends Vue {

	@Prop({type: String, required: true})
	public id!: string;

	private icons = Icon;
	private loading: boolean = false;
	private loadingAttributes: boolean = false;
	private category: ICategoryAdmin | null = null;

	private get color(): string | null {
		return this.category === null ? null : CategoryHelper.colorHierarchical(this.category);
	}

	private get nameTranslated(): string | null {
		return this.category === null ? null : TranslatedValueHelper.get(this.category.name, this.$i18n.locale);
	}

	private get path(): PathEntry[] {
		return this.category === null ? [] : CategoryHelper.path(this.category, true);
	}

	private get activeTab(): number {
		return this.$store.getters['ui/activeTabAdmin']('category');
	}

	private set activeTab(index: number) {
		this.$store.commit('ui/activeTabAdmin', {key: 'category', activeTab: index});
	}

	@Watch('id', {immediate: true})
	private onIdChanged(): void {
		this.loadCategory();
	}

	private loadCategory(): void {
		this.loading = true;
		this.$categoryServiceAdmin.category(this.id)
			.then(model => this.category = model)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loading = false);
	}

}
