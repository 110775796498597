import { render, staticRenderFns } from "./ItemAttributeFilterBoolean.vue?vue&type=template&id=32a2504f&scoped=true"
import script from "./ItemAttributeFilterBoolean.vue?vue&type=script&lang=ts"
export * from "./ItemAttributeFilterBoolean.vue?vue&type=script&lang=ts"
import style0 from "./ItemAttributeFilterBoolean.vue?vue&type=style&index=0&id=32a2504f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32a2504f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
installComponents(component, {VBtnToggle})
