




















import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import TopBar from "@/components/layout/TopBar.vue";
import RootCategoryNavigation from "@/views/shop/layout/partials/RootCategoryNavigation.vue";
import Footer from "@/views/shop/layout/partials/Footer.vue";
import CategoryNavigation from "@/components/category/shop/CategoryNavigation.vue";
import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
import CartDrawer from "@/components/layout/CartDrawer.vue";

@Component({
	components: {
		CartDrawer,
		CategoryNavigation,
		Footer,
		RootCategoryNavigation,
		TopBar
	}
})
export default class LayoutShop extends mixins(ResponsiveChecks) {

	private rootNavSticky: boolean = false;

	public created(): void {
		this.$store.dispatch('category/loadCategories');
	}

	private get topBarHeight(): number {
		return this.$vuetify.application.top;
	}

	private get rootCategories(): ICategoryShopListEntry[] {
		return this.$store.getters['category/rootCategories'];
	}

}
