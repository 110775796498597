







import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";

@Component
export default class AsiAlert extends Vue {

	@Prop({type: String, required: true})
	public type!: string;

	@Prop({type: String, default: null})
	public icon!: string | null;

	@Prop({type: Boolean, default: false})
	public tile!: boolean;

	@Prop({type: Boolean, default: false})
	public dense!: boolean;

	@Prop({type: Number, default: 0})
	public elevation!: number | string;

	@Prop({type: Boolean, default: false})
	public noBottomMargin!: boolean;

}
