






































import {Component, Emit} from "vue-property-decorator";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import AsiTextField from "@/components/common/AsiTextField";
import PromotionCreate from "@/models/promotion/PromotionCreate";
import ItemAutocompleteInput from "@/components/item/admin/ItemAutocompleteInput.vue";
import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
import DateTimeHelper from "@/helpers/DateTimeHelper";

@Component({
	components: {
		AsiDatePickerCombined,
		ItemAutocompleteInput, AsiTextField, AsiDialogFormLayout
	}
})
export default class PromotionCreateDialog extends AsiDialogForm {

	private model: PromotionCreate = new PromotionCreate();

	private get validFromInput(): string | null {
		return this.model.validFrom === null ? null : DateTimeHelper.toISODateString(new Date(this.model.validFrom));
	}

	private set validFromInput(value: string | null) {
		if (value === null) {
			this.model.validFrom = null;
			return;
		}

		const date = new Date(value);
		date.setHours(0, 0, 0);
		this.model.validFrom = DateTimeHelper.toISODateTimeString(date);
	}

	private get validToInput(): string | null {
		return this.model.validTo === null ? null : DateTimeHelper.toISODateString(new Date(this.model.validTo));
	}

	private set validToInput(value: string | null) {
		if (value === null) {
			this.model.validTo = null;
			return;
		}

		const date = new Date(value);
		date.setHours(23, 59, 59);
		this.model.validTo = DateTimeHelper.toISODateTimeString(date);
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		const form = this.$refs.form as HTMLFormElement;
		this.reset([this.model], form);
		return event;
	}

	private requestSave(): void {
		if (!this.valid) return;

		this.loading = true;
		this.$promotionServiceAdmin.create(this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

}
