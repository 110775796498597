



















import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import PathEntry from "../../models/PathEntry";

@Component
export default class AsiBreadcrumbsEntry extends Vue {

	@Prop({required: true})
	public entry!: string | PathEntry;

	@Prop({type: Boolean, default: false})
	public small!: boolean;

	@Prop({type: Boolean, default: false})
	public hideIcon!: boolean;

	@Prop({type: Boolean, default: false})
	public linkEntry!: boolean;

	private get isPathEntry(): boolean {
		return this.entry instanceof PathEntry;
	}

	private get label(): string {
		return this.entry instanceof PathEntry ? this.entry.label : this.entry;
	}

	private get icon(): string | null {
		return this.entry instanceof PathEntry ? this.entry.icon : null;
	}

}
