























































import Vue from 'vue';
import {Component} from "vue-property-decorator";
import ShopView from "@/components/layout/ShopView.vue";
import AsiAlert from "@/components/common/AsiAlert.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import VueI18n from "vue-i18n";
import Icon from "@/plugins/icons";
import AsiBtn from "@/components/common/AsiBtn.vue";
import RoutesHelper from "@/helpers/RoutesHelper";
import AsiTextField from "@/components/common/AsiTextField";
import {ValidationRule} from "@/declarations/ValidationRule";
import UserRules from "@/models/user/UserRules";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiTextField, AsiBtn, AsiCard, AsiAlert, ShopView}
})
export default class RegistrationConfirm extends Vue {

	private icons = Icon;
	private loading: boolean = false;
	private success: boolean | null = null;
	private passwordFormValid: boolean = false;
	private password: string | null = null;
	private showPassword: boolean = false;

	private get cardColor(): string | undefined {
		switch (this.success) {
			case true:
				return 'success';
			case false:
				return 'error';
			case null:
			default:
				return undefined;
		}
	}

	private get passwordRules(): ValidationRule[] {
		return UserRules.passwordRules;
	}

	private get confirmationStateLabel(): string | TranslateResult | null {
		if (this.loading) {
			return this.$t('user.terms.confirmationInProgress');
		} else if (this.success === true) {
			return this.$t('user.terms.confirmationSuccessful');
		} else if (this.success === false) {
			return this.$t('user.terms.confirmationFailed');
		}
		return this.$t('user.terms.confirmationEnterData');
	}

	private get isValid(): boolean {
		return this.token !== null && this.userId !== null && this.passwordFormValid;
	}

	private get token(): string | null {
		return (this.$route.query.token ?? null) as string | null;
	}

	private get userId(): string | null {
		return (this.$route.query.userId ?? null) as string | null;
	}

	private performConfirm(): void {
		if (!this.isValid || this.userId === null || this.token === null || this.password === null) {
			return;
		}

		this.loading = true;
		this.$userServiceShop.confirmRegistration(this.userId, this.token, this.password)
			.then(() => this.success = true)
			.catch(() => this.success = false)
			.finally(() => this.loading = false);
	}

	private showLogin(): void {
		this.$router.push(RoutesHelper.loginShopRoute());
	}

}
