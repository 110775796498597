
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {Permissions} from "@/helpers/constants";

@Component
export default class RightChecks extends Vue {

	protected get rLoggedIn(): boolean {
		return this.$store.getters['user/isLoggedIn'];
	}


	protected get rAdmin(): boolean {
		return this.$store.getters['user/hasPermission'](Permissions.rightAdmin);
	}

	protected get rMasterData(): boolean {
		return this.$store.getters['user/hasPermission'](Permissions.rightMasterData);
	}

	protected get rMetaData(): boolean {
		return this.$store.getters['user/hasPermission'](Permissions.rightMetaData);
	}

	protected get rFinance(): boolean {
		return this.$store.getters['user/hasPermission'](Permissions.rightFinance);
	}


	protected get rCustomerBusiness(): boolean {
		return this.$store.getters['user/hasPermission'](Permissions.rightCustomerBusiness);
	}

	protected get rCustomerPrivate(): boolean {
		return this.$store.getters['user/hasPermission'](Permissions.rightCustomerPrivate);
	}


	protected get rBackendUser(): boolean {
		return this.$store.getters['user/hasPermission'](Permissions.rightBackendUser);
	}

	protected get rCustomerManager(): boolean {
		return this.$store.getters['user/hasPermission'](Permissions.rightCustomerManager);
	}

}
