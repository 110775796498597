import { render, staticRenderFns } from "./AddressCardSimple.vue?vue&type=template&id=01a37e2e&scoped=true"
import script from "./AddressCardSimple.vue?vue&type=script&lang=ts"
export * from "./AddressCardSimple.vue?vue&type=script&lang=ts"
import style0 from "./AddressCardSimple.vue?vue&type=style&index=0&id=01a37e2e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01a37e2e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDivider,VList,VListItem,VListItemAction,VListItemContent,VListItemTitle,VTooltip})
