





























import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import VueI18n from "vue-i18n";
import ItemHelper from "@/models/item/ItemHelper";
import AsiTextField from "@/components/common/AsiTextField";
import ItemUpdateAttributes from "@/models/item/ItemUpdateAttributes";
import TranslateResult = VueI18n.TranslateResult;
import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
import LocaleHelper from "@/helpers/LocaleHelper";

@Component({
	components: {AsiTextField}
})
export default class ItemAttributesEntry extends Vue {

	@Prop({type: String, required: true})
	public attribute!: string;

	@Prop({default: null})
	public value!: string | number | null;

	@Prop({type: Object, default: null})
	public updateModel!: ItemUpdateAttributes | null;

	@Prop({type: Boolean, default: false})
	public edit!: boolean;

	private get label(): string | TranslateResult {
		return ItemHelper.attributeLabel(this.attribute);
	}

	private get valueFinal(): string | null {
		return ItemHelper.attributeValueFormatted(this.attribute, this.value);
	}

	private get icon(): string {
		return ItemHelper.attributeIcon(this.attribute);
	}

	private get inputValue(): string | number | ITranslatedValue | null {
		//@ts-ignore
		return this.updateModel[this.attribute];
	}

	private set inputValue(value: string | number | ITranslatedValue | null) {
		if (this.updateModel === null) return;
		//@ts-ignore
		this.updateModel[this.attribute] = value;
	}

	private createLabelWithLanguage(lang: string, label: string): string {
		const locale = LocaleHelper.localeFromLanguage(lang);
		return `${label} (${this.$t(`locale.${locale}`)})`;
	}

}
