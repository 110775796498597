var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AsiListTableLayout',{attrs:{"filter-text":_vm.filterModel.fulltext,"has-filter":_vm.hasFilter,"hide-toolbar":_vm.hideToolbar},on:{"clearFilterText":function($event){return _vm.filterTextChanged(null)},"clearSearch":_vm.clearSearch,"update:filterText":_vm.filterTextChanged},scopedSlots:_vm._u([{key:"action-buttons",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('AsiBtn',{attrs:{"icon":_vm.icons.export},on:{"click":_vm.performExport}})],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('ui.terms.exportView'))+" ")])]},proxy:true},{key:"advanced-filters",fn:function(){return [_c('AsiTextFieldSimple',{attrs:{"placeholder":_vm.$t('customer.name'),"clearable":""},model:{value:(_vm.filterModel.name),callback:function ($$v) {_vm.$set(_vm.filterModel, "name", $$v)},expression:"filterModel.name"}}),_c('AsiSelectSimple',{attrs:{"items":_vm.typeOptions,"placeholder":_vm.$t('customer.type'),"multiple":"","clearable":""},model:{value:(_vm.filterModel.types),callback:function ($$v) {_vm.$set(_vm.filterModel, "types", $$v)},expression:"filterModel.types"}}),_c('AsiSelectSimple',{attrs:{"items":_vm.stateOptions,"placeholder":_vm.$t('customer.state'),"multiple":"","clearable":""},model:{value:(_vm.filterModel.states),callback:function ($$v) {_vm.$set(_vm.filterModel, "states", $$v)},expression:"filterModel.states"}}),_c('AsiTextFieldSimple',{attrs:{"placeholder":_vm.$t('customer.notes'),"clearable":""},model:{value:(_vm.filterModel.notes),callback:function ($$v) {_vm.$set(_vm.filterModel, "notes", $$v)},expression:"filterModel.notes"}})]},proxy:true}])},[_c('AsiContentContainer',{attrs:{"no-bottom-margin":""}},[_c('v-data-table',{attrs:{"footer-props":{itemsPerPageOptions: _vm.perPageOptions},"headers":_vm.createHeaders(),"items":_vm.items,"loading":_vm.loadingInternal,"options":_vm.tableOptions,"server-items-length":_vm.total,"item-key":"id","multi-sort":""},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.openModelDetailPage},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('AsiAvatar',{attrs:{"size":32,"icon":_vm.icons.customer,"image-url":_vm.avatarUrl(item)}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(item.createdAt), 'short'))+" "+_vm._s(_vm.$d(new Date(item.createdAt), 'timeShort'))+" ")])]}},{key:"item.shippingAddress",fn:function(ref){
var item = ref.item;
return [(item.defaultShippingAddress)?_c('span',[_vm._v(" "+_vm._s(item.defaultShippingAddress.street)+" "+_vm._s(item.defaultShippingAddress.houseNumber)+" "+_vm._s(item.defaultShippingAddress.zip)+" "+_vm._s(item.defaultShippingAddress.city)+" "+_vm._s(item.defaultShippingAddress.countryCode)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('CustomerTypeChip',{attrs:{"customer":item}})]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('CustomerStateChip',{attrs:{"customer":item}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }