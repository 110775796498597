import { render, staticRenderFns } from "./GalleryDetail.vue?vue&type=template&id=43e96742&scoped=true"
import script from "./GalleryDetail.vue?vue&type=script&lang=ts"
export * from "./GalleryDetail.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43e96742",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
