










import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import MailTemplateInfoCard from "@/components/mail-template/MailTemplateInfoCard.vue";
import MailTemplateDetailEntry from "@/components/mail-template/MailTemplateDetailEntry.vue";
import {IMailTemplateAdminListEntry} from "@/models/mail-template/MailTemplateAdminModels";
import Icon from "@/plugins/icons";
import {MailType} from "@/helpers/constants";
import MailTemplateInfo from "@/models/mail-template/MailTemplateInfo";

@Component({
	components: {MailTemplateDetailEntry, MailTemplateInfoCard, AsiCardDetail}
})
export default class SettingsCardMailTemplateSampleOrder extends Vue {

	@Prop({type: Array, required: true})
	public templates!: IMailTemplateAdminListEntry[];

	private icons = Icon;

	private generalInfos(type: MailType): MailTemplateInfo[] {
		const placeHolders = [];
		if (type === MailType.mailTypeNewSampleOrder) {
			placeHolders.push(new MailTemplateInfo('{street}', 'mailTemplate.placeholders.newSampleOrder.street'));
			placeHolders.push(new MailTemplateInfo('{houseNumber}', 'mailTemplate.placeholders.newSampleOrder.houseNumber'));
			placeHolders.push(new MailTemplateInfo('{poBox}', 'mailTemplate.placeholders.newSampleOrder.poBox'));
			placeHolders.push(new MailTemplateInfo('{zip}', 'mailTemplate.placeholders.newSampleOrder.zip'));
			placeHolders.push(new MailTemplateInfo('{city}', 'mailTemplate.placeholders.newSampleOrder.city'));
			placeHolders.push(new MailTemplateInfo('{country}', 'mailTemplate.placeholders.newSampleOrder.country'));
			placeHolders.push(new MailTemplateInfo('{email}', 'mailTemplate.placeholders.newSampleOrder.email'));
			placeHolders.push(new MailTemplateInfo('{phone}', 'mailTemplate.placeholders.newSampleOrder.phone'));
			placeHolders.push(new MailTemplateInfo('{comment}', 'mailTemplate.placeholders.newSampleOrder.comment'));
			placeHolders.push(new MailTemplateInfo('{items}', 'mailTemplate.placeholders.newSampleOrder.items'));
		}

		return placeHolders;
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('delete')
	public emitDelete(id: string): string {
		return id;
	}
}
