





























































import {Component, Prop} from "vue-property-decorator";
import {IItemShop} from "@/models/item/ItemShopModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import Icon from "@/plugins/icons";
import AsiCard from "@/components/common/AsiCard.vue";
import ItemAttributes from "@/components/item/ItemAttributes.vue";
import ItemAttachments from "@/components/item/shop/ItemAttachments.vue";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import ItemRelatedItemsList from "@/components/item/shop/ItemRelatedItemsList.vue";

@Component({
	components: {ItemRelatedItemsList, ItemAttachments, ItemAttributes, AsiCard}
})
export default class ItemCardData extends mixins(ResponsiveChecks) {

	@Prop({type: Object, required: true})
	public item!: IItemShop;

	private icons = Icon;
	private expandedItems: number[] = [0, 1, 2, 3];
	private hideRelatedItems: boolean = true;

	private get descriptionFinalParts(): string[] {
		return [
			this.item.description2,
			this.item.description3,
			this.item.description4,
			this.item.description5,
		]
			.map(d => d === null ? null : TranslatedValueHelper.get(d, this.$i18n.locale, false))
			.filter(v => v !== null) as string[];
	}
}
