



























import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import ItemAttributeFilter from "@/components/item/shop/attribute-filters/ItemAttributeFilter.vue";
import VueI18n from "vue-i18n";
import Icon from "@/plugins/icons";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import {
	FilterEntryNumericSelection,
	FilterEntryNumericSelectionOption
} from "@/models/item/IItemShopPaginatedResponse";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiTextFieldSimple, ItemAttributeFilter}
})
export default class ItemAttributeFilterNumericSelection extends Vue {

	@Prop({type: String, required: true})
	public attribute!: string;

	@Prop({required: true})
	public value!: (number | null)[];

	@Prop({type: Object, required: true})
	public data!: FilterEntryNumericSelection;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private icons = Icon;
	private inputValueFromInternal: number | null = null;
	private inputValueToInternal: number | null = null;


	private get optionsSorted(): FilterEntryNumericSelectionOption[] {
		return this.data.options
			.sort((a: FilterEntryNumericSelectionOption, b: FilterEntryNumericSelectionOption) => a.name - b.name);
	}

	private get disabledInternal(): boolean {
		return this.disabled || this.data.min === this.data.max;
	}

	private get inputValueFrom(): number | null {
		return this.value[0] !== null ? this.value[0] : (this.data.min !== null ? this.data.min : 0);
	}

	private get inputValueTo(): number {
		return this.value[1] !== null ? this.value[1] : (this.data.max !== null ? this.data.max : 0);
	}

	private get sliderValue(): (number | null)[] {
		return [
			this.value[0] ?? this.data.min ?? 0,
			this.value[1] ?? this.data.max ?? 100,
		];
	}

	private set sliderValue(value: (number | null)[]) {
		this.input([value[0], value[1]]);
	}

	private optionSelected(option: FilterEntryNumericSelectionOption): boolean {
		return this.inputValueFromInternal === option.name && this.inputValueToInternal === option.name;
	}

	private setInputValue(value: number): void {
		this.inputValueFromInternal = value;
		this.inputValueToInternal = value;
		this.updateInputValue();
	}

	private setInputValueFrom(value: string | number | null) {
		if (typeof value === 'string') {
			value = Number.parseFloat(value);
			if (Number.isNaN(value)) value = null;
		}
		this.inputValueFromInternal = value;
	}

	private setInputValueTo(value: string | number | null) {
		if (typeof value === 'string') {
			value = Number.parseFloat(value);
			if (Number.isNaN(value)) value = null;
		}
		this.inputValueToInternal = value;
	}

	private updateInputValue(): void {
		let from = this.inputValueFromInternal !== null ? this.inputValueFromInternal : this.data.min;
		let to = this.inputValueFromInternal !== null ? this.inputValueToInternal : this.data.max;
		this.input([from, to]);
	}

	private get stepSize(): string {
		let maxDecimals = 0;

		this.data.options
			.map(option => option.name.toString())
			.forEach(option => {
				if (option.includes('.')) {
					const str = option.split('.')[1];

					let ret = str.length;
					for (let i = str.length-1; i >= 0; i--) {
						if (str[i] === '0') {
							ret--;
						}
					}
					if (ret > maxDecimals) {
						maxDecimals = ret;
					}
				}
			});

		return Math.pow(10, maxDecimals * -1).toString();
	}

	private get filterActive(): boolean {
		return this.value.some(v => v !== null);
	}

	private get valueFormatted(): string | TranslateResult | null {
		return `${this.value[0] ?? '?'} - ${this.value[1] ?? '?'}`;
	}

	private clear(): void {
		this.input([null, null]);
	}

	@Emit('input')
	public input(value: (number | null)[]): (number | null)[] {
		return [value[0], value[1]];
	}

}
