


























import Vue from 'vue';
import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiBtn from "./AsiBtn.vue";
import Icon from "../../plugins/icons";
import AsiSelectSimple from "@/components/common/AsiSelectSimple";
import AsiSelect from "@/components/common/AsiSelect";

@Component({
	components: {AsiSelect, AsiSelectSimple, AsiBtn}
})
export default class AsiPagination extends Vue {

	@Prop({type: Number, required: true})
	public value!: number;

	@Prop({type: Number, required: true})
	public perPage!: number;

	@Prop({type: Number, required: true})
	public total!: number;

	@Prop({type: Array, default: () => [10, 20, 50, 100]})
	public perPageOptions!: number[];

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	@Prop({type: Boolean, default: false})
	public hidePerPageOptions!: boolean;

	@Prop({type: Number, default: 10})
	public wrapOverEntries!: number;

	@Prop({type: Boolean, default: false})
	public small!: boolean;

	private icons = Icon;
	private perPageOptionsInternal: { text: string; value: number }[] = [];

	private get numPages(): number {
		return Math.ceil(this.total / this.perPage);
	}

	private get selectEntries(): object[] {
		const ret: object[] = [];
		for (let i = 0; i < this.numPages; i++) {
			ret.push({
				text: i + 1,
				value: i + 1,
			});
		}
		return ret;
	}

	public created(): void {
		this.createPerPageInternal();
	}

	@Emit('input')
	public setPage(page: number): number {
		return page;
	}

	@Emit('update:perPage')
	public perPageChanged(perPage: number): number {
		return perPage;
	}

	@Watch('perPageOptions', {immediate: true})
	private onPerPageOptionsChanged(): void {
		this.createPerPageInternal();
	}

	private createPerPageInternal(): void {
		this.perPageOptionsInternal = this.perPageOptions.map(opt => {
			return {
				text: opt.toString(),
				value: opt,
			};
		});

		if (!this.perPageOptions.includes(this.perPage)) {
			this.perPageOptionsInternal.push({
				text: this.perPage.toString(),
				value: this.perPage,
			});
		}

		this.perPageOptionsInternal = this.perPageOptionsInternal.sort((a: { text: string; value: number }, b: { text: string; value: number }) => {
			return a.value - b.value;
		});
	}

}
