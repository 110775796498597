






















import {Component, Prop} from "vue-property-decorator";
import {FrameAssemblyType} from "@/helpers/constants";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

@Component
export default class ItemCardFrameAssemblyFrame extends mixins(ResponsiveChecks) {

	@Prop({type: Number, required: true})
	public type!: FrameAssemblyType;

	@Prop({type: Number, required: true})
	public width!: number;

	@Prop({type: Number, required: true})
	public height!: number;


	private get ratio(): number {
		const gcd = (x: number, y: number): number => (y ? gcd(y, x % y) : x);
		const d = gcd(this.width, this.height);
		return this.width / d / (this.height / d);
	}

	private get heightInternal(): number {
		return this.height < this.width ? 100 / this.ratio : 100;
	}

	private get widthInternal(): number {
		return this.width < this.height ? 100 * this.ratio : 100;
	}

	private get typeClass(): string {
		switch (this.type) {
			case FrameAssemblyType.frame:
				return 'frame';
			case FrameAssemblyType.cFrame:
				return 'c-frame';
			case FrameAssemblyType.uFrame:
				return 'u-frame';
			case FrameAssemblyType.corner:
				return 'corner';
		}
		return 'frame';
	}
}
