var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AsiCard',{staticClass:"overflow-hidden",attrs:{"title":_vm.$t('cartPosition.plural'),"unwrapped":""},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.amountsInclVat),callback:function ($$v) {_vm.amountsInclVat=$$v},expression:"amountsInclVat"}},[_c('AsiBtn',{class:{'white--text': _vm.amountsInclVat},attrs:{"value":true,"small":"","color":_vm.amountsInclVat ? 'primary' : undefined}},[_vm._v(" "+_vm._s(_vm.$t('ui.terms.inclVat'))+" ")]),_c('AsiBtn',{class:{'white--text': !_vm.amountsInclVat},attrs:{"value":false,"small":"","color":!_vm.amountsInclVat ? 'primary' : undefined}},[_vm._v(" "+_vm._s(_vm.$t('ui.terms.exclVat'))+" ")])],1)]},proxy:true}])},[_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.createHeaders(),"items":_vm.positionsFinal,"options":_vm.options,"custom-sort":_vm.sortEntries,"hide-default-footer":"","disable-pagination":"","item-key":"id"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.showItem},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [(item.quantity !== null)?_c('span',[_vm._v(_vm._s(item.quantity))]):_vm._e(),(item.salesUnitOfMeasure !== null)?_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.unitLabel(item.salesUnitOfMeasure))+" ")]):_vm._e()]}},{key:"item.item",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.title))]),(item.subtitle !== null)?_c('div',{staticClass:"grey--text mt-n1"},[_c('small',[_vm._v(_vm._s(item.subtitle))])]):_vm._e(),(item.frameAssembly !== null)?_c('div',[_c('pre',[_vm._v(_vm._s(JSON.stringify(item.frameAssembly, null, 2)))])]):_vm._e()]}},{key:"item.pricePerUnit",fn:function(ref){
var item = ref.item;
return [(item.price !== null && item.quantity !== null)?_c('span',{staticClass:"grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.$n(_vm.finalAmount(item.price), { key: 'currencyDisplay', currency: item.price.currency.currencyCode, }))+" ")]):_vm._e()]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(item.price !== null)?_c('span',[_vm._v(" "+_vm._s(_vm.$n(_vm.finalAmount(item.price) * (item.quantity === null ? 1 : item.quantity), { key: 'currencyDisplay', currency: item.price.currency.currencyCode }))+" ")]):_vm._e()]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"grey lighten-5"},[_c('td',{staticClass:"font-weight-bold",attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.$t('ui.terms.total'))+" ")]),_c('td',[_vm._l(((_vm.amountsInclVat ? _vm.totalEntriesInclVat : _vm.totalEntriesExclVat)),function(total){return _c('div',{key:total.currency.currencyCode,staticClass:"d-flex flex-column align-end"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$n(total.amount, { key: 'currencyDisplay', currency: total.currency.currencyCode, }))+" ")])])}),_c('div',{staticClass:"text-right"},[_c('small',[_vm._v(_vm._s(_vm.$t(_vm.amountsInclVat ? 'ui.terms.inclVat' : 'ui.terms.exclVat')))])])],2)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }