








import Vue from 'vue';
import {Component, Prop, Watch} from "vue-property-decorator";
import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
import Icon from "@/plugins/icons";
import {IShoppingListAdmin} from "@/models/shopping-list/ShoppingListAdminModels";
import Snackbar from "@/helpers/Snackbar";
import ShoppingListTabDetails from "@/components/shopping-list/admin/ShoppingListTabDetails.vue";
@Component({
	components: {ShoppingListTabDetails, AsiPageTitle}
})
export default class ShoppingList extends Vue {

	@Prop({type: String, required: true})
	public id!: string;

	private icons = Icon;
	private loading: boolean = false;
	private shoppingList: IShoppingListAdmin | null = null;

	@Watch('id', {immediate: true})
	private onIdChanged(): void {
		this.loadCart();
	}

	private loadCart(): void {
		this.loading = true;
		this.$shoppingListServiceAdmin.shoppingList(this.id)
			.then(model => this.shoppingList = model)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loading = false);
	}
}
