











































import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import Icon from "@/plugins/icons";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";

@Component({
	components: {AsiFadeTransition, AsiAvatar}
})
export default class AsiCardDetailEntryTranslatedValue extends Vue {

	@Prop({type: Object, required: true})
	public value!: ITranslatedValue | null;

	@Prop({type: String, default: Icon.list})
	public icon!: string | null;

	@Prop({type: String, default: 'grey'})
	public iconColor!: string | null;

	@Prop({type: String, default: 'transparent'})
	public iconBgColor!: string | null;

	@Prop({type: String, default: null})
	public imageUrl!: string | null;

	@Prop({type: String, default: null})
	public label!: string | null;

	@Prop({type: String, default: null})
	public labelHint!: string | null;

	@Prop({type: Boolean, default: false})
	public hideIfEmpty!: boolean;

	@Prop({type: Boolean, default: false})
	public actionButtonOnHover!: boolean;

	private icons = Icon;
	private hover: boolean = false;

	private get hasAvatar(): boolean {
		return !!this.$slots.avatar || !!this.$scopedSlots['avatar'];
	}

	private get hasActionButton(): boolean {
		return !!this.$slots.actionButton || !!this.$scopedSlots['actionButton'];
	}

	@Emit('click')
	public click(event: Event): Event {
		return event;
	}

	private get hasClickListener(): boolean {
		return !!this.$listeners.click;
	}

}
