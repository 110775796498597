
































































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiListTableLayout from "../../common/AsiListTableLayout.vue";
import AsiContentContainer from "../../common/AsiContentContainer.vue";
import Icon from "@/plugins/icons";
import AsiListTableHeader from "@/components/common/AsiListTableHeader";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import ShipmentTypeListFilter from "@/models/shipment-type/ShipmentTypeListFilter";
import AsiListTable from "@/components/common/AsiListTable.vue";
import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
import DialogHandler from "@/components/common/DialogHandler";
import ShipmentTypeUpdateDialog from "@/components/shipment-type/admin/ShipmentTypeUpdateDialog.vue";
import Snackbar from "@/helpers/Snackbar";
import {IShipmentTypeAdminListEntry} from "@/models/shipment-type/ShipmentTypeAdminModels";
import CategoryStateChip from "@/components/category/CategoryStateChip.vue";

@Component({
	components: {
		CategoryStateChip,
		ShipmentTypeUpdateDialog, AsiConfirmDialog, AsiContentContainer, AsiListTableLayout}
})
export default class ShipmentTypeList extends AsiListTable<IShipmentTypeAdminListEntry, ShipmentTypeListFilter> implements IAsiListTableImplementation<IShipmentTypeAdminListEntry, ShipmentTypeListFilter> {

	@Prop({type: Boolean, default: false})
	public hideToolbar!: boolean;

	@Prop({type: Boolean, default: false})
	public showActions!: boolean;

	private icons = Icon;
	private initialized: boolean = false;
	private loadingDelete: boolean = false;
	private updateShipmentTypeId: string | null = null;
	private updateDialog: DialogHandler = new DialogHandler(() => this.updateShipmentTypeId = null);

	public created(): void {
		this.reload(false, false);
		this.$nextTick(() => this.initialized = true);
	}

	public createFilterModel(): ShipmentTypeListFilter {
		return new ShipmentTypeListFilter();
	}

	public openModelDetailPage(): void {
		//do nothing...
	}

	public createHeaders(): AsiListTableHeader[] {
		const ret: AsiListTableHeader[] = [
			new AsiListTableHeader(this.$t('shipmentType.name'), 'name', true, true, 'start'),
			new AsiListTableHeader(this.$t('shipmentType.postage'), 'postage', true, true, 'end', '15%'),
			new AsiListTableHeader(this.$t('shipmentType.isBulkyGoodsCharged'), 'isBulkyGoodsCharged', true, true, 'end', '15%'),
		];

		if (this.showActions) {
			ret.push(
				new AsiListTableHeader('', 'actions', false, false, 'end', '6rem'),
			);
		}

		return ret;
	}

	public fetchData(filterModel: ShipmentTypeListFilter | null, tableOptions: AsiListTableOptions | null): Promise<IPaginatedResponse<IShipmentTypeAdminListEntry>> {
		return this.$shipmentTypeServiceAdmin.shipmentTypes(filterModel, tableOptions);
	}

	public filterTextChanged(filterText: string): void {
		if (this.filterModel === null) return;
		this.filterModel.fulltext = filterText;
	}

	public reload(goToFirstPage: boolean = false, debounce: boolean = true): void {
		this.reloadInternal(this, goToFirstPage, debounce);
	}

	protected prepareOptions(options: AsiListTableOptions): AsiListTableOptions {
		if (options.sortBy.length === 0 && this.defaultSortBy.length === 0) {
			options.sortBy = ['name.de'];
			options.sortDesc = [false];
		}
		return options;
	}

	private performDelete(entry: IShipmentTypeAdminListEntry): void {
		const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
		confirm.openDialog().then((res: boolean) => {
			if (!res) return;

			const id = entry.id;
			this.loadingDelete = true;
			this.$shipmentTypeServiceAdmin.delete(id)
				.then(() => {
					this.reload(false, false);
					this.deleted(id);
				})
				.catch(() => Snackbar.deleteError())
				.finally(() => this.loadingDelete = false);
		});
	}

	@Watch('tableOptions', {deep: true})
	private onTableOptionsChanged(): void {
		if (!this.initialized) return;
		this.reload();
	}

	@Watch('filterModel', {deep: true})
	private onFilterModelChanged(): void {
		if (!this.initialized) return;
		this.reload(true);
	}

	@Emit('changed')
	public changed(id: string): string {
		return id;
	}

	@Emit('deleted')
	public deleted(id: string): string {
		return id;
	}
}
