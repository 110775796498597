












import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import ItemStateChip from "../ItemStateChip.vue";
import {IItemShop} from "@/models/item/ItemShopModels";
import {IItemAdmin} from "@/models/item/ItemAdminModels";
import Icon from "../../../plugins/icons";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import ItemUpdateDialogState from "@/components/item/admin/ItemUpdateDialogState.vue";
import DialogHandler from "@/components/common/DialogHandler";

@Component({
	components: {ItemUpdateDialogState, AsiCardDetailEntry, AsiCardDetail, ItemStateChip}
})
export default class ItemCardState extends Vue {
	@Prop({type: Object, required: true})
	public item!: IItemShop | IItemAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	@Emit('change')
	public change(): void {
		return;
	}
}
