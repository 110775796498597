

























import {Component, Emit, Prop} from "vue-property-decorator";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
import {ICartAdmin} from "@/models/cart/CartAdminModels";
import CartCardBasicData from "./CartCardBasicData.vue";
import Vue from "vue";
import CartCardPositions from "@/components/cart/admin/CartCardPositions.vue";
import AddressCardSimple from "@/components/address/AddressCardSimple.vue";
import {IAddressFields} from "@/models/address/AddressModels";
import Icon from "@/plugins/icons";

@Component({
	components: {
		AddressCardSimple,
		CartCardPositions,
		CartCardBasicData,
		AsiSkeletonCards, AsiContentContainer
	}
})
export default class CartTabDetails extends Vue {

	@Prop({required: true})
	public cart!: ICartAdmin | null;

	private icons = Icon;

	private get billingAddress(): IAddressFields | null {
		return this.cart?.customerBillingAddress ?? this.cart?.oneTimeBillingAddress ?? null;
	}

	private get shippingAddress(): IAddressFields | null {
		return this.cart?.customerShippingAddress ?? this.cart?.oneTimeShippingAddress ?? null;
	}

	@Emit('change')
	public change(): void {
		return;
	}

}
