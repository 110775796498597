





















import Vue from 'vue';
import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
import {ICartShopListEntry} from "@/models/cart/CartShopModels";
import Snackbar from "@/helpers/Snackbar";
import AsiTextarea from "@/components/common/AsiTextarea";
import AsiTextField from "@/components/common/AsiTextField";
import {ValidationRule} from "@/declarations/ValidationRule";
import CartRules from "@/models/cart/CartRules";

@Component({
	components: {AsiTextField, AsiTextarea}
})
export default class CheckoutWizardStepMisc extends Vue {

	@Prop({type: Object, required: true})
	public cart!: ICartShopListEntry;

	@Prop({type: Boolean, required: true})
	public isGuestCheckout!: boolean;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	private loadingInternal: boolean = false;

	private get oneTimeEmail(): string | null {
		return this.cart.oneTimeEmail;
	}

	private set oneTimeEmail(value: string | null) {
		this.loadingInternal = true;
		this.$store.dispatch('cart/updateOneTimeEmail', {cartId: this.cart.id, email: value})
			.catch(() => Snackbar.updateError())
			.finally(() => this.loadingInternal = false);
	}

	private get oneTimePhone(): string | null {
		return this.cart.oneTimePhone;
	}

	private set oneTimePhone(value: string | null) {
		this.loadingInternal = true;
		this.$store.dispatch('cart/updateOneTimePhone', {cartId: this.cart.id, phone: value})
			.catch(() => Snackbar.updateError())
			.finally(() => this.loadingInternal = false);
	}

	private get oneTimeEmailRules(): ValidationRule[] {
		return CartRules.oneTimeEmailRules;
	}

	private get oneTimePhoneRules(): ValidationRule[] {
		return CartRules.oneTimePhoneRules;
	}

	private get customerReference(): string | null {
		return this.cart.customerReference;
	}

	private set customerReference(value: string | null) {
		this.loadingInternal = true;
		this.$store.dispatch('cart/updateCustomerReference', {cartId: this.cart.id, customerReference: value})
			.catch(() => Snackbar.updateError())
			.finally(() => this.loadingInternal = false);
	}

	private get remark(): string | null {
		return this.cart.remark;
	}

	private set remark(value: string | null) {
		this.loadingInternal = true;
		this.$store.dispatch('cart/updateRemark', {cartId: this.cart.id, remark: value})
			.catch(() => Snackbar.updateError())
			.finally(() => this.loadingInternal = false);
	}

	@Watch('loading', {immediate: true})
	private onLoadingChanged(value: boolean): void {
		this.loadingInternal = value;
	}

	@Watch('loadingInternal')
	@Emit('loadingChanged')
	private onLoadingInternalChanged(value: boolean): boolean {
		if (this.loading !== value) this.$emit('update:loading', value);
		return value;
	}

}
