




































































import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import ShoppingListPosition from "@/components/shopping-list-position/ShoppingListPosition.vue";
import {IShoppingListShopListEntry} from "@/models/shopping-list/ShoppingListShopModels";
import ShoppingListUpdateDialogName from "@/components/shopping-list/shop/ShoppingListUpdateDialogName.vue";
import DialogHandler from "@/components/common/DialogHandler";
import Icon from "@/plugins/icons";

@Component({
	components: {ShoppingListUpdateDialogName, ShoppingListPosition, AsiBtn, AsiCard, AsiCardDetail}
})
export default class ShoppingList extends Vue {

	private icons = Icon;
	private show: boolean = false;
	private nameDialog: DialogHandler = new DialogHandler();
	private shoppingListInternal!: IShoppingListShopListEntry

	private get shoppingLists(): IShoppingListShopListEntry[] {
		return this.$store.state.shoppingList.shoppingLists;
	}

	private openDialog(shoppingList: IShoppingListShopListEntry): void {
		this.shoppingListInternal = shoppingList;
		this.nameDialog.open();
	}

	private remove(id: string): void {
		this.$store.dispatch('shoppingList/deleteShoppingList', id);
	}

	private create(): void {
		this.$store.dispatch('shoppingList/createShoppingList');
	}

}
