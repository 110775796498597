






































import {Component, Prop} from "vue-property-decorator";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import ItemHelper from "@/models/item/ItemHelper";
import ItemAttributesEntry from "@/components/item/shop/ItemAttributesEntry.vue";
import {ItemAttributeVisibility} from "@/helpers/constants";
import {IItemAdminListEntry} from "@/models/item/ItemAdminModels";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import ItemUpdateAttributes from "@/models/item/ItemUpdateAttributes";
import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import i18n from "@/plugins/i18n";

@Component({
	components: {AsiBtn, ItemAttributesEntry}
})
export default class ItemAttributes extends mixins(ResponsiveChecks) {

	@Prop({type: Object, required: true})
	public item!: IItemShopListEntry | IItemAdminListEntry;

	@Prop({type: Object, default: null})
	public updateModel!: ItemUpdateAttributes | null;

	@Prop({type: Boolean, default: false})
	public dense!: boolean;

	@Prop({type: Number, default: 1})
	public columns!: number;

	@Prop({type: Boolean, default: false})
	public edit!: boolean;

	@Prop({type: Boolean, default: false})
	public showAll!: boolean;

	private icons = Icon;

	private get attributeData(): Map<string, number | string | ITranslatedValue | null> {
		return !this.showAll
			? ItemHelper.relevantAttributes(this.item, ItemAttributeVisibility.detailViewOnly, false)
			: ItemHelper.allAttributes(this.item);
	}

	private get baseAttributeData(): Map<string, number | string | null>[] {
		const keys = Array.from(this.attributeData.keys()).slice().filter(k => ItemHelper.isBaseAttribute(k, true));

		const ret: Map<string, number | string | null>[] = [];
		const map = new Map<string, number | string | null>();
		keys
			.forEach(k => map.set(k, this.attributeData.get(k) as number | string | null));
		ret.push(map);

		return ret;
	}

	private get columnMaps(): Map<string, number | string | ITranslatedValue| null>[] {
		const keys = Array.from(this.attributeData.keys()).slice().filter(k => !ItemHelper.isBaseAttribute(k));
		const columnSize = Math.ceil(keys.length / this.columns);

		const ret: Map<string, number | string | null>[] = [];
		for (let i = 0; i < this.columns; i++) {
			const map = new Map<string, number | string | null>();
			keys
				.slice(i * columnSize, i * columnSize + columnSize)
				.forEach(k => {
					let value = this.attributeData.get(k);

					if (value !== null && typeof value === 'object') {
						let translatedValue = value as ITranslatedValue;
						value = TranslatedValueHelper.get(translatedValue, i18n.locale);
					}

					return map.set(k, value as number | string | null);
				});
			ret.push(map);
		}
		return ret;
	}

}
