import { render, staticRenderFns } from "./CategoryHeader.vue?vue&type=template&id=4424ee77&scoped=true"
import script from "./CategoryHeader.vue?vue&type=script&lang=ts"
export * from "./CategoryHeader.vue?vue&type=script&lang=ts"
import style0 from "./CategoryHeader.vue?vue&type=style&index=0&id=4424ee77&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4424ee77",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VProgressLinear,VSpacer})
