







































































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import AsiTextField from "@/components/common/AsiTextField";
import AsiCheckbox from "@/components/common/AsiCheckbox";
import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
import CategoryUpdateBasicData from "@/models/category/CategoryUpdateBasicData";
import TranslatedValueFormPart from "@/components/translated-value/admin/TranslatedValueFormPart.vue";
import EnumHelper from "@/helpers/EnumHelper";
import {CategoryView, ItemState, ItemType, StockStatus, UnitOfMeasure} from "@/helpers/constants";
import AsiSelectSimple from "@/components/common/AsiSelectSimple";
import AsiSelect from "@/components/common/AsiSelect";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import {ICategoryShopSimple} from "@/models/category/CategoryShopModels";
import i18n from "@/plugins/i18n";
import {IItemPriceShopSimple} from "@/models/item-price/ItemPriceModelsShop";
import AsiAutocomplete from "@/components/common/AsiAutocomplete";
import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
import {IAttachment} from "@/models/attachment/AttachmentModels";
import AsiBtn from "@/components/common/AsiBtn.vue";

class FakeItemShopListEntry implements IItemShopListEntry {
	hasFrameAssembly: boolean = false;
	frameAssemblyPriceTable: IItemPriceShopSimple[] | null = null;
	itemType: ItemType = ItemType.linkItem;
	remoteUrl: string | null = '';
	isTrimmingCostCharged: boolean = false;
	alternativeItemNumber: string | null = null;
	itemNumberShort: string | null = null;
	applicationRange: string | null = null;
	assemblyGroup: string | null = null;
	avatarAttachmentId: string | null = null;
	baseUnitOfMeasure: UnitOfMeasure = UnitOfMeasure.unitAssortment;
	canOrderSample: boolean = false;
	canonical: string | null = null;
	category: ICategoryShopSimple = {} as ICategoryShopSimple;
	color: ITranslatedValue | null = null;
	connectionType: ITranslatedValue | null = null;
	crossSection: number | null = null;
	deliveryTime: number | null = null;
	density: number | null = null;
	diameterMm: number | null = null;
	fireProtectionStandard: ITranslatedValue | null = null;
	fixture: string | null = null;
	foodStandard: string | null = null;
	galleryAttachments: IAttachment[] = [];
	glassThickness: number | null = null;
	grooveDepth: number | null = null;
	grooveWidth: number | null = null;
	grossWeight: number | null = null;
	hardness: number | null = null;
	hardnessType: ITranslatedValue | null = null;
	height: number | null = null;
	id: string = '';
	innerDiameter: number | null = null;
	isTopSeller: boolean = false;
	itemNumber: string = '';
	length: number | null = null;
	material: ITranslatedValue | null = null;
	name: ITranslatedValue = {} as ITranslatedValue;
	otherStandard: string | null = null;
	outerDiameter: number | null = null;
	price: IItemPriceShopSimple | null = null;
	prices: IItemPriceShopSimple[] = [];
	properties: ITranslatedValue | null = null;
	rebate: number | null = null;
	rebateSpace: number | null = null;
	resistance: string | null = null;
	ringDiameter: number | null = null;
	salesUnitOfMeasure: UnitOfMeasure = UnitOfMeasure.unitAssortment;
	salesUnitQuantity: number = 0;
	state: ItemState = ItemState.active;
	stockQuantity: number = 0;
	externalStockStatus: StockStatus = StockStatus.stockHigh;
	stockStatus: StockStatus = StockStatus.stockHigh;
	surfaceTreatment: ITranslatedValue | null = null;
	system: string | null = null;
	thread: string | null = null;
	threadLength: number | null = null;
	tolerance: ITranslatedValue | null = null;
	type: ITranslatedValue | null = null;
	width: number | null = null;
	wrenchSize: number | null = null;
}

@Component({
	components: {
		AsiBtn,
		AsiAutocomplete,
		AsiSelect,
		AsiSelectSimple, TranslatedValueFormPart, AsiTextField, AsiDialogFormLayout, AsiDialogForm, AsiCheckbox
	}
})
export default class CategoryUpdateDialogBasicData extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public category!: ICategoryAdmin;

	private model: CategoryUpdateBasicData | null = null;
	private viewOptions = EnumHelper.toSelectItems(CategoryView, true);
	private defaultSortOptions = Object.keys(new FakeItemShopListEntry()).map(key => ({
		text: i18n.t('item.' + key).toString(),
		value: key,
	})).sort((e1, e2) => e1.text.localeCompare(e2.text));
	private sortIndexSlider = 0;

	@Watch('category', {deep: true, immediate: true})
	private onCategoryChanged(): void {
		this.setCategoryInternal();
	}

	@Watch('sortIndexSlider')
	private onSortIndexSliderChanged(value: number): void {
		if (this.model === null) return;
	    this.model!.sortIndex = value;
	}

	@Watch('model.sortIndex')
	private onModelsortIndexChanged(value: number): void {
		if (value % 10 !== 0) return;
		this.sortIndexSlider = value;
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.setCategoryInternal();
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private setCategoryInternal(): void {
		if (this.model === null) {
			this.model = new CategoryUpdateBasicData(this.category);
			this.sortIndexSlider = Math.floor(this.model.sortIndex / 10) * 10;
			this.valid = true;
		} else {
			this.model.setDetailModel(this.category);
		}
	}

	private requestSave(): void {
		if (this.model === null) return;

		this.loading = true;
		this.$categoryServiceAdmin.updateBasicData(this.category.id, this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

}
