



















import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import PaymentTypeList from "@/components/payment-type/admin/PaymentTypeList.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import DialogHandler from "@/components/common/DialogHandler";
import PromotionList from "@/components/promotion/admin/PromotionList.vue";
import PromotionCreateDialog from "@/components/promotion/admin/PromotionCreateDialog.vue";

@Component({
	components: {PromotionCreateDialog, PromotionList, AsiBtn, PaymentTypeList}
})
export default class SettingsTabPromotions extends Vue {

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private createDialog: DialogHandler = new DialogHandler();

	private reloadList(): void {
		const list = this.$refs.list as PromotionList | undefined;
		if (list !== undefined) list.reload(false, false);
	}

}
