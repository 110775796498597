













import {Component} from "vue-property-decorator";
import Login from "@/views/admin/user/Login.vue";
import PrimaryNavigation from "@/views/admin/layout/partials/PrimaryNavigation.vue";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import {Routes} from "@/helpers/constants";
import TopBar from "@/components/layout/TopBar.vue";

@Component({
	components: {TopBar, PrimaryNavigation, Login}
})
export default class LayoutAdmin extends mixins(ResponsiveChecks) {

	private get isLoggedIn(): boolean {
		return this.$store.getters['user/isLoggedIn'];
	}

	private showHome(): void {
		if (this.$router.currentRoute.name === Routes.adminHome) {
			return;
		}
		this.$router.push({name: Routes.adminHome});
	}

}
