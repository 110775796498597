




















import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AsiLoginForm from "@/components/common/AsiLoginForm.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import ShopView from "@/components/layout/ShopView.vue";
import LocaleHelper from "@/helpers/LocaleHelper";
import {Tenant} from "@/models/ui/Tenant";
import EnumHelper from "@/helpers/EnumHelper";

class PrivacyParagraph {
	title!: string;
	content!: string | string[];
}

@Component({
	components: {ShopView, AsiCard, AsiLoginForm}
})
export default class PrivacyContent extends Vue {

	private data: Map<string, PrivacyParagraph[]> = new Map<string, PrivacyParagraph[]>();

	private get lang(): string {
		return this.$i18n.locale === null ? 'de' : LocaleHelper.extractLanguage(this.$i18n.locale);
	}

	private get paragraphs(): PrivacyParagraph[] {
		return this.data.get(this.lang) ?? [];
	}

	public created(): void {
		this.init();
	}

	private init(): void {
		const tenant = EnumHelper.textFromValue(Tenant, this.$isTenant, true);
		this.data.set('de', [
			{
				title: 'Haftungsausschluss',
				content: [
					'Es besteht keine Gewährleistung bezüglich der inhaltlichen Richtigkeit, Genauigkeit, Vollständigkeit und Aktualität der Informationen auf dieser Webseite. Der Inhalt wurde von der  ' + tenant + '  sorgfältig erstellt und geprüft, jedoch übernehmen wir keine Haftung für Schäden materieller oder immaterieller Art,  welche aus der Nutzung der von uns veröffentlichten Informationen entstehen.',
					'Wir bitten Sie zu beachten, dass alle Angebote unverbindlich sind. Die ' + tenant + ' kann diese, sowie auch Teile der Webseite jederzeit und ohne Vorankündigung verändern, ergänzen oder löschen.'
				]
			},
			{
				title: 'Haftung für Links',
				content: 'Wir übernehmen keine Haftung für Verweise und Links, welche auf Webseiten führen, welche nicht zur  ' + tenant + '  gehören und deren Inhalt damit nicht unserer Verantwortung unterliegt. Zugriff und Nutzung erfolgen auf eigene Gefahr.'
			},
			{
				title: 'Urheberrechte',
				content: 'Die  ' + tenant + '  besitzt die Urheber- und alle anderen Rechte, der auf dieser Webseite publizierten Inhalte. Bestehen Rechtsansprüche Dritter, werden diese speziell ausgewiesen. Ohne schriftliche Zustimmung von der  ' + tenant + '  ist das Verwenden oder Kopieren jeglicher Elemente untersagt.',
			},
			{
				title: 'Datenschutz',
				content: [
					'Jede Person hat laut Artikel 13 der schweizerischen Bundesverfassung und der datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz, DSG) Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen Daten.',
					'Die persönlichen Daten der Nutzer und Nutzerinnen unserer Webseite werden daher streng vertraulich behandelt und nicht weiter gegeben. Zudem bemühen wir uns in Zusammenarbeit mit unseren Hosting-Providern die Datenbanken vor fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.',
					'Folgende Daten werden bei Zugriffen auch unsere Webseite in Logfiles gespeichert: IP-Adresse, Datum, Uhrzeit, Browser-Anfrage und allgemein übertragene Informationen zum Betriebssystem resp. Browser. Damit entstehen anonyme Auswertungen, welche von uns zur Verbesserung unserer Webseite bzw. unseres Angebots verwendet werden können.'
				]
			},
			{
				title: 'Datenschutzerklärung für die Nutzung von Facebook-Plugins (Like Button)',
				content: [
					'Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem “Like-Button” (“Gefällt mir”) auf unserer Seite. Eine Übersicht über die Facebook-Plugins finden Sie hier: <a href="https://developers.facebook.com/docs/plugins/">developers.facebook.com/docs/plugins</a>.',
					'Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook “Like-Button” anklicken während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von facebook unter <a href="https://www.facebook.com/about/privacy/">www.facebook.com/about/privacy</a>.',
					'Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.'
				]
			},
			{
				title: 'Datenschutzerklärung für die Nutzung von Google Analytics',
				content: [
					'Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (“Google”). Google Analytics verwendet sog. “Cookies”, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.',
					'Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IPAdresse wird nicht mit anderen Daten von Google zusammengeführt.',
					'Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.'
				]
			}
		] as PrivacyParagraph[]);
		this.data.set('fr', [
			{
				title: 'Exclusion de responsabilité',
				content: [
					'Aucune garantie n\'est donnée quant à l\'exactitude, la précision, l\'intégralité et l\'actualité des informations contenues dans ce site Web. Le contenu a été élaboré et vérifié minutieusement par  ' + tenant + ' . Nous déclinons néanmoins toute responsabilité quant aux dommages matériels et immatériels découlant de l\'utilisation des informations que nous avons publiées.',
					'Veuillez noter que nos offres sont sans engagement. La société  ' + tenant + '  peut, à tout moment et sans préavis, modifier, compléter ou supprimer ces offres, ainsi que des parties du site Web.',
				],
			},
			{
				title: 'Responsabilité concernant les liens',
				content: 'Nous déclinons toute responsabilité pour les renvois et liens vers des sites Web qui n\'appartiennent pas à  ' + tenant + '  et dont le contenu ne relève pas de notre responsabilité. L\'accès et l\'utilisation se font aux propres risques des utilisateurs.',
			},
			{
				title: 'Droits d\'auteur',
				content: [
					'La société  ' + tenant + '  est propriétaire de tous les droits d\'auteur et autres droits sur les contenus publiés sur ce site Web. Les éventuels droits de tiers sont mentionnés spécifiquement. L\'utilisation et la copie de quelconques éléments sont interdites sans l\'accord écrit de  ' + tenant + ' .',
				],
			},
			{
				title: 'Protection des données',
				content: [
					'En vertu de l\'article 13 de la Constitution fédérale et des dispositions fédérales relatives à la protection des données (loi sur la protection des données, LPD), toute personne a droit à la protection de sa sphère privée et à la protection de ses données personnelles contre leur utilisation abusive.',
					'Les données des utilisateurs et utilisatrices de notre site Web sont donc traitées de manière strictement confidentielle et ne sont pas transmises. Nous nous efforçons en outre, en collaboration avec nos fournisseurs de services d\'hébergement, de protéger les bases de données contre les accès de tiers, pertes, abus ou falsifications.',
					'Lors de l\'accès à notre site Web, les données suivantes sont enregistrées dans des fichiers journaux: adresse IP, date, heure, requête navigateur et informations généralement transmises sur le système d\'exploitation et/ou le navigateur. Nous pouvons utiliser les analyses anonymes effectuées à partir de ces données pour améliorer notre site Web ou notre offre.',
				],
			},
			{
				title: 'Déclaration de protection des données pour l\'utilisation des plugins de Facebook (bouton j\'aime)',
				content: [
					'Nos pages intègrent des plugins du réseau social Facebook. Vous les identifiez avec le logo Facebook ou le bouton «like» («j\'aime») sur notre page. Vous trouverez un aperçu des plugins de Facebook ici: <a href="https://developers.facebook.com/docs/plugins/">developers.facebook.com/docs/plugins</a>',
					'Lorsque vous consultez nos sites, le plugin crée une connexion directe entre votre navigateur et le serveur Facebook. Facebook reçoit ainsi l\'information que vous avez visité notre page avec votre adresse IP. Si vous cliquez sur le bouton «j\'aime» de Facebook lorsque vous êtes connecté à votre compte Facebook, vous pouvez relier le contenu de nos sites sur votre profil Facebook. Ainsi, Facebook peut attribuer la consultation de nos pages à votre compte d\'utilisateur. Nous attirons votre attention sur le fait qu\'en tant que fournisseur, nous n\'obtenons aucune information sur le contenu des données transmises ainsi que sur leur utilisation par Facebook. Vous pouvez obtenir de plus amples informations à ce sujet en consultant la politique de confidentialité de Facebook sur <a href="https://www.facebook.com/about/privacy/">www.facebook.com/about/privacy</a>.',
					'Si vous ne souhaitez pas que Facebook puisse associer la visite de nos pages à votre compte d\'utilisateur Facebook, veuillez-vous déconnecter de votre compte d\'utilisateur Facebook.',
				],
			},
			{
				title: 'Déclaration de protection des données pour l\'utilisation de Google Analytics',
				content: [
					'Ce site Web utilise Google Analytics, un service d\'analyse web de Google Inc. («Google»). Google Analytics utilise les «cookies». Il s\'agit de fichiers texte enregistrés sur votre ordinateur et qui permettent d\'analyser votre utilisation du site Web. Les informations générées par les cookies concernant votre utilisation de ce site Web sont en général envoyées à un serveur de Google aux États-Unis où elles sont stockées. En cas d\'activation de l\'anonymisation de l\'IP sur ce site Web, Google raccourcira votre adresse IP au sein des États membres de l\'Union européenne ou dans d\'autres États signataires de l\'accord sur l\'Espace économique européen.',
					'L\'adresse IP complète ne sera envoyée à un serveur aux États-Unis et raccourcie là-bas que dans certains cas exceptionnels. Google utilisera ces informations dans le but d\'évaluer votre utilisation du site Web, pour mettre à disposition du propriétaire du site des rapports d\'activité du site et pour lui fournir d\'autres prestations de service en rapport avec l\'utilisation de son site Web et d\'Internet. Google transmettra également ces informations à des tiers le cas échéant, dans la mesure où la loi le prescrit ou des tiers traitent ces données pour le compte de Google. L\'adresse IP transmise par votre navigateur dans le cadre de Google Analytics n\'est pas combinée avec d\'autres données de Google.',
					'Vous pouvez bloquer l\'enregistrement des cookies en paramétrant votre navigateur; mais veuillez noter que, le cas échéant, vous ne pourrez pas utiliser les fonctions offertes pas ce site Web dans leur intégralité. En utilisant ce site Web, vous donnez votre accord au traitement par Google des données collectées vous concernant conformément au procédé décrit précédemment et aux fins précitées.',
				],
			},
		] as PrivacyParagraph[]);
		this.data.set('en', [
			{
				title: 'Exclusion of liability',
				content: [
					'We provide no guarantee as to the correctness, accuracy, completeness and timeliness of the information on this website. The content has been carefully prepared and checked by  ' + tenant + ' , but we accept no liability for material or immaterial damage arising from the use of the information published by us.',
					'Please note that all offers are non-binding.  ' + tenant + '  may change, supplement or delete this website as well as parts thereof at any time and without prior notice.'
				]
			},
			{
				title: 'Liability for links',
				content: 'We accept no liability for references and links to websites that do not belong to  ' + tenant + '  and whose content is therefore not our responsibility. Access and use are at your own risk.'
			},
			{
				title: 'Copyrights',
				content: ' ' + tenant + '  owns the copyright and all other rights to the content published on this website. Any existing legal claims for third parties are specifically identified as such. The use or copying of any elements is prohibited without the written consent of  ' + tenant + ' .',
			},
			{
				title: 'Data protection',
				content: [
					'Pursuant to Article 13 of the Swiss Federal Constitution and Federal Data Protection Act (Datenschutzgesetz, DSG), every person is entitled to the protection of their privacy and to protection against misuse of their personal data.',
					'We therefore treat our website users’ personal data in the strictest confidence and do not pass this on. In addition, we work together with our hosting providers to protect the databases from unauthorised access, loss, misuse or forgery.',
					'The following data is also stored in log files when our website is accessed: IP address, date, time, browser request and generally transmitted information about the operating system or browser. Anonymous evaluations are created from these, which we can use to improve our website or our offer.'
				]
			},
			{
				title: 'Privacy policy for the use of Facebook plug-ins (Like Button)',
				content: [
					'Our web pages include plug-ins of the social network Facebook. You can recognise the Facebook plug-ins on our website by the Facebook logo or the “Like” button. An overview of the Facebook plug-ins can be found here: <a href="https://developers.facebook.com/docs/plugins/">developers.facebook.com/docs/plugins</a>.',
					'When you visit our web pages, the plug-in establishes a direct connection between your browser and the Facebook server. This informs Facebook that you visited our website using your IP address. If you click on the Facebook “Like” button while logged in to your Facebook account, you can link the contents of our website to your Facebook profile. This allows Facebook to allocate your visit to our web pages to your user account. We would like to point out that we as the site provider have no knowledge regarding the content of the data transmitted and its use by Facebook. Further information about this is available in the Facebook privacy policy at <a href="https://www.facebook.com/about/privacy/">www.facebook.com/about/privacy</a>.',
					'If you do not wish Facebook to associate your visit to our website with your Facebook user account, please log out of your Facebook user account.'
				]
			},
			{
				title: 'Privacy policy for the use of Google Analytics',
				content: [
					'This website makes use of Google Analytics, a web analysis service from Google Inc. (“Google”). Google Analytics uses so-called ‘cookies,’ i.e. text files which are stored on your computer and permit analysis of the use of the website by you. The information generated by the cookie about your use of this website is generally transferred to a Google server in the USA and stored there. However, if IP anonymisation is activated on this website, your IP address will be abbreviated by Google beforehand within member states of the European Union or in other signatory states of the Agreement on the European Economic Area.',
					'Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there. Google will use this information to evaluate your use of the website, to compile reports on website activity for the website operators and to provide other services related to website activity and Internet usage. Google may also transfer this information to third parties if required by law or where third parties process this data on behalf of Google. The IP address transmitted by your browser as part of Google Analytics is not combined with other data from Google.',
					'You can prevent the installation of cookies by configuring your browser software accordingly; however, we would like to point out that in this case you may not be able to use all the features of this website in their entirety. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.'
				]
			},
		] as PrivacyParagraph[]);
	}
}
