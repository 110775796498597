






















import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import Icon from "@/plugins/icons";
import AsiBtn from "@/components/common/AsiBtn.vue";

@Component({
	components: {AsiBtn}
})
export default class AsiStepperNavigation extends Vue {

	@Prop({type: Boolean, default: false})
	public hidePrev!: boolean;

	@Prop({type: Boolean, default: false})
	public hideNext!: boolean;

	@Prop({type: Boolean, default: false})
	public prevDisabled!: boolean;

	@Prop({type: Boolean, default: false})
	public nextDisabled!: boolean;

	@Prop({type: Boolean, default: false})
	public small!: boolean;

	@Prop({type: String, default: undefined})
	public prevColor!: string | undefined;

	@Prop({type: String, default: 'primary'})
	public nextColor!: string | undefined;

	@Prop({type: Boolean, default: false})
	public hideLabels!: boolean;

	private icons = Icon;

	private get hasPrepend(): boolean {
		return !!this.$slots['prepend'];
	}

	private get hasAppend(): boolean {
		return !!this.$slots['append'];
	}

	@Emit('prev')
	public prev(): void {
		return;
	}

	@Emit('next')
	public next(): void {
		return;
	}

}
