





































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import TranslatedValueFormPart from "@/components/translated-value/admin/TranslatedValueFormPart.vue";
import AsiTextField from "@/components/common/AsiTextField";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import {IItemAdmin} from "@/models/item/ItemAdminModels";
import ItemUpdateBasicData from "@/models/item/ItemUpdateBasicData";
import {ItemType} from "@/helpers/constants";
@Component({
	components: {AsiTextField, TranslatedValueFormPart, AsiDialogFormLayout}
})
export default class ItemUpdateDialogBasicData extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public item!: IItemAdmin;

	private model: ItemUpdateBasicData | null = null;

	private itemType = ItemType;

	@Watch('item', {deep: true, immediate: true})
	private onItemChanged(): void {
		this.setItemInternal();
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.setItemInternal();
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private setItemInternal(): void {
		if (this.model === null) {
			this.model = new ItemUpdateBasicData(this.item);
			this.valid = true;
		} else {
			this.model.setDetailModel(this.item);
		}
	}

	private requestSave(): void {
		if (this.model === null) return;
		this.loading = true;
		this.$itemServiceAdmin.updateBasicData(this.item.id, this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}
}
