import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import i18n from './plugins/i18n';
import './scss/global.scss';
import './scss/editor.scss';
import './plugins/services';
import './plugins/tenants';
import './plugins/dynamicComponents';
import './plugins/countries';
import './plugins/gtm';

Vue.config.productionTip = false;
new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: (createElement) => createElement(App),
}).$mount('#app', true);