








































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogFormLayout from "../common/AsiDialogFormLayout.vue";
import AsiTextField from "../common/AsiTextField";
import AsiTextarea from "../common/AsiTextarea";
import MailTemplateUpdate from "../../models/mail-template/MailTemplateUpdate";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import {AxiosError} from "axios";
import AsiBtn from "@/components/common/AsiBtn.vue";
import {IMailTemplateAdminSimple} from "@/models/mail-template/MailTemplateAdminModels";

@Component({
	components: {AsiBtn, AsiTextarea, AsiTextField, AsiDialogFormLayout}
})
export default class MailTemplateUpdateDialog extends AsiDialogForm {

	@Prop({type: String, default: null})
	public subtitle!: string | null;

	@Prop({type: Object, required: true})
	public model!: IMailTemplateAdminSimple;

	private updateModel: MailTemplateUpdate | null = null;
	private uploadedFile: File | null = null;

	public created(): void {
		this.setMailTemplateInternal();
	}

	private setMailTemplateInternal(): void {
		if (this.updateModel === null) {
			this.updateModel = new MailTemplateUpdate(this.model);
		} else {
			this.updateModel.setDetailModel(this.model);
		}
	}

	private requestSave(): void {

		if (!this.valid) return;

		if (this.updateModel === null) return;

		this.loading = true;
		this.$mailTemplateServiceAdmin.update(this.model.id, this.updateModel)
			.then(this.save)
			.catch((err: AxiosError) => this.errors = err.response?.data)
			.finally(() => this.loading = false);
	}

	private onFileChanged(e: any): void {
		this.uploadedFile = e.target.files[0];
	}

	private clearHtmlInput(): void {
		if (this.updateModel === null) return;
		this.updateModel.htmlTemplate = "";
	}

	@Watch('uploadedFile')
	private onUploadedFileChanged(value: File): void {
		if (value !== null) {
			const reader = new FileReader();
			reader.onload = (res) => {
				if (this.updateModel !== null && res.target !== null) {
					this.updateModel.htmlTemplate = res.target.result as string;
				}
			};
			reader.readAsText(value);
		}
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

}
