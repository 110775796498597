


















import {Component, Emit, Prop} from "vue-property-decorator";
import ItemQuantitySelection from "./ItemQuantitySelection.vue";
import Icon from "../../../plugins/icons";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import {IItemPriceShopSimple} from "@/models/item-price/ItemPriceModelsShop";
import Vue from "vue";
import TotalHelper from "@/helpers/TotalHelper";
import {IPrice} from "@/models/price/PriceModels";

@Component({
	components: {ItemQuantitySelection}
})
export default class ItemQuantitySelectionWithPrice extends Vue {

	@Prop({type: Number, required: true})
	public value!: number;

	@Prop({type: Object, required: true})
	public item!: IItemShopListEntry;

	@Prop({type: Number, default: 1})
	public step!: number;

	private icons = Icon;

	private get sortedPrices(): IItemPriceShopSimple[] {
		return this.item.prices.map(p => p).sort((a: IItemPriceShopSimple, b: IItemPriceShopSimple) => b.fromQuantity - a.fromQuantity);
	}

	private get currencyCode(): string | null {
		return this.item.price?.price.currency.currencyCode ?? null;
	}

	private get price(): IPrice | null {
		if (this.value === 0) return null;

		return this.sortedPrices
			.map(p => p)
			.find(p => p.fromQuantity <= (this.value as number))?.price ?? null;
	}

	private get priceTotal(): number | null {
		if (this.price === null) return null;

		const amount = this.price.amount + (this.amountsInclVat ? TotalHelper.vatAmount(this.price) : 0);
		return this.value * amount;
	}

	private get amountsInclVat(): boolean {
		return this.$store.state.ui.amountsInclVat;
	}

	@Emit('input')
	public input(value: number): number {
		return value;
	}
}
