


































































































import Vue from 'vue';
import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
import {Permissions, Roles} from "@/helpers/constants";
import Snackbar from "@/helpers/Snackbar";
import AsiCard from "@/components/common/AsiCard.vue";
import EnumHelper from "@/helpers/EnumHelper";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import VueI18n from "vue-i18n";
import IUserServiceAdmin from "@/services/definition/IUserServiceAdmin";
import IUserServiceShop from "@/services/definition/IUserServiceShop";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiBtn, AsiCard}
})
export default class UserCardRights extends Vue {

	@Prop({type: String, required: true})
	public id!: string;

	@Prop({type: Object, required: true})
	public service!: IUserServiceAdmin | IUserServiceShop;

	@Prop({type: Array, default: () => []})
	public roles!: Roles[];

	@Prop({type: Array, default: () => []})
	public assignedRoles!: Roles[];

	@Prop({type: Array, default: () => []})
	public permissions!: Permissions[];

	@Prop({type: Array, default: () => []})
	public assignedPermissions!: Permissions[];

	@Prop({type: String, default: null})
	public title!: string | null;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	@Prop({type: Boolean, default: false})
	public hideTypeHints!: boolean;

	private icons = Icon;
	private loadingInternal: boolean = false;

	private get isOwnUser(): boolean {
		return this.$store.getters['user/userId'] === this.id;
	}

	private get roleOptions(): { value: string | number, text: string }[] {
		return EnumHelper
			.toSelectItems(Roles, true)
			.filter(r => this.roles.includes(r.value as Roles));
	}

	private get permissionOptions(): { value: string | number, text: string }[] {
		return EnumHelper
			.toSelectItems(Permissions, true)
			.filter(p => this.permissions.includes(p.value as Permissions));
	}

	@Watch('loading', {immediate: true})
	private onLoadingChanged(value: boolean): void {
		this.loadingInternal = value;
	}

	@Watch('loadingInternal')
	private onLoadingInternalChanged(value: boolean): void {
		if (this.loading !== value) this.$emit('update:loading', value);
	}

	@Emit('change')
	public change(): void {
		return;
	}

	private hasRole(role: Roles): boolean {
		return this.hasRoleExplicit(role) || this.hasRoleImplicit(role);
	}

	private hasRoleExplicit(role: Roles): boolean {
		return this.assignedRoles.includes(role);
	}

	private hasRoleImplicit(role: Roles): boolean {
		return role !== Roles.roleAdmin && this.hasRoleExplicit(Roles.roleAdmin);
	}

	private toggleRole(role: Roles): void {
		if (!this.roles.includes(role)) {
			return;
		}

		this.loadingInternal = true;
		(this.hasRole(role) ? this.service.revokeRole(this.id, role) : this.service.assignRole(this.id, role))
			.then(() => this.change())
			.catch(() => Snackbar.updateError())
			.finally(() => {
				this.loadingInternal = false;
				this.change();
			});
	}

	private hasPermission(permission: Permissions): boolean {
		return this.hasPermissionExplicit(permission) || this.hasPermissionImplicit(permission);
	}

	private hasPermissionExplicit(permission: Permissions): boolean {
		return this.assignedPermissions.includes(permission);
	}

	private hasPermissionImplicit(permission: Permissions): boolean {
		switch (permission) {
			case Permissions.rightCustomerManager:
				return false;
			default:
				return this.hasRoleExplicit(Roles.roleAdmin);
		}
	}

	private togglePermission(permission: Permissions): void {
		if (!this.permissions.includes(permission)) {
			return;
		}

		this.loadingInternal = true;

		(this.hasPermission(permission) ? this.service.revokePermission(this.id, permission) : this.service.assignPermission(this.id, permission))
			.then(() => this.change())
			.catch(() => Snackbar.updateError())
			.finally(() => {
				this.loadingInternal = false;
				this.change();
			});
	}

	private hint(value: Roles | Permissions): string | TranslateResult | null {
		switch (value) {
			case Roles.roleAdmin:
				return this.$t('right.role.hints.admin');
			case Roles.roleMasterData:
				return this.$t('right.role.hints.masterData');
			case Roles.roleMetaData:
				return this.$t('right.role.hints.metaData');
			case Roles.roleFinance:
				return this.$t('right.role.hints.finance');
			case Permissions.rightBackendUser:
				return this.$t('right.permission.hints.backendUser');
			case Permissions.rightCustomerManager:
				return this.$t('right.permission.hints.customerManager');
			default:
				return null;
		}
	}

}
