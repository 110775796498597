import { render, staticRenderFns } from "./ItemAttributeFilterSelection.vue?vue&type=template&id=c6f50dc6&scoped=true"
import script from "./ItemAttributeFilterSelection.vue?vue&type=script&lang=ts"
export * from "./ItemAttributeFilterSelection.vue?vue&type=script&lang=ts"
import style0 from "./ItemAttributeFilterSelection.vue?vue&type=style&index=0&id=c6f50dc6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6f50dc6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCheckbox,VDivider,VList,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemTitle})
