















import Vue from 'vue';
import {Component, Prop, Watch} from "vue-property-decorator";
import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import VueI18n, {IVueI18n} from "vue-i18n";
import {State} from "@/store";
import {Store} from "vuex";
import CategoryHelper from "@/models/category/CategoryHelper";
import AsiCard from "@/components/common/AsiCard.vue";
import {RawLocation} from "vue-router";

class CategoryTreeItem {
	public readonly id: string;
	public readonly name: string;
	public readonly children: CategoryTreeItem[];
	public readonly model: ICategoryShopListEntry;
	public readonly detailRoute: RawLocation;

	public constructor(model: ICategoryShopListEntry, store: Store<State>, i18n: VueI18n & IVueI18n) {
		const childCategories: ICategoryShopListEntry[] = store.getters['category/categoryChildren'](model.id);

		this.id = model.id;
		this.name = TranslatedValueHelper.get(model.name, i18n.locale) ?? '?';
		this.children = childCategories.map(c => new CategoryTreeItem(c, store, i18n));
		this.model = model;
		this.detailRoute = CategoryHelper.detailRoute(CategoryHelper.currentCanonical(model.canonical));
	}
}

@Component({
	components: {AsiCard}
})
export default class CategoryTree extends Vue {

	@Prop({default: null})
	public title!: string | null;

	@Prop({default: null})
	public topLevelCategories!: ICategoryShopListEntry[] | null;

	@Prop({type: String, default: null})
	public activeCategoryId!: string | null;

	private topLevelCategoriesInternal: ICategoryShopListEntry[] = [];
	private openNodes: string[] = [];
	private activeNodes: string[] = [];

	private get items(): CategoryTreeItem[] {
		return this.topLevelCategoriesInternal.map(c => new CategoryTreeItem(c, this.$store, this.$i18n));
	}

	@Watch('activeCategoryId', {immediate: true})
	private onActiveCategoryIdChanged(value: string | null): void {
		if (value === null) return;
		const cat = this.$store.getters['category/categoryById'](this.activeCategoryId) as ICategoryShopListEntry | undefined;
		if (cat === undefined || cat === null) return;

		this.openNodes = cat.path.map(c => c.id).concat(cat.id);
		this.activeNodes = cat.path.map(c => c.id).concat(cat.id);
	}

	@Watch('activeNodes')
	private onActiveNodesChanged(ids: string[]): void {
		if (ids.length > 0) this.showCategory(ids[ids.length - 1]);
	}

	@Watch('topLevelCategories', {immediate: true})
	private onTopLevelChanged(): void {
		this.topLevelCategoriesInternal = this.topLevelCategories ?? this.$store.getters['category/rootCategories'];
	}

	private showCategory(id: string): void {
		if (this.activeCategoryId === id) return;
		const category = this.$store.getters['category/categoryById'](id);
		this.$router.push(CategoryHelper.detailRoute(CategoryHelper.currentCanonical(category.canonical)));
	}

}
