



































































import {Component, Prop, Watch} from "vue-property-decorator";
import Icon from "@/plugins/icons";
import Snackbar from "@/helpers/Snackbar";
import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
import UserList from "@/components/user/admin/UserList.vue";
import AsiTabs from "@/components/common/AsiTabs";
import CustomerTypeChip from "@/components/customer/CustomerTypeChip.vue";
import CustomerTabDetails from "@/components/customer/admin/CustomerTabDetails.vue";
import CustomerTabAddresses from "@/components/customer/admin/CustomerTabAddresses.vue";
import RightChecks from "@/mixins/RightChecks.vue";
import {mixins} from "vue-class-component";
import CartList from "@/components/cart/admin/CartList.vue";
import OrderList from "@/components/order/admin/OrderList.vue";
import DialogHandler from "@/components/common/DialogHandler";
import AsiMenuItem from "@/components/common/AsiMenuItem.vue";
import CustomerMergeDialog from "@/components/customer/admin/CustomerMergeDialog.vue";
import CustomerHelper from "@/models/customer/CustomerHelper";
import UserCreateDialog from "@/components/user/UserCreateDialog.vue";

@Component({
	components: {
		UserCreateDialog,
		CustomerMergeDialog,
		AsiMenuItem,
		OrderList,
		CartList,
		CustomerTabAddresses,
		CustomerTabDetails, CustomerTypeChip, AsiTabs, UserList, AsiContentContainer, AsiPageTitle
	}
})
export default class Customer extends mixins(RightChecks) {

	@Prop({type: String, required: true})
	public id!: string;

	private icons = Icon;
	private loading: boolean = false;
	private customer: ICustomerAdmin | null = null;
	private mergeDialog: DialogHandler = new DialogHandler();
	private userCreateDialog: DialogHandler = new DialogHandler();

	private get name(): string | null {
		return this.customer?.name ?? null;
	}

	private get activeTab(): number {
		return this.$store.getters['ui/activeTabAdmin']('customer');
	}

	private set activeTab(index: number) {
		this.$store.commit('ui/activeTabAdmin', {key: 'customer', activeTab: index});
	}

	@Watch('id', {immediate: true})
	private onIdChanged(): void {
		this.loadCustomer();
	}

	private customerChanged(): void {
		if (this.id === this.$store.getters['user/modelCustomer']?.id) {
			this.$store.dispatch('user/loadLoggedInUserData');
		}
		this.loadCustomer();
	}

	private loadCustomer(): void {
		this.loading = true;
		this.$customerServiceAdmin.customer(this.id)
			.then(model => this.customer = model)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loading = false);
	}

	private merged(targetId: string): void {
		this.mergeDialog.close();
		this.$router.push(CustomerHelper.detailRouteAdmin(targetId));
	}

	private userCreated(): void {
		this.userCreateDialog.close();

		let list = this.$refs.userList;
		if (list !== undefined) {
			(this.$refs.userList as UserList).reload();
		}
	}

}
