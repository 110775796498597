














import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import Faqs from "@/components/faq/Faqs.vue";
import ShopView from "@/components/layout/ShopView.vue";
import AsiCard from "@/components/common/AsiCard.vue";

@Component({
	components: {AsiCard, ShopView, Faqs}
})
export default class Faq extends Vue {

}
