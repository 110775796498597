




















import Vue from 'vue';
import {Component, Prop, Watch} from "vue-property-decorator";
import ItemList from "@/components/item/admin/ItemList.vue";
import ItemListFilterAdmin from "@/models/item/ItemListFilterAdmin";
import {IItemAdminListEntry} from "@/models/item/ItemAdminModels";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import Snackbar from "@/helpers/Snackbar";

@Component({
	components: {AsiBtn, ItemList}
})
export default class ItemTabRelatedItems extends Vue {
	@Prop({type: String, required: true})
	public itemId!: string;

	private icons = Icon;

	private relatedItems: IItemAdminListEntry[] = [];
	private relatedItemsFilter = new ItemListFilterAdmin();

	private loadRelatedItems(): void {
		this.$itemServiceAdmin.items(this.relatedItemsFilter, null)
			.then(res => this.relatedItems = res.data);
	}

	private processRelatedItem(selectedItemIds: string[]): void {
		if (selectedItemIds.length > this.relatedItems.length) {
			// add missing item
			selectedItemIds
				.filter(itemId => !this.relatedItems.map(i => i.id).includes(itemId))
				.forEach(itemId => {
					this.addRelatedItem(itemId);
				});
		} else if (selectedItemIds.length < this.relatedItems.length) {
			// remove item
			this.relatedItems
				.map(i => i.id)
				.filter(itemId => !selectedItemIds.includes(itemId))
				.forEach(itemId => {
					this.removeRelatedItem(itemId);
				});
		}
	}

	private addRelatedItem(itemId: string): void {
		this.$itemServiceAdmin.addRelatedItem(this.itemId, itemId)
			.then(() => {
				(this.$refs.itemList as ItemList).reload();
				this.loadRelatedItems();
			})
			.catch(() => {
				Snackbar.updateError();
				this.loadRelatedItems();
			});
	}

	private removeRelatedItem(itemId: string): void {
		this.$itemServiceAdmin.deleteRelatedItem(this.itemId, itemId)
			.then(() => {
				(this.$refs.itemList as ItemList).reload();
				this.loadRelatedItems();
			});
	}

	@Watch('itemId', {immediate: true})
	private onItemIdChanged(value: string): void {
		this.relatedItemsFilter.relatedToItemIds = [];
		this.relatedItemsFilter.relatedToItemIds.push(value);
		this.loadRelatedItems();
	}
}
