

















import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import {ValidationRule} from "@/declarations/ValidationRule";
import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
import {CustomerType} from "@/helpers/constants";
import EnumHelper from "@/helpers/EnumHelper";
import AsiSelect from "@/components/common/AsiSelect";
import CustomerRules from "@/models/customer/CustomerRules";

@Component({
	components: {AsiSelect, AsiDialogFormLayout}
})
export default class CustomerUpdateDialogType extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public customer!: ICustomerAdmin;

	private type: CustomerType | null = null;

	// noinspection JSMethodCanBeStatic
	private get options(): { text: string, value: string | number }[] {
		return EnumHelper.toSelectItems(CustomerType, true);
	}

	// noinspection JSMethodCanBeStatic
	private get rules(): ValidationRule[] {
		return CustomerRules.typeRules;
	}

	@Watch('customer.type', {immediate: true})
	private onCustomerTypeChanged(value: CustomerType): void {
		this.type = value;
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.type = this.customer.type;
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private requestSave(): void {
		if (this.type === null) return;

		this.loading = true;
		this.$customerServiceAdmin.updateType(this.customer.id, this.type)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

}
