









import {Component} from "vue-property-decorator";
import AsiLoginForm from "@/components/common/AsiLoginForm.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import Icon from "@/plugins/icons";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

@Component({
	components: {AsiCard, AsiLoginForm}
})
export default class Login extends mixins(ResponsiveChecks) {

	private icons = Icon;
	private loading: boolean = false;

}
