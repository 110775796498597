var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AsiCard',{staticClass:"overflow-hidden",attrs:{"title":_vm.labelFinal,"icon":_vm.iconFinal,"no-bottom-margin":_vm.noBottomMargin,"unwrapped":"","loading":_vm.loading},scopedSlots:_vm._u([(!_vm.readonly)?{key:"headerActions",fn:function(){return [_c('div',{staticClass:"d-flex flex-row flex-wrap"},[_c('AsiBtn',{attrs:{"disabled":_vm.disabled || _vm.loading,"icon":_vm.icons.edit,"small":""},on:{"click":function($event){return _vm.updateDialog.open()}}}),_c('AsiBtn',{attrs:{"disabled":_vm.disabled || _vm.loading,"icon":_vm.icons.delete,"small":""},on:{"click":_vm.requestDelete}})],1)]},proxy:true}:null],null,true)},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"px-6",attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-content',[_c('v-list-item-title',[_c('address',{staticClass:"body-2"},[_vm._l((_vm.lines),function(line,i){return [_c('span',{key:(i + "-content"),class:{'font-weight-bold': i === 0}},[_vm._v(" "+_vm._s(line)+" ")]),(i < _vm.lines.length - 1)?_c('br',{key:(i + "-linebreak")}):_vm._e()]})],2)])],1)],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('AsiBtn',{attrs:{"icon":_vm.icons.map},on:{"click":_vm.openMap}})],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('ui.terms.showOnMap'))+" ")])],1)],1)],1),(!_vm.readonly)?[_c('v-divider'),_c('div',{staticClass:"grey lighten-4 text-center d-flex flex-row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"default-btn d-flex flex-row align-center justify-center",class:{'success': _vm.isDefaultShippingAddress}},'div',attrs,false),on),[(!_vm.readonly && !_vm.isDefaultShippingAddress)?_c('AsiBtn',{attrs:{"disabled":_vm.loading,"icon":_vm.icons.shipping,"color":"transparent","depressed":"","block":"","tile":"","prevent-icon-mode":""},on:{"click":_vm.requestDefaultShippingAddress}}):_c('v-icon',{attrs:{"color":_vm.isDefaultShippingAddress ? 'white' : undefined}},[_vm._v(" "+_vm._s(_vm.icons.shipping)+" ")])],1)]}}],null,false,4200497853)},[_vm._v(" "+_vm._s(_vm.$t(_vm.isDefaultShippingAddress ? 'address.terms.defaultShippingAddress' : 'address.terms.setDefaultShippingAddress'))+" ")]),_c('v-divider',{staticStyle:{"z-index":"1"},attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"default-btn d-flex flex-row align-center justify-center",class:{'success': _vm.isDefaultBillingAddress}},'div',attrs,false),on),[(!_vm.readonly && !_vm.isDefaultBillingAddress)?_c('AsiBtn',{attrs:{"disabled":_vm.loading,"icon":_vm.icons.currency,"color":"transparent","depressed":"","block":"","tile":"","prevent-icon-mode":""},on:{"click":_vm.requestDefaultBillingAddress}}):_c('v-icon',{attrs:{"color":_vm.isDefaultBillingAddress ? 'white' : undefined}},[_vm._v(" "+_vm._s(_vm.icons.currency)+" ")])],1)]}}],null,false,3435228029)},[_vm._v(" "+_vm._s(_vm.$t(_vm.isDefaultBillingAddress ? 'address.terms.defaultBillingAddress' : 'address.terms.setDefaultBillingAddress'))+" ")])],1)]:_vm._e(),(!_vm.readonly)?_c('AsiConfirmDialog',{ref:"confirm"}):_vm._e(),(!_vm.readonly && _vm.updateDialog.isLoaded)?_c('AddressUpdateDialog',{attrs:{"open":_vm.updateDialog.isOpen,"service":_vm.service,"customer-id":_vm.customerId,"address":_vm.address,"business":_vm.business,"allow-type-selection":_vm.allowTypeSelection},on:{"save":function($event){_vm.changed(); _vm.updateDialog.close();},"cancel":function($event){return _vm.updateDialog.close()}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }