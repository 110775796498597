




















import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import RightChecks from "@/mixins/RightChecks.vue";
import AsiAlert from "@/components/common/AsiAlert.vue";
import RoutesHelper from "@/helpers/RoutesHelper";
import ShopView from "@/components/layout/ShopView.vue";
import Icon from "@/plugins/icons";
import RegistrationWizard from "@/components/user/shop/RegistrationWizard.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";

@Component({
	components: {AsiBtn, RegistrationWizard, ShopView, AsiAlert}
})
export default class Registration extends mixins(RightChecks) {

	private icons = Icon;

	private showHome(): void {
		this.$router.push(RoutesHelper.homeShopRoute());
	}

}
