














import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import ItemAttributeFilter from "@/components/item/shop/attribute-filters/ItemAttributeFilter.vue";
import VueI18n from "vue-i18n";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import {FilterEntryNumeric} from "@/models/item/IItemShopPaginatedResponse";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiTextFieldSimple, AsiBtn, ItemAttributeFilter}
})
export default class ItemAttributeFilterNumeric extends Vue {

	@Prop({type: String, required: true})
	public attribute!: string;

	@Prop({required: true})
	public value!: (number | null)[];

	@Prop({type: Object, required: true})
	public data!: FilterEntryNumeric;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private icons = Icon;
	private maxPrecision: number = 3;
	private precision: number = 0;
	private inputValueFromInternal: number | null = null;
	private inputValueToInternal: number | null = null;

	public mounted(): void {
	    this.updatePrecision();
	}

	private get disabledInternal(): boolean {
		return this.disabled || this.data.min === this.data.max;
	}

	private get inputValueFrom(): number | null {
		return this.value[0] !== null ? this.value[0] : (this.data.min !== null ? this.data.min : 0);
	}

	private get inputValueTo(): number {
		return this.value[1] !== null ? this.value[1] : (this.data.max !== null ? this.data.max : 0);
	}

	private get sliderValue(): (number | null)[] {
		return [
			this.value[0] ?? this.data.min ?? 0,
			this.value[1] ?? this.data.max ?? 100,
		];
	}

	private set sliderValue(value: (number | null)[]) {
		this.input([value[0], value[1]]);
		this.updatePrecision();
	}

	private get stepSize(): string {
		return Math.pow(10, this.precision * -1).toString();
	}

	private setInputValueFrom(value: string | number | null) {
		if (typeof value === 'string') {
			value = Number.parseFloat(value);
			if (Number.isNaN(value)) value = null;
		}
		this.inputValueFromInternal = value;
	}

	private setInputValueTo(value: string | number | null) {
		if (typeof value === 'string') {
			value = Number.parseFloat(value);
			if (Number.isNaN(value)) value = null;
		}
		this.inputValueToInternal = value;
	}

	private updateInputValue(): void {
		let from = this.inputValueFromInternal !== null ? this.inputValueFromInternal : this.data.min;
		let to = this.inputValueFromInternal !== null ? this.inputValueToInternal : this.data.max;
		this.input([from, to]);
		this.updatePrecision();
	}

	private updatePrecision(): void {
		const numbers = [
			this.data.min ?? 0,
			this.data.max ?? 0,
			this.value[0] ?? 0,
			this.value[1] ?? 0,
		].filter(n => n !== 0) as number[];
		this.precision = numbers
			.map(n => {
				let str = n.toFixed(this.maxPrecision);
				const start = str.indexOf('.') + 1;
				str = str.substring(start, start + 3);

				let ret = 0;
				for (let i = 0; i < 3; i++) {
					if (str[i] === '0') break;
					ret++;
				}
				return ret;
			})
			.reduce((max: number, cur: number) => {
				return cur > max ? cur : max;
			}, 0);
	}

	private get filterActive(): boolean {
		return this.value.some(v => v !== null);
	}

	private get valueFormatted(): string | TranslateResult | null {
		return `${this.value[0] ?? '?'} - ${this.value[1] ?? '?'}`;
	}

	private clear(): void {
		this.input([null, null]);
	}

	@Emit('input')
	public input(value: (number | null)[]): (number | null)[] {
		return [value[0], value[1]];
	}

}
