










import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import MailTemplateDetailEntry from "@/components/mail-template/MailTemplateDetailEntry.vue";
import MailTemplateInfoCard from "@/components/mail-template/MailTemplateInfoCard.vue";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import {IMailTemplateAdminListEntry} from "@/models/mail-template/MailTemplateAdminModels";
import Icon from "@/plugins/icons";
import {MailType} from "@/helpers/constants";
import MailTemplateInfo from "@/models/mail-template/MailTemplateInfo";

@Component({
	components: {AsiCardDetail, MailTemplateInfoCard, MailTemplateDetailEntry}
})
export default class SettingsTabMailTemplatesContact extends Vue {

	@Prop({type: Array, required: true})
	public templates!: IMailTemplateAdminListEntry[];

	private icons = Icon;

	private generalInfos(type: MailType): MailTemplateInfo[] {
		const placeHolders = [];
		if (type === MailType.mailTypeContactForm) {
			placeHolders.push(new MailTemplateInfo('{company}', 'mailTemplate.placeholders.contactForm.company'));
			placeHolders.push(new MailTemplateInfo('{street}', 'mailTemplate.placeholders.contactForm.street'));
			placeHolders.push(new MailTemplateInfo('{houseNumber}', 'mailTemplate.placeholders.contactForm.houseNumber'));
			placeHolders.push(new MailTemplateInfo('{zip}', 'mailTemplate.placeholders.contactForm.zip'));
			placeHolders.push(new MailTemplateInfo('{city}', 'mailTemplate.placeholders.contactForm.city'));
			placeHolders.push(new MailTemplateInfo('{email}', 'mailTemplate.placeholders.contactForm.email'));
			placeHolders.push(new MailTemplateInfo('{phone}', 'mailTemplate.placeholders.contactForm.phone'));
			placeHolders.push(new MailTemplateInfo('{message}', 'mailTemplate.placeholders.contactForm.message'));
		}
		return placeHolders;
	}


	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('delete')
	public emitDelete(id: string): string {
		return id;
	}
}
