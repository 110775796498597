




























import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import AsiCard from "@/components/common/AsiCard.vue";
import {IShoppingListShopListEntry} from "@/models/shopping-list/ShoppingListShopModels";
import Snackbar from "@/helpers/Snackbar";

@Component({
	components: {AsiCard, AsiBtn}
})
export default class ShoppingListButton extends Vue {
	private icons = Icon;
	private open: boolean = false;

	@Prop({type: Boolean, default: false})
	public simple!: boolean;

	@Prop({type: Boolean, default: false})
	public large!: boolean;

	@Prop({type: String, required: true})
	public itemId!: string;

	@Prop({type: Number, default: 1})
	public quantity!: number;

	private get shoppingLists(): IShoppingListShopListEntry[] {
		return this.$store.state.shoppingList.shoppingLists;
	}

	private addToList(listId: string | null): void {
		this.$store.dispatch('shoppingList/addPosition', {
			itemId: this.itemId,
			quantity: this.quantity,
			shoppingListId: listId ?? null,
		})
			.then(() => Snackbar.show(this.$t('shoppingList.terms.added'), 'success', 1500));
	}
}
