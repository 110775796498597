




























import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import CategoryCardAttributes from "@/components/category/admin/CategoryCardAttributes.vue";
import CategoryUpdateItemAttributes from "@/models/category/CategoryUpdateItemAttributes";
import RightChecks from "@/mixins/RightChecks.vue";
import {mixins} from "vue-class-component";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import Snackbar from "@/helpers/Snackbar";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;

class AttributeGroup {
	public attributes!: string[];
	public title!: string | TranslateResult;
	public icon!: string;

	public constructor(
		attributes: string[],
		title: string | TranslateResult,
		icon: string | null = Icon.attributes
	) {
		this.attributes = attributes;
		this.title = title;
		this.icon = icon !== null ? icon : Icon.attributes;
	}
}

@Component({
	components: {AsiBtn, CategoryCardAttributes, AsiContentContainer}
})
export default class CategoryTabAttributes extends mixins(RightChecks) {

	@Prop({required: true})
	public category!: ICategoryAdmin | null;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	private icons = Icon;
	private loadingInternal: boolean = false;
	private edit: boolean = false;
	private updateModel: CategoryUpdateItemAttributes | null = null;

	private get attributeGroups(): AttributeGroup[] {
		return [
			new AttributeGroup(['avatarAttachmentId', 'price'], this.$t('item.terms.basicData'), this.icons.category),
			new AttributeGroup(['stockStatus', 'isTopSeller', 'canOrderSample'], this.$t('item.terms.miscData'), this.icons.category),
			new AttributeGroup(['length', 'width', 'height', 'grossWeight'], this.$t('item.terms.dimensionalData')),
			new AttributeGroup([
				'material', 'color', 'type', 'rebate', 'rebateSpace', 'grooveWidth', 'grooveDepth',
				'hardnessType', 'hardness', 'properties', 'density', 'connectionType', 'surfaceTreatment',
				'tolerance', 'fireProtectionStandard', 'foodStandard', 'otherStandard', 'fixture',
				'wrenchSize', 'thread', 'threadLength', 'diameterMm', 'applicationRange', 'resistance',
				'system', 'glassThickness', 'innerDiameter', 'outerDiameter', 'ringDiameter', 'crossSection',
				'assemblyGroup'
			], this.$t('item.terms.attributes')),
		];
	}

	@Watch('category', {deep: true, immediate: true})
	private onCategoryChanged(): void {
		this.initUpdateModel();
	}

	@Watch('loading')
	private onLoadingChanged(value: boolean): void {
		this.loadingInternal = value;
	}

	@Watch('loadingInternal')
	private onLoadingInternalChanged(value: boolean): void {
		if (this.loading !== value) this.$emit('update:loading', value);
	}

	@Emit('change')
	public change(): void {
		return;
	}

	private initUpdateModel(): void {
		this.updateModel = this.category === null ? null : new CategoryUpdateItemAttributes(this.category);
	}

	private requestCancel(): void {
		this.initUpdateModel();
		this.edit = false;
	}

	private requestSave(): void {
		if (this.category === null || this.updateModel === null) return;

		this.loadingInternal = true;
		this.$categoryServiceAdmin.updateItemAttributeConfig(this.category.id, this.updateModel)
			.then(() => {
				this.initUpdateModel();
				this.edit = false;
				this.change();
			})
			.catch(() => Snackbar.updateError())
			.finally(() => this.loadingInternal = false);
	}

}
