






import Vue from 'vue';
import {Component} from "vue-property-decorator";
import CategoryCard from "@/components/category/shop/CategoryCard.vue";
import CategoryList from "@/components/category/shop/CategoryNavigation.vue";
import ShopView from "@/components/layout/ShopView.vue";
@Component({
	components: {ShopView, CategoryList, CategoryCard}
})
export default class Categories extends Vue {

}
