import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "../../models/IPaginatedResponse";
import Vue from "vue";
import UserListFilter from "@/models/user/UserListFilter";
import AvatarCreate from "@/models/attachment/AvatarCreate";
import {Permissions, Roles, UserState} from "@/helpers/constants";
import IUserServiceAdmin from "@/services/definition/IUserServiceAdmin";
import AxiosService from "@/services/AxiosService";
import {IUserAdmin, IUserAdminListEntry} from "@/models/user/UserAdminModels";
import AxiosAuthService from "@/services/implementation/AxiosAuthService";
import UserUpdateName from "@/models/user/UserUpdateName";
import UserUpdatePersonalData from "@/models/user/UserUpdatePersonalData";

export default class AxiosUserServiceAdmin extends AxiosService implements IUserServiceAdmin {

	public static readonly BASE_URL = '/admin/users';

	protected defineBaseUrl(): string {
		return AxiosUserServiceAdmin.BASE_URL;
	}

	public user(id: string): Promise<IUserAdmin> {
		return Vue.$axios.get<IUserAdmin>(this.url(id)).then(res => res.data);
	}

	public users(filter: UserListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IUserAdminListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['firstname', 'lastname'];
			options.sortDesc = [false, false];
		}

		return Vue.$axios.get<IPaginatedResponse<IUserAdminListEntry>>(this.url(), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public updateName(id: string, model: UserUpdateName): Promise<null> {
		return Vue.$axios.patch(this.url(`${id}/name`), model).then(res => res.data);
	}

	public updatePhone(id: string, phone: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/phone`, 'users'), {
			phone: phone,
		}).then(res => res.data);
	}

	public updatePersonalData(id: string, model: UserUpdatePersonalData): Promise<null> {
		return Vue.$axios.patch(this.url(`${id}/name`), model).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(`${id}`)).then(res => res.data);
	}

	public state(id: string, requestedState: UserState): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/state`), {
			state: requestedState,
		}).then(res => res.data);
	}

	public uploadAvatar(model: AvatarCreate, onProgress: ((event: ProgressEvent) => void)): Promise<null> {
		const formData = new FormData();
		formData.append('avatar', model.file as Blob, model.file?.name);

		return Vue.$axios.put<null>(this.url(`${model.model?.id}/avatar`), formData, {
			onUploadProgress: onProgress,
		}).then(res => res.data);
	}

	public deleteAvatar(model: IUserAdmin): Promise<null> {
		return Vue.$axios.delete(this.url(`${model.id}/avatar`)).then(res => res.data);
	}

	public updateUsername(id: string, username: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/username`), {
			username: username,
		}).then(res => res.data);
	}

	public updatePassword(id: string, password: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/password`), {
			password: password,
		}).then(res => res.data);
	}

	public updateState(id: string, state: UserState): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/state`, 'users'), {
			state: state,
		}).then(res => res.data);
	}

	public roles(id: string): Promise<Roles[]> {
		return Vue.$axios.get(this.url(`${id}/roles`, AxiosAuthService.BASE_URL)).then(res => res.data);
	}

	public assignRole(id: string, role: Roles): Promise<null> {
		return Vue.$axios.post(this.url(`${id}/roles`, AxiosAuthService.BASE_URL), {
			roleName: role,
		}).then(res => res.data);
	}

	public revokeRole(id: string, role: Roles): Promise<null> {
		return Vue.$axios.delete(this.url(`${id}/roles/${role}`, AxiosAuthService.BASE_URL)).then(res => res.data);
	}

	public permissions(id: string): Promise<Permissions[]> {
		return Vue.$axios.get(this.url(`${id}/permissions`, AxiosAuthService.BASE_URL)).then(res => res.data);
	}

	public assignPermission(id: string, permission: Permissions): Promise<null> {
		return Vue.$axios.post(this.url(`${id}/permissions`, AxiosAuthService.BASE_URL), {
			permissionName: permission,
		}).then(res => res.data);
	}

	public revokePermission(id: string, permission: Permissions): Promise<null> {
		return Vue.$axios.delete(this.url(`${id}/permissions/${permission}`, AxiosAuthService.BASE_URL)).then(res => res.data);
	}

	public exportExcel(filter: UserListFilter | null, options: AsiListTableOptions, id?: string): Promise<Blob> {
		return Vue.$axios.get<Blob>(this.url(`/export`), {
				params: {
					...filter,
					...options,
				},
				responseType: 'blob',
			}).then(res => res.data);
	}

}
