














import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import {VuetifyThemeItem} from "vuetify/types/services/theme";

@Component
export default class AsiAvatar extends Vue {

	@Prop({default: 64})
	public size!: number | string;

	@Prop({type: String, default: null})
	public icon!: string | null;

	@Prop({type: String, default: null})
	public imageUrl!: string | null;

	@Prop({type: String, default: null})
	public acronym!: string | null;

	@Prop({type: String, default: null})
	public bgColor!: string | null;

	@Prop({type: String, default: 'white'})
	public iconColor!: string | null;

	@Prop({type: String, default: null})
	public acronymTextColor!: string | null;

	@Prop({type: String, default: null})
	public vOn!: string | null;

	@Prop({type: String, default: null})
	public vBind!: string | null;

	@Prop({type: Boolean, default: false})
	public left!: boolean;

	@Prop({type: Boolean, default: false})
	public right!: boolean;

	@Prop({type: Boolean, default: false})
	public rounded!: boolean;

	private get acronymFinal(): string | null {
		return this.acronym === null ? null : this.acronym.substr(0, 3).toLocaleUpperCase();
	}

	private get bgColorFinal(): string {
		return this.bgColor ?? 'primary';
	}

	private get iconColorFinal(): string {
		return this.iconColor ?? 'secondary';
	}

	private get acronymTextColorFinal(): string | null | VuetifyThemeItem {
		const color = this.acronymTextColor ?? 'secondary';

		const themeKeys = Object.keys(this.$vuetify.theme.currentTheme);
		if (themeKeys.includes(color)) {
			return this.$vuetify.theme.themes.light[color];
		}
		return this.acronymTextColor;
	}

}
