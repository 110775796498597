import { render, staticRenderFns } from "./UserHeader.vue?vue&type=template&id=2ed0ae3c&scoped=true"
import script from "./UserHeader.vue?vue&type=script&lang=ts"
export * from "./UserHeader.vue?vue&type=script&lang=ts"
import style0 from "./UserHeader.vue?vue&type=style&index=0&id=2ed0ae3c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ed0ae3c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VFadeTransition,VOverlay,VSpacer})
