












import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import AddressUpdate from "@/models/address/AddressUpdate";
import {IAddressSimple} from "@/models/address/AddressModels";
import AddressForm from "@/components/address/AddressForm.vue";
import ICustomerServiceAdmin from "@/services/definition/ICustomerServiceAdmin";
import ICustomerServiceShop from "@/services/definition/ICustomerServiceShop";

@Component({
	components: {AddressForm, AsiDialogFormLayout}
})
export default class AddressUpdateDialog extends AsiDialogForm {

	@Prop({type: String, required: true})
	public customerId!: string;

	@Prop({type: Object, required: true})
	public address!: IAddressSimple;

	@Prop({type: Object, required: true})
	public service!: ICustomerServiceAdmin | ICustomerServiceShop;

	@Prop({type: Boolean, default: false})
	public business!: boolean;

	@Prop({type: Boolean, default: false})
	public allowTypeSelection!: boolean;

	private model: AddressUpdate | null = null;

	public created(): void {
		this.setAddressInternal();
	}

	@Watch('address', {deep: true})
	private onAddressChanged(): void {
		this.setAddressInternal();
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.setAddressInternal();
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private setAddressInternal(): void {
		if (this.model === null) {
			this.model = new AddressUpdate(this.address);
			this.valid = true;
		} else {
			this.model.setDetailModel(this.address);
		}
	}

	private requestSave(): void {
		if (this.model === null) return;

		this.loading = true;
		this.service.updateAddress(this.customerId, this.address.id, this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

}
