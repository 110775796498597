











import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {OrderPaymentState} from "@/helpers/constants";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import Icon from "@/plugins/icons";
import {IOrderAdmin} from "@/models/order/OrderAdminModels";
import AddressHelper from "@/helpers/AddressHelper";

@Component({
	components: {
		AsiCardDetailEntry,
		AsiCardDetail,
	}
})
export default class OrderCardPayment extends Vue {

	@Prop({required: true})
	public order!: IOrderAdmin;

	private icons = Icon;

	private get isPaid(): boolean {
		return this.order.paymentState === OrderPaymentState.orderPaymentPaid;
	}

	private get paymentType(): string | null {
		return TranslatedValueHelper.get(this.order.paymentType.name, this.$i18n.locale, true);
	}

	// noinspection JSMethodCanBeStatic
	private get addressLines(): string[] {
		return AddressHelper.addressLines(this.order.billingAddress);
	}

}
