
























import Vue from 'vue';
import {Component} from "vue-property-decorator";
import ShopView from "@/components/layout/ShopView.vue";
import {ICartShopListEntry} from "@/models/cart/CartShopModels";
import AsiCard from "@/components/common/AsiCard.vue";
import Snackbar from "@/helpers/Snackbar";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import CartCard from "@/components/cart/shop/CartCard.vue";

@Component({
	components: {
		CartCard,
		AsiBtn, AsiCard, ShopView
	}
})
export default class Carts extends Vue {

	private icons = Icon;
	private loadingCreate: boolean = false;

	private get activeCartId(): string | null {
		return this.$store.state.cart.activeCartId;
	}

	private set activeCartId(id: string | null) {
		this.$store.commit('cart/setActiveCartId', id);
	}

	private get carts(): ICartShopListEntry[] {
		return this.$store.state.cart.carts;
	}

	private createCart(): void {
		this.loadingCreate = true;
		this.$store.dispatch('cart/createCart', {
			name: null,
			positions: [],
		})
			.catch(() => Snackbar.createError())
			.finally(() => this.loadingCreate = false);
	}

}
