








import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import Icon from "@/plugins/icons";
import {IShoppingListAdmin} from "@/models/shopping-list/ShoppingListAdminModels";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import {IUserAdminSimple} from "@/models/user/UserAdminModels";
import UserHelper from "@/models/user/UserHelper";
@Component({
	components: {AsiCardDetailEntry, AsiCardDetail}
})
export default class ShoppingListCardBasicData extends Vue {

	@Prop({type: Object, required: true})
	public shoppingList!: IShoppingListAdmin;

	private icons = Icon;

	private userName(user: IUserAdminSimple): string | null {
		return UserHelper.fullName(user);
	}
}
