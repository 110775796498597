










import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import Icon from "@/plugins/icons";
import {IOrderAdmin} from "@/models/order/OrderAdminModels";
import OrderStateChip from "@/components/order/OrderShipmentStateChip.vue";
import EnumHelper from "@/helpers/EnumHelper";
import {OrderExportFailReason, OrderExportState} from "@/helpers/constants";
import OrderExportStateChip from "@/components/order/OrderExportStateChip.vue";

@Component({
	components: {OrderExportStateChip, OrderStateChip, AsiCardDetail, AsiCardDetailEntry}
})
export default class OrderCardExport extends Vue {

	@Prop({required: true})
	public order!: IOrderAdmin;

	private icons = Icon;

	private get exportState(): string | null {
		return EnumHelper.textFromValue(OrderExportState, this.order.exportState, true);
	}
	private get failReason(): string | null {
		return EnumHelper.textFromValue(OrderExportFailReason, this.order.exportFailReason, true);
	}

}
