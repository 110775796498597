














































import {Component} from "vue-property-decorator";
import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
import Icon from "@/plugins/icons";
import AsiTabs from "@/components/common/AsiTabs";
import SettingsTabPaymentTypes from "@/components/settings/admin/SettingsTabPaymentTypes.vue";
import SettingsTabShipmentTypes from "@/components/settings/admin/SettingsTabShipmentTypes.vue";
import {mixins} from "vue-class-component";
import RightChecks from "@/mixins/RightChecks.vue";
import SettingsTabPromotions from "@/components/settings/admin/SettingsTabPromotions.vue";
import SettingsTabGlobalConfig from "@/components/settings/admin/SettingsTabGlobalConfig.vue";
import SettingsTabMailTemplates from "@/components/settings/admin/SettingsTabMailTemplates.vue";

@Component({
	components: {
		SettingsTabMailTemplates,
		SettingsTabGlobalConfig,
		SettingsTabPromotions, SettingsTabShipmentTypes, SettingsTabPaymentTypes, AsiTabs, AsiPageTitle
	}
})
export default class Settings extends mixins(RightChecks) {

	private icons = Icon;

	private get activeTab(): number {
		return this.$store.getters['ui/activeTabAdmin']('settings');
	}

	private set activeTab(index: number) {
		this.$store.commit('ui/activeTabAdmin', {key: 'settings', activeTab: index});
	}
}
