























import Vue from 'vue';
import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
import AsiRadioGroup from "@/components/common/AsiRadioGroup";
import AsiCard from "@/components/common/AsiCard.vue";
import {IShipmentTypeShopListEntry} from "@/models/shipment-type/ShipmentTypeShopModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import {ICartShopListEntry} from "@/models/cart/CartShopModels";
import Snackbar from "@/helpers/Snackbar";

@Component({
	components: {AsiCard, AsiRadioGroup}
})
export default class CheckoutWizardStepShipment extends Vue {

	@Prop({type: Object, required: true})
	public cart!: ICartShopListEntry;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	private loadingInternal: boolean = false;
	private shipmentTypes: IShipmentTypeShopListEntry[] = [];

	public created(): void {
		this.loadShipmentTypes();
	}

	private get shipmentTypeId(): string | null {
		return this.cart?.shipmentType?.id ?? null;
	}

	private set shipmentTypeId(id: string | null) {
		this.loadingInternal = true;
		this.$store.dispatch('cart/updateShipmentType', {cartId: this.cart.id, shipmentTypeId: id})
			.catch(() => Snackbar.updateError())
			.finally(() => this.loadingInternal = false);
	}

	@Watch('loading', {immediate: true})
	private onLoadingChanged(value: boolean): void {
		this.loadingInternal = value;
	}

	@Watch('loadingInternal')
	@Emit('loadingChanged')
	private onLoadingInternalChanged(value: boolean): boolean {
		if (this.loading !== value) this.$emit('update:loading', value);
		return value;
	}

	private shipmentTypeName(shipmentType: IShipmentTypeShopListEntry): string | null {
		return TranslatedValueHelper.get(shipmentType.name, this.$i18n.locale);
	}

	private loadShipmentTypes(): void {
		this.loadingInternal = true;
		this.$shipmentTypeServiceShop.shipmentTypes(null, null)
			.then(data => this.shipmentTypes = data.data)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loadingInternal = false);
	}

}
