var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AsiListTableLayout',{attrs:{"filter-text":_vm.filterModel.fulltext,"has-filter":_vm.hasFilter,"hide-toolbar":_vm.hideToolbar},on:{"clearFilterText":function($event){return _vm.filterTextChanged(null)},"clearSearch":function($event){return _vm.clearSearch()},"update:filterText":_vm.filterTextChanged},scopedSlots:_vm._u([{key:"advanced-filters",fn:function(){return [_c('AsiTextFieldSimple',{attrs:{"placeholder":_vm.$t('shipmentType.name'),"clearable":""},model:{value:(_vm.filterModel.name),callback:function ($$v) {_vm.$set(_vm.filterModel, "name", $$v)},expression:"filterModel.name"}})]},proxy:true}])},[_c('AsiContentContainer',{attrs:{"no-bottom-margin":""}},[_c('v-data-table',{attrs:{"footer-props":{itemsPerPageOptions: _vm.perPageOptions},"headers":_vm.createHeaders(),"items":_vm.items,"loading":_vm.loadingInternal || _vm.loadingDelete,"options":_vm.tableOptions,"server-items-length":_vm.total,"item-key":"id","multi-sort":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(item.name.de))]),_c('small',{staticClass:"grey--text"},[_vm._v(_vm._s(item.name.fr !== null ? item.name.fr : '-'))]),_c('small',{staticClass:"grey--text"},[_vm._v(_vm._s(item.name.en !== null ? item.name.en : '-'))])])]}},{key:"item.postage",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.$n(item.postage.amount, item.postage.currency.currencyCode !== null ? { key: 'currencyDisplay', currency: item.postage.currency.currencyCode } : {})))]),_c('small',{staticClass:"grey--text"},[_vm._v(_vm._s(item.postage.vat)+"% "+_vm._s(_vm.$t('ui.terms.vat')))])])]}},{key:"item.isBulkyGoodsCharged",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.isBulkyGoodsCharged ? 'ui.yes' : 'ui.no'))+" ")]}},(_vm.showActions)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row flex-wrap"},[_c('AsiBtn',{attrs:{"disabled":_vm.loadingDelete,"icon":_vm.icons.edit,"small":""},nativeOn:{"click":function($event){$event.stopPropagation();_vm.updateShipmentTypeId = item.id; _vm.updateDialog.open();}}}),_c('AsiBtn',{attrs:{"disabled":_vm.loadingDelete,"icon":_vm.icons.delete,"small":""},on:{"click":function($event){return _vm.performDelete(item)}}})],1)]}}:null],null,true)})],1),(_vm.showActions)?_c('AsiConfirmDialog',{ref:"confirm"}):_vm._e(),(_vm.showActions && _vm.updateDialog.isLoaded && _vm.updateShipmentTypeId !== null)?_c('ShipmentTypeUpdateDialog',{attrs:{"open":_vm.updateDialog.isOpen,"id":_vm.updateShipmentTypeId},on:{"cancel":function($event){return _vm.updateDialog.close()},"save":function($event){_vm.changed(_vm.updateShipmentTypeId); _vm.updateDialog.close(); _vm.reload(false, false)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }