
















import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
import AsiTextarea from "@/components/common/AsiTextarea";

@Component({
	components: {AsiTextarea, AsiDialogFormLayout}
})
export default class CustomerUpdateDialogNotes extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public customer!: ICustomerAdmin;

	private notes: string | null = null;

	@Watch('customer.notes', {immediate: true})
	private onCustomerNotesChanged(value: string): void {
		this.notes = value;
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.notes = this.customer.notes;
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private requestSave(): void {
		this.loading = true;
		this.$customerServiceAdmin.updateNotes(this.customer.id, this.notes)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

}
