
























































import {Component, Emit} from "vue-property-decorator";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import AsiTextField from "@/components/common/AsiTextField";
import TranslatedValueFormPart from "@/components/translated-value/admin/TranslatedValueFormPart.vue";
import PaymentTypeCreate from "@/models/payment-type/PaymentTypeCreate";
import AsiCheckbox from "@/components/common/AsiCheckbox";
import {CustomerType} from "@/helpers/constants";
import EnumHelper from "../../../helpers/EnumHelper";
import AsiListTableHeader from "@/components/common/AsiListTableHeader";

@Component({
	components: {AsiCheckbox, TranslatedValueFormPart, AsiTextField, AsiDialogFormLayout}
})
export default class PaymentTypeCreateDialog extends AsiDialogForm {

	private model: PaymentTypeCreate = new PaymentTypeCreate();
	private customerTypes: {
		'index': CustomerType
	}[] = EnumHelper.toArray(CustomerType).map(ct => ({'index': Number(ct.value)}));
	private selectedCustomerTypes: { 'index': CustomerType }[] = [];

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		const form = this.$refs.form as HTMLFormElement;
		this.reset([this.model], form);
		return event;
	}

	private requestSave(): void {
		if (!this.valid) return;

		this.loading = true;
		this.updateAllowedCustomerTypes();
		this.$paymentTypeServiceAdmin.create(this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

	private updateAllowedCustomerTypes(): void {
		if (this.model) {
			this.model.allowedCustomerTypes = this.selectedCustomerTypes.map(ct => ct.index);
		}
	}

	private get customerTypesHeaders(): AsiListTableHeader[] {
		return [
			new AsiListTableHeader(this.$t('customer.type'), 'label', false, false, null),
		];
	}

	private get EnumHelper() {
		return EnumHelper;
	}

	private get CustomerType() {
		return CustomerType;
	}

}
