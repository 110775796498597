import PathEntry from "@/models/PathEntry";
import {IItemShopListEntry, IItemShopSimple} from "@/models/item/ItemShopModels";
import {IItemAdmin, IItemAdminListEntry, IItemAdminSimple} from "@/models/item/ItemAdminModels";
import {RawLocation} from "vue-router";
import {
	AttachmentMediaSize,
	ItemAttribute,
	ItemAttributeVisibility,
	ItemState,
	Routes
} from "@/helpers/constants";
import Icon from "@/plugins/icons";
import i18n from "@/plugins/i18n";
import CategoryHelper from "@/models/category/CategoryHelper";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import store from "@/store";
import AttachmentHelper from "@/models/attachment/AttachmentHelper";
import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
import EnumHelper from "@/helpers/EnumHelper";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;
import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";

export default class ItemHelper {

	public static detailRoute(itemNumber: string): RawLocation {
		return {
			name: Routes.item,
			params: {
				itemNumber: itemNumber,
			},
		};
	}

	public static detailRouteAdmin(id: string): RawLocation {
		return {
			name: Routes.adminItem,
			params: {
				id: id,
			},
		};
	}

	public static avatarUrl(model: IItemAdminListEntry | IItemShopListEntry, size: AttachmentMediaSize): string | null {
		if (model.avatarAttachmentId !== null) {
			const avatar = model.galleryAttachments.find(a => a.id === model.avatarAttachmentId);
			if (avatar !== undefined) {
				const avatarUrl = AttachmentHelper.getImageUrl(avatar, size);
				if (avatarUrl !== null) return avatarUrl;
			}
		}

		const category = store.getters['category/categoryById'](model.category.id) as ICategoryShopListEntry | null;
		return category === null ? null : CategoryHelper.avatarUrl(category, size);
	}

	public static colorHierarchical(model: IItemAdminListEntry | IItemShopListEntry): string | null {
		const category = store.getters['category/categoryById'](model.category.id) as ICategoryShopListEntry | null;
		return category === null ? null : CategoryHelper.colorHierarchical(category);
	}

	public static pathIcon(): string {
		return Icon.item;
	}

	public static pathLabel(model: IItemAdminSimple | IItemShopSimple): string {
		return TranslatedValueHelper.get(model.name, i18n.locale, true) ?? '?';
	}

	public static pathEntry(model: IItemAdminSimple | IItemShopSimple, adminRoute: boolean = false): PathEntry {
		return new PathEntry(this.pathIcon(), this.pathLabel(model), adminRoute ? this.detailRouteAdmin(model.id) : this.detailRoute(model.itemNumber));
	}

	public static path(model: IItemAdminListEntry | IItemShopListEntry, adminRoutes: boolean = false): PathEntry[] {
		return [
			...CategoryHelper.path(model.category, adminRoutes),
			CategoryHelper.pathEntry(model.category, adminRoutes),
		];
	}

	public static isImported(model: IItemAdmin): boolean {
		return model.externalId !== null;
	}

	public static allAttributes(model: IItemAdminListEntry | IItemShopListEntry): Map<string, number | string | ITranslatedValue | null> {
		const map = new Map<string, number | string | ITranslatedValue | null>();
		EnumHelper.toArray(ItemAttribute).forEach(attr => {
			const attrName: string = attr.value.toString();
			//@ts-ignore
			const value: string | number | null = model[attrName];

			map.set(attrName, value);
		});
		return map;
	}

	public static relevantAttributes(model: IItemAdminListEntry | IItemShopListEntry, visibility: ItemAttributeVisibility | null = null, includeNullValues: boolean = true): Map<string, number | string | ITranslatedValue | null> {
		const map = new Map<string, number | string | ITranslatedValue | null>();
		EnumHelper.toArray(ItemAttribute).forEach(attr => {
			const attrName: string = attr.value.toString();
			//@ts-ignore
			const value: string | ITranslatedValue| number | null = model[attrName];
			//@ts-ignore
			const visible = visibility === null || this.attributeVisibleAt(model.category[`${attrName}Visibility`], visibility);

			if (visible && (value !== null || includeNullValues)) {
				map.set(attrName, value);
			}
		});
		return map;
	}

	public static attributeIcon(attribute: string | ItemAttribute): string {
		//TODO: add missing icons
		switch (attribute) {
			case ItemAttribute.stockStatus:
				return Icon.stock;
			case ItemAttribute.canOrderSample:
				return Icon.canOrderSample;
			case ItemAttribute.isTopSeller:
				return Icon.favorite;
			default:
				return Icon.list;
		}
	}

	public static attributeLabel(attribute: string | ItemAttribute): string | TranslateResult {
		return i18n.t(`item.${attribute}`);
	}

	public static attributeValueFormatted(attribute: string | ItemAttribute, value: number | string | null): string | null {
		if (value === null) return null;

		switch (attribute) {
			case 'length':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'width':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'rebate':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'rebateSpace':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'grooveWidth':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'grooveDepth':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'thread':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'threadLength':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'diameterMm':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'glassThickness':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'innerDiameter':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'outerDiameter':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'ringDiameter':
				return value + i18n.t('constants.unitMillimeter').toString();
			case 'crossSection':
				return value + i18n.t('constants.unitMillimeter').toString();

			case 'grossWeight':
				return value + i18n.t('constants.unitKilogram').toString();

			case 'density':
				return value + i18n.t('constants.unitGramPerSquareCentimeter').toString();

			case 'isTopSeller':
				return value ? i18n.t('ui.yes').toString() : i18n.t('ui.no').toString();
			case 'canOrderSample':
				return value ? i18n.t('ui.yes').toString() : i18n.t('ui.no').toString();
			default:
				return value.toString();
		}
	}

	public static attributeVisibleAt(visibility: ItemAttributeVisibility, visibilityAt: ItemAttributeVisibility): boolean {
		switch (visibility) {
			case ItemAttributeVisibility.detailViewOnly:
				return visibilityAt === ItemAttributeVisibility.detailViewOnly;
			case ItemAttributeVisibility.largeView:
				return [
					ItemAttributeVisibility.detailViewOnly,
					ItemAttributeVisibility.largeView
				].includes(visibilityAt);
			case ItemAttributeVisibility.mediumView:
				return [
					ItemAttributeVisibility.detailViewOnly,
					ItemAttributeVisibility.largeView,
					ItemAttributeVisibility.mediumView,
				].includes(visibilityAt);
			case ItemAttributeVisibility.smallView:
				return visibilityAt !== ItemAttributeVisibility.never;
			case ItemAttributeVisibility.never:
			default:
				return false;
		}
	}

	public static stateColor(model: IItemAdminSimple | IItemShopSimple): string {
		switch (model.state) {
			case ItemState.active:
				return 'success';
			case ItemState.archived:
			default:
				return 'error';
		}
	}

	public static stateIcon(model: IItemAdminSimple | IItemShopSimple): string {
		switch (model.state) {
			case ItemState.active:
				return Icon.unarchive;
			case ItemState.archived:
			default:
				return Icon.archive;
		}
	}

	public static isBaseAttribute(attribute: string, excludePricePic: boolean = false): boolean {
		const baseAttributes = [
			ItemAttribute.stockStatus,
			ItemAttribute.isTopSeller,
			ItemAttribute.canOrderSample,
		];

		if (!excludePricePic) {
			baseAttributes.push(...[ItemAttribute.price, ItemAttribute.avatarAttachmentId]);
		}

		return baseAttributes.map(attr => attr.toString()).includes(attribute);
	}

}
