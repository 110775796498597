





































































































import {Component, Watch} from 'vue-property-decorator';
import AsiTextField from "@/components/common/AsiTextField";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import AsiDialog from "@/components/common/AsiDialog.vue";
import DialogHandler from "@/components/common/DialogHandler";
import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import StringHelper from "@/helpers/StringHelper";
import ItemListFilterShop from "@/models/item/ItemListFilterShop";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import {AttachmentMediaSize, PerPageOptions} from "@/helpers/constants";
import Snackbar from "@/helpers/Snackbar";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiPagination from "@/components/common/AsiPagination.vue";
import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import LocaleHelper from "@/helpers/LocaleHelper";
import ItemHelper from "@/models/item/ItemHelper";
import CategoryHelper from "@/models/category/CategoryHelper";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import {mixins} from "vue-class-component";
import ItemChipNumber from "@/components/item/ItemChipNumber.vue";
import ItemChipAltNumber from "@/components/item/ItemChipAltNumber.vue";

@Component({
	components: {
		ItemChipAltNumber,
		ItemChipNumber,
		AsiAvatar, AsiPagination, AsiCard, AsiDialog, AsiTextFieldSimple, AsiBtn, AsiTextField}
})
export default class GlobalSearch extends mixins(ResponsiveChecks) {

	private static readonly DEBOUNCE_TIMEOUT = 1000;

	private icons = Icon;
	private loading: boolean = false;
	private term: string | null = null;
	private dialog: DialogHandler = new DialogHandler();
	private searchTimeout: any = null;

	private resultsCategory: IPaginatedResponse<ICategoryShopListEntry> | null = null;
	private resultsCategoryPage: number = 1;
	private resultsCategoryPerPage: number = PerPageOptions[0];
	private resultsItem: IPaginatedResponse<IItemShopListEntry> | null = null;
	private resultsItemPerPage: number = PerPageOptions[0];
	private resultsItemPage: number = 1;

	private expand(): void {
		setTimeout(() => {
			const input = this.$refs.searchInput as any;
			if (input === undefined || input.$refs?.input === undefined) return;
			input.$refs.input.select();
		}, 50);
	}

	@Watch('term')
	private onTermChanged(value: string | null): void {
		clearTimeout(this.searchTimeout);

		if (value === null || StringHelper.isEmpty(value)) {
			this.reset();
		} else {
			if (value.length < 4) return;

			this.searchTimeout = setTimeout(() => {
				this.resultsCategoryPage = 1;
				this.performSearchCategories();
				this.resultsItemPage = 1;
				this.performSearchItems();
			}, GlobalSearch.DEBOUNCE_TIMEOUT);
		}
	}

	private open(): void {
		this.dialog.open();

		setTimeout(() => {
			const input = this.$refs.searchInput as any;
			if (input === undefined || input.$refs?.input === undefined) return;
			input.$refs.input.select();
		}, 50);
	}

	private close(): void {
		this.dialog.close();
		this.reset();
	}

	private reset(): void {
		this.term = null;
		this.resultsCategory = null;
		this.resultsCategoryPage = 1;
		this.resultsItem = null;
		this.resultsItemPage = 1;
	}

	private performSearchCategories(): void {
		if (this.term === null || StringHelper.isEmpty(this.term)) {
			return;
		}
		const termFinal = this.term.toLowerCase();

		const result = this.$store.state.category.categories.filter((c: ICategoryShopListEntry) => {
			const catSearchString = [c.name.de, c.name.fr, c.name.en].filter(s => s !== null).join().toLowerCase();
			return catSearchString.indexOf(termFinal) > -1;
		});

		const start = (this.resultsCategoryPage - 1) * this.resultsCategoryPerPage;
		this.resultsCategory = {
			total: result.length,
			data: result.slice(start, start + this.resultsCategoryPerPage),
		} as IPaginatedResponse<ICategoryShopListEntry>;
	}

	private performSearchItems(): void {
		if (this.term === null || StringHelper.isEmpty(this.term)) {
			return;
		}
		const termFinal = this.term.toLowerCase();

		const filter = new ItemListFilterShop();
		filter.fulltext = termFinal;
		const options = new AsiListTableOptions();
		options.itemsPerPage = this.resultsItemPerPage;
		options.page = this.resultsItemPage;
		options.sortBy = ['name.' + LocaleHelper.extractLanguage(this.$i18n.locale)];
		options.sortDesc = [false];

		this.loading = true;
		this.$itemServiceShop.items(filter, options)
			.then(data => this.resultsItem = data)
			.catch(() => {
				Snackbar.loadingError();
				this.resultsItem = null;
			})
			.finally(() => this.loading = false);
	}

	private translatedValue(value: ITranslatedValue): string | null {
		return TranslatedValueHelper.get(value, this.$i18n.locale, true);
	}

	private showItem(item: IItemShopListEntry): void {
		try {
			this.$router.push(ItemHelper.detailRoute(item.itemNumber));
		} catch (e) {
			//do nothing
		} finally {
			this.close();
		}
	}

	// noinspection JSMethodCanBeStatic
	private itemPrice(item: IItemShopListEntry): number | null {
		return item.price?.price.amount ?? null;
	}

	// noinspection JSMethodCanBeStatic
	private itemCurrencyCode(item: IItemShopListEntry): string | null {
		const c = item.price?.price.currency.currencyCode ?? null;
		if (null === c) {
			console.log(item);
		}
		return c;
	}

	private showCategory(category: ICategoryShopListEntry): void {
		try {
			this.$router.push(CategoryHelper.detailRoute(CategoryHelper.currentCanonical(category.canonical)));
		} catch (e) {
			//do nothing
		} finally {
			this.close();
		}
	}

	// noinspection JSMethodCanBeStatic
	private categoryColor(category: ICategoryShopListEntry): string | null {
		return CategoryHelper.colorHierarchical(category);
	}

	// noinspection JSMethodCanBeStatic
	private categoryAvatar(category: ICategoryShopListEntry): string | null {
		return CategoryHelper.avatarUrl(category, AttachmentMediaSize.s);
	}
}
