


















































































































import Vue from 'vue';
import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
import CartTotalEntries from "@/components/cart/shop/CartTotalEntries.vue";
import AsiCheckbox from "@/components/common/AsiCheckbox";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import {ICartShopListEntry} from "@/models/cart/CartShopModels";
import DialogHandler from "@/components/common/DialogHandler";
import AsiDialog from "@/components/common/AsiDialog.vue";
import LegalTermsAndConditions from "@/components/legal/LegalTermsAndConditions.vue";
import {IShipmentTypeShopListEntry} from "@/models/shipment-type/ShipmentTypeShopModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import {IPaymentTypeShopListEntry} from "@/models/payment-type/PaymentTypeShopModels";
import {IAddressFields} from "@/models/address/AddressModels";
import AddressHelper from "@/helpers/AddressHelper";
import {Tenant} from "@/models/ui/Tenant";
import EnumHelper from "@/helpers/EnumHelper";

@Component({
	components: {LegalTermsAndConditions, AsiDialog, AsiBtn, AsiCheckbox, CartTotalEntries}
})
export default class CheckoutWizardStepSummary extends Vue {

	@Prop({type: Object, required: true})
	public cart!: ICartShopListEntry;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	private icons = Icon;
	private loadingInternal: boolean = false;
	private tacDialog: DialogHandler = new DialogHandler();
	private tacConfirmed: boolean = false;

	private get amountsInclVat(): boolean {
		return this.$store.state.ui.amountsInclVat;
	}

	private get tenantName(): string {
		return EnumHelper.textFromValue(Tenant, this.$isTenant, true) ?? '';
	}

	@Watch('loading', {immediate: true})
	private onLoadingChanged(value: boolean): void {
		this.loadingInternal = value;
	}

	@Watch('loadingInternal')
	@Emit('loadingChanged')
	private onLoadingInternalChanged(value: boolean): boolean {
		if (this.loading !== value) this.$emit('update:loading', value);
		return value;
	}

	@Emit('performOrder')
	public performOrder(): void {
		return;
	}

	private requestOrder(): void {
		if (!this.tacConfirmed) return;
		this.performOrder();
	}

	private shipmentTypeName(shipmentType: IShipmentTypeShopListEntry): string | null {
		return TranslatedValueHelper.get(shipmentType.name, this.$i18n.locale);
	}

	private paymentTypeName(paymentType: IPaymentTypeShopListEntry): string | null {
		return TranslatedValueHelper.get(paymentType.name, this.$i18n.locale);
	}

	private get shippingAddressLines(): string[] | null {
		if (this.cart.customerShippingAddress === null && this.cart.oneTimeShippingAddress === null) {
			return null;
		}
		const address = this.cart.customerShippingAddress ?? this.cart.oneTimeShippingAddress as IAddressFields;
		return AddressHelper.addressLines(address);
	}

	private get billingAddressLines(): string[] | null {
		if (this.cart.customerBillingAddress === null && this.cart.oneTimeBillingAddress === null) {
			return null;
		}
		const address = this.cart.customerBillingAddress ?? this.cart.oneTimeBillingAddress as IAddressFields;
		return AddressHelper.addressLines(address);
	}

}
