







import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import AsiChip from "@/components/common/AsiChip.vue";
import ClipboardHelper from "@/helpers/ClipboardHelper";
@Component({
	components: {AsiChip}
})
export default class ItemChipNumber extends Vue {

	@Prop({type: Object, required: true})
	public item!: IItemShopListEntry;

	@Prop({type: Boolean, default: false})
	public small!: boolean;

	@Prop({type: Boolean, default: false})
	public xSmall!: boolean;

	private copy(): void {
		if (this.item.alternativeItemNumber === null) return;
		ClipboardHelper.copyText(this.item.alternativeItemNumber);
	}

}
