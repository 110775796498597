


































import Vue from 'vue';
import {Component, Watch} from "vue-property-decorator";
import SampleOrderForm from "@/components/sample-order/shop/SampleOrderForm.vue";
import ShopView from "@/components/layout/ShopView.vue";
import Snackbar from "@/helpers/Snackbar";
import SampleOrderAddress, {SampleOrderSummaryDisplay} from "@/models/sample-order/SampleOrderAddress";
import SampleOrderRequestData from "@/models/sample-order/SampleOrderRequestData";
import {IItemShopSimple} from "@/models/item/ItemShopModels";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import OrderShipmentStateChip from "@/components/order/OrderShipmentStateChip.vue";
import OrderPaymentStateChip from "@/components/order/OrderPaymentStateChip.vue";
import Icon from "@/plugins/icons";
import AsiCard from "@/components/common/AsiCard.vue";
import SampleOrderCart from "@/components/sample-order/shop/SampleOrderCart.vue";
import AddressHelper from "@/helpers/AddressHelper";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiPageTitle from "@/components/common/AsiPageTitle.vue";

@Component({
	components: {
		AsiPageTitle,
		AsiBtn,
		SampleOrderCart, AsiCard,
		OrderPaymentStateChip, OrderShipmentStateChip, AsiCardDetail,
		AsiCardDetailEntry,
		ShopView,
		SampleOrderForm
	}
})
export default class SampleOrder extends Vue {
	private icons = Icon;
	public model: SampleOrderAddress | null = null;
	private confirmationModel!: SampleOrderSummaryDisplay;
	private confirmationItems!: IItemShopSimple[]
	public valid = false;
	private isFormSubmitted = false;

	public created() {
		this.isFormSubmitted = false;
		if (this.sampleOrderFormData === null) {
			this.hydrateStoreWithDefaultShippingAddress();
		}
	}

	@Watch('$store.state.sampleOrderFormData.data')
	public onStoreModelChanged(): void {
		this.model = this.sampleOrderFormData !== null ? this.sampleOrderFormData : new SampleOrderAddress();
	}

	public mounted() {
		this.model = this.sampleOrderFormData !== null ? this.sampleOrderFormData : new SampleOrderAddress();
	}

	private get sampleOrderFormData(): SampleOrderAddress | null {

		const storeData = this.$store.getters["sampleOrderFormData/data"];
		if (storeData === null) {
			return null;
		}
		return SampleOrderAddress.fromISampleOrderAddressData(storeData);
	}

	protected get sMobile(): boolean {
		return this.$vuetify.breakpoint.smAndDown;
	}

	private performSampleOrder() {
		this.$sampleOrderService.sendSampleOrder(this.prepareDataForPost())
			.then(() => {
				this.prepareConfirmationData();
				this.reset();
				this.isFormSubmitted = true;
			})
			.catch(() => Snackbar.createError())
			.finally();
	}

	private prepareDataForPost(): SampleOrderRequestData {
		if (this.model === null) {
			return new SampleOrderRequestData();
		}
		let postObject = new SampleOrderRequestData();
		postObject.Address = this.model.address;
		postObject.Email = this.model.email;
		postObject.Phone = this.model.phone;
		postObject.Comment = this.model.comment;
		postObject.ItemIds = [];
		this.$store.state.sampleOrder.items.forEach((item: IItemShopSimple) => {
			postObject.ItemIds.push(item.id);
		});
		return postObject;
	}

	private addressLines(address: SampleOrderSummaryDisplay): string[] {
		return AddressHelper.addressLinesSampleOrder(address);
	}

	private reset() {
		if (this.model === null) {
			return;
		}
		this.$store.dispatch('sampleOrder/removeAll');
		this.model.reset();
		this.model.address.reset();
		this.$store.commit('sampleOrderFormData/resetModel');
		this.hydrateStoreWithDefaultShippingAddress();
		this.valid = false;
	}

	private prepareConfirmationData() {
		if (this.model === null) {
			return;
		}
		this.confirmationModel = new SampleOrderSummaryDisplay(this.model);
		this.confirmationItems = this.$store.state.sampleOrder.items;
	}

	private hydrateStoreWithDefaultShippingAddress() {
		this.$store.dispatch('sampleOrderFormData/rehydrate', this.$customerServiceShop);
	}
}
