

















import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import {IAttachment} from "@/models/attachment/AttachmentModels";
import {AttachmentMediaSize} from "@/helpers/constants";
import AttachmentHelper from "@/models/attachment/AttachmentHelper";
import AsiDialog from "@/components/common/AsiDialog.vue";
import LegalTermsAndConditions from "@/components/legal/LegalTermsAndConditions.vue";
import DialogHandler from "@/components/common/DialogHandler";
import Icon from "@/plugins/icons";
import {Tenant} from "@/models/ui/Tenant";

@Component({
	components: {LegalTermsAndConditions, AsiDialog}
})
export default class GalleryDetail extends Vue {

	@Prop({type: Object, default: null})
	public image!: IAttachment | null;

	@Prop({type: String, default: null})
	public width!: string | null;

	@Prop({type: String, default: null})
	public height!: string | null;

	@Prop({type: Number, default: AttachmentMediaSize.m})
	public mediaSize!: AttachmentMediaSize;

	@Prop({type: Number, default: undefined})
	public aspectRatio!: number | undefined;

	@Prop({type: Boolean, default: false})
	public contain!: boolean;


	private icons = Icon;
	private detailDialog: DialogHandler = new DialogHandler();

	private get detailUrl(): string | null {
		return this.image === null ? null : AttachmentHelper.getImageUrl(this.image, this.mediaSize);
	}

	private get imageStyles(): object {
		switch (this.$isTenant) {
			case Tenant.herzog:
				return {
					'background-color': '#EEEEEE'
				};
			case Tenant.mk:
				return {
					'background-color': '#FFFFFF',
				};
			default:
				return {};
		}
	}

	private openPreview() {
		console.log('ckucj');
	}

}
