























import {Component, Prop, Watch} from "vue-property-decorator";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import ItemListViewCards from "@/components/item/shop/ItemListViewCards.vue";
import ItemListViewTable from "@/components/item/shop/ItemListViewTable.vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import AsiListTable from "@/components/common/AsiListTable.vue";
import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
import AsiListTableHeader from "@/components/common/AsiListTableHeader";
import AsiCard from "@/components/common/AsiCard.vue";
import {ItemType, UnitOfMeasure} from "@/helpers/constants";
import Icon from "@/plugins/icons";
import ItemListFilterShop from "@/models/item/ItemListFilterShop";
import ItemFilter from "@/components/item/shop/ItemFilter.vue";
import {IItemShopPaginatedResponse} from "@/models/item/IItemShopPaginatedResponse";
import ShowDetailEvent from "@/helpers/ShowDetailEvent";
import DialogHandler from "@/components/common/DialogHandler";
import {IItemPriceShopSimple} from "@/models/item-price/ItemPriceModelsShop";
import ItemHelper from "@/models/item/ItemHelper";
import Gtm, {itemShopListEntryToGtmItem, SelectItemGtmEvent, ViewItemGtmEvent} from "@/plugins/gtm";
import HyperlinkHelper from "@/helpers/HyperlinkHelper";

@Component({
	components: {ItemFilter, AsiCard, ItemListViewTable, ItemListViewCards}
})
export default class ItemList extends AsiListTable<IItemShopListEntry, ItemListFilterShop, IItemShopPaginatedResponse> implements IAsiListTableImplementation<IItemShopListEntry, ItemListFilterShop, IItemShopPaginatedResponse> {

	@Prop({type: String, required: true})
	public categoryId!: string | null;

	@Prop({type: Boolean, default: false})
	public tableView!: boolean;

	@Prop({default: null})
	public showAttributeCallback!: ((column: string) => boolean) | null;

	private icons = Icon;
	private tableViewInternal: boolean = false;

	private toCartItemId: string | null = null;
	private toCartItemUnit: UnitOfMeasure | null = null;
	private toCartItemPrices: IItemPriceShopSimple[] | null = null;
	private toCartDialog: DialogHandler = new DialogHandler(() => this.toCartItemId = null);

	@Watch('categoryId', {immediate: true})
	private onCategoryIdChanged(): void {
		this.filterModel = this.createFilterModelInternal();
		this.reload(true);
	}

	@Watch('filterModel', {deep: true})
	private onFilterModelChanged(): void {
		this.reload(false, true);
	}

	@Watch('tableOptions', {deep: true})
	private onTableOptionsChanged(): void {
		this.reload();
	}

	@Watch('tableView', {immediate: true})
	private onTableViewChanged(value: boolean): void {
		this.tableViewInternal = value;
	}

	@Watch('tableViewInternal')
	private onTableViewInternalChanged(value: boolean): void {
		if (this.tableView !== value) this.$emit('update:tableView', value);
	}

	@Watch('items')
	private onItemsChanged(): void {
		if (this.items.length <= 0) {
			return;
		}
		const gtmItems = this.items.map(i => itemShopListEntryToGtmItem(i));
		Gtm.viewItem({
			currency: 'CHF',
			value: gtmItems.map(gi => gi.price).reduce((a, b) => a + b, 0),
			items: gtmItems
		} as ViewItemGtmEvent);
	}

	public reload(goToFirstPage: boolean = false, debounce: boolean = false): void {
		this.reloadInternal(this, goToFirstPage, debounce);
	}

	public fetchData(filterModel: ItemListFilterShop | null, tableOptions: AsiListTableOptions | null): Promise<IItemShopPaginatedResponse> {
		return this.categoryId !== null
			? this.$categoryServiceShop.items(this.categoryId, filterModel, tableOptions)
			: this.$itemServiceShop.items(filterModel, tableOptions);
	}

	public createFilterModel(): ItemListFilterShop {
		return new ItemListFilterShop();
	}

	public openModelDetailPage(model: IItemShopListEntry): void {
		switch (model.itemType) {
			case ItemType.linkItem:
				if (model.remoteUrl !== null) {
					HyperlinkHelper.openUrl(model.remoteUrl);
				}
				break;
			case ItemType.shopItem:
				Gtm.selectItem({
					items: [
						itemShopListEntryToGtmItem(model)
					]
				} as SelectItemGtmEvent);
				this.$router.push(ItemHelper.detailRoute(model.itemNumber));
				break;
		}
	}

	public createHeaders(): AsiListTableHeader[] {
		return [];
	}

	public showItemToCart(item: {
		id: string,
		unit: UnitOfMeasure,
		prices: IItemPriceShopSimple[]
	}): ShowDetailEvent {
		this.toCartDialog.open();
		this.toCartItemId = item.id;
		this.toCartItemUnit = item.unit;
		this.toCartItemPrices = item.prices;
		return new ShowDetailEvent(item.id);
	}
}
