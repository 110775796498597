var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AsiListTableLayout',{attrs:{"filter-text":_vm.filterModel.fulltext,"has-filter":_vm.hasFilter,"hide-toolbar":_vm.hideToolbar},on:{"clearFilterText":function($event){return _vm.filterTextChanged(null)},"clearSearch":function($event){return _vm.clearSearch()},"update:filterText":_vm.filterTextChanged},scopedSlots:_vm._u([{key:"advanced-filters",fn:function(){return [_c('AsiDatePickerCombined',{attrs:{"label":_vm.$t('promotion.terms.validDateRange'),"clearable":"","range":"","simple":""},model:{value:(_vm.validAtDateRange),callback:function ($$v) {_vm.validAtDateRange=$$v},expression:"validAtDateRange"}})]},proxy:true}])},[_c('AsiContentContainer',{attrs:{"no-bottom-margin":""}},[_c('v-data-table',{attrs:{"footer-props":{itemsPerPageOptions: _vm.perPageOptions},"headers":_vm.createHeaders(),"items":_vm.items,"loading":_vm.loadingInternal || _vm.loadingDelete,"options":_vm.tableOptions,"server-items-length":_vm.total,"item-key":"id","multi-sort":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('AsiAvatar',{attrs:{"size":32,"icon":_vm.icons.promotion}})]}},{key:"item.item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.itemName(item.item))+" ")]}},{key:"item.minimumOrderPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.minimumOrderPrice.amount, { key: 'currencyDisplay', currency: item.minimumOrderPrice.currency.currencyCode, }))+" ")]}},{key:"item.validFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.validFrom), 'short'))+" ")]}},{key:"item.validTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.validTo), 'short'))+" ")]}},(_vm.showActions)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row flex-wrap"},[_c('AsiBtn',{attrs:{"disabled":_vm.loadingDelete,"icon":_vm.icons.edit,"small":""},nativeOn:{"click":function($event){$event.stopPropagation();_vm.updatePromotionId = item.id; _vm.updateDialog.open();}}}),_c('AsiBtn',{attrs:{"disabled":_vm.loadingDelete,"icon":_vm.icons.delete,"small":""},on:{"click":function($event){return _vm.performDelete(item)}}})],1)]}}:null],null,true)})],1),(_vm.showActions)?_c('AsiConfirmDialog',{ref:"confirm"}):_vm._e(),(_vm.showActions && _vm.updateDialog.isLoaded && _vm.updatePromotionId !== null)?_c('PromotionUpdateDialog',{attrs:{"open":_vm.updateDialog.isOpen,"id":_vm.updatePromotionId},on:{"cancel":function($event){return _vm.updateDialog.close()},"save":function($event){_vm.changed(_vm.updatePromotionId); _vm.updateDialog.close(); _vm.reload(false, false)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }