












import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import {Routes} from "@/helpers/constants";
import SampleOrderHelper from "@/helpers/SampleOrderHelper";

@Component({
	components: {AsiBtn}
})
export default class StampButton extends Vue {

	private icons = Icon;

	private get showBadge(): boolean {
		return this.numPositions > 0;
	}

	private get numPositions(): number {
		return this.$store.getters['sampleOrder/items'].length ?? 0;
	}

	private showSampleOrderForm(): void {
		if (this.$router.currentRoute.name === Routes.sampleOrder) {
			return;
		}
		this.$router.push(SampleOrderHelper.detailRoute());
	}

}
