









import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import AddressHelper from "@/helpers/AddressHelper";
import {IOrderAdmin} from "@/models/order/OrderAdminModels";
import Icon from "@/plugins/icons";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";

@Component({
	components: {AsiCardDetailEntry, AsiCardDetail}
})
export default class OrderCardShipment extends Vue {

	@Prop({required: true})
	public order!: IOrderAdmin;

	private icons = Icon;

	private get shipmentType(): string | null {
		return TranslatedValueHelper.get(this.order.shipmentType.name, this.$i18n.locale, true);
	}

	// noinspection JSMethodCanBeStatic
	private get addressLines(): string[] {
		return AddressHelper.addressLines(this.order.shippingAddress);
	}

}
