
















































import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import Icon from "../../plugins/icons";
import AsiBreadcrumbsEntry from "@/components/common/AsiBreadcrumbsEntry.vue";

@Component({
	components: {AsiBreadcrumbsEntry, AsiBreadCrumbsEntry: AsiBreadcrumbsEntry}
})
export default class AsiBreadcrumbs extends Vue {

	@Prop({type: Array, default: () => []})
	public entries!: any[];

	@Prop({type: Boolean, default: false})
	public collapse!: boolean;

	@Prop({type: Boolean, default: false})
	public hideIcons!: boolean;

	@Prop({type: Boolean, default: false})
	public small!: boolean;

	@Prop({type: Boolean, default: false})
	public linkEntries!: boolean;

	private icons = Icon;

	private get collapsedEntries(): any[] {
		const ret: any[] = [];
		ret.push(this.entries[0]);
		ret.push('...');
		ret.push(this.entries[this.entries.length - 1]);
		return ret;
	}

}
