























import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogFormLayout from "../../common/AsiDialogFormLayout.vue";
import AsiDialogForm from "../../common/AsiDialogForm.vue";
import AsiTextField from "../../common/AsiTextField";
import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
import CategoryUpdateMetaData from "../../../models/category/CategoryUpdateMetaData";
@Component({
	components: {AsiTextField, AsiDialogFormLayout}
})
export default class CategoryUpdateDialogMetaData extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public category!: ICategoryAdmin;

	private model: CategoryUpdateMetaData | null = null;

	@Watch('category', {deep: true, immediate: true})
	private onCategoryChanged(): void {
		this.setItemInternal();
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.setItemInternal();
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private setItemInternal(): void {
		if (this.model === null) {
			this.model = new CategoryUpdateMetaData(this.category);
			this.valid = true;
		} else {
			this.model.setDetailModel(this.category);
		}
	}

	private requestSave(): void {
		if (this.model === null) return;

		this.loading = true;
		this.$categoryServiceAdmin.updateMetaData(this.category.id, this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}
}
