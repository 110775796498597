


































































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {IOrderShop} from "@/models/order/OrderShopModels";
import AsiListTableHeader from "@/components/common/AsiListTableHeader";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import {UnitOfMeasure} from "@/helpers/constants";
import VueI18n from "vue-i18n";
import EnumHelper from "@/helpers/EnumHelper";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import Icon from "@/plugins/icons";
import {IPrice} from "@/models/price/PriceModels";
import TotalHelper, {CurrencyAmount} from "@/helpers/TotalHelper";
import AsiBtn from "@/components/common/AsiBtn.vue";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import ItemHelper from "@/models/item/ItemHelper";
import {IFrameAssemblySimple} from "@/models/frame-assembly/FrameAssemblyModels";
import CartHelper from "@/helpers/CartHelper";
import TranslateResult = VueI18n.TranslateResult;


class PositionEntry {
	public id: string | null;
	public title!: string | TranslateResult;
	public subtitle: string | TranslateResult | null = null;
	public price: IPrice | null = null;
	public trimmingCost: IPrice | null = null;
	public positionIndex: number | null = null;
	public quantity: number | null = null;
	public salesUnitOfMeasure: UnitOfMeasure | null = null;
	public frameAssembly: IFrameAssemblySimple | null = null;

	public constructor(id: string | null, title: string | TranslateResult) {
		this.id = id;
		this.title = title;
	}
}

@Component({
	components: {AsiBtn, AsiAvatar, AsiCard}
})
export default class OrderCardPositions extends Mixins(ResponsiveChecks) {

	@Prop({type: Object, required: true})
	public order!: IOrderShop;

	@Prop({type: Boolean, default: false})
	public reorder!: boolean;

	private icons = Icon;
	private options: AsiListTableOptions = new AsiListTableOptions();

	public created(): void {
		this.options.sortBy = ['positionIndex'];
		this.options.sortDesc = [false];
	}

	private get amountsInclVat(): boolean {
		return this.$store.state.ui.amountsInclVat;
	}

	private set amountsInclVat(value: boolean) {
		this.$store.commit('ui/setAmountsInclVat', value);
	}

	private get totalEntriesExclVat(): CurrencyAmount[] {
		return TotalHelper.totalExclVat(this.order, true);
	}

	private get totalEntriesInclVat(): CurrencyAmount[] {
		return TotalHelper.totalInclVat(this.order, true);
	}

	private get positionsFinal(): PositionEntry[] {
		const positions: PositionEntry[] = this.order.positions.map(p => {
			const entry = new PositionEntry(p.id, TranslatedValueHelper.get(p.item.name, this.$i18n.locale, true) ?? '?');
			entry.subtitle = p.customerReference;
			entry.price = p.price;
			entry.trimmingCost = p.trimmingCost;
			entry.positionIndex = p.positionIndex;
			entry.quantity = p.quantity;
			entry.salesUnitOfMeasure = p.item.salesUnitOfMeasure;
			entry.frameAssembly = p.frameAssembly;
			return entry;
		});

		// create trimming cost summary position
		const trimmingCostPrices = (this.order.positions
			.map(pos => pos.trimmingCost)
			.filter(tc => tc !== null) as IPrice[]);

		if (trimmingCostPrices.length > 0) {
			const totalAmount = trimmingCostPrices.map(tc => tc.amount).reduce((acc: number, cur: number) => acc + cur, 0);
			const qty = trimmingCostPrices.length;

			const trimmingCostSummaryPosition = new PositionEntry(null, this.$t('globalConfig.terms.trimmingCost'));
			trimmingCostSummaryPosition.price = {
				vat: trimmingCostPrices[0].vat,
				currency: trimmingCostPrices[0].currency,
				amount: totalAmount,
			} as IPrice;
			trimmingCostSummaryPosition.quantity = qty;
			trimmingCostSummaryPosition.salesUnitOfMeasure = UnitOfMeasure.unitPiece;
			positions.push(trimmingCostSummaryPosition);
		}

		if (this.order.promotionalItem !== null) {
			const promoEntry = new PositionEntry('promotionalItem', this.$t('item.promotionalItem.singular'));
			promoEntry.subtitle = TranslatedValueHelper.get(this.order.promotionalItem.item.name, this.$i18n.locale, true);
			promoEntry.quantity = 1;
			positions.push(promoEntry);
		}

		if (this.order.minimumOrderSurcharge !== null) {
			const surchargeEntry = new PositionEntry('minimumOrderSurcharge', this.$t('cart.minimumOrderSurcharge'));
			surchargeEntry.price = this.order.minimumOrderSurcharge.price;
			surchargeEntry.quantity = 1;
			positions.push(surchargeEntry);
		}

		if (this.order.bulkyGoodsSurcharge !== null) {
			const surchargeEntry = new PositionEntry('bulkyGoodsSurcharge', this.$t('cart.bulkyGoodsSurcharge'));
			surchargeEntry.price = this.order.bulkyGoodsSurcharge.price;
			surchargeEntry.quantity = 1;
			positions.push(surchargeEntry);
		}

		const postageEntry = new PositionEntry('postage', this.$t('shipmentType.postage'));
		postageEntry.subtitle = TranslatedValueHelper.get(this.order.shipmentType.name, this.$i18n.locale, true);
		postageEntry.price = this.order.shipmentType.postage;
		positions.push(postageEntry);

		return positions;
	}

	private createHeaders(): AsiListTableHeader[] {
		return [
			new AsiListTableHeader(this.$t('cartPosition.positionIndex'), 'positionIndex', true, false, 'center', '7rem'),
			new AsiListTableHeader(this.$t('cartPosition.quantity'), 'quantity', false, false, 'right', '7rem'),
			new AsiListTableHeader(this.$t('item.singular'), 'item', true, false, 'left'),
			new AsiListTableHeader(this.$t('item.unitPrice'), 'pricePerUnit', false, false, 'right', '10rem'),
			new AsiListTableHeader(this.$t('item.price'), 'price', true, false, 'right', '10rem'),
		];
	}

	// noinspection JSMethodCanBeStatic
	private unitLabel(unit: UnitOfMeasure): string | TranslateResult | null {
		return EnumHelper.textFromValue(UnitOfMeasure, unit, true);
	}

	private sortEntries(entries: PositionEntry[], sortBy: string[], sortDesc: boolean[]): PositionEntry[] {
		const column = sortBy.length > 0 ? sortBy[0] : 'user';
		const desc = sortDesc.length > 0 ? sortDesc[0] : true;

		return entries.sort((a: PositionEntry, b: PositionEntry) => {
			if (a.positionIndex === null && b.positionIndex === null) return 0;
			if (a.positionIndex === null) return 1;
			if (b.positionIndex === null) return -1;

			switch (column) {
				case 'item':
					return a.title.toString().localeCompare(b.title.toString()) * (desc ? -1 : 1);
				default:
					//@ts-ignore
					return desc ? (b[column] < a[column] ? -1 : 1) : (a[column] < b[column] ? -1 : 1);
			}
		});
	}

	private finalAmount(price: IPrice): number {
		return price.amount + (this.amountsInclVat ? TotalHelper.vatAmount(price) : 0);
	}

	private showItem(positionEntry: PositionEntry): void {
		let position = this.order.positions
			.find(position => position.id === positionEntry.id);

		let itemNumber = position !== undefined ? position.item.itemNumber : null;

		if (itemNumber === null) return;
		this.$router.push(ItemHelper.detailRoute(itemNumber));
	}

	private execReorder(): void {
		this.$orderServiceShop.reorder(this.order.id)
			.then(res => {
				this.$store.commit('cart/setActiveCartId', res.id);
				this.$store.dispatch('cart/loadCarts');
				this.$router.push(CartHelper.checkoutRoute(res.id));
			})
			.catch(() => console.error('reorder failed'));
	}

}
