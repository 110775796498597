














































































import {Component, Emit, Prop} from "vue-property-decorator";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import UserCreate from "@/models/user/UserCreate";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import Snackbar from "@/helpers/Snackbar";
import AsiTextField from "@/components/common/AsiTextField";
import AsiLocaleAutocompleteInput from "@/components/common/AsiLocaleAutocompleteInput.vue";
import AsiAlert from "@/components/common/AsiAlert.vue";
import AsiSelectSimple from "@/components/common/AsiSelectSimple";
import CustomerAutocompleteInput from "@/components/customer/admin/CustomerAutocompleteInput.vue";
import StringHelper from "@/helpers/StringHelper";
import AsiRadioGroup from "@/components/common/AsiRadioGroup";
import SalutationInput from "@/components/common/SalutationInput.vue";

@Component({
	components: {
		SalutationInput,
		AsiRadioGroup,
		CustomerAutocompleteInput,
		AsiSelectSimple,
		AsiAlert, AsiLocaleAutocompleteInput, AsiTextField, AsiDialogFormLayout, AsiDialogForm
	}
})
export default class UserCreateDialog extends AsiDialogForm {

	@Prop({type: String, default: null})
	public customerId!: string | null;

	@Prop({type: Boolean, default: false})
	public showCustomerSelect!: boolean;

	private model: UserCreate = new UserCreate();

	@Emit('cancel')
	public cancel(event: Event): Event {
		const form = this.$refs.form as HTMLFormElement;
		this.reset([this.model], form);
		return event;
	}

	@Emit('save')
	public save(id: string): string {
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		this.model = new UserCreate();
		return id;
	}

	private requestSave(): void {
		if (!this.valid || null === this.customerId || StringHelper.isEmpty(this.customerId)) return;

		this.loading = true;
		this.$customerServiceShop.createUser(this.customerId, this.model)
			.then(res => {
				this.$store.dispatch('user/loadUsers');
				this.save(res.id);
			})
			.catch(err => {
				let errorMessage = null;
				const errorData = err.response.data;
				if (Array.isArray(errorData)
					&& errorData.length > 0
					&& errorData[0].toLowerCase() !== 'unspecified error') {
					errorMessage = errorData.join(', ');
				}

				Snackbar.createError(errorMessage);
			})
			.finally(() => this.loading = false);
	}

}
