




























import Vue from 'vue';
import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiAlert from "@/components/common/AsiAlert.vue";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import VueI18n from "vue-i18n";
import Icon from "@/plugins/icons";
import {StockStatus} from "@/helpers/constants";
import EnumHelper from "@/helpers/EnumHelper";
import TranslateResult = VueI18n.TranslateResult;
import i18n from "@/plugins/i18n";

@Component({
	components: {AsiAlert}
})
export default class ItemAlertStockStatus extends Vue {

	@Prop({type: Object, required: true})
	public item!: IItemShopListEntry;

	@Prop({type: Boolean, default: false})
	public tile!: boolean;

	@Prop({type: Boolean, default: false})
	public noBottomMargin!: boolean;

	@Prop({type: Boolean, default: false})
	public small!: boolean;

	@Prop({type: Boolean, default: false})
	public simple!: boolean;

	@Prop({type: Boolean, default: false})
	public showExternalStockQuantity!: boolean;

	private showLabelText = false;

	private get type(): string {
		const stockStatus = this.showExternalStockQuantity ? this.item.externalStockStatus : this.item.stockStatus;
		switch (stockStatus) {
			case StockStatus.stockHigh:
				return 'success';
			case StockStatus.stockMedium:
			case StockStatus.stockLow:
				return 'warning';
			case StockStatus.stockNone:
				return 'error';
			default:
				return 'info';
		}
	}

	private get label(): string | TranslateResult | null {
		const stockStatus = this.showExternalStockQuantity ? this.item.externalStockStatus : this.item.stockStatus;
		const prefix = this.showExternalStockQuantity ? `${i18n.t('item.externalWarehouse')}: ` : '';
		return prefix + EnumHelper.textFromValue(StockStatus, stockStatus, true);
	}

	// noinspection JSMethodCanBeStatic
	private get icon(): string {
		return Icon.shipping;
	}

	public get showReplacementTime(): boolean {
		if (this.showExternalStockQuantity || this.item.deliveryTime === null) {
			return false;
		}
		return this.item.stockStatus === StockStatus.stockNone && this.item.deliveryTime > 0;
	}

	private hideLabelText(): void {
		setTimeout(() => {
			this.showLabelText = false;
		}, 200);
	}

	@Watch('type', {immediate: true})
	private onTypeChanged(): void {
	    this.emitType();
	}

	@Emit('type')
	public emitType(): string {
	    return this.type;
	}

}
