import Vue from 'vue';
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router';
import {Routes} from "@/helpers/constants";
import HomeShop from "@/views/shop/home/Home.vue";
import HomeAdmin from "@/views/admin/home/Home.vue";
import i18n from "@/plugins/i18n";
import store from '@/store/index';
import CategoriesShop from "@/views/shop/category/Categories.vue";
import CategoryShop from "@/views/shop/category/Category.vue";
import CategoriesAdmin from "@/views/admin/category/Categories.vue";
import CategoryAdmin from "@/views/admin/category/Category.vue";
import UsersAdmin from "@/views/admin/user/Users.vue";
import UserAdmin from "@/views/admin/user/User.vue";
import CustomersAdmin from "@/views/admin/customer/Customers.vue";
import CustomerAdmin from "@/views/admin/customer/Customer.vue";
import ItemsAdmin from "@/views/admin/item/Items.vue";
import ItemAdmin from "@/views/admin/item/Item.vue";
import CartsAdmin from "@/views/admin/cart/Carts.vue";
import CartAdmin from "@/views/admin/cart/Cart.vue";
import OrdersAdmin from "@/views/admin/order/Orders.vue";
import OrderAdmin from "@/views/admin/order/Order.vue";
import Item from "@/views/shop/item/Item.vue";
import Login from "@/views/shop/user/Login.vue";
import Registration from "@/views/shop/user/Registration.vue";
import RegistrationConfirm from "@/views/shop/user/RegistrationConfirm.vue";
import User from "@/views/shop/user/User.vue";
import Customer from "@/views/shop/customer/Customer.vue";
import Orders from "@/views/shop/order/Orders.vue";
import Order from "@/views/shop/order/Order.vue";
import Carts from "@/views/shop/cart/Carts.vue";
import RoutesHelper from "@/helpers/RoutesHelper";
import Debug from "@/views/admin/debug/Debug.vue";
import Checkout from "@/views/shop/checkout/Checkout.vue";
import Settings from "@/views/admin/settings/Settings.vue";
import TermsAndConditions from "@/views/shop/legal/TermsAndConditions.vue";
import LegalNotice from "@/views/shop/legal/LegalNotice.vue";
import Faq from "@/views/shop/faq/Faq.vue";
import {PositionResult} from "vue-router/types/router";
import ShoppingLists from "@/views/admin/shopping-list/ShoppingLists.vue";
import ShoppingList from "@/views/admin/shopping-list/ShoppingList.vue";
import SampleOrder from "@/views/shop/sample-order/SampleOrder.vue";
import UserUpdatePersonalData from "@/models/user/UserUpdatePersonalData";
import LocaleHelper from "@/helpers/LocaleHelper";
import NotFound from "@/views/NotFound.vue";
import Privacy from "@/views/shop/legal/Privacy.vue";
import Contact from "@/views/shop/contact/Contact.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	//shop
	{
		path: '/',
		redirect: LocaleHelper.extractLanguage(LocaleHelper.determinePreferredLocale())
	},
	{
		path: '/:language',
		name: Routes.home,
		component: HomeShop,
		meta: {
			title: 'pageTitles.shop.home',
		},
	},
	{
		path: '/:language/category',
		name: Routes.categories,
		component: CategoriesShop,
		meta: {
			title: 'category.plural',
		},
	},
	{
		path: '/:language/category/:canonical',
		name: Routes.category,
		component: CategoryShop,
		props: true,
		meta: {
			title: 'category.singular'
		},
	},
	{
		path: '/:language/item/:itemNumber',
		name: Routes.item,
		component: Item,
		props: true,
		meta: {
			title: 'item.singular'
		},
	},
	{
		path: '/:language/user/:id',
		name: Routes.user,
		component: User,
		props: true,
		meta: {
			title: 'user.terms.profile',
			authenticated: true,
		},
	},
	{
		path: '/:language/customer/:id',
		name: Routes.customer,
		component: Customer,
		props: true,
		meta: {
			title: 'customer.singular',
			authenticated: true,
		},
	},
	{
		path: '/:language/orders',
		name: Routes.orders,
		component: Orders,
		meta: {
			title: 'order.plural',
			authenticated: true,
		},
	},
	{
		path: '/:language/carts',
		name: Routes.carts,
		component: Carts,
		meta: {
			title: 'cart.plural',
			authenticated: true,
		},
	},
	{
		path: '/:language/orders/:id',
		name: Routes.order,
		component: Order,
		props: true,
		meta: {
			title: 'order.singular',
		},
	},
	{
		path: '/:language/checkout/:id',
		name: Routes.checkout,
		component: Checkout,
		props: true,
		meta: {
			title: 'pageTitles.shop.checkout',
		},
	},
	{
		path: '/:language/sample-order',
		name: Routes.sampleOrder,
		component: SampleOrder,
		meta: {
			title: 'sampleOrder.singular',
		},
	},
	{
		path: '/:language/login',
		name: Routes.login,
		component: Login,
		meta: {
			title: 'pageTitles.shop.login',
			authenticated: false,
		},
	},
	{
		path: '/:language/registration',
		name: Routes.registration,
		component: Registration,
		meta: {
			title: 'pageTitles.shop.registration',
			authenticated: false,
		},
	},
	{
		path: '/:language/registration/confirm',
		name: Routes.registrationConfirm,
		component: RegistrationConfirm,
		meta: {
			title: 'pageTitles.shop.registrationConfirm',
			authenticated: false,
		},
	},
	{
		path: '/:language/contact',
		name: Routes.contact,
		component: Contact,
		meta: {
			title: 'pageTitles.shop.contact',
		},
	},
	{
		path: '/:language/frequently-asked-questions',
		name: Routes.faq,
		component: Faq,
		meta: {
			title: 'pageTitles.shop.faq',
		},
	},
	{
		path: '/:language/privacy',
		name: Routes.privacy,
		component: Privacy,
		meta: {
			title: 'pageTitles.shop.privacy',
		},
	},
	{
		path: '/:language/terms-and-conditions',
		name: Routes.termsAndConditions,
		component: TermsAndConditions,
		meta: {
			title: 'pageTitles.shop.termsAndConditions',
		},
	},
	{
		path: '/:language/legal-notice',
		name: Routes.legalNotice,
		component: LegalNotice,
		meta: {
			title: 'pageTitles.shop.legalNotice',
		},
	},

	{
		path: RoutesHelper.notFoundUri(),
		name: Routes.notFound,
		component: NotFound,
		meta: {
			title: 'pageTitles.notFound',
		},
	},

	//admin
	{
		path: '/:language/admin',
		name: Routes.adminHome,
		component: HomeAdmin,
		meta: {
			title: 'pageTitles.admin.home',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/user',
		name: Routes.adminUsers,
		component: UsersAdmin,
		meta: {
			title: 'user.plural',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/user/:id',
		name: Routes.adminUser,
		component: UserAdmin,
		props: true,
		meta: {
			title: 'user.singular',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/customer',
		name: Routes.adminCustomers,
		component: CustomersAdmin,
		meta: {
			title: 'customer.plural',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/customer/:id',
		name: Routes.adminCustomer,
		component: CustomerAdmin,
		props: true,
		meta: {
			title: 'customer.singular',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/category',
		name: Routes.adminCategories,
		component: CategoriesAdmin,
		meta: {
			title: 'category.plural',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/category/:id',
		name: Routes.adminCategory,
		component: CategoryAdmin,
		props: true,
		meta: {
			title: 'category.singular',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/item',
		name: Routes.adminItems,
		component: ItemsAdmin,
		meta: {
			title: 'item.plural',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/item/:id',
		name: Routes.adminItem,
		component: ItemAdmin,
		props: true,
		meta: {
			title: 'item.singular',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/cart',
		name: Routes.adminCarts,
		component: CartsAdmin,
		meta: {
			title: 'cart.plural',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/cart/:id',
		name: Routes.adminCart,
		component: CartAdmin,
		props: true,
		meta: {
			title: 'cart.singular',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/shoppinglist',
		name: Routes.adminShoppingLists,
		component: ShoppingLists,
		meta: {
			title: 'shoppingList.plural',
			authenticated: true,
			adminLayout: true,
		}
	},
	{
		path: '/:language/admin/shoppinglist/:id',
		name: Routes.adminShoppingList,
		component: ShoppingList,
		props: true,
		meta: {
			title: 'shoppingList.singular',
			authenticated: true,
			adminLayout: true,
		}
	},
	{
		path: '/:language/admin/order',
		name: Routes.adminOrders,
		component: OrdersAdmin,
		meta: {
			title: 'order.plural',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/order/:id',
		name: Routes.adminOrder,
		component: OrderAdmin,
		props: true,
		meta: {
			title: 'order.singular',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/settings',
		name: Routes.adminSettings,
		component: Settings,
		meta: {
			title: 'pageTitles.admin.settings',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '/:language/admin/debug',
		name: Routes.debug,
		component: Debug,
		meta: {
			title: 'pageTitles.admin.debug',
			authenticated: true,
			adminLayout: true,
		},
	},
	{
		path: '*',
		name: Routes.catchAll,
		beforeEnter: () => {
			const url = RoutesHelper.notFoundUri().replace(':language', LocaleHelper.extractLanguage(LocaleHelper.determinePreferredLocale()));
			window.location.replace(url); //enforce refresh for 404 (see nginx.conf)
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition): PositionResult | null {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				x: 0,
				y: 0,
			};
		}
	},
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {

	if (from.params.language !== to.params.language) {
		const language = to.params.language;

		// if locale not supported -> 404
		if (!LocaleHelper.supportedLanguages().includes(language)) {
			next(RoutesHelper.catchAllRoute());
			return;
		}

		const locale = LocaleHelper.localeFromLanguage(language) ?? LocaleHelper.determinePreferredLocale();
		if (store.getters['user/isLoggedIn']) {
			const user = store.getters['user/model'];
			const updateModel = new UserUpdatePersonalData(user);
			updateModel.locale = locale;
			await Vue.$userServiceShop.updatePersonalData(user.id, updateModel);
			await store.dispatch('user/loadLoggedInUserData');
		} else {
			store.commit('ui/setLocale', locale, {root: true});
		}

		// set document language
		document.documentElement.lang = LocaleHelper.extractLanguage(locale);
	}

	//set page title
	const tenant = Vue.$isHerzogShop ? 'herzog'
		: Vue.$isMkShop ? 'mk'
			: null;
	const title = i18n.t(`application.${tenant}`).toString();

	if (to.name !== undefined && to.name !== null) {
		RoutesHelper.documentTitle(to.params.id, to.name)
			.then(res => {
				if (res !== null) {
					document.title = res + ' | ' + title;
				} else if (to.meta && to.meta.title !== undefined) {
					document.title = i18n.t(to.meta.title).toString() + ' | ' + title;
				}
			});
	}
	if (to.meta && to.meta.title !== undefined) {
		document.title = i18n.t(to.meta.title).toString() + ' | ' + title;
	}


	//reset fullscreen dialog state
	store.commit('ui/resetFullScreenDialogOpenState');

	//check authentication requirements and react accordingly
	const isAuthenticated = store.getters['user/isLoggedIn'];
	const authenticationNeeded: boolean | null = to.meta?.authenticated ?? null;
	if (authenticationNeeded === true && !isAuthenticated) {
		next(RoutesHelper.loginShopRoute());
	} else if (authenticationNeeded === false && isAuthenticated) {
		return;
	}

	next();
});

router.afterEach((to: Route) => {
	//remove existing hreflang tags and re-add
	//note: this task is added to the afterEach hook to retrieve the complete URL after navigation.
	LocaleHelper.removeHrefLangTags();
	LocaleHelper.createHreflangTags(to.params.language);
});

export default router;
