














import {Component, Emit, Prop} from "vue-property-decorator";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import {IUserShop} from "@/models/user/UserShopModels";
import {IUserAdmin} from "@/models/user/UserAdminModels";
import Icon from "@/plugins/icons";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import UserUpdateDialogName from "@/components/user/UserUpdateDialogName.vue";
import DialogHandler from "@/components/common/DialogHandler";
import Vue from "vue";
import {Salutation} from "@/helpers/constants";
import EnumHelper from "@/helpers/EnumHelper";

@Component({
	components: {UserUpdateDialogName, AsiCardDetailEntry, AsiCardDetail}
})
export default class UserCardName extends Vue {

	@Prop({type: Object, required: true})
	public user!: IUserShop | IUserAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	@Emit('change')
	public change(): void {
		return;
	}

	private getSalutation(salutation: Salutation | null): string {
		return EnumHelper.textFromValue(Salutation, salutation, true) ?? '';
	}

}
