import { render, staticRenderFns } from "./MailTemplateInfoCard.vue?vue&type=template&id=f4caeb84&scoped=true"
import script from "./MailTemplateInfoCard.vue?vue&type=script&lang=ts"
export * from "./MailTemplateInfoCard.vue?vue&type=script&lang=ts"
import style0 from "./MailTemplateInfoCard.vue?vue&type=style&index=0&id=f4caeb84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4caeb84",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSimpleTable})
