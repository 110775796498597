

































































































































































import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import RightChecks from "@/mixins/RightChecks.vue";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
import ItemQuantitySelection from "@/components/item/shop/ItemQuantitySelection.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import Icon from "@/plugins/icons";
import {ICartShopListEntry} from "@/models/cart/CartShopModels";
import CartHelper from "@/helpers/CartHelper";
import {ICartPositionShopSimple} from "@/models/cart-position/CartPositionShopModels";
import Snackbar from "@/helpers/Snackbar";
import {IItemShopSimple} from "@/models/item/ItemShopModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import VueI18n from "vue-i18n";
import {Routes} from "@/helpers/constants";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import CartPositionCustomerReferenceInput from "@/components/cart-position/CartPositionCustomerReferenceInput.vue";
import CartTotalEntries from "@/components/cart/shop/CartTotalEntries.vue";
import {IPrice} from "@/models/price/PriceModels";
import TotalHelper from "@/helpers/TotalHelper";
import FrameAssemblyUpdateDialog from "@/components/frame-assembly/FrameAssemblyUpdateDialog.vue";
import UserUpdateDialogName from "@/components/user/UserUpdateDialogName.vue";
import ItemHelper from "@/models/item/ItemHelper";
import FrameAssemblyUpdateButton from "@/components/frame-assembly/FrameAssemblyUpdateButton.vue";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {
		FrameAssemblyUpdateButton,
		UserUpdateDialogName,
		FrameAssemblyUpdateDialog,
		CartTotalEntries,
		CartPositionCustomerReferenceInput,
		AsiTextFieldSimple, AsiAvatar, AsiBtn, ItemQuantitySelection, AsiConfirmDialog
	}
})
export default class CartContent extends mixins(RightChecks) {

	@Prop({required: true})
	public id!: string | null;

	@Prop({type: Boolean, default: false})
	public hideCheckoutButton!: boolean;

	@Prop({type: Boolean, default: false})
	public showShipment!: boolean;

	@Prop({type: Boolean, default: false})
	public showCustomerReferences!: boolean;

	private icons = Icon;

	private get cart(): ICartShopListEntry | null {
		return this.id === null ? null : this.$store.getters['cart/cartById'](this.id);
	}

	private get amountsInclVat(): boolean {
		return this.$store.state.ui.amountsInclVat;
	}

	private set amountsInclVat(value: boolean) {
		this.$store.commit('ui/setAmountsInclVat', value);
	}

	private get shipmentTypeLabel(): string | TranslateResult | null {
		if (this.cart === null || this.cart.shipmentType === null) return null;
		return TranslatedValueHelper.get(this.cart.shipmentType.name, this.$i18n.locale, true);
	}

	private get canOrder(): boolean {
		return this.cart !== null
			&& this.cart.positions.length > 0
			&& !this.cart.positions.some(pos => pos.price === null);
	}

	private get trimmingCost(): { qty: number, totalAmount: number, currencyCode: string | null } {
		if (this.cart === null || this.cart.positions.length < 1) {
			return {qty: 0, totalAmount: 0, currencyCode: null};
		}

		const trimmingCostPrices = (this.cart.positions
			.map(pos => pos.trimmingCost)
			.filter(tc => tc !== null) as IPrice[]);

		if (trimmingCostPrices.length < 1) {
			return {qty: 0, totalAmount: 0, currencyCode: null};
		}

		const currency = trimmingCostPrices[0].currency;
		const trimmingCostAmounts = trimmingCostPrices.map(tc => this.finalAmount(tc));

		const qty = trimmingCostAmounts.length;
		const amount = trimmingCostAmounts.reduce((acc: number, cur: number) => acc + cur, 0);

		return {qty: qty, totalAmount: amount, currencyCode: currency.currencyCode};
	}

	private updateQuantity(position: ICartPositionShopSimple, quantity: number): void {
		this.$store.dispatch('cart/updatePositionQuantity', {
			positionId: position.id,
			quantity: quantity,
		}).catch(() => Snackbar.updateError());
	}

	private removePosition(position: ICartPositionShopSimple): void {
		this.$store.dispatch('cart/removePosition', position.id).catch(() => Snackbar.deleteError());
	}

	private itemTitle(item: IItemShopSimple): string | TranslateResult | null {
		return TranslatedValueHelper.get(item.name, this.$i18n.locale);
	}

	private showCheckout(): void {
		if (this.$route.name === Routes.checkout || this.cart === null) return;
		this.$router.push(CartHelper.checkoutRoute(this.cart.id));
	}

	private showItem(position: ICartPositionShopSimple): void {
		if (this.$router.resolve(ItemHelper.detailRoute(position.item.itemNumber)).route.path === this.$route.path) return;
		this.$router.push(ItemHelper.detailRoute(position.item.itemNumber));
	}

	private finalAmount(price: IPrice): number {
		return price.amount + (this.amountsInclVat ? TotalHelper.vatAmount(price) : 0);
	}

}
