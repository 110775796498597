























import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import Icon from "@/plugins/icons";
import AsiCard from "@/components/common/AsiCard.vue";

class faqs {
	title!: string;
	faq!: { question: string, answer: string }[];
}

@Component({
	components: {AsiCard}
})
export default class Faqs extends Vue {

	private icons = Icon;
	private faqs!: faqs

	public created(): void {
		if (this.$isHerzogShop) {
			this.faqs = this.$t('faqs-herzog') as unknown as faqs;
		} else {
			this.faqs = this.$t('faqs-mk') as unknown as faqs;
		}
	}

}
