







































import {Component, Prop, Watch} from "vue-property-decorator";
import Icon from "@/plugins/icons";
import Snackbar from "@/helpers/Snackbar";
import {IUserAdmin} from "@/models/user/UserAdminModels";
import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
import UserHelper from "@/models/user/UserHelper";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import AsiTabs from "@/components/common/AsiTabs";
import {AttachmentMediaSize, Routes, UserState} from "@/helpers/constants";
import PathEntry from "@/models/PathEntry";
import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
import UserTabDetails from "@/components/user/admin/UserTabDetails.vue";
import UserTabRights from "@/components/user/admin/UserTabRights.vue";
import AsiMenuItem from "@/components/common/AsiMenuItem.vue";
import {mixins} from "vue-class-component";
import RightChecks from "@/mixins/RightChecks.vue";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";

@Component({
	components: {
		AsiConfirmDialog,
		AsiMenuItem,
		UserTabRights, UserTabDetails, AsiBreadcrumbs, AsiTabs, AsiContentContainer, AsiPageTitle
	}
})
export default class User extends mixins(RightChecks) {

	@Prop({type: String, required: true})
	public id!: string;

	private icons = Icon;
	private userStates = UserState;
	private loading: boolean = false;
	private user: IUserAdmin | null = null;

	private get fullName(): string | null {
		return UserHelper.fullName(this.user);
	}

	private get avatarUrl(): string | null {
		return this.user === null ? null : UserHelper.avatarUrl(this.user, AttachmentMediaSize.s);
	}

	private get path(): PathEntry[] {
		return this.user === null ? [] : UserHelper.path(this.user, true);
	}

	private get activeTab(): number {
		return this.$store.getters['ui/activeTabAdmin']('user');
	}

	private set activeTab(index: number) {
		this.$store.commit('ui/activeTabAdmin', {key: 'user', activeTab: index});
	}

	private get userState(): UserState | undefined {
		return this.user?.state;
	}

	@Watch('id', {immediate: true})
	private onIdChanged(): void {
		this.loadUser();
	}

	private loadUser(): void {
		this.loading = true;
		this.$userServiceAdmin.user(this.id)
			.then(model => this.user = model)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loading = false);
	}

	private performDelete(): void {
		const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;


		confirm.openDialog().then((res: boolean) => {
			if (!res) return;

			if (this.user !== null) {
				this.loading = true;
				this.$userServiceShop.delete(this.user.id)
					.then(() => {
						this.$router.push({name: Routes.adminUsers});
					})
					.catch(() => Snackbar.deleteError())
					.finally(() => this.loading = false);

			}
		});
	}

	private performStateChange(newState: UserState): void {
		if (this.userState !== undefined) {

			this.loading = true;
			this.$userServiceAdmin.updateState(this.id, newState)
				.then(() => this.loadUser())
				.catch(() => Snackbar.updateError())
				.finally(() => this.loading = false);
		}
	}

	private resendConfirmation(): void {
		if (this.user !== null) {
			this.$authService.resendRegistrationConfirmation(this.user.username)
				.then(() => Snackbar.show(this.$t('user.terms.resendConfirmationSuccess'), 'success'))
				.catch(() => Snackbar.updateError())
				.finally(() => this.loading = false);
		}
	}

}
