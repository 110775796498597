
























import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import {IItemAdmin} from "@/models/item/ItemAdminModels";
import ItemAttributes from "@/components/item/ItemAttributes.vue";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import Icon from "@/plugins/icons";
import RightChecks from "@/mixins/RightChecks.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import ItemUpdateAttributes from "@/models/item/ItemUpdateAttributes";
import Snackbar from "@/helpers/Snackbar";

@Component({
	components: {AsiBtn, AsiContentContainer, ItemAttributes}
})
export default class ItemTabAttributes extends mixins(ResponsiveChecks, RightChecks) {

	@Prop({required: true})
	public item!: IItemAdmin | null;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	private icons = Icon;
	private loadingInternal: boolean = false;
	private edit: boolean = false;
	private updateModel: ItemUpdateAttributes | null = null;

	private get columns(): number {
		if (this.sMobile) return 1;
		if (this.sSemiMobile) return 2;
		return 4;
	}

	@Watch('item', {deep: true, immediate: true})
	private onCategoryChanged(): void {
		this.initUpdateModel();
	}

	@Watch('loading')
	private onLoadingChanged(value: boolean): void {
		this.loadingInternal = value;
	}

	@Watch('loadingInternal')
	private onLoadingInternalChanged(value: boolean): void {
		if (this.loading !== value) this.$emit('update:loading', value);
	}

	@Emit('change')
	public change(): void {
		return;
	}

	private initUpdateModel(): void {
		this.updateModel = this.item === null ? null : new ItemUpdateAttributes(this.item);
	}

	private requestCancel(): void {
		this.initUpdateModel();
		this.edit = false;
	}

	private requestSave(): void {
		if (this.item === null || this.updateModel === null) return;

		this.loadingInternal = true;
		this.$itemServiceAdmin.updateAttributes(this.item.id, this.updateModel)
			.then(() => {
				this.initUpdateModel();
				this.edit = false;
				this.change();
			})
			.catch(() => Snackbar.updateError())
			.finally(() => this.loadingInternal = false);
	}

	private get isReadonly(): boolean {
		return this.item?.externalId !== null;
	}

}
