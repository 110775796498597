























import {Component, Prop} from 'vue-property-decorator';
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";
import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
import GlobalConfigCardSurcharge from "@/components/global-config/admin/GlobalConfigCardSurcharge.vue";
import GlobalConfigCardExternalSource from "@/components/global-config/admin/GlobalConfigCardExternalSource.vue";
import RightChecks from "@/mixins/RightChecks.vue";
import {mixins} from "vue-class-component";
import GlobalConfigCardTrimmingCost from "@/components/global-config/admin/GlobalConfigCardTrimmingCost.vue";
import GlobalConfigCardBulkyGoodsSurcharge
	from "@/components/global-config/admin/GlobalConfigCardBulkyGoodsSurcharge.vue";

@Component({
	components: {
		GlobalConfigCardBulkyGoodsSurcharge,
		GlobalConfigCardExternalSource,
		GlobalConfigCardSurcharge, GlobalConfigCardTrimmingCost, AsiSkeletonCards, AsiContentContainer
	}
})
export default class SettingsTabGlobalConfig extends mixins(RightChecks) {

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private get globalConfig(): IGlobalConfigAdmin | null {
		return this.$store.state.globalConfig.modelAdmin;
	}

	private reloadGlobalConfig(): void {
		this.$store.dispatch('globalConfig/reload');
	}

}
