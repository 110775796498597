










































import Vue from 'vue';
import {Component, Emit, Prop} from 'vue-property-decorator';
import {IOrderAdmin} from "@/models/order/OrderAdminModels";
import OrderCardPayment from "@/components/order/admin/OrderCardPayment.vue";
import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import OrderCardBasicData from "@/components/order/admin/OrderCardBasicData.vue";
import OrderCardExport from "@/components/order/admin/OrderCardExport.vue";
import OrderCardShipment from "@/components/order/admin/OrderCardShipment.vue";
import OrderCardPositions from "@/components/order/admin/OrderCardPositions.vue";
import Icon from "@/plugins/icons";
import {OrderPaymentState} from "@/helpers/constants";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
import Snackbar from "@/helpers/Snackbar";
import OrderCardContactInformation from "@/components/order/admin/OrderCardContactInformation.vue";

@Component({
	components: {
		OrderCardContactInformation,
		AsiConfirmDialog,
		AsiBtn,
		OrderCardPositions,
		OrderCardShipment,
		OrderCardExport, OrderCardBasicData, AsiContentContainer, AsiSkeletonCards, OrderCardPayment
	}
})
export default class OrderTabDetails extends Vue {

	@Prop({required: true})
	public order!: IOrderAdmin | null;

	private icons = Icon;
	private loading: boolean = false;

	private get showPaidButton(): boolean {
		return this.order?.paymentType.paymentProviderCode === null && this.order.paymentState === OrderPaymentState.orderPaymentUnpaid;
	}

	private setOrderState(): void {
		const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
		confirm.openDialog().then((res: boolean) => {
			if (!res) return;
			this.loading = true;
			if (this.order !== null) {
				this.$orderServiceAdmin.paid(this.order.id)
					.then(() => this.change())
					.catch(() => Snackbar.deleteError())
					.finally(() => this.loading = false);
			}
		});
	}

	@Emit('change')
	public change(): void {
		return;
	}

}
