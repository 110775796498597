















import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";

@Component({
	components: {AsiBtn}
})
export default class CartButton extends Vue {

	private icons = Icon;

	private get loading(): boolean {
		return this.$store.state.cart.loading;
	}

	private get showBadge(): boolean {
		return this.numPositions > 0;
	}

	private get numPositions(): number {
		return this.$store.getters['cart/activeCart']?.positions.length ?? 0;
	}

	private toggleCartExpanded(): void {
		this.$store.commit('cart/setExpanded', !this.$store.state.cart.expanded);
	}

}
