
















import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import {IUserShop} from "@/models/user/UserShopModels";
import {IUserAdmin} from "@/models/user/UserAdminModels";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import AsiTextField from "@/components/common/AsiTextField";
import {ValidationRule} from "@/declarations/ValidationRule";
import UserRules from "@/models/user/UserRules";

@Component({
	components: {AsiTextField, AsiDialogFormLayout, AsiDialogForm}
})
export default class UserUpdateDialogContact extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public user!: IUserShop | IUserAdmin;

	private phone: string | null = null;

	private get rules(): ValidationRule[] {
		return UserRules.phoneRules();
	}

	@Watch('user.phone', {immediate: true})
	private onUserNameChanged(value: string): void {
		this.phone = value;
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.phone = this.user.phone;
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private requestSave(): void {
		this.loading = true;
		this.$userServiceAdmin.updatePhone(this.user.id, this.phone)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

}
