

























import Vue from 'vue';
import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialog from "../common/AsiDialog.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import {AttachmentMediaSize} from "@/helpers/constants";
import Icon from "@/plugins/icons";
import {IAttachment} from "@/models/attachment/AttachmentModels";

@Component({
	components: {AsiBtn, AsiCard, AsiDialog}
})
export default class AttachmentPreviewDialog extends Vue {

	@Prop({type: Object, required: true})
	public attachment!: IAttachment;

	@Prop({type: Boolean, default: false})
	public open!: boolean;

	private icons = Icon;

	private get isImage(): boolean {
		return this.attachment.mimeType.startsWith('image/');
	}

	private get isPdf(): boolean {
		return this.attachment.mimeType === 'application/pdf';
	}

	private get isText(): boolean {
		return [
			'text/plain',
		].includes(this.attachment.mimeType);
	}

	private get objectUrl(): string | null {
		return this.attachment.files.find(f => f.mediaSize === AttachmentMediaSize.orig)?.url ?? null;
	}

	@Emit('cancel')
	public cancel(): void {
		return;
	}

	@Watch('open', {immediate: true})
	private onOpenChanged(value: boolean): void {
		this.$store.commit('ui/setFullscreenDialogOpenState', value);
	}

}
