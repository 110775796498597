













import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import Icon from "@/plugins/icons";
import DialogHandler from "@/components/common/DialogHandler";
import {IUserShop} from "@/models/user/UserShopModels";
import {IUserAdmin} from "@/models/user/UserAdminModels";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import UserUpdateDialogName from "@/components/user/UserUpdateDialogName.vue";
import UserUpdateDialogContact from "@/components/user/UserUpdateDialogContact.vue";

@Component({
	components: {UserUpdateDialogContact, UserUpdateDialogName, AsiCardDetailEntry, AsiCardDetail}
})
export default class UserCardContact extends Vue {

	@Prop({type: Object, required: true})
	public user!: IUserShop | IUserAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	private call(nr: string | null): void {
		if (nr !== null) {
			const finalNumber = nr.replace(/\s/g, '');
			window.location.href = 'tel:' + finalNumber;
		}
	}

	@Emit('change')
	public change(): void {
		return;
	}

}
