import { render, staticRenderFns } from "./AsiPagination.vue?vue&type=template&id=f9e3b412&scoped=true"
import script from "./AsiPagination.vue?vue&type=script&lang=ts"
export * from "./AsiPagination.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9e3b412",
  null
  
)

export default component.exports