







































import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import {ICartShopListEntry} from "@/models/cart/CartShopModels";
import CartHelper from "@/helpers/CartHelper";
import CartContent from "@/components/cart/shop/CartContent.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import VueI18n from "vue-i18n";
import Snackbar from "@/helpers/Snackbar";
import AsiMenuItem from "@/components/common/AsiMenuItem.vue";
import Icon from "@/plugins/icons";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
import DialogHandler from "@/components/common/DialogHandler";
import CartUpdateDialogName from "@/components/cart/shop/CartUpdateDialogName.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiConfirmDialog, AsiBtn, CartUpdateDialogName, AsiMenuItem, AsiCard, CartContent}
})
export default class CartCard extends Vue {

	@Prop({type: Object, required: true})
	public cart!: ICartShopListEntry;

	@Prop({type: Boolean, default: false})
	public active!: boolean;

	private icons = Icon;
	private loading: boolean = false;
	private nameDialog: DialogHandler = new DialogHandler();


	private get label(): string | TranslateResult | null {
		return CartHelper.label(this.cart);
	}

	@Emit('change')
	public change(): string {
		return this.cart.id;
	}

	@Emit('deleted')
	public deleted(id: string): string {
		return id;
	}

	private clearCart(): void {
		if (this.cart.positions.length === 0) return;

		const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
		confirm.openDialog().then((res: boolean) => {
			if (!res) return;
			this.$store.dispatch('cart/clearCart', this.cart.id)
				.then(this.change)
				.catch(() => Snackbar.updateError());
		});
	}

	private deleteCart(): void {
		const id = this.cart.id;

		const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
		confirm.openDialog().then((res: boolean) => {
			if (!res) return;
			this.$store.dispatch('cart/deleteCart', id)
				.then(() => this.deleted(id))
				.catch(() => Snackbar.deleteError());
		});
	}

}
