import { render, staticRenderFns } from "./CategoryTree.vue?vue&type=template&id=6ae832c4&scoped=true"
import script from "./CategoryTree.vue?vue&type=script&lang=ts"
export * from "./CategoryTree.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ae832c4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VTreeview})
