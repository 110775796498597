




















































import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import AsiToolbar from "./AsiToolbar.vue";
import AsiTextFieldSimple from "./AsiTextFieldSimple";
import AsiBtn from "./AsiBtn.vue";
import AsiListTableAdvancedFilters from "./AsiListTableAdvancedFilters.vue";
import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
import AsiListTableFilterTemplate from "@/components/common/AsiListTableFilterTemplate";
import Icon from "@/plugins/icons";

@Component({
	components: {AsiFadeTransition, AsiListTableAdvancedFilters, AsiBtn, AsiTextFieldSimple, AsiToolbar}
})
export default class AsiListTableLayout extends Vue {

	@Prop({type: Boolean, default: false})
	public hideToolbar!: boolean;

	@Prop({type: String, default: null})
	public filterText!: string | null;

	@Prop({type: Boolean, default: false})
	public hasFilter!: boolean;

	@Prop({type: Array, default: () => []})
	public filterTemplates!: AsiListTableFilterTemplate<object>[];

	private icons = Icon;

	private get hasAdvancedFiltersContent(): boolean {
		return !!this.$slots['advanced-filters'];
	}

	@Emit('clearSearch')
	public clearSearch(event: Event): Event {
		return event;
	}

	@Emit('clearFilterText')
	public clearFilterText(event: Event): Event {
		return event;
	}

	@Emit('applyFilterTemplate')
	public applyFilterTemplate(filterTemplate: AsiListTableFilterTemplate<object>): AsiListTableFilterTemplate<object> {
		return filterTemplate;
	}

}
