




import {Component, Prop} from "vue-property-decorator";
import Icon from "@/plugins/icons";
import IFormModel from "@/models/IFormModel";
import {AxiosError} from "axios";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

@Component
export default class AsiDialogForm extends mixins(ResponsiveChecks) {

	@Prop({type: Boolean, required: true})
	public open!: boolean;

	protected loading: boolean = false;
	protected valid: boolean = false;
	protected errors: string[] = [];
	protected icons = Icon;

	protected reset(models: IFormModel[] = [], form: HTMLFormElement | null = null): void {
		this.valid = true;
		this.errors = [];
		models.forEach(m => m.reset());

		if (form !== null) {
			form.resetValidation();
		}
	}

	protected handleErrors(err: AxiosError): void {
		const errorData: string | string[] = err.response?.data ?? null;
		if (errorData === null) this.errors = [];

		this.errors = Array.isArray(errorData) ? errorData : [errorData];
	}
}
