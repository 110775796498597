






import {Component, Emit, Prop} from "vue-property-decorator";
import AsiDialog from "@/components/common/AsiDialog.vue";
import Vue from "vue";
import Icon from "@/plugins/icons";
import VideoCard from "@/components/common/VideoCard.vue";

@Component({
	components: {VideoCard, AsiDialog}
})
export default class VideoCardDialog extends Vue {

	@Prop({type: Boolean, required: true})
	public open!: boolean;

	@Prop({type: String, required: true})
	public source!: string;

	private icons = Icon;

	private stopVideo: boolean = false;

	@Emit('cancel')
	public cancel(): boolean {
		this.stopVideo = true;
		return true;
	}

}
