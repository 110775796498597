import OrderListFilter from "@/models/order/OrderListFilter";
import StringHelper from "@/helpers/StringHelper";
import {OrderExportState} from "@/helpers/constants";

export default class OrderListFilterAdmin extends OrderListFilter {

	public exportStates: OrderExportState[] = [];
	public customerName: string | null = null;
	public oneTimeEmail: string | null = null;

	public clear() {
		super.clear();
		this.customerName = null;
	}

	public hasFilter(): boolean {
		return super.hasFilter()
			|| this.exportStates.length > 0
			|| !StringHelper.allEmpty([this.customerName, this.oneTimeEmail]);
	}

}
