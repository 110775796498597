









import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AsiLoginForm from "@/components/common/AsiLoginForm.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import Icon from "@/plugins/icons";

@Component({
	components: {AsiCard, AsiLoginForm}
})
export default class Login extends Vue {

	private icons = Icon;
	private loading: boolean = false;

}
