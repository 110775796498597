































import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import ShopView from "@/components/layout/ShopView.vue";
import {TranslateResult} from "vue-i18n";
import CheckoutWizard from "@/components/checkout/CheckoutWizard.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import CartContent from "@/components/cart/shop/CartContent.vue";
import CartHelper from "@/helpers/CartHelper";
import {ICartShopListEntry} from "@/models/cart/CartShopModels";
import AsiAlert from "@/components/common/AsiAlert.vue";
import Icon from "@/plugins/icons";
import AsiBtn from "@/components/common/AsiBtn.vue";
import RoutesHelper from "@/helpers/RoutesHelper";
import DateTimeHelper from "@/helpers/DateTimeHelper";

@Component({
	components: {AsiBtn, AsiAlert, CartContent, AsiCard, CheckoutWizard, ShopView}
})
export default class Checkout extends Vue {

	@Prop({type: String, required: true})
	public id!: string;

	private icons = Icon;

	private get displayChristmasInfo(): boolean {
		const until = DateTimeHelper.createDateObjectFromDateString('3.1.2024');
		return until != null ? new Date() < until : false;
	}

	private get cart(): ICartShopListEntry | null {
		return this.$store.getters['cart/cartById'](this.id);
	}

	private get checkoutPossible(): boolean {
		return (this.cart?.positions.length ?? 0) > 0;
	}

	private get cartLabel(): string | TranslateResult {
		return CartHelper.label(this.cart);
	}

	private showHome(): void {
		this.$router.push(RoutesHelper.homeShopRoute());
	}

}
