import Vue from "vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "../../models/IPaginatedResponse";
import AvatarCreate from "@/models/attachment/AvatarCreate";
import UserListFilter from "@/models/user/UserListFilter";
import {CustomerState, CustomerType} from "@/helpers/constants";
import CustomerListFilter from "@/models/customer/CustomerListFilter";
import CustomerCreate from "@/models/customer/CustomerCreate";
import ICustomerServiceAdmin from "@/services/definition/ICustomerServiceAdmin";
import {ICustomerAdmin, ICustomerAdminListEntry} from "@/models/customer/CustomerAdminModels";
import ICreateResponse from "@/models/ICreateResponse";
import {IUserAdminListEntry} from "@/models/user/UserAdminModels";
import AxiosService from "@/services/AxiosService";
import CustomerUpdateNumbers from "@/models/customer/CustomerUpdateNumbers";
import AddressListFilter from "@/models/address/AddressListFilter";
import {IAddressListEntry} from "@/models/address/AddressModels";
import AddressCreate from "@/models/address/AddressCreate";
import AddressUpdate from "@/models/address/AddressUpdate";
import {ICartAdminListEntry} from "@/models/cart/CartAdminModels";
import CartListFilter from "@/models/cart/CartListFilter";
import OrderListFilter from "@/models/order/OrderListFilter";
import {IOrderAdminListEntry} from "@/models/order/OrderAdminModels";

export default class AxiosCustomerServiceAdmin extends AxiosService implements ICustomerServiceAdmin {

	public static readonly BASE_URL = '/admin/customers';
	public static readonly BASE_URL_COMMON = '/customers';

	protected defineBaseUrl(): string {
		return AxiosCustomerServiceAdmin.BASE_URL;
	}

	public customer(id: string): Promise<ICustomerAdmin> {
		return Vue.$axios.get<ICustomerAdmin>(this.url(id)).then(res => res.data);
	}

	public customers(filter: CustomerListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<ICustomerAdminListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['name'];
			options.sortDesc = [false];
		}

		return Vue.$axios.get<IPaginatedResponse<ICustomerAdminListEntry>>(this.url(), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public create(model: CustomerCreate): Promise<ICreateResponse> {
		return Vue.$axios.post<ICreateResponse>(this.url(), model).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id)).then(res => res.data);
	}

	public mergeInto(id: string, targetId: string): Promise<null> {
		return Vue.$axios.post(this.url(`${id}/merge-into`, AxiosCustomerServiceAdmin.BASE_URL_COMMON), {
			targetCustomerId: targetId,
		}).then(res => res.data);
	}

	public updateName(id: string, name: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/name`, 'customers'), {
			name: name,
		}).then(res => res.data);
	}

	public updatePhone(id: string, phone: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/phone`, 'customers'), {
			phone: phone,
		}).then(res => res.data);
	}

	public updateState(id: string, state: CustomerState): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/state`, 'customers'), {
			state: state,
		}).then(res => res.data);
	}

	public updateType(id: string, type: CustomerType): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/type`, 'customers'), {
			customerType: type,
		}).then(res => res.data);
	}

	public updateNumbers(id: string, model: CustomerUpdateNumbers): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/number`, 'customers'), model).then(res => res.data);
	}

	public updateNotes(id: string, notes: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/notes`, 'customers'), {
			notes: notes,
		}).then(res => res.data);
	}

	public addresses(id: string, filter: AddressListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IAddressListEntry>> {
		return Vue.$axios.get<IPaginatedResponse<IAddressListEntry>>(this.url(`${id}/addresses`), {
			params: {
				...filter,
				...options
			}
		}).then(res => res.data);
	}

	public createAddress(id: string, model: AddressCreate): Promise<ICreateResponse> {
		return Vue.$axios.post<ICreateResponse>(this.url(`${id}/addresses`, '/customers'), model).then(res => res.data);
	}

	public updateAddress(id: string, addressId: string, model: AddressUpdate): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/addresses/${addressId}`, '/customers'), model).then(res => res.data);
	}

	public deleteAddress(id: string, addressId: string): Promise<null> {
		return Vue.$axios.delete(this.url(`${id}/addresses/${addressId}`, '/customers')).then(res => res.data);
	}

	public setDefaultShippingAddress(id: string, addressId: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/default-shipping-address`, '/customers'), {
			addressId: addressId,
		}).then(res => res.data);
	}

	public setDefaultBillingAddress(id: string, addressId: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/default-billing-address`, '/customers'), {
			addressId: addressId,
		}).then(res => res.data);
	}

	public uploadAvatar(model: AvatarCreate, onProgress: ((event: ProgressEvent) => void)): Promise<null> {
		const formData = new FormData();
		formData.append('avatar', model.file as Blob, model.file?.name);

		return Vue.$axios.put<null>(this.url(`${model.model?.id}/avatar`), formData, {
			onUploadProgress: onProgress,
		}).then(res => res.data);
	}

	public deleteAvatar(model: ICustomerAdmin): Promise<null> {
		return Vue.$axios.delete(this.url(`${model.id}/avatar`)).then(res => res.data);
	}


	public users(id: string, filter: UserListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IUserAdminListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['firstname', 'lastname'];
			options.sortDesc = [false, false];
		}

		return Vue.$axios.get<IPaginatedResponse<IUserAdminListEntry>>(this.url(`${id}/users`), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public carts(id: string, filter: CartListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<ICartAdminListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['name'];
			options.sortDesc = [false];
		}

		return Vue.$axios.get<IPaginatedResponse<ICartAdminListEntry>>(this.url(`${id}/carts`), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public orders(id: string, filter: OrderListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IOrderAdminListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['orderNumber'];
			options.sortDesc = [true];
		}

		return Vue.$axios.get<IPaginatedResponse<IOrderAdminListEntry>>(this.url(`${id}/orders`), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public exportExcel(filter: CustomerListFilter | null, options: AsiListTableOptions): Promise<Blob> {
		return Vue.$axios.get<Blob>(this.url(`/export`), {
			params: {
				...filter,
				...options,
			},
			responseType: 'blob',
		}).then(res => res.data);
	}

	public exportUsersExcel(id: string, filter: UserListFilter | null, options: AsiListTableOptions): Promise<Blob> {
		return Vue.$axios.get<Blob>(this.url(`${ id }/users/export`), {
			params: {
				...filter,
				...options,
			},
			responseType: 'blob',
		}).then(res => res.data);
	}

}
