
































































































































































































import {Component, Prop, Watch} from "vue-property-decorator";
import Registration from "@/models/registration/Registration";
import AsiTextField from "@/components/common/AsiTextField";
import Icon from "@/plugins/icons";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AddressForm from "@/components/address/AddressForm.vue";
import AddressCreate from "@/models/address/AddressCreate";
import AsiRadioGroup from "@/components/common/AsiRadioGroup";
import {CustomerType} from "@/helpers/constants";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiStepperNavigation from "@/components/common/AsiStepperNavigation.vue";
import AsiAlert from "@/components/common/AsiAlert.vue";
import AsiCurrencyAutocomplete from "@/components/common/AsiCurrencyAutocomplete.vue";
import RegistrationSummary from "@/components/user/shop/RegistrationSummary.vue";
import SalutationInput from "@/components/common/SalutationInput.vue";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

@Component({
	components: {
		SalutationInput,
		RegistrationSummary,
		AsiCurrencyAutocomplete,
		AsiAlert, AsiStepperNavigation, AsiCard, AsiRadioGroup, AddressForm, AsiBtn, AsiTextField
	}
})
export default class RegistrationWizard extends mixins(ResponsiveChecks) {

	@Prop({type: Boolean, default: false})
	public simple!: boolean;

	private readonly stepType = 1;
	private readonly stepUser = 2;
	private readonly stepAddresses = 3;
	private readonly stepSummary = 4;
	private readonly typeBusiness = CustomerType.business;
	private readonly typePrivate = CustomerType.private;
	private readonly createStateNone = 0;
	private readonly createStateStarted = 1;
	private readonly createStateSuccess = 2;
	private readonly createStateFail = 3;

	private icons = Icon;
	private loading: boolean = false;
	private showPassword: boolean = false;
	private step: number = this.stepType;
	private model: Registration = new Registration();
	private typeDataValid: boolean = false;
	private userDataValid: boolean = false;
	private addressesValid: boolean[] = [];
	private createState: number = this.createStateNone;
	private createdId: string | null = null;
	private addressStepVisited: boolean = false;
	private summaryStepVisited: boolean = false;

	private get stepTypeComplete(): boolean {
		return this.typeDataValid;
	}

	private get stepUserComplete(): boolean {
		return this.userDataValid;
	}

	private get stepAddressesComplete(): boolean {
		return this.addressDataValid && this.addressStepVisited;
	}

	private get addressDataValid(): boolean {
		return !this.addressesValid.some(v => !v);
	}

	private get textFieldComponent(): string {
		return this.simple ? AsiTextFieldSimple.name : AsiTextField.name;
	}

	private addAddress(): void {
		const address = new AddressCreate();
		if (this.model.addresses.length === 0) {
			address.isDefaultShippingAddress = true;
			address.isDefaultBillingAddress = true;
		}
		this.model.addresses.push(address);
		this.addressesValid.push(true);
	}

	@Watch('step')
	private onStepChanged(value: number): void {
		if (value === this.stepAddresses) {
			this.addressStepVisited = true;
		} else if (value === this.stepSummary) {
			this.summaryStepVisited = true;
		}
	}

	@Watch('model.type')
	private onModelTypeChanged(value: CustomerType): void {
		if (value === CustomerType.private) {
			this.model.customerName = null;
		}
	}

	private performRegistration(): void {

		if (this.model.type === this.typePrivate) {
			this.model.phone = this.model.customerPhone;
		}

		this.createState = this.createStateStarted;
		this.loading = true;
		this.$userServiceShop.registration(this.model)
			.then(res => {
				this.createState = this.createStateSuccess;
				this.createdId = res.id;
			})
			.catch(() => this.createState = this.createStateFail)
			.finally(() => this.loading = false);
	}

	private deleteAddress(index: number): void {
		this.model.addresses = this.model.addresses.filter((a: AddressCreate, i: number) => i !== index);
		this.addressesValid = this.addressesValid.filter((a: boolean, i: number) => i !== index);
	}

	private setDefaultShippingAddress(index: number, value: boolean): void {
		this.model.addresses.forEach((a: AddressCreate, i: number) => a.isDefaultShippingAddress = value && i === index);
	}

	private setDefaultBillingAddress(index: number, value: boolean): void {
		this.model.addresses.forEach((a: AddressCreate, i: number) => a.isDefaultBillingAddress = value && i === index);
	}

}
