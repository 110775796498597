





















import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import MailTemplateInfo from "@/models/mail-template/MailTemplateInfo";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
@Component({
	components: {AsiCardDetail, AsiCard}
})
export default class MailTemplateInfoCard extends Vue {

	@Prop({type: String, required: true})
	public title!: string;

	@Prop({type: Array, required: true})
	public infos!: MailTemplateInfo[];

	@Prop({default: null})
	public icon!: string | null;

	@Prop({type: Boolean, default: false})
	public noBottomMargin!: boolean;
}
