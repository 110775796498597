



















































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AddressForm from "@/components/address/AddressForm.vue";
import AsiTextField from "@/components/common/AsiTextField";
import AsiTextarea from "@/components/common/AsiTextarea";
import AsiCheckbox from "@/components/common/AsiCheckbox";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import CartContent from "@/components/cart/shop/CartContent.vue";
import ShopView from "@/components/layout/ShopView.vue";
import SampleOrderAddress from "@/models/sample-order/SampleOrderAddress";
import SampleOrderCart from "@/components/sample-order/shop/SampleOrderCart.vue";
import DialogHandler from "@/components/common/DialogHandler";
import {IItemShopSimple} from "@/models/item/ItemShopModels";
import Snackbar from "@/helpers/Snackbar";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import AsiDialog from "@/components/common/AsiDialog.vue";
import LegalTermsAndConditions from "@/components/legal/LegalTermsAndConditions.vue";
import EnumHelper from "@/helpers/EnumHelper";
import {Tenant} from "@/models/ui/Tenant";

@Component({
	components: {
		LegalTermsAndConditions, AsiDialog,
		SampleOrderCart,
		ShopView, CartContent, AsiBtn, AsiCard, AsiCheckbox, AsiTextarea, AsiTextField, AddressForm
	}
})
export default class SampleOrderForm extends mixins(ResponsiveChecks) {
	@Prop({type: SampleOrderAddress, required: true})
	public value!: SampleOrderAddress;

	@Prop({type: Boolean, required: true})
	public valid!: boolean;

	private icons = Icon;
	private business = false;
	private addressFormValid: boolean = true;
	private additionalFormValid: boolean = true;
	private isTermsAndConditionsAccepted = false;
	private tacDialog: DialogHandler = new DialogHandler();

	@Watch('addressFormValid', {immediate: true})
	private onAddressFormValidChanged(): void {
		this.change(this.addressFormValid && this.additionalFormValid);
	}

	@Watch('additionalFormValid', {immediate: true})
	private onAdditionalFormValidChanged(): void {
		this.change(this.addressFormValid && this.additionalFormValid);
	}

	@Emit('change')
	public change(valid: boolean): boolean {
		return valid;
	}

	@Emit('performSampleOrder')
	public performSampleOrder(): boolean {
		return true;
	}

	private get tenantName(): string {
		return EnumHelper.textFromValue(Tenant, this.$isTenant, true) ?? '';
	}

	public get items(): IItemShopSimple[] {
		return this.$store.state.sampleOrder.items;
	}

	private sendSampleOrder(): void {
		if (this.addressFormValid && this.isTermsAndConditionsAccepted) {
			if (this.items.length > 0) {
				this.performSampleOrder();
			} else {
				Snackbar.show(this.$t('sampleOrder.noSampleOrdersInCart'), 'error', 5000);
			}
		}
	}

	private async updateStoreModel() {
		await this.$store.commit('sampleOrderFormData/updateModel', this.value);
	}
}
