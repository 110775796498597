




























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {ICartShopListEntry} from "@/models/cart/CartShopModels";
import TotalHelper, {CurrencyAmount} from "@/helpers/TotalHelper";

@Component
export default class CartTotalEntries extends Vue {

	@Prop({required: true})
	public cart!: ICartShopListEntry | null;

	@Prop({type: String, default: null})
	public bgColor!: string | null;

	@Prop({type: Boolean, default: false})
	public includeShipment!: boolean;

	@Prop({type: Boolean, default: false})
	public includeVat!: boolean;

	private get totalEntries(): CurrencyAmount[] {
		if (this.cart === null) return [];

		return this.includeVat
			? TotalHelper.totalInclVat(this.cart, this.includeShipment)
			: TotalHelper.totalExclVat(this.cart, this.includeShipment);
	}

}
