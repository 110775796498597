












import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import {IUserShop} from "@/models/user/UserShopModels";
import {IUserAdmin} from "@/models/user/UserAdminModels";
import Icon from "@/plugins/icons";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import UserUpdateDialogState from "@/components/user/admin/UserUpdateDialogState.vue";
import DialogHandler from "@/components/common/DialogHandler";
import UserStateChip from "@/components/user/UserStateChip.vue";

@Component({
	components: {UserStateChip, UserUpdateDialogState, AsiCardDetailEntry, AsiCardDetail}
})
export default class UserCardState extends Vue {

	@Prop({type: Object, required: true})
	public user!: IUserShop | IUserAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	@Emit('change')
	public change(): void {
		return;
	}

}
