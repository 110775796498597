




















import Vue from 'vue';
import {Component, Emit, Prop} from 'vue-property-decorator';
import AsiDialog from "@/components/common/AsiDialog.vue";
import Icon from "@/plugins/icons";
import PaymentTypeAutocompleteInput from "@/components/payment-type/PaymentTypeAutocompleteInput.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import IOrderPaymentResponse from "@/models/IOrderPaymentResponse";
import Snackbar from "@/helpers/Snackbar";

@Component({
	components: {AsiBtn, PaymentTypeAutocompleteInput, AsiDialog}
})
export default class OrderPaymentReinitializeDialog extends Vue {

	@Prop({type: String, required: true})
	public id!: string;

	@Prop({type: Boolean, default: false})
	public open!: boolean;

	private icons = Icon;
	private paymentTypeId: string | null = null;
	private loading: boolean = false;

	@Emit('cancel')
	public cancel(event: Event): Event {
		return event;
	}

	@Emit('save')
	public save(result: IOrderPaymentResponse): IOrderPaymentResponse {
		return result;
	}

	private performReinitialize(): void {
		if (this.paymentTypeId === null) return;

		this.loading = true;
		this.$orderServiceShop.reinitializePayment(this.id, this.paymentTypeId)
			.then(this.save)
			.catch(() => Snackbar.updateError())
			.finally(() => this.loading = false);
	}

}
