

































































































































































import Vue from 'vue';
import {Component} from "vue-property-decorator";
import LogoHerzogDichtungen from "@/components/layout/LogoHerzogDichtungen.vue";
import Icon from "@/plugins/icons";
import AsiLocaleSwitcher from "@/components/common/AsiLocaleSwitcher.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import {Routes} from "@/helpers/constants";
import {RawLocation} from "vue-router";
import LogoMkDichtungen from "@/components/layout/LogoMkDichtungen.vue";

@Component({
	components: {LogoMkDichtungen, AsiBtn, AsiLocaleSwitcher, LogoHerzogDichtungen}
})
export default class Footer extends Vue {

	private icons = Icon;

	private get faqRoute(): RawLocation {
		return {
			name: Routes.faq,
		};
	}

	private get privacyRoute(): RawLocation {
		return {
			name: Routes.privacy,
		};
	}

	private get termsAndConditionsRoute(): RawLocation {
		return {
			name: Routes.termsAndConditions,
		};
	}

	private get legalNoticeRoute(): RawLocation {
		return {
			name: Routes.legalNotice,
		};
	}

	// noinspection JSUnusedLocalSymbols
	private showMap(): void {
		const url = this.$isHerzogShop ? 'https://goo.gl/maps/oLFbRRFVWztJaBWRA'
			: this.$isMkShop ? 'https://goo.gl/maps/gPcCkjGgGPLhwdg36'
				: null;
		if (url === null) {
			return;
		}

		const w = window.open(url, '_blank');
		if (w !== null) w.focus();
	}

	// noinspection JSUnusedLocalSymbols
	private showMapPickup(): void {
		const w = window.open('https://goo.gl/maps/gPcCkjGgGPLhwdg36', '_blank');
		if (w !== null) w.focus();
	}

	private showContact(): void {
		if (this.$router.currentRoute.name === Routes.contact) return;
		this.$router.push({name: Routes.contact});
	}
}
