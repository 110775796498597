

















































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {IAddressFields} from "@/models/address/AddressModels";
import VueI18n from "vue-i18n";
import Icon from "@/plugins/icons";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AddressHelper from "@/helpers/AddressHelper";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiBtn, AsiCard}
})
export default class AddressCardSimple extends Vue {

	@Prop({type: Object, required: true})
	public address!: IAddressFields | null;

	@Prop({type: String, default: null})
	public label!: string | null;

	@Prop({type: String, default: null})
	public icon!: string | null;

	@Prop({type: Boolean, default: false})
	public noBottomMargin!: boolean;

	private icons = Icon;

	private get labelFinal(): string | TranslateResult {
		return this.label ?? this.address?.label ?? this.$t('address.terms.noLabel');
	}

	private get iconFinal(): string {
		return this.icon ?? Icon.address;
	}

	private get lines(): string[] {
		return this.address === null ? [] : AddressHelper.addressLines(this.address);
	}

	private get hasPrepend(): boolean {
		return !!this.$slots['prepend'];
	}

	private get hasAppend(): boolean {
		return !!this.$slots['append'];
	}

	private openMap(): void {
		if (this.address === null) return;
		const w = window.open(AddressHelper.googleMapsLink(this.address), '_blank');
		if (w !== null) w.focus();
	}

}
