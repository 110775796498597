










import {Component, Vue} from 'vue-property-decorator';
import ShopView from "@/components/layout/ShopView.vue";
import Icon from "@/plugins/icons";
import AsiCard from "@/components/common/AsiCard.vue";

@Component({
	components: {AsiCard, ShopView}
})
export default class NotFound extends Vue {
	private icons = Icon;
}
