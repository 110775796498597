var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AsiListTableLayout',{attrs:{"hide-toolbar":""}},[_c('v-data-table',{attrs:{"items":_vm.item.additionalAttachments,"headers":_vm.createHeaders(),"loading":_vm.loadingInternal || _vm.loadingDelete,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('AsiAvatar',{attrs:{"size":32,"icon":_vm.icon(item)}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(item.name.de !== null ? item.name.de : '-'))]),_c('small',{staticClass:"grey--text"},[_vm._v(_vm._s(item.name.fr !== null ? item.name.fr : '-'))]),_c('small',{staticClass:"grey--text"},[_vm._v(_vm._s(item.name.en !== null ? item.name.en : '-'))])])]}},{key:"item.files",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.fileSize(item)))])]}},(_vm.showActions)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row flex-wrap justify-end"},[(_vm.canPreview(item))?_c('AsiBtn',{attrs:{"icon":_vm.icons.preview,"small":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.preview(item)}}}):_vm._e(),_c('AsiBtn',{attrs:{"disabled":_vm.loadingDelete,"icon":_vm.icons.edit,"small":""},nativeOn:{"click":function($event){$event.stopPropagation();_vm.updateAttachment = item; _vm.updateDialog.open();}}}),_c('AsiBtn',{attrs:{"disabled":_vm.loadingDelete,"icon":_vm.icons.delete,"small":""},on:{"click":function($event){return _vm.performDelete(item)}}})],1)]}}:null],null,true)}),(_vm.showActions)?_c('AsiConfirmDialog',{ref:"confirm"}):_vm._e(),(_vm.showActions && _vm.updateDialog.isLoaded && _vm.updateAttachment !== null)?_c('AttachmentUpdateDialog',{attrs:{"service":_vm.$itemServiceAdmin,"subject-id":_vm.item.id,"open":_vm.updateDialog.isOpen,"attachment-model":_vm.updateAttachment},on:{"cancel":function($event){return _vm.updateDialog.close()},"save":function($event){_vm.changed(_vm.updateAttachment.id); _vm.updateDialog.close();}}}):_vm._e(),(_vm.previewDialog.isLoaded)?_c('AttachmentPreviewDialog',{attrs:{"open":_vm.previewDialog.isOpen,"attachment":_vm.previewAttachment},on:{"cancel":function($event){return _vm.previewDialog.close()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }