

















































import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import AsiCardDetailEntry from "../common/AsiCardDetailEntry.vue";
import AsiBtn from "../common/AsiBtn.vue";
import Icon from "../../plugins/icons";
import {MailType} from "@/helpers/constants";
import EnumHelper from "@/helpers/EnumHelper";
import DialogHandler from "@/components/common/DialogHandler";
import MailTemplateCreateDialog from "@/components/mail-template/MailTemplateCreateDialog.vue";
import MailTemplateInfoCard from "@/components/mail-template/MailTemplateInfoCard.vue";
import MailTemplateInfo from "@/models/mail-template/MailTemplateInfo";
import MailTemplateUpdateDialog from "@/components/mail-template/MailTemplateUpdateDialog.vue";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
import AsiConfirmDialogDefinition from "@/helpers/AsiConfirmDialogDefinition";
import {IMailTemplateAdminListEntry} from "@/models/mail-template/MailTemplateAdminModels";

@Component({
	components: {
		AsiConfirmDialog,
		MailTemplateUpdateDialog,
		MailTemplateInfoCard, MailTemplateCreateDialog, AsiBtn, AsiCardDetailEntry
	}
})
export default class MailTemplateDetailEntry extends Vue {

	@Prop({type: Object, required: true})
	public mailTemplate!: IMailTemplateAdminListEntry;

	private icons = Icon;
	private createDialog: DialogHandler = new DialogHandler();
	private updateDialog: DialogHandler = new DialogHandler();

	private get mailTypeName(): string | null {
		return EnumHelper.textFromValue(MailType, this.mailTemplate.mailType, true);
	}

	private get listEntryIcon(): string {
		return this.mailTemplate.htmlTemplate !== "" ? this.icons.fileCheck : this.icons.close;
	}

	private get recipientInfos(): MailTemplateInfo[] {
		return [
			new MailTemplateInfo('{salutation}', 'mailTemplate.placeholders.base.salutation'),
			new MailTemplateInfo('{firstname}', 'mailTemplate.placeholders.base.firstname'),
			new MailTemplateInfo('{lastname}', 'mailTemplate.placeholders.base.lastname'),
			new MailTemplateInfo('{fullSalutation}', 'mailTemplate.placeholders.base.fullSalutation'),
		];
	}

	private get hasSpecificInfos(): boolean {
		return !!this.$slots.specificInfos;
	}

	private requestDelete(): void {
		const confirm = this.$refs.confirm as unknown as AsiConfirmDialogDefinition;
		confirm.openDialog().then((res: boolean) => {
			if (!res) return;

			this.deleteTemplate();
		});
	}

	@Emit('save')
	public reload(): void {
		return;
	}

	@Emit('delete')
	public deleteTemplate(): string {
		return this.mailTemplate.id;
	}

}
