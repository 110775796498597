






















































import {Component, Emit, Prop} from "vue-property-decorator";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import AsiListTableHeader from "@/components/common/AsiListTableHeader";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import ItemHelper from "@/models/item/ItemHelper";
import Icon from "@/plugins/icons";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import EnumHelper from "@/helpers/EnumHelper";
import {AttachmentMediaSize, ItemAttribute, UnitOfMeasure} from "@/helpers/constants";
import {IItemPriceShopSimple} from "@/models/item-price/ItemPriceModelsShop";
import ItemAddToCart from "@/components/item/shop/ItemAddToCart.vue";
import {IAttachment} from "@/models/attachment/AttachmentModels";
import AttachmentHelper from "@/models/attachment/AttachmentHelper";
import ItemListViewTableMobileEntry from "@/components/item/shop/ItemListViewTableMobileEntry.vue";
import AsiSelectSimple from "@/components/common/AsiSelectSimple";
import ItemListPagination from "@/components/item/shop/ItemListPagination.vue";
import i18n from "@/plugins/i18n";

@Component({
	components: {ItemListPagination, AsiSelectSimple, ItemListViewTableMobileEntry, ItemAddToCart}
})
export default class ItemListViewTable extends mixins(ResponsiveChecks) {

	@Prop({type: Array, required: true})
	public items!: IItemShopListEntry[];

	@Prop({type: Number, required: true})
	public totalItems!: number;

	@Prop({type: Object, required: true})
	public tableOptions!: AsiListTableOptions;

	@Prop({type: Array, required: true})
	public perPageOptions!: number[];

	@Prop({default: null})
	public showAttributeCallback!: ((column: string) => boolean) | null;

	private icons = Icon;

	private portrait: boolean = window.matchMedia("(orientation: portrait)").matches;

	public get headers(): AsiListTableHeader[] {
		let ret = [
			new AsiListTableHeader(this.$t('item.name'), 'name', true, false, 'start'),
		];

		if (this.items.length > 0) {
			this.itemAttributes
				.forEach(attr => {
					switch (attr.key) {
						case 'price':
							ret.push(new AsiListTableHeader('', 'actions', true, true, 'end'));
							break;
						case 'avatarAttachmentId':
							ret.push(new AsiListTableHeader(ItemHelper.attributeLabel(attr.key), 'avatar', false, true, 'start', '5%'));
							break;
						case 'stockStatus':
							ret.push(new AsiListTableHeader(ItemHelper.attributeLabel(attr.key), attr.key, true, true, 'center'));
							break;
						case 'isTopSeller':
							ret.push(new AsiListTableHeader(ItemHelper.attributeLabel(attr.key), attr.key, true, true, 'center'));
							break;
						case 'canOrderSample':
							ret.push(new AsiListTableHeader(ItemHelper.attributeLabel(attr.key), attr.key, true, true, 'center'));
							break;
						default:
							ret.push(new AsiListTableHeader(ItemHelper.attributeLabel(attr.key), attr.key, true, true, 'start'));
					}
				});
		}

		const order: string[] = ['avatar', 'name', 'x', 'actions'];

		ret.sort((a, b) => {
			let aValueIndex = order.indexOf(a.value);
			let bValueIndex = order.indexOf(b.value);

			if (aValueIndex === -1) {
				aValueIndex = order.indexOf('x');
			}
			if (bValueIndex === -1) {
				bValueIndex = order.indexOf('x');
			}

			return aValueIndex - bValueIndex;
		});

		return ret;
	}

	public get itemAttributes()
	{
		return EnumHelper
			.toArray(ItemAttribute)
			.filter(attr => this.showAttributeCallback !== null ? this.showAttributeCallback(attr.key) : true)
			.concat();
	}

	public created(): void {
		window.matchMedia("(orientation: portrait)").addEventListener("change", (e) => {
			this.portrait = e.matches;
		});
	}

	private itemAttributeValue(itemAttribute: string | ITranslatedValue)
	{
		if (typeof itemAttribute === 'object') {
			return TranslatedValueHelper.get(itemAttribute as ITranslatedValue, i18n.locale);
		}

		return itemAttribute;
	}

	private nameTranslated(name: ITranslatedValue): string | null {
		return TranslatedValueHelper.get(name, this.$i18n.locale);
	}

	private avatarUrl(item: IItemShopListEntry): string | null {
		return  ItemHelper.avatarUrl(item, AttachmentMediaSize.s);
	}

	@Emit('itemSelected')
	public itemSelected(model: IItemShopListEntry): IItemShopListEntry {
		return model;
	}

	@Emit('showAddToCart')
	public showAddToCart(item: { id: string, unit: UnitOfMeasure, prices: IItemPriceShopSimple[] }): { id: string, unit: UnitOfMeasure, prices: IItemPriceShopSimple[] } {
		return item;
	}

	private imageUrl(attachment: IAttachment): string | null {
		return AttachmentHelper.getImageUrl(attachment, AttachmentMediaSize.s);
	}

}
