





























































import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
import Icon from "@/plugins/icons";
import AsiBtn from "@/components/common/AsiBtn.vue";
import {AttachmentMediaSize} from "@/helpers/constants";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import {IAttachment} from "@/models/attachment/AttachmentModels";
import AttachmentHelper from "@/models/attachment/AttachmentHelper";
import ImageGalleryUploadDialog from "@/components/common/ImageGalleryUploadDialog.vue";
import DialogHandler from "@/components/common/DialogHandler";
import AsiCard from "@/components/common/AsiCard.vue";
import AttachmentPreviewDialog from "@/components/attachment/AttachmentPreviewDialog.vue";
import AsiChip from "@/components/common/AsiChip.vue";
import Snackbar from "@/helpers/Snackbar";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
import AsiConfirmDialogDefinition from "@/helpers/AsiConfirmDialogDefinition";

@Component({
	components: {
		AsiConfirmDialog,
		AsiChip, AttachmentPreviewDialog, AsiCard, ImageGalleryUploadDialog, AsiContentContainer, AsiBtn
	}
})
export default class CategoryTabImages extends Vue {

	@Prop({required: true})
	public category!: ICategoryAdmin | null;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private loading: boolean = false;
	private uploadDialog: DialogHandler = new DialogHandler();
	private previewDialog: DialogHandler = new DialogHandler(() => this.previewAttachment = null);
	private previewAttachment: IAttachment | null = null;

	private get imagesSorted(): IAttachment[] {
		return this.category === null
			? []
			: this.category.galleryAttachments.sort((a: IAttachment, b: IAttachment) => {
				if (this.isAvatar(a)) return -1;
				if (this.isAvatar(b)) return 1;
				return a.filename.localeCompare(b.filename);
			});
	}

	@Emit('change')
	public change(): void {
		return;
	}

	// noinspection JSMethodCanBeStatic
	private imageUrl(image: IAttachment): string | null {
		return AttachmentHelper.getImageUrl(image, AttachmentMediaSize.m);
	}

	private isAvatar(image: IAttachment): boolean {
		return this.category?.avatarAttachmentId === image.id;
	}

	private isBannerImage(image: IAttachment): boolean {
		return this.category?.bannerAttachmentId === image.id;
	}

	private uploadDialogCancelled(numSuccessful: number): void {
		this.uploadDialog.close();
		if (numSuccessful > 0) this.change();
	}

	private performSetAvatar(image: IAttachment): void {
		if (this.category === null) return;

		this.loading = true;
		this.$categoryServiceAdmin.setGalleryImageAvatar(this.category.id, this.isAvatar(image) ? null : image.id)
			.then(() => this.change())
			.catch(() => Snackbar.updateError())
			.finally(() => this.loading = false);
	}

	private performSetBanner(image: IAttachment): void {
		if (this.category === null) return;

		this.loading = true;
		this.$categoryServiceAdmin.setGalleryImageBanner(this.category.id, this.isBannerImage(image) ? null : image.id)
			.then(() => this.change())
			.catch(() => Snackbar.updateError())
			.finally(() => this.loading = false);
	}

	private performDelete(image: IAttachment): void {
		if (this.category === null) return;

		const confirm = this.$refs.confirm as unknown as AsiConfirmDialogDefinition;
		confirm.openDialog().then((res: boolean) => {
			if (!res || this.category === null) return;

			this.loading = true;
			this.$categoryServiceAdmin.deleteGalleryImage(this.category.id, image.id)
				.then(() => this.change())
				.catch(() => Snackbar.deleteError())
				.finally(() => this.loading = false);
		});
	}

	private downloadAttachment(attachment: IAttachment): void {
		AttachmentHelper.download(attachment);
	}

}
