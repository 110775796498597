import Vue from "vue";
import AuthTypeRequest from "@/models/auth/AuthTypeRequest";
import TokenResult from "@/models/auth/TokenResult";
import JwtHelper from "@/helpers/JwtHelper";
import MsalHelper from "@/helpers/MsalHelper";
import {Module, Store} from "vuex";
import {State} from "@/store";
import {AuthType} from "@/helpers/constants";
import {Guid} from "guid-typescript";
import {timerAsync} from "@/helpers/TimerAsync";
import {IUserShop} from "@/models/user/UserShopModels";
import {ICustomerShopSimple} from "@/models/customer/CustomerShopModels";

export interface UserState {
	token: string | null;
	refreshToken: string | null;
	isRefreshingToken: { value: boolean; setDate: number };
	isAxiosRefreshingToken: boolean;
	loggedInUser: IUserShop | null;
	loggedInUserCustomer: ICustomerShopSimple | null;
}

const currentWindowGuid = Guid.create();
const tokenStorageKey = 'poesia-shop-auth-token';
const isRefreshingStorageKey = 'poesia-shop-isRefreshing';

const user: Module<UserState, State> = {
	namespaced: true,

	state: {
		token: null,
		refreshToken: null,
		isRefreshingToken: {value: false, setDate: Date.now()},
		isAxiosRefreshingToken: false,
		loggedInUser: null,
		loggedInUserCustomer: null,
	},
	getters: {
		userId: (state, getters) => {
			const tokenData = getters.tokenData;
			if (null === tokenData) {
				return null;
			}
			return tokenData.sub;
		},
		model: (state): IUserShop | null => {
			return state.loggedInUser;
		},
		modelCustomer: (state): ICustomerShopSimple | null => {
			return state.loggedInUser?.customer ?? null;
		},
		isLoggedIn: (state): boolean => {
			return state.token !== null;
		},
		permissions: (state, getters): string[] => {
			try {
				const permissions = getters.tokenData['poesia.permission'];
				return Array.isArray(permissions) ? permissions : [permissions];
			} catch (e) {
				return [];
			}
		},
		hasPermission: (state, getters) => (permission: string): boolean => {
			const permissions = getters.permissions as string[];
			return permissions.includes(permission);
		},
		roles: (state, getters): string[] => {
			try {
				const roles = getters.tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
				return Array.isArray(roles) ? roles : [roles];
			} catch (e) {
				return [];
			}
		},
		hasRole: (state, getters) => (role: string): boolean => {
			const roles = getters.roles as string[];
			return roles.includes(role);
		},
		tokenData: state => {
			if (null === state.token) {
				return null;
			}
			return JwtHelper.tokenData(state.token);
		},
		isTokenExpired: (state) => {
			return null === state.token ? false : JwtHelper.isTokenExpired(state.token);
		}
	},
	mutations: {
		setTokens(state, payload: { token: string; refreshToken: string }) {
			state.token = payload.token;
			state.refreshToken = payload.refreshToken;
			localStorage.setItem(tokenStorageKey, JSON.stringify({
				token: state.token,
				refreshToken: state.refreshToken,
				sender: currentWindowGuid.toString()
			}));
		},
		setIsRefreshingToken(state, payload: boolean) {
			const isRefreshingToken = {value: payload, setDate: Date.now()};
			localStorage.setItem(isRefreshingStorageKey, JSON.stringify({
				...isRefreshingToken,
				sender: currentWindowGuid.toString()
			}));
			state.isRefreshingToken = isRefreshingToken;
		},
		setIsAxiosRefreshingToken(state, payload: boolean) {
			state.isAxiosRefreshingToken = payload;
		},
		setLoggedInUser(state, payload: IUserShop | null) {
			state.loggedInUser = payload;
			state.loggedInUserCustomer = payload?.customer ?? null;
		},
	},
	actions: {
		async refreshToken(context) {
			if (null === context.state.refreshToken) {
				return;
			}

			if (context.state.isRefreshingToken.value) {
				while (context.state.isRefreshingToken.value && (Date.now() - context.state.isRefreshingToken.setDate < 10_000)) {
					await timerAsync(1000);
				}
				if (Date.now() - context.state.isRefreshingToken.setDate < 10_000) {
					return;
				}
			}

			context.commit('setIsRefreshingToken', true);
			try {
				const newToken = await Vue.$authService.refreshToken({
					refreshToken: context.state.refreshToken,
					token: context.state.token ?? '',
				});

				context.commit('setTokens', newToken);
				await context.dispatch('loadLoggedInUserData');
				context.commit('setIsRefreshingToken', false);
			} catch (err) {
				console.error('error refreshing token', err);
				context.commit('setIsRefreshingToken', false);
				await context.dispatch('logout');
			}
		},
		async logout(context, payload: { userId: string } | null) {
			const userName: string | null = context.state.loggedInUser?.username ?? null;
			const authType = userName !== null ? await Vue.$authService.authType(new AuthTypeRequest(userName)) : null;

			try {
				await Vue.$authService.logout().then(async () => {
					if (authType !== null && authType.name === AuthType.azureAd && authType.clientId !== null) {
						context.commit('setTokens', {token: null, refreshToken: null});
						await context.dispatch('loadLoggedInUserData');

						const msal = MsalHelper.createInstance(authType.clientId);
						msal.logout();
					}
				});
			} catch (e) {
				console.error('logout failed', e);
			}

			if (null === payload || undefined === payload || null === payload.userId) {
				context.commit('setTokens', {token: null, refreshToken: null});
				await context.dispatch('loadLoggedInUserData');
			}
		},
		async loadLoggedInUserData(context, payload: { assignCartId: string | null } | null = null) {
			if (context.state.token === null) {
				context.commit('setLoggedInUser', null);
				context.commit('cart/setActiveCartId', null, {root: true});
				context.commit('cart/setCarts', [], {root: true});
				await context.dispatch('sampleOrderFormData/rehydrate', Vue.$customerServiceShop, {root: true});
				return;
			}

			//user data
			let user: IUserShop | null = null;
			try {
				user = await Vue.$userServiceShop.user(context.getters.userId);
				context.commit('setLoggedInUser', user);
				context.commit('ui/setLocale', user.locale, {root: true});
			} catch (e) {
				console.error('fetching logged in user data failed', e);
			}

			//cart data
			if (user !== null && payload !== null && payload.assignCartId !== null) {
				await context.dispatch('cart/updateCustomer', {
					cartId: payload.assignCartId,
					customerId: user.customer.id
				}, {root: true});
			}
			await context.dispatch('cart/loadCarts', undefined, {root: true});

			//shopping-lists
			await context.dispatch('shoppingList/loadShoppingLists', undefined, {root: true});

			//global config
			await context.dispatch('globalConfig/reload', null, {root: true});
		},
	},
};

/**
 * listens on storage changes for auth changes
 * @param context the store
 */
export function listenAuthStorageChange(context: Store<State>) {
	window.addEventListener('storage', async (event: StorageEvent) => {
		if (event.key === tokenStorageKey && null !== event.newValue) {
			const val = JSON.parse(event.newValue) as { sender: string } & TokenResult;
			if (val.sender === currentWindowGuid.toString()) {
				return;
			}
			const oldTokenVal = context.state.user.token;

			if (oldTokenVal !== val.token || context.state.user.refreshToken !== val.refreshToken) {
				if (null !== oldTokenVal && null !== val.token) {
					const oldTokenData = JwtHelper.tokenData(oldTokenVal);
					const newTokenData = JwtHelper.tokenData(val.token);
					// compare issuing time stamp to break circular updating
					if (oldTokenData.iat > newTokenData.iat) {
						return;
					}
				}

				context.commit('user/setTokens', val);
				if (null === oldTokenVal) {
					await context.dispatch('user/loadLoggedInUserData');
				}
			}
		}

		if (event.key === isRefreshingStorageKey && null !== event.newValue) {
			const val = JSON.parse(event.newValue) as { sender: string; isRefreshingToken: boolean; setDate: number };
			if (val.sender === currentWindowGuid.toString() || context.state.user.isRefreshingToken.setDate > val.setDate) {
				return;
			}
			if (context.state.user.isRefreshingToken.value !== val.isRefreshingToken) {
				context.commit('user/setIsRefreshingToken', val.isRefreshingToken);
			}
		}
	});
}

export default user;
