




















import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
import Icon from "@/plugins/icons";
import DialogHandler from "@/components/common/DialogHandler";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiChip from "@/components/common/AsiChip.vue";
import StringHelper from "@/helpers/StringHelper";
import CategoryUpdateDialogMetaData from "@/components/category/admin/CategoryUpdateDialogMetaData.vue";

@Component({
	components: {CategoryUpdateDialogMetaData, AsiChip, AsiCardDetail, AsiCardDetailEntry}
})
export default class CategoryCardMeta extends Vue {

	@Prop({type: Object, required: true})
	public category!: ICategoryAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	private get keywords(): string[] {
		if (this.category.metaKeywords === null || StringHelper.isEmpty(this.category.metaKeywords)) {
			return [];
		}

		return this.category.metaKeywords
			.split(/\s*[,;]\s*/)
			.filter(w => !StringHelper.isEmpty(w))
			.map(w => w.trim());
	}

	@Emit('change')
	public change(): void {
		return;
	}

}
