








































import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogFormLayout from "../common/AsiDialogFormLayout.vue";
import MailTemplateCreate from "@/models/mail-template/MailTemplateCreate";
import AsiTextarea from "@/components/common/AsiTextarea";
import AsiTextField from "@/components/common/AsiTextField";
import AsiCard from "@/components/common/AsiCard.vue";
import {AxiosError} from "axios";
import AsiBtn from "@/components/common/AsiBtn.vue";
import {IMailTemplateAdminListEntry} from "@/models/mail-template/MailTemplateAdminModels";

@Component({
	components: {AsiBtn, AsiCard, AsiTextField, AsiTextarea, AsiDialogFormLayout}
})
export default class MailTemplateCreateDialog extends AsiDialogForm {

	@Prop({type: String, default: null})
	public subtitle!: string | null;

	@Prop({type: Object, required: true})
	public model!: IMailTemplateAdminListEntry;

	private createModel: MailTemplateCreate = new MailTemplateCreate();
	private uploadedFile: File | null = null;

	private requestSave(): void {

		this.createModel.locale = this.model.locale;
		this.createModel.mailType = this.model.mailType;

		if (!this.valid) return;

		this.loading = true;
		this.$mailTemplateServiceAdmin.create(this.createModel)
			.then(this.save)
			.catch((err: AxiosError) => this.errors = err.response?.data)
			.finally(() => this.loading = false);
	}

	private onFileChanged(e: any): void {
		this.uploadedFile = e.target.files[0];
	}

	@Watch('uploadedFile')
	private onUploadedFileChanged(value: File): void {
		if (value !== null) {
			const reader = new FileReader();
			reader.onload = (res) => {
				if (res.target !== null) {
					this.createModel.htmlTemplate = res.target.result as string;
				}
			};
			reader.readAsText(value);
		}
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		const form = this.$refs.form as HTMLFormElement;
		this.reset([this.createModel], form);
		return event;
	}
}
