





































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import {IUserShopListEntry} from "@/models/user/UserShopModels";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Snackbar from "@/helpers/Snackbar";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import {AttachmentMediaSize, PerPageOptions} from "@/helpers/constants";
import AsiListTableHeader from "@/components/common/AsiListTableHeader";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import UserHelper from "@/models/user/UserHelper";
import UserCreateDialog from "@/components/user/UserCreateDialog.vue";
import DialogHandler from "@/components/common/DialogHandler";
import Vue from "vue";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";

@Component({
	components: {AsiConfirmDialog, UserCreateDialog, AsiAvatar, AsiBtn}
})
export default class CustomerTabUsers extends Vue {

	@Prop({type: String, required: true})
	public customerId!: string;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private data: IPaginatedResponse<IUserShopListEntry> | null = null;
	private loading: boolean = false;
	private options: AsiListTableOptions = new AsiListTableOptions();
	private createDialog: DialogHandler = new DialogHandler();

	private get headers(): AsiListTableHeader[] {
		const ret = [
			new AsiListTableHeader('', 'avatar', false, false, 'center', '4rem'),
			new AsiListTableHeader(this.$t('ui.terms.username'), 'username', true, true, null),
			new AsiListTableHeader(this.$t('user.firstname'), 'firstname', true, true, null, '35%'),
			new AsiListTableHeader(this.$t('user.lastname'), 'lastname', true, true, null, '35%'),
		];

		if (!this.readonly) {
			ret.push(new AsiListTableHeader('', 'actions', false, false, 'end', '6rem'));
		}

		return ret;
	}

	protected get perPageOptions(): number[] {
		return PerPageOptions as number[];
	}

	@Watch('customerId', {immediate: true})
	private onCustomerIdChanged(): void {
		this.loadUsers();
	}

	@Emit('change')
	public change(id: string): string {
		return id;
	}

	@Emit('create')
	public create(id: string): string {
		return id;
	}

	// noinspection JSMethodCanBeStatic
	private userSelected(user: IUserShopListEntry): void {
		this.$router.push(UserHelper.detailRoute(user.id));
	}

	// noinspection JSMethodCanBeStatic
	private userAvatarUrl(user: IUserShopListEntry): string | null {
		return UserHelper.avatarUrl(user, AttachmentMediaSize.s);
	}

	private userCreated(id: string): void {
		this.loadUsers();
		this.createDialog.close();
		this.create(id);
	}

	private loadUsers(): void {
		this.loading = true;
		this.$customerServiceShop.users(this.customerId, null, this.options)
			.then(data => this.data = data)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loading = false);
	}

	private performDelete(user: IUserShopListEntry): void {
		const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
		confirm.openDialog().then((res: boolean) => {
			if (!res) return;

			this.loading = true;
			this.$userServiceShop.delete(user.id)
				.then(() => {
					this.loadUsers();
				})
				.catch(() => Snackbar.deleteError())
				.finally(() => this.loading = false);
		});
	}

}
