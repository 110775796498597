





































import {Component, Emit, Prop} from "vue-property-decorator";
import AsiDialog from "./AsiDialog.vue";
import AsiFormErrors from "@/components/common/AsiFormErrors.vue";
import Icon from "@/plugins/icons";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

@Component({
	components: {AsiFormErrors, AsiDialog}
})
export default class AsiDialogFormLayout extends mixins(ResponsiveChecks) {

	@Prop({type: Boolean, required: true})
	public open!: boolean;

	@Prop({type: String, default: '50rem'})
	public maxWidth!: string;

	@Prop({type: String, default: null})
	public title!: string | null;

	@Prop({type: String, default: null})
	public subtitle!: string | null;

	@Prop({type: String, default: Icon.warning})
	public icon!: string;

	@Prop({type: Boolean, default: true})
	public valid!: boolean;

	@Prop({type: Array, default: () => []})
	public errors!: string[];

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	@Prop({type: Boolean, default: false})
	public saveDisabled!: boolean;

	@Prop({type: String, default: null})
	public disabledHint!: string | null;

	@Prop({type: Boolean, default: false})
	public unwrapped!: boolean;

	@Prop({type: String, default: Icon.save})
	public saveIcon!: string;

	@Prop({default: null})
	public saveLabel!: string | TranslateResult | null;

	@Prop({type: String, default: Icon.cancel})
	public cancelIcon!: string;

	@Prop({default: null})
	public cancelLabel!: string | TranslateResult | null;

	@Prop({type: Boolean, default: false})
	public scrollable!: boolean;

	protected icons = Icon;

	@Emit('cancel')
	public cancel(event: Event): Event {
		return event;
	}

	@Emit('save')
	public save(event: Event): Event {
		return event;
	}

}
