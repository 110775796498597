












































import {Component, Prop, Watch} from "vue-property-decorator";
import AsiListTable from "@/components/common/AsiListTable.vue";
import AsiListTableHeader from "@/components/common/AsiListTableHeader";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
import AsiListTableLayout from "@/components/common/AsiListTableLayout.vue";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import Icon from "@/plugins/icons";
import EnumHelper from "@/helpers/EnumHelper";
import AsiSelectSimple from "@/components/common/AsiSelectSimple";
import OrderHelper from "@/helpers/OrderHelper";
import OrderStateChip from "@/components/order/OrderShipmentStateChip.vue";
import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
import DateTimeHelper from "@/helpers/DateTimeHelper";
import {IOrderShopListEntry} from "@/models/order/OrderShopModels";
import OrderListFilterShop from "@/models/order/OrderListFilterShop";
import OrderPaymentStateChip from "@/components/order/OrderPaymentStateChip.vue";
import OrderShipmentStateChip from "@/components/order/OrderShipmentStateChip.vue";
import {OrderPaymentState, OrderShipmentState} from "@/helpers/constants";
import CartHelper from "@/helpers/CartHelper";

@Component({
	components: {
		OrderShipmentStateChip,
		OrderPaymentStateChip,
		AsiDatePickerCombined,
		OrderStateChip,
		AsiSelectSimple,
		AsiContentContainer,
		AsiListTableLayout
	}
})
export default class OrderList extends AsiListTable<IOrderShopListEntry, OrderListFilterShop> implements IAsiListTableImplementation<IOrderShopListEntry, OrderListFilterShop> {

	@Prop({type: Boolean, default: false})
	public hideToolbar!: boolean;

	private icons = Icon;
	private initialized: boolean = false;

	public created(): void {
		this.reload(false, false);
		this.$nextTick(() => this.initialized = true);
	}

	// noinspection JSMethodCanBeStatic
	private get shipmentStateOptions(): { text: string, value: string | number }[] {
		return EnumHelper.toSelectItems(OrderShipmentState, true);
	}
	// noinspection JSMethodCanBeStatic
	private get paymentStateOptions(): { text: string, value: string | number }[] {
		return EnumHelper.toSelectItems(OrderPaymentState, true);
	}

	private get createdAtDateRange(): string[] | null {
		if (this.filterModel === null) return [];
		return [this.filterModel.createdAtFrom, this.filterModel.createdAtTo].filter(d => d !== null) as string[];
	}

	private set createdAtDateRange(range: string[] | null) {
		if (this.filterModel === null) return;

		const dateFrom = range !== null && range.length > 0 ? new Date(range[0]) : null;
		if (dateFrom !== null) dateFrom.setHours(0, 0, 0);
		const dateTo = range !== null && range.length > 1 ? new Date(range[1]) : null;
		if (dateTo !== null) dateTo.setHours(23, 59, 59);

		this.filterModel.createdAtFrom = dateFrom === null ? null : DateTimeHelper.toISODateTimeString(dateFrom);
		this.filterModel.createdAtTo = dateTo === null ? null : DateTimeHelper.toISODateTimeString(dateTo);
	}

	public createFilterModel(): OrderListFilterShop {
		return new OrderListFilterShop();
	}

	public createHeaders(): AsiListTableHeader[] {
		return [
			new AsiListTableHeader(this.$t('order.orderNumber'), 'orderNumber', true, true, 'left'),
			new AsiListTableHeader(this.$t('order.createdAt'), 'createdAt', true, true, 'center', '15rem'),
			new AsiListTableHeader(this.$t('order.paymentState'), 'paymentState', true, true, 'center', '10rem'),
			new AsiListTableHeader(this.$t('order.shipmentState'), 'shipmentState', true, true, 'center', '10rem'),
			new AsiListTableHeader('', 'actions', true, false, 'end', '5rem'),
		];
	}

	public fetchData(filterModel: OrderListFilterShop | null, tableOptions: AsiListTableOptions | null): Promise<IPaginatedResponse<IOrderShopListEntry>> {
		return this.$orderServiceShop.orders(filterModel, tableOptions);
	}

	public filterTextChanged(filterText: string): void {
		if (this.filterModel === null) return;
		this.filterModel.fulltext = filterText;
	}

	public openModelDetailPage(model: IOrderShopListEntry): void {
		this.$router.push(OrderHelper.detailRoute(model.id));
	}

	public reload(goToFirstPage: boolean = false, debounce: boolean = true): void {
		this.reloadInternal(this, goToFirstPage, debounce);
	}

	protected prepareOptions(options: AsiListTableOptions): AsiListTableOptions {
		if (options.sortBy.length === 0 && this.defaultSortBy.length === 0) {
			options.sortBy = ['orderNumber'];
			options.sortDesc = [true];
		}
		return options;
	}

	private reorder(itemId: string): void {
		this.$orderServiceShop.reorder(itemId)
			.then(res => {
				this.$store.commit('cart/setActiveCartId', res.id);
				this.$store.dispatch('cart/loadCarts');
				this.$router.push(CartHelper.checkoutRoute(res.id));
			})
			.catch(() => console.error('reorder failed'));
	}

	@Watch('tableOptions', {deep: true})
	private onTableOptionsChanged(): void {
		if (!this.initialized) return;
		this.reload();
	}

	@Watch('filterModel', {deep: true})
	private onFilterModelChanged(): void {
		if (!this.initialized) return;
		this.reload(true);
	}

}
