























import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import AsiTextField from "@/components/common/AsiTextField";
import AsiLocaleAutocompleteInput from "@/components/common/AsiLocaleAutocompleteInput.vue";
import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
import CustomerUpdateNumbers from "@/models/customer/CustomerUpdateNumbers";

@Component({
	components: {AsiLocaleAutocompleteInput, AsiTextField, AsiDialogFormLayout, AsiDialogForm}
})
export default class CustomerUpdateDialogNumbers extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public customer!: ICustomerAdmin;

	private model: CustomerUpdateNumbers | null = null;

	@Watch('customer', {deep: true, immediate: true})
	private onCustomerChanged(): void {
		this.setCustomerInternal();
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.setCustomerInternal();
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private setCustomerInternal(): void {
		if (this.model === null) {
			this.model = new CustomerUpdateNumbers(this.customer);
			this.valid = true;
		} else {
			this.model.setDetailModel(this.customer);
		}
	}

	private requestSave(): void {
		if (this.model === null) return;

		this.loading = true;
		this.$customerServiceAdmin.updateNumbers(this.customer.id, this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

}
