import { render, staticRenderFns } from "./OrderPaymentRedirectionDialog.vue?vue&type=template&id=7f3baaba&scoped=true"
import script from "./OrderPaymentRedirectionDialog.vue?vue&type=script&lang=ts"
export * from "./OrderPaymentRedirectionDialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f3baaba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VDivider,VProgressCircular})
