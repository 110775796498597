var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"server-items-length":_vm.totalItems,"items":_vm.items,"items-per-page":_vm.tableOptions.itemsPerPage,"page":_vm.tableOptions.page,"hide-default-footer":"","headers":_vm.headers,"item-key":"id","hide-default-header":_vm.sMobile && _vm.portrait},on:{"update:options":function($event){return _vm.$emit('update:tableOptions', $event)},"update:page":function($event){return _vm.$set(_vm.tableOptions, "page", $event)},"click:row":_vm.itemSelected},scopedSlots:_vm._u([(_vm.sMobile && _vm.portrait)?{key:"body",fn:function(){return [(_vm.items.length > 0)?_c('tbody',_vm._l((_vm.items),function(item){return _c('ItemListViewTableMobileEntry',{key:item.id,attrs:{"item":item,"show-attribute-callback":_vm.showAttributeCallback},nativeOn:{"click":function($event){return _vm.itemSelected(item)}}})}),1):_vm._e()]},proxy:true}:null,{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-1",attrs:{"src":_vm.avatarUrl(item),"alt":_vm.nameTranslated,"aspect-ratio":1}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.nameTranslated(item.name))+" ")]}},_vm._l((_vm.itemAttributes),function(attr){return {key:("item." + (attr.key)),fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.itemAttributeValue(item[attr.key]))+" ")]}}}),{key:"item.isTopSeller",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-icon',[_vm._v(_vm._s(_vm.icons.yes))]):_c('v-icon',[_vm._v(_vm._s(_vm.icons.no))])]}},{key:"item.canOrderSample",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-icon',[_vm._v(_vm._s(_vm.icons.yes))]):_c('v-icon',[_vm._v(_vm._s(_vm.icons.no))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ItemAddToCart',{attrs:{"item":item,"small":""}})]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('ItemListPagination',{attrs:{"total-items":_vm.totalItems,"table-options":_vm.tableOptions,"per-page-options":_vm.perPageOptions}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }