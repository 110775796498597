





















import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Snackbar from "@/helpers/Snackbar";
import {MailType} from "@/helpers/constants";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import MailTemplateListFilter from "@/models/mail-template/MailTemplateListFilter";
import {IMailTemplateAdmin, IMailTemplateAdminListEntry} from "@/models/mail-template/MailTemplateAdminModels";
import SettingsCardMailTemplatesGeneral from "@/components/settings/admin/SettingsCardMailTemplatesGeneral.vue";
import SettingsCardMailTemplatesOrder from "@/components/settings/admin/SettingsCardMailTemplatesOrder.vue";
import SettingsCardMailTemplateSampleOrder
	from "@/components/settings/admin/SettingsCardMailTemplatesSampleOrder.vue";
import SettingsTabMailTemplatesContact from "@/components/settings/admin/SettingsTabMailTemplatesContactForm.vue";

@Component({
	components: {
		SettingsTabMailTemplatesContact,
		SettingsCardMailTemplateSampleOrder,
		SettingsCardMailTemplatesGeneral,
		SettingsCardMailTemplatesOrder,
		AsiBtn, AsiContentContainer
	}
})
export default class SettingsTabMailTemplates extends Vue {

	private loading: boolean = false;
	private locale: string = this.$i18n.locale;
	private mailTemplates: IMailTemplateAdminListEntry[] = [];

	private get generalMailTemplates(): IMailTemplateAdminListEntry[] {
		const generalMailTypes = [
			MailType.mailTypeConfirmation,
			MailType.mailTypePasswordReset,
		];

		return this.prepareMailTemplates(generalMailTypes);
	}

	private get orderMailTemplates(): IMailTemplateAdminListEntry[] {
		const taskMailTypes = [
			MailType.mailTypeOrderConfirmed,
			MailType.mailTypeOrderShipped,
			MailType.mailTypeOrderReadyForPickup,
		];
		return this.prepareMailTemplates(taskMailTypes);
	}

	private get sampleOrderMailTemplates(): IMailTemplateAdminListEntry[] {
		const taskMailTypes = [
			MailType.mailTypeNewSampleOrder,
		];
		return this.prepareMailTemplates(taskMailTypes);
	}

	private get contactConfirmationMailTemplates(): IMailTemplateAdminListEntry[] {
		const taskMailTypes = [
			MailType.mailTypeContactForm,
			MailType.mailTypeContactFormConfirmation,
		];
		return this.prepareMailTemplates(taskMailTypes);
	}

	public created(): void {
		this.loadMailTemplates();
	}

	private loadMailTemplates(): void {
		const filter = new MailTemplateListFilter();
		const options = new AsiListTableOptions();
		options.itemsPerPage = 0;

		this.loading = true;
		this.$mailTemplateServiceAdmin.mailTemplates(filter, options)
			.then(data => this.mailTemplates = data.data)
			.catch(() => Snackbar.loadingError())
			.finally(() => {
				this.loading = false;
			});
	}

	private deleteTemplate(id: string): void {
		this.loading = true;
		this.$mailTemplateServiceAdmin.delete(id)
			.then(() => this.loadMailTemplates())
			.catch(() => Snackbar.deleteError())
			.finally(() => {
				this.loading = false;
			});
	}

	/**
	 * Returns mail templates for all requested types, generates empty templates for missing ones
	 * @param types
	 * @private
	 */
	private prepareMailTemplates(types: MailType[]): IMailTemplateAdminListEntry[] {
		const returnValue = this.mailTemplates
			.filter(mt => types.includes(mt.mailType))
			.filter(mt => mt.locale === this.locale);

		const availableTypes = returnValue.map(mt => mt.mailType);
		const missingTypes = types.filter(t => !availableTypes.includes(t));
		missingTypes.forEach(mailType => {
			returnValue.push(this.generateEmptyMailTemplate(mailType, this.locale));
		});

		return returnValue.sort((a: IMailTemplateAdminListEntry, b: IMailTemplateAdminListEntry) => a.mailType - b.mailType);
	}

	private generateEmptyMailTemplate(type: MailType, locale: string): IMailTemplateAdmin {
		return {
			mailType: type,
			locale: locale,
			htmlTemplate: "",
			subject: null
		} as IMailTemplateAdmin;
	}

}
