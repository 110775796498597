











































import {Component, Prop} from 'vue-property-decorator';
import EnumHelper from "@/helpers/EnumHelper";
import {ItemAttributeFilterVisibility, ItemAttributeVisibility} from "@/helpers/constants";
import {ICategoryAdminListEntry} from "@/models/category/CategoryAdminModels";
import VueI18n from "vue-i18n";
import CategoryUpdateItemAttributes from "@/models/category/CategoryUpdateItemAttributes";
import AsiSelect from "@/components/common/AsiSelect";
import {ValidationRule} from "@/declarations/ValidationRule";
import RequiredValidator from "@/validators/RequiredValidator";
import ItemHelper from "@/models/item/ItemHelper";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import {mixins} from "vue-class-component";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiCardDetailEntry, AsiSelect}
})
export default class CategoryCardAttributesEntry extends mixins(ResponsiveChecks) {

	@Prop({type: Object, required: true})
	public category!: ICategoryAdminListEntry;

	@Prop({type: Object, default: null})
	public updateModel!: CategoryUpdateItemAttributes | null;

	@Prop({type: String, required: true})
	public attribute!: string;

	@Prop({type: Boolean, default: false})
	public edit!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private optionsFilterVisibility = EnumHelper.toSelectItems(ItemAttributeFilterVisibility, true);

	private get optionsVisibility(): { text: string; value: number | string }[] {
		let availableVisibilities: number[] = [];

		switch (this.attribute) {
			case 'avatarAttachmentId':
				availableVisibilities = [ItemAttributeVisibility.detailViewOnly, ItemAttributeVisibility.largeView, ItemAttributeVisibility.mediumView, ItemAttributeVisibility.smallView];
				break;
			case 'price':
				availableVisibilities = [ItemAttributeVisibility.detailViewOnly, ItemAttributeVisibility.largeView, ItemAttributeVisibility.mediumView, ItemAttributeVisibility.smallView];
				break;
			default:
				availableVisibilities = EnumHelper.toArray(ItemAttributeVisibility).map(i => i.value) as number[];
				break;
		}

		return EnumHelper.toSelectItems(ItemAttributeVisibility, true).filter(a => availableVisibilities.includes(a.value as number));
	}

	private get showFilter(): boolean {
		switch (this.attribute) {
			case 'avatarAttachmentId':
				return false;
			default:
				return true;
		}
	}

	private get attributeIcon(): string {
		return ItemHelper.attributeIcon(this.attribute);
	}

	private get attributeLabel(): string | TranslateResult | null {
		return this.$t(`item.${this.attribute}`);
	}

	private get visibilityRules(): ValidationRule[] {
		return [
			new RequiredValidator(this.$t('item.terms.attributeVisibility').toString()).validationRule(),
		];
	}

	private get visibilityLabel(): string | TranslateResult | null {
		//@ts-ignore
		const value = this.updateModel === null ? this.category[`${this.attribute}Visibility`] : this.updateModel[`${this.attribute}Visibility`];
		return this.optionsVisibility.find(o => o.value === value)?.text ?? null;
	}

	private get visibilityValue(): ItemAttributeVisibility | null {
		//@ts-ignore
		return this.updateModel === null ? null : this.updateModel[`${this.attribute}Visibility`];
	}

	private set visibilityValue(value: ItemAttributeVisibility | null) {
		if (value === null) return;
		//@ts-ignore
		this.updateModel[`${this.attribute}Visibility`] = value;
	}

	private get filterVisibilityRules(): ValidationRule[] {
		return [
			new RequiredValidator(this.$t('item.terms.attributeFilterVisibility').toString()).validationRule(),
		];
	}

	private get filterVisibilityLabel(): string | TranslateResult | null {
		//@ts-ignore
		const value = this.updateModel === null ? this.category[`${this.attribute}FilterVisibility`] : this.updateModel[`${this.attribute}FilterVisibility`];
		return this.optionsFilterVisibility.find(o => o.value === value)?.text ?? null;
	}

	private get filterVisibilityValue(): ItemAttributeFilterVisibility | null {
		//@ts-ignore
		return this.updateModel === null ? null : this.updateModel[`${this.attribute}FilterVisibility`];
	}

	private set filterVisibilityValue(value: ItemAttributeFilterVisibility | null) {
		if (value === null) return;
		//@ts-ignore
		this.updateModel[`${this.attribute}FilterVisibility`] = value;
	}
}
