

















































import Vue from 'vue';
import Icon from "@/plugins/icons";
import {Component, Emit, Prop} from "vue-property-decorator";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
import AsiAvatar from "@/components/common/AsiAvatar.vue";

@Component({
	components: {AsiAvatar, AsiFadeTransition, AsiBtn},
})
export default class AsiPageTitle extends Vue {

	@Prop({type: String, default: null})
	public title!: string | null;

	@Prop({type: String, default: null})
	public subtitle!: string | null;

	@Prop({type: String, default: null})
	public icon!: string | null;

	@Prop({type: String, default: null})
	public avatarUrl!: string | null;

	@Prop({type: String, default: 'white'})
	public avatarIconColor!: string | null;

	@Prop({type: String, default: 'primary'})
	public avatarBgColor!: string | null;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	@Prop({type: Boolean, default: true})
	public showCreate!: boolean;

	@Prop({type: Boolean, default: true})
	public showMenuActions!: boolean;

	@Prop({type: Boolean, default: true})
	public showFixedActions!: boolean;

	@Prop({type: String, default: 'primary'})
	public progressColor!: string;

	@Prop({type: Boolean, default: true})
	public progressIndeterminate!: boolean;

	@Prop({type: Number, default: 0})
	public progressValue!: number;

	@Prop({type: Boolean, default: false})
	public progressPersistent!: boolean;

	private icons = Icon;

	private get hasCreateListener(): boolean {
		return !!this.$listeners.create;
	}

	private get hasMenuActions(): boolean {
		return !!this.$slots['menu-actions'];
	}

	private get hasFixedActions(): boolean {
		return !!this.$slots['fixed-actions'];
	}

	@Emit('create')
	public create(event: Event): Event {
		return event;
	}

}
