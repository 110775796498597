











































































































import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";

@Component
export default class LogoHerzogDichtungen extends Vue {

	@Prop({type: Boolean, default: false})
	public small!: boolean;

	@Prop({type: Boolean, default: false})
	public hideTypo!: boolean;

	@Prop({type: String, default: null})
	public iconColor!: string | null;

	@Prop({type: String, default: null})
	public typoColor!: string | null;

	@Prop({type: String, default: null})
	public width!: string | null;

	@Prop({type: String, default: null})
	public height!: string | null;

	private get dimensions(): string {
		return this.hideTypo ? '0 0  17.75 8.37' : '0 0 100 8.37';
	}

	private get iconColorFinal(): string {
		return this.iconColor ?? '#eb2918';
	}

	private get typoColorFinal(): string {
		return this.typoColor ?? '#eb2918';
	}

	private get hasClickListener(): boolean {
		return !!this.$listeners.click;
	}

	@Emit('click')
	public click(event: Event): Event {
		return event;
	}

}
