



















import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import PaymentTypeList from "@/components/payment-type/admin/PaymentTypeList.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import PaymentTypeCreateDialog from "@/components/payment-type/admin/PaymentTypeCreateDialog.vue";
import DialogHandler from "@/components/common/DialogHandler";

@Component({
	components: {PaymentTypeCreateDialog, AsiBtn, PaymentTypeList}
})
export default class SettingsTabPaymentTypes extends Vue {

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private createDialog: DialogHandler = new DialogHandler();

	private reloadList(): void {
		const list = this.$refs.list as PaymentTypeList | undefined;
		if (list !== undefined) list.reload(false, false);
	}

}
