




















import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import EnumHelper from "@/helpers/EnumHelper";
import AsiChip from "@/components/common/AsiChip.vue";
import {IOrderAdminSimple} from "@/models/order/OrderAdminModels";
import {IOrderShopSimple} from "@/models/order/OrderShopModels";
import OrderHelper from "@/helpers/OrderHelper";
import {OrderShipmentState} from "@/helpers/constants";
import Icon from "@/plugins/icons";

@Component({
	components: {AsiChip}
})
export default class OrderShipmentStateChip extends Vue {

	@Prop({type: Object, required: true})
	public order!: IOrderAdminSimple | IOrderShopSimple;

	@Prop({type: Boolean, default: false})
	public expanded!: boolean;

	private icons = Icon;

	private stateLabel(shipmentState: OrderShipmentState): string|null {
		return EnumHelper.textFromValue(OrderShipmentState, shipmentState, true);
	}
	private stateIcon(shipmentState: OrderShipmentState): string {
		return OrderHelper.shipmentStateIcon(shipmentState);
	}
	private stateColor(shipmentState: OrderShipmentState): string {
		return OrderHelper.shipmentStateColor(shipmentState);
	}

	private get states(): OrderShipmentState[] {
		return EnumHelper.toSelectItems(OrderShipmentState, true).map(s => s.value) as OrderShipmentState[];
	}

}
