




















import {Component, Emit, Prop} from "vue-property-decorator";
import AsiDialogFormLayout from "../../common/AsiDialogFormLayout.vue";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiTextField from "@/components/common/AsiTextField";
import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
import ItemAutocompleteInput from "@/components/item/admin/ItemAutocompleteInput.vue";
import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";
import GlobalConfigUpdateExternalSource from "@/models/global-config/GlobalConfigUpdateExternalSource";

@Component({
	components: {
		ItemAutocompleteInput,
		AsiDatePickerCombined, AsiTextField, AsiDialogFormLayout
	}
})
export default class GlobalConfigUpdateDialogExternalSource extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public globalConfig!: IGlobalConfigAdmin;

	private model: GlobalConfigUpdateExternalSource | null = null;

	public created(): void {
		this.setGlobalConfigInternal();
	}

	private setGlobalConfigInternal(): void {
		if (this.globalConfig === null) {
			this.model = null;
			return;
		}

		if (this.model === null) {
			this.model = new GlobalConfigUpdateExternalSource(this.globalConfig);
			this.valid = true;
		} else {
			this.model.setDetailModel(this.globalConfig);
		}
	}

	private requestSave(): void {
		if (this.model === null) return;

		this.loading = true;
		this.$globalConfigServiceAdmin.updateExternalSource(this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.setGlobalConfigInternal();
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

}
