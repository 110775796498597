import { render, staticRenderFns } from "./ShoppingListPosition.vue?vue&type=template&id=aefd9956&scoped=true"
import script from "./ShoppingListPosition.vue?vue&type=script&lang=ts"
export * from "./ShoppingListPosition.vue?vue&type=script&lang=ts"
import style0 from "./ShoppingListPosition.vue?vue&type=style&index=0&id=aefd9956&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aefd9956",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListItem,VListItemAction,VListItemContent,VListItemTitle})
