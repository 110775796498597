





















import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";

@Component
export default class AsiBtn extends Vue {

	@Prop({type: String, default: null})
	public icon!: string | null;

	@Prop({type: String, default: null})
	public color!: null;

	@Prop({type: String, default: null})
	public iconColor!: null;

	@Prop({type: Boolean, default: false})
	public block!: boolean;

	@Prop({type: Boolean, default: false})
	public depressed!: boolean;

	@Prop({type: Boolean, default: false})
	public text!: boolean;

	@Prop({type: Boolean, default: false})
	public outlined!: boolean;

	@Prop({type: Boolean, default: false})
	public tile!: boolean;

	@Prop({type: Boolean, default: false})
	public xLarge!: boolean;

	@Prop({type: Boolean, default: false})
	public large!: boolean;

	@Prop({type: Boolean, default: false})
	public small!: boolean;

	@Prop({type: Boolean, default: false})
	public xSmall!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: false;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	@Prop({type: Boolean, default: false})
	public preventIconMode!: boolean;

	@Prop({type: String, default: null})
	public href!: string;

	@Prop({type: String, default: null})
	public target!: string;

	@Prop({type: Boolean, default: false})
	public hideIcon!: boolean;

	@Prop({type: Boolean, default: false})
	public hideContent!: boolean;

	@Prop({default: null})
	public value!: any;

	@Prop({type: Boolean, default: false})
	public fab!: boolean;

	@Prop({type: Number, default: null})
	public iconSize!: number | null;

	@Prop({type: Boolean, default: false})
	public appendIcon!: boolean;

	@Prop({type: Boolean, default: false})
	public iconSpin!: boolean;

	private get iconSizeFinal(): number {
		if (this.iconSize !== null) return this.iconSize;

		if (this.xSmall) return 11;
		if (this.small) return 14;
		if (this.large) return 24;
		if (this.xLarge) return 28;
		return 20;
	}

	private get iconMode(): boolean {
		return !this.preventIconMode && this.hasIcon && !this.hasContent;
	}

	private get hasIcon(): boolean {
		return this.icon !== null;
	}

	private get hasContent(): boolean {
		return (this.$slots.default ?? []).length > 0;
	}

	@Emit('click')
	public click(event: Event): Event {
		return event;
	}

}
