

































import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import ItemAttributeFilter from "@/components/item/shop/attribute-filters/ItemAttributeFilter.vue";
import AsiSelectSimple from "@/components/common/AsiSelectSimple";
import {FilterEntrySelection, FilterEntrySelectionOption} from "@/models/item/IItemShopPaginatedResponse";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiSelectSimple, ItemAttributeFilter}
})
export default class ItemAttributeFilterSelection extends Vue {

	@Prop({type: String, required: true})
	public attribute!: string;

	@Prop({type: Array, required: true})
	public value!: string[];

	@Prop({type: Object, required: true})
	public data!: FilterEntrySelection;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private get filterActive(): boolean {
		return this.value.length > 0;
	}

	private get valueFormatted(): string | TranslateResult | null {
		return this.value.length > 0 ? this.value.join(', ') : null;
	}

	private get optionsSorted(): FilterEntrySelectionOption[] {
		return this.data.options.sort((a: FilterEntrySelectionOption, b: FilterEntrySelectionOption) => a.name.localeCompare(b.name));
	}

	private clear(): void {
		this.input([]);
	}

	@Emit('input')
	public input(value: string[]): string[] {
		return value;
	}

	private get hasLabelSlot(): boolean {
		return !!this.$slots.label || !!this.$scopedSlots['label'];
	}

}
