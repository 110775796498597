





























import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import TranslatedValueCreate from "@/models/translated-value/TranslatedValueCreate";
import TranslatedValueUpdate from "@/models/translated-value/TranslatedValueUpdate";
import AsiTextField from "@/components/common/AsiTextField";
import {ValidationRule} from "@/declarations/ValidationRule";
import TranslatedValueRules from "@/models/translated-value/TranslatedValueRules";
import VueI18n from "vue-i18n";
import LocaleHelper from "@/helpers/LocaleHelper";
import AsiTextarea from "@/components/common/AsiTextarea";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiTextarea, AsiTextField}
})
export default class TranslatedValueForm extends Vue {

	@Prop({type: Object, required: true})
	public model!: TranslatedValueCreate | TranslatedValueUpdate;

	@Prop({type: Boolean, default: false})
	public required!: boolean;

	@Prop({type: [String, Object], default: null})
	public label!: string | TranslateResult | null;

	@Prop({type: Boolean, default: false})
	public textArea!: boolean;

	private get deRules(): ValidationRule[] {
		return this.required ? TranslatedValueRules.deRules : [];
	}

	private createLabel(lang: string): string | TranslateResult {
		const locale = LocaleHelper.localeFromLanguage(lang);
		const label = this.label?.toString() ?? null;

		if (label !== null) {
			return `${label} (${locale === null ? lang.toUpperCase() : this.$t(`locale.${locale}`)})`;
		} else {
			return locale === null ? lang.toUpperCase() : this.$t(`locale.${locale}`);
		}
	}

}
