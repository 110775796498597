






































import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import Registration from "@/models/registration/Registration";
import VueI18n from "vue-i18n";
import {CustomerType} from "@/helpers/constants";
import TranslateResult = VueI18n.TranslateResult;
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import Icon from "@/plugins/icons";
import AddressCreate from "@/models/address/AddressCreate";
import AddressHelper from "@/helpers/AddressHelper";
import AsiChip from "@/components/common/AsiChip.vue";

@Component({
	components: {AsiChip, AsiCardDetailEntry, AsiCardDetail}
})
export default class RegistrationSummary extends Vue {

	@Prop({type: Registration, required: true})
	public model!: Registration;

	private icons = Icon;
	private readonly typeBusiness = CustomerType.business;

	private get customerTypeLabel(): string | TranslateResult | null {
		if (this.model.type === null) return null;
		return this.$t(`constants.${this.model.type === CustomerType.business ? 'business' : 'private'}`);
	}

	private addressLabel(address: AddressCreate, index: number): string {
		return address.label ?? `${this.$t('address.singular')} ${index + 1}`;
	}

	private addressLines(address: AddressCreate): string[] {
		return AddressHelper.addressLines(address);
	}

}
