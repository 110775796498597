






import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import CartContent from "@/components/cart/shop/CartContent.vue";
import {ICartAdmin} from "@/models/cart/CartAdminModels";
import Icon from "@/plugins/icons";
import AsiCard from "@/components/common/AsiCard.vue";

@Component({
	components: {AsiCard, CartContent}
})
export default class CartCardPositions extends Vue {

	@Prop({required: true})
	public cart!: ICartAdmin | null;

	private icons = Icon;

}
