






import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import EnumHelper from "@/helpers/EnumHelper";
import {CategoryState} from "@/helpers/constants";
import AsiChip from "@/components/common/AsiChip.vue";
import CategoryHelper from "@/models/category/CategoryHelper";
import {ICategoryShopSimple} from "@/models/category/CategoryShopModels";
import {ICategoryAdminSimple} from "@/models/category/CategoryAdminModels";

@Component({
	components: {AsiChip}
})
export default class CategoryStateChip extends Vue {

	@Prop({type: Object, required: true})
	public category!: ICategoryAdminSimple | ICategoryShopSimple;

	// noinspection JSMethodCanBeStatic
	private get label(): string | null {
		return EnumHelper.textFromValue(CategoryState, this.category.state, true);
	}

	// noinspection JSMethodCanBeStatic
	private get icon(): string {
		return CategoryHelper.stateIcon(this.category);
	}

	// noinspection JSMethodCanBeStatic
	private get color(): string {
		return CategoryHelper.stateColor(this.category);
	}

}
