
































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AddressCreateDialog from "@/components/address/AddressCreateDialog.vue";
import AddressCard from "@/components/address/AddressCard.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import DialogHandler from "@/components/common/DialogHandler";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Snackbar from "@/helpers/Snackbar";
import {IAddressListEntry} from "@/models/address/AddressModels";
import {ICustomerShop} from "@/models/customer/CustomerShopModels";
import {CustomerType} from "@/helpers/constants";
import AddressHelper from "@/helpers/AddressHelper";
import Vue from "vue";

@Component({
	components: {AsiBtn, AddressCard, AddressCreateDialog}
})
export default class CustomerTabAddresses extends Vue {

	@Prop({type: Object, required: true})
	public customer!: ICustomerShop;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private loading: boolean = false;
	private addressCreateDialog: DialogHandler = new DialogHandler();
	private addresses: IAddressListEntry[] = [];

	private get isBusinessCustomer(): boolean {
		return this.customer.type === CustomerType.business;
	}

	private get addressesSorted(): IAddressListEntry[] {
		return this.addresses.sort(AddressHelper.sort);
	}

	private get defaultShippingAddressId(): string | null {
		return this.customer.defaultShippingAddress?.id ?? null;
	}

	private get defaultBillingAddressId(): string | null {
		return this.customer.defaultBillingAddress?.id ?? null;
	}

	@Watch('customer.id', {immediate: true})
	private onCustomerIdChanged(): void {
		this.loadAddresses();
	}

	@Emit('customerChanged')
	public customerChanged(): void {
		return;
	}

	@Emit('create')
	public create(id: string): string {
		return id;
	}

	private addressCreated(event: { id: string; defaultChanged: boolean }): void {
		this.loadAddresses();
		this.addressCreateDialog.close();
		this.create(event.id);
		if (event.defaultChanged) this.customerChanged();
	}

	private loadAddresses(): void {
		const options = new AsiListTableOptions();
		options.itemsPerPage = 0;

		this.loading = true;
		this.$customerServiceAdmin.addresses(this.customer.id, null, options)
			.then(data => this.addresses = data.data)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loading = false);
	}

}
