











import Vue from 'vue';
import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import {FrameAssemblyType} from "@/helpers/constants";
import AsiSelectSimple from "@/components/common/AsiSelectSimple";
import EnumHelper from "@/helpers/EnumHelper";

@Component({
	components: {AsiSelectSimple}
})
export default class ItemCardFrameAssemblyTypeSelection extends Vue {

	@Prop({type: Boolean, default: false})
	public small!: boolean;
	private FrameAssemblyType = FrameAssemblyType
	private frameType: FrameAssemblyType = FrameAssemblyType.frame

	private get frameTypes(): { text: string; value: number | string }[] {
		return EnumHelper.toSelectItems(FrameAssemblyType, true);
	}

	@Watch('frameType')
	private onFrameTypeChanged(): void {
	    this.typeChanged();
	}

	@Emit('changed')
	public typeChanged(): FrameAssemblyType {
	    return this.frameType;
	}
}
