












import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
import {IShoppingListAdmin} from "@/models/shopping-list/ShoppingListAdminModels";
import ShoppingListCardBasicData from "@/components/cart/admin/ShoppingListCardBasicData.vue";
import ShoppingListCardPositions from "@/components/cart/admin/ShoppingListCardPositions.vue";

@Component({
	components: {ShoppingListCardPositions, ShoppingListCardBasicData, AsiSkeletonCards, AsiContentContainer}
})
export default class ShoppingListTabDetails extends Vue {
	@Prop({required: true})
	public shoppingList!: IShoppingListAdmin | null;
}
