































import Vue from 'vue';
import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import StringHelper from "@/helpers/StringHelper";
import {ICartPositionShopSimple} from "@/models/cart-position/CartPositionShopModels";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import Snackbar from "@/helpers/Snackbar";

@Component({
	components: {AsiBtn, AsiTextFieldSimple}
})
export default class CartPositionCustomerReferenceInput extends Vue {

	@Prop({type: Object, required: true})
	public position!: ICartPositionShopSimple;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private icons = Icon;
	private menuOpen: boolean = false;
	private valueInternal: string | null = null;
	private loading: boolean = false;

	private get hasChange(): boolean {
		return this.position.customerReference !== this.valueInternal;
	}

	private get inputValue(): string | null {
		return this.valueInternal;
	}

	private set inputValue(value: string | null) {
		this.valueInternal = value === null || StringHelper.isEmpty(value) ? null : value.trim();
	}

	@Watch('position.customerReference', {immediate: true})
	private onCartPositionCustomerReferenceChanged(value: string): void {
		this.valueInternal = value;
	}

	@Watch('menuOpen')
	private onMenuOpenChanged(value: boolean): void {
		if (!value) {
			this.valueInternal = this.position.customerReference;
		}
	}

	@Emit('change')
	public change(customerReference: string | null): string | null {
		return customerReference;
	}

	private keydownEnter(): void {
		if (this.hasChange) this.performUpdate();
	}

	private performUpdate(): void {
		if (!this.hasChange) return;

		this.loading = true;
		this.$store.dispatch('cart/updatePositionCustomerReference', {
			positionId: this.position.id,
			customerReference: this.valueInternal,
		})
			.then(() => {
				this.change(this.valueInternal);
				this.menuOpen = false;
			})
			.catch(() => Snackbar.updateError())
			.finally(() => this.loading = false);
	}

}
