











import Vue from 'vue';
import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import {IAttachment} from "@/models/attachment/AttachmentModels";
import AttachmentHelper from "@/models/attachment/AttachmentHelper";
import {AttachmentMediaSize} from "@/helpers/constants";

@Component
export default class GalleryPreview extends Vue {

	@Prop({type: String, default: null})
	public value!: string | null;

	@Prop({type: Array, required: true})
	public images!: IAttachment[];

	@Prop({type: Number, default: AttachmentMediaSize.s})
	public mediaSize!: AttachmentMediaSize;

	@Prop({type: Boolean, default: false})
	public small!: boolean;

	@Prop({type: Boolean, default: false})
	public auto!: boolean;

	@Prop({type: Number, default: 10000})
	public autoIntervalMs!: number;

	@Prop({type: Boolean, default: false})
	public dark!: boolean;

	private valueInitial: string | null = null;
	private autoInternal: boolean = false;
	private autoInterval: any = null;

	public created(): void {
	    this.valueInitial = this.value;
	}

	private get imagesSorted(): IAttachment[] {
		return this.images.sort((a: IAttachment, b: IAttachment) => {
			if (a.id === this.valueInitial) return -1;
			if (b.id === this.valueInitial) return 1;
			return 0;
		});
	}

	private get selectedIndex(): number {
		if (this.value === null) return 0;
		const index = this.imagesSorted.findIndex(a => a.id === this.value);
		return index === -1 ? 0 : index;
	}

	private set selectedIndex(index: number) {
		this.input(this.imagesSorted[index]?.id ?? null);
	}

	@Watch('auto', {immediate: true})
	private onAutoChanged(value: boolean): void {
		this.autoInternal = value;
	}

	@Watch('autoInternal', {immediate: true})
	private onAutoInternalChanged(value: boolean): void {
		clearInterval(this.autoInterval);
		if (value) {
			this.autoInterval = setInterval(this.next, this.autoIntervalMs);
		}
		if (this.auto !== value) {
			this.$emit('update:auto', this.autoInternal);
		}
	}

	public next(): void {
		this.selectedIndex = (this.selectedIndex + 1) % this.imagesSorted.length;
	}

	public prev(): void {
		this.selectedIndex = this.selectedIndex === 0 ? this.imagesSorted.length - 1 : this.selectedIndex - 1;
	}

	@Emit('input')
	public input(selectedId: string | null): string | null {
		return selectedId;
	}

	private imageUrl(attachment: IAttachment): string | null {
		return AttachmentHelper.getImageUrl(attachment, this.mediaSize);
	}

}
