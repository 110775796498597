






















































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiBtn from "@/components/common/AsiBtn.vue";
import ItemCardFrameAssemblyTypeSelection from "@/components/item/shop/ItemCardFrameAssemblyTypeSelection.vue";
import ItemCardFrameAssemblyFrame from "@/components/item/shop/ItemCardFrameAssemblyFrame.vue";
import AsiRadioGroup from "@/components/common/AsiRadioGroup";
import AsiTextareaSimple from "@/components/common/AsiTextareaSimple";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import ItemPriceTable from "@/components/item/ItemPriceTable.vue";
import ItemQuantitySelection from "@/components/item/shop/ItemQuantitySelection.vue";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import Icon from "@/plugins/icons";
import FrameAssemblyCreate from "@/models/frame-assembly/FrameAssemblyCreate";
import FrameAssemblyUpdate from "@/models/frame-assembly/FrameAssemblyUpdate";
import {IFrameAssembly} from "@/models/frame-assembly/FrameAssemblyModels";
import {FrameAssemblyInstallation, FrameAssemblyMeasuringPoint} from "@/helpers/constants";

@Component({
	components: {
		ItemQuantitySelection,
		ItemPriceTable,
		AsiTextFieldSimple,
		AsiTextareaSimple,
		AsiRadioGroup,
		ItemCardFrameAssemblyFrame,
		ItemCardFrameAssemblyTypeSelection,
		AsiBtn
	}
})
export default class FrameAssemblyConfigurator extends mixins(ResponsiveChecks) {
	private icons = Icon;

	@Prop({type: Object, default: null})
	public frameAssembly!: IFrameAssembly | null;

	private model: FrameAssemblyCreate = new FrameAssemblyCreate();

	private frameAssemblyInstallation = FrameAssemblyInstallation;
	private frameAssemblyMeasuringPoint = FrameAssemblyMeasuringPoint;

	public created(): void {
		if (this.frameAssembly !== null) {
			this.model.frameType = this.frameAssembly.frameType;
			this.model.width = this.frameAssembly.width;
			this.model.height = this.frameAssembly.height;
			this.model.measuringPoint = this.frameAssembly.measuringPoint;
			this.model.installation = this.frameAssembly.installation;
			this.model.description = this.frameAssembly.description;
		}
	}

	@Watch('model', {deep: true, immediate: true})
	private onModelChanged(): void {
		if (this.frameAssembly === null) {
			this.assemblyCreated();
		} else {
			this.changed();
		}
	}

	@Emit('created')
	public assemblyCreated(): FrameAssemblyCreate {
	    return this.model;
	}

	@Emit('changed')
	public changed(): FrameAssemblyUpdate {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const updateModel = new FrameAssemblyUpdate(this.frameAssembly!);
		updateModel.frameType = this.model.frameType;
		updateModel.width = this.model.width;
		updateModel.height = this.model.height;
		updateModel.measuringPoint = this.model.measuringPoint;
		updateModel.installation = this.model.installation;
		updateModel.description = this.model.description;

		return updateModel;
	}
}
