























import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import {IItemPriceShopSimple} from "@/models/item-price/ItemPriceModelsShop";

@Component({
	components: {AsiBtn}
})
export default class ItemAddToCartButton extends Vue {

	@Prop({type: Number, required: true})
	public quantity!: number;

	@Prop({type: Object, required: true})
	public item!: IItemShopListEntry;

	@Prop({type: Boolean, default: false})
	public small!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private icons = Icon;

	private get sortedPrices(): IItemPriceShopSimple[] {
		return this.item.prices.map(p => p).sort((a: IItemPriceShopSimple, b: IItemPriceShopSimple) => b.fromQuantity - a.fromQuantity);
	}

	private get currencyCode(): string | null {
		return this.item.price?.price.currency.currencyCode ?? null;
	}

	private get price(): number | null {
		return this.quantity === null
			? null
			: this.sortedPrices.map(p => p).find(p => p.fromQuantity <= (this.quantity as number))?.price.amount ?? null;
	}

	private get priceTotal(): number | null {
		return this.price !== null ? this.price * this.quantity : null;
	}

	@Emit('click')
	public click(event: Event): Event {
		return event;
	}

}
