



































import Vue from 'vue';
import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import {AttachmentMediaSize, ItemState} from "@/helpers/constants";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import Icon from "@/plugins/icons";
import Snackbar from "@/helpers/Snackbar";
import StringHelper from "@/helpers/StringHelper";
import AsiAutocomplete from "@/components/common/AsiAutocomplete";
import {IItemAdminListEntry} from "@/models/item/ItemAdminModels";
import ItemListFilterAdmin from "@/models/item/ItemListFilterAdmin";
import ItemHelper from "@/models/item/ItemHelper";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
import PathEntry from "@/models/PathEntry";

@Component({
	components: {AsiBreadcrumbs, AsiAutocomplete, AsiAvatar}
})
export default class ItemAutocompleteInput extends Vue {

	@Prop({default: null})
	public value!: string | string[] | null;

	@Prop({default: null})
	public items!: IItemAdminListEntry[] | null;

	@Prop({type: Object, default: null})
	public itemListFilter!: ItemListFilterAdmin | null;

	@Prop({type: Array, default: () => []})
	public rules!: (string | null | number | object | unknown | boolean)[];

	@Prop({type: Boolean, default: false})
	public clearable!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	@Prop({type: Boolean, default: false})
	public multiple!: boolean;

	@Prop({type: String, default: null})
	public label!: string | null;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	@Prop({type: Boolean, default: false})
	public simple!: boolean;

	@Prop({type: Boolean, default: false})
	public noBusinessCards!: boolean;

	@Prop({type: Boolean, default: false})
	public keepSearch!: boolean;

	private loadingInternal: boolean = false;
	private itemsInternal: IItemAdminListEntry[] = [];
	private icons = Icon;
	private itemListFilterInternal: ItemListFilterAdmin | null = null;
	private searchInput: string | null = null;

	private get finalLabel(): string {
		return this.label !== null ? this.label : this.$t(this.multiple ? 'item.plural' : 'item.singular').toString();
	}

	private get loadingActual(): boolean {
		return this.loading || this.loadingInternal;
	}

	private get itemOptions(): object[] {
		return this.itemsInternal;
	}

	public mounted(): void {
		if (this.items !== null) {
			this.itemsInternal = this.items;
			this.itemOptionsChanged();
		} else {
			this.setItemListFilterInternal();
			this.loadItems();
		}
	}

	@Emit('input')
	public input(selection: string | string[] | null): string | string[] | null {
		if (!this.keepSearch) this.clearSearchInput();
		return selection;
	}

	@Emit('change')
	public change(selection: string | string[] | null): string | string[] | null {
		return selection;
	}

	@Emit('itemOptionsChanged')
	public itemOptionsChanged(): IItemAdminListEntry[] {
		return this.itemsInternal;
	}

	private clearSearchInput(): void {
		this.searchInput = null;
	}

	// noinspection JSMethodCanBeStatic
	private removeFromSelection(item: IItemAdminListEntry): void {
		if (!this.multiple || !Array.isArray(this.value)) return;

		const newSelection = this.value.filter(id => id !== item.id);
		this.input(newSelection);
	}

	@Watch('items', {deep: true})
	private onItemsChanged(value: IItemAdminListEntry[]): void {
		this.itemsInternal = value;
	}

	@Watch('itemListFilter', {deep: true})
	private onItemListFilterChanged(): void {
		this.setItemListFilterInternal();
		this.loadItems();
	}

	private setItemListFilterInternal(): void {
		this.itemListFilterInternal = this.itemListFilter ?? new ItemListFilterAdmin();
		this.itemListFilterInternal.states = [ItemState.active];
	}

	private loadItems(): void {
		this.loadingInternal = true;
		this.$itemServiceAdmin.items(this.itemListFilterInternal, null)
			.then((data) => {
				this.itemsInternal = data.data;
				this.itemOptionsChanged();
			})
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loadingInternal = false);
	}

	// noinspection JSMethodCanBeStatic
	private itemAvatarUrl(item: IItemAdminListEntry): string | null {
		return ItemHelper.avatarUrl(item, AttachmentMediaSize.s);
	}

	private itemText(item: IItemAdminListEntry | []): string {
		if (Array.isArray(item)) return '';
		// noinspection JSIncompatibleTypesComparison
		if (item.id === undefined) return '';

		return this.itemName(item);
	}

	private itemSearch(item: any, queryText: string, itemText: string): boolean {
		const parts = queryText
			.toLowerCase()
			.split(/\s+/)
			.filter(p => !StringHelper.isEmpty(p));
		return !parts.some(p => itemText.toLowerCase().indexOf(p) < 0);
	}

	private itemName(item: IItemAdminListEntry): string {
		return TranslatedValueHelper.get(item.name, this.$i18n.locale, true) ?? '?';
	}

	// noinspection JSMethodCanBeStatic
	private itemPath(item: IItemAdminListEntry): PathEntry[] {
		return ItemHelper.path(item);
	}

}
