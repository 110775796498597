































































import {Component, Prop} from "vue-property-decorator";
import AsiCard from "@/components/common/AsiCard.vue";
import Icon from "@/plugins/icons";
import AsiChip from "@/components/common/AsiChip.vue";
import ItemCardFrameAssemblyFrame from "@/components/item/shop/ItemCardFrameAssemblyFrame.vue";
import AsiTextField from "@/components/common/AsiTextField";
import AsiTextarea from "@/components/common/AsiTextarea";
import AsiRadioGroup from "@/components/common/AsiRadioGroup";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import AsiTextareaSimple from "@/components/common/AsiTextareaSimple";
import ItemAddToCartButton from "@/components/item/shop/ItemAddToCartButton.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import ItemCardFrameAssemblyTypeSelection from "@/components/item/shop/ItemCardFrameAssemblyTypeSelection.vue";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import ItemQuantitySelection from "@/components/item/shop/ItemQuantitySelection.vue";
import ItemPriceTable from "@/components/item/ItemPriceTable.vue";
import FrameAssemblyConfigurator from "@/components/frame-assembly/FrameAssemblyConfigurator.vue";
import {IPrice} from "@/models/price/PriceModels";
import TotalHelper from "@/helpers/TotalHelper";
import {IItemPriceShopSimple} from "@/models/item-price/ItemPriceModelsShop";
import Snackbar from "@/helpers/Snackbar";
import FrameAssemblyCreate from "@/models/frame-assembly/FrameAssemblyCreate";

@Component({
	components: {
		FrameAssemblyConfigurator,
		ItemPriceTable,
		ItemQuantitySelection,
		ItemCardFrameAssemblyTypeSelection,
		AsiBtn,
		ItemAddToCartButton,
		AsiTextareaSimple,
		AsiTextFieldSimple,
		AsiRadioGroup, AsiTextarea, AsiTextField, ItemCardFrameAssemblyFrame, AsiChip, AsiCard
	}
})
export default class ItemCardFrameAssembly extends mixins(ResponsiveChecks) {

	@Prop({type: Object, required: true})
	public item!: IItemShopListEntry;

	private icons = Icon;

	private quantity: number = 1;
	private showPriceTable: boolean = false;
	private selectedCartId: string | null = null;
	private frameAssemblyCreate!: FrameAssemblyCreate;

	private get sortedPrices(): IItemPriceShopSimple[] {
		return this.item.prices.map(p => p).sort((a: IItemPriceShopSimple, b: IItemPriceShopSimple) => b.fromQuantity - a.fromQuantity);
	}

	private get price(): IPrice | null {
		if (this.quantity === 0) return null;

		return this.sortedPrices
			.map(p => p)
			.find(p => p.fromQuantity <= (this.quantity as number))?.price ?? null;
	}

	private get priceTotal(): number | null {
		if (this.price === null) return null;

		const amount = this.price.amount + (this.amountsInclVat ? TotalHelper.vatAmount(this.price) : 0);
		return this.quantity * amount;
	}

	private get amountsInclVat(): boolean {
		return this.$store.state.ui.amountsInclVat;
	}

	private get currencyCode(): string | null {
		return this.item.price?.price.currency.currencyCode ?? null;
	}

	public created(): void {
		this.selectedCartId = this.$store.state.cart.activeCartId;
	}

	private addToCart(): void {
		if (this.item !== null) {
			this.$store.dispatch('cart/addPosition', {
				item: this.item,
				frameAssembly: this.frameAssemblyCreate,
				quantity: this.quantity,
				cartId: this.selectedCartId,
			})
				.then(() => this.$store.commit('cart/setExpanded', true))
				.catch(() => Snackbar.createError());
		}
	}
}
