

























































import {Component} from "vue-property-decorator";
import AsiBtn from "@/components/common/AsiBtn.vue";
import CategoryNavigation from "@/components/category/shop/CategoryNavigation.vue";
import ShopView from "@/components/layout/ShopView.vue";
import {mixins} from "vue-class-component";
import RightChecks from "@/mixins/RightChecks.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import LocaleHelper from "@/helpers/LocaleHelper";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import Icon from "@/plugins/icons";
import VideoCard from "@/components/common/VideoCard.vue";
import VideoCardDialog from "@/components/common/VideoCardDialog.vue";
import AsiDialog from "@/components/common/AsiDialog.vue";
import {Routes} from "@/helpers/constants";

@Component({
	components: {AsiDialog, VideoCardDialog, VideoCard, AsiCard, ShopView, CategoryNavigation, AsiBtn}
})
export default class Home extends mixins(RightChecks, ResponsiveChecks) {

	private data: Map<string, string> = new Map<string, string>();

	private icons = Icon;

	private showVideoDialog = false;
	private showWelcomeDialog = false;
	private herzogVideoThumbnail = 'https://prodchnorth02908337ae324.blob.core.windows.net/herzog-dichtungen-poesia-webshop-assets/herzog-video/thumbnail.png';
	private silvioPic = 'https://prodchnorth02908337ae324.blob.core.windows.net/herzog-dichtungen-poesia-webshop-assets/herzog-video/silvio.png';

	private get lang(): string {
		return this.$i18n.locale === null ? 'de' : LocaleHelper.extractLanguage(this.$i18n.locale);
	}

	private get headline(): string | null {
		return this.data.get(this.lang) ?? null;
	}

	private get videoSource(): string {
		switch (this.lang) {
			case 'de':
				return 'https://prodchnorth02908337ae324.blob.core.windows.net/herzog-dichtungen-poesia-webshop-assets/herzog-video/webshop-herzog-dichtungen-de.mp4';
			case 'en':
				return 'https://prodchnorth02908337ae324.blob.core.windows.net/herzog-dichtungen-poesia-webshop-assets/herzog-video/webshop-herzog-dichtungen-en.mp4';
			case 'fr':
				return 'https://prodchnorth02908337ae324.blob.core.windows.net/herzog-dichtungen-poesia-webshop-assets/herzog-video/webshop-herzog-dichtungen-fr.mp4';
			default:
				return 'https://prodchnorth02908337ae324.blob.core.windows.net/herzog-dichtungen-poesia-webshop-assets/herzog-video/webshop-herzog-dichtungen-de.mp4';
		}
	}

	private get blogUrl(): string {
		switch (this.lang) {
			case 'de':
				return 'https://www.poesia.ch/de/home-de/news/465-mk-webshop-in-neuem-glanz.html';
			case 'en':
				return 'https://www.poesia.ch/en/home-en/news/467-webshop-in-a-new-light.html';
			case 'fr':
				return 'https://www.poesia.ch/fr/home-fr/actualites/466-la-boutique-en-ligne-de-mk-fait-peau-neuve.html';
			default:
				return 'https://www.poesia.ch/de/home-de/news/465-mk-webshop-in-neuem-glanz.html';
		}
	}

	private get videoWatched(): boolean {
		return this.$store.state.ui.videoWatched;
	}

	private get welcomeMessageSeen(): boolean {
		return this.$store.state.ui.welcomeMessageSeen;
	}

	public created(): void {
		this.init();
	}

	private init(): void {

		if (this.$isHerzogShop) {
			this.showVideoDialog = !this.videoWatched;
		}

		if (this.$isMkShop){
			this.showWelcomeDialog = !this.welcomeMessageSeen;
		}

		if (this.$isHerzogShop) {
			this.data.set('en',
				'In our new webshop you can order seals quickly and easily at any time. Find O-rings, shaft seals, grooved rings,\n' +
				'flat seals, hose seals and much more for a wide range of applications in the industrial sector - always in proven\n' +
				'top quality.');

			this.data.set('fr',
				'Dans notre nouvelle boutique en ligne, vous pouvez commander des joints à tout moment, rapidement et facilement. ' +
				'Vous trouverez des joints toriques, des joints d\'arbre, des bagues rainurées, des joints plats, des joints de\n' +
				'tuyau et bien d\'autres choses encore pour les applications les plus diverses dans le domaine de l\'industrie -\n' +
				'toujours dans une qualité de pointe éprouvée.');

			this.data.set('de',
				'In unserem neuen Webshop bestellen Sie Dichtungen jederzeit schnell und einfach. ' +
				'Finden Sie O-Ringe, Wellendichtungen, Nutringe, Flachdichtungen, Schlauchdichtungen uvm. für unterschiedlichste Anwendung im\n' +
				'Industrie-Bereich – stets in bewährter Top-Qualität.');
		}
		if (this.$isMkShop) {
			this.data.set('en',
				'mk dichtungs ag supplies its customers with almost 5000 seal profiles for all conceivable applications in the ' +
				'fields of building, refrigeration/air conditioning technology, automotive engineering, power plants, rolling stock ' +
				'and transport. Many years of experience and know-how as well as a wide range of new, replacement and retrofit seals ' +
				'for metal frames, doors, windows, showers, fridges, refrigerated drawers and many other applications.');

			this.data.set('fr',
				'mk dichtungs ag propose à ses clients  près de 5000 profilés d’étanchéité pour toutes les applications possibles ' +
				'et imaginables dans les secteurs du bâtiment, de la réfrigération et de la climatisation, de l’industrie, de l’industrie ' +
				'automobile, des centrales électriques, des véhicules ferroviaires et des transports. Une longue expérience et un ' +
				'savoir faire, ainsi qu’un large assortiment de joints neufs, de remplacement et de rajout pour les huisseries métalliques, ' +
				'les portes, fenêtres, douches, réfrigérateurs, tiroirs de meubles réfrigérés et pour de nombreuses autres utilisations.');

			this.data.set('de',
				'Die mk dichtungs ag versorgt ihre Kunden mit fast 5000 Dichtungsprofilen für alle denkbaren Anwendungen in den ' +
				'Bereichen Bau, Kälte-/Klimatechnik, Industrie, Fahrzeugbau, Kraftwerk, Schienenfahrzeug und Transport. Langjährige ' +
				'Erfahrung und Know-how sowie grosses Sortiment an Neu-, Ersatz- und Nachrüstdichtungen für Metallzargen, Türen, Fenster,' +
				' Duschen, Kühlschränke, Kühlschubladen und für viele weitere Anwendungen.');
		}


	}

	private videoDismissed(): void {
		this.showVideoDialog = false;

		this.$store.commit('ui/setVideoWatched', true);
	}

	private dialogDismissed(eventType?: string): void {
		this.showWelcomeDialog = false;

		this.$store.commit('ui/setWelcomeMessageSeen', true);

		if (eventType != 'cancel') {
			this.register();
		}
	}

	private register(): void {
		if (this.rLoggedIn || this.$router.currentRoute.name === Routes.registration) return;
		this.$router.push({name: Routes.registration});
	}
}
