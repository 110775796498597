





















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";
import Icon from "@/plugins/icons";
import DialogHandler from "@/components/common/DialogHandler";
import GlobalConfigUpdateDialogBulkyGoodsSurcharge from "@/components/global-config/admin/GlobalConfigUpdateDialogBulkyGoodsSurcharge.vue";

@Component({
	components: {
		GlobalConfigUpdateDialogBulkyGoodsSurcharge,
		AsiCardDetailEntry, AsiCardDetail
	}
})
export default class GlobalConfigCardBulkyGoodsSurcharge extends Vue {
	@Prop({type: Object, required: true})
	public globalConfig!: IGlobalConfigAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	@Emit('change')
	public change(): void {
		return;
	}
}
