
















import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";

@Component
export default class AsiMenuItem extends Vue {

	@Prop({type: String, required: true})
	public label!: string;

	@Prop({type: String, required: true})
	public icon!: string;

	@Prop({type: String, default: null})
	public hintText!: string | null;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	@Emit('click')
	public click(event: Event): Event {
		return event;
	}

}
