











import {Vue, Component, Prop} from 'vue-property-decorator';
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import Icon from "@/plugins/icons";
import {IOrderAdmin} from "@/models/order/OrderAdminModels";
import OrderShipmentStateChip from "@/components/order/OrderShipmentStateChip.vue";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";

@Component({
	components: {AsiCardDetailEntry, OrderShipmentStateChip, AsiCardDetail}
})
export default class OrderCardContactInformation extends Vue {

	@Prop({required: true})
	public order!: IOrderAdmin;

	private icons = Icon;

}
