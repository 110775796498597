





















import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import AsiCardDetail from "../../common/AsiCardDetail.vue";
import AsiCardDetailEntry from "../../common/AsiCardDetailEntry.vue";
import AsiChip from "../../common/AsiChip.vue";
import Icon from "../../../plugins/icons";
import DialogHandler from "../../common/DialogHandler";
import StringHelper from "../../../helpers/StringHelper";
import {IItemAdmin} from "@/models/item/ItemAdminModels";
import ItemUpdateDialogMetaData from "@/components/item/admin/ItemUpdateDialogMetaData.vue";

@Component({
	components: {ItemUpdateDialogMetaData, AsiChip, AsiCardDetailEntry, AsiCardDetail}
})
export default class ItemCardMeta extends Vue {
	@Prop({type: Object, required: true})
	public item!: IItemAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	private get keywords(): string[] {
		if (this.item.metaKeywords === null || StringHelper.isEmpty(this.item.metaKeywords)) {
			return [];
		}

		return this.item.metaKeywords
			.split(/\s*[,;]\s*/)
			.filter(w => !StringHelper.isEmpty(w))
			.map(w => w.trim());
	}

	@Emit('change')
	public change(): void {
		return;
	}
}
