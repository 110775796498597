































































import {Component} from "vue-property-decorator";
import Icon from "@/plugins/icons";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import {IUserShop} from "@/models/user/UserShopModels";
import UserHelper from "@/models/user/UserHelper";
import {mixins} from "vue-class-component";
import RightChecks from "@/mixins/RightChecks.vue";
import {AttachmentMediaSize, Routes} from "@/helpers/constants";
import AsiLoginForm from "@/components/common/AsiLoginForm.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import RoutesHelper from "@/helpers/RoutesHelper";
import CustomerHelper from "@/models/customer/CustomerHelper";
import AsiMenuItem from "@/components/common/AsiMenuItem.vue";

@Component({
	components: {AsiMenuItem, AsiCard, AsiBtn, AsiLoginForm, AsiAvatar}
})
export default class UserMenu extends mixins(RightChecks) {

	private icons = Icon;
	private open: boolean = false;

	private get menuIcon(): string {
		return this.rLoggedIn ? Icon.avatar : Icon.user;
	}

	private get user(): IUserShop | null {
		return this.$store.getters['user/model'];
	}

	private get userFullName(): string | null {
		return this.user === null ? null : UserHelper.fullName(this.user);
	}

	private get avatarUrl(): string | null {
		return this.user === null ? null : UserHelper.avatarUrl(this.user, AttachmentMediaSize.l);
	}

	private get amountsInclVat(): boolean {
		return this.$store.state.ui.amountsInclVat;
	}

	private set amountsInclVat(value: boolean) {
		this.$store.commit('ui/setAmountsInclVat', value);
	}

	private showProfile(): void {
		if (!this.rLoggedIn || this.$router.currentRoute.name === Routes.user) return;
		this.$router.push(UserHelper.detailRoute(this.$store.getters['user/userId']));
	}

	private showCustomer(): void {
		if (!this.rLoggedIn || this.$router.currentRoute.name === Routes.customer) return;
		this.$router.push(CustomerHelper.detailRoute(this.$store.getters['user/modelCustomer'].id));
	}

	private showOrders(): void {
		if (!this.rLoggedIn || this.$router.currentRoute.name === Routes.orders) return;
		this.$router.push({name: Routes.orders});
	}

	private showCarts(): void {
		if (!this.rLoggedIn || this.$router.currentRoute.name === Routes.carts) return;
		this.$router.push({name: Routes.carts});
	}

	private showBackend(): void {
		if (!this.rBackendUser || this.$router.currentRoute.name === Routes.adminHome) return;
		this.$router.push(RoutesHelper.homeAdminRoute());
	}

	private showFrontend(): void {
		if (this.$router.currentRoute.name === Routes.home) return;
		this.$router.push(RoutesHelper.homeShopRoute());
	}

	private async logout(): Promise<void> {
		if (!this.rLoggedIn) return;
		await this.$store.dispatch('user/logout');
		this.$router.go(0);
	}

	private login(): void {
		if (this.rLoggedIn || this.$router.currentRoute.name === Routes.login) return;
		this.$router.push({name: Routes.login});
	}

	private register(): void {
		if (this.rLoggedIn || this.$router.currentRoute.name === Routes.registration) return;
		this.$router.push({name: Routes.registration});
	}

}
