
























import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import {IUserShop} from "@/models/user/UserShopModels";
import {IUserAdmin} from "@/models/user/UserAdminModels";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import AsiTextField from "@/components/common/AsiTextField";
import UserUpdatePersonalData from "@/models/user/UserUpdatePersonalData";
import AsiLocaleAutocompleteInput from "@/components/common/AsiLocaleAutocompleteInput.vue";

@Component({
	components: {AsiLocaleAutocompleteInput, AsiTextField, AsiDialogFormLayout, AsiDialogForm}
})
export default class UserUpdateDialogPersonalData extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public user!: IUserShop | IUserAdmin;

	private model: UserUpdatePersonalData | null = null;

	public created(): void {
		this.setUserInternal();
	}

	@Watch('user', {deep: true})
	private onUserChanged(): void {
		this.setUserInternal();
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.setUserInternal();
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private setUserInternal(): void {
		if (this.model === null) {
			this.model = new UserUpdatePersonalData(this.user);
			this.valid = true;
		} else {
			this.model.setDetailModel(this.user);
		}
	}

	private requestSave(): void {
		if (this.model === null) return;

		this.loading = true;
		this.$userServiceShop.updatePersonalData(this.user.id, this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

}
