var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"bottom":"","offset-y":"","nudge-top":-10,"close-on-content-click":false,"min-width":"25em","max-width":"30em"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AsiBtn',_vm._g(_vm._b({attrs:{"icon":_vm.icons.shoppingList}},'AsiBtn',attrs,false),on))]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('AsiCard',{attrs:{"highlighted":"","unwrapped":"","no-bottom-margin":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('AsiBtn',{attrs:{"small":"","block":"","text":true},on:{"click":_vm.create}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.add))]),_c('span',[_vm._v(_vm._s(_vm.$t('shoppingList.terms.create')))])],1)]},proxy:true}])},[(_vm.shoppingLists.length === 0)?_c('span',{staticClass:"d-block text-center pa-3 caption"},[_vm._v(" "+_vm._s(_vm.$t('shoppingList.terms.noLists'))+" ")]):_c('v-expansion-panels',_vm._l((_vm.shoppingLists),function(shoppingList,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AsiBtn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":_vm.icons.menu,"icon-color":"primary","small":""}},'AsiBtn',attrs,false),on))]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openDialog(shoppingList)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('ui.rename'))+" ")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.remove(shoppingList.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('ui.delete'))+" ")])],1)],1)],1),_c('span',[_vm._v(_vm._s(shoppingList.name))])],1)]}},{key:"actions",fn:function(){return [_c('AsiBtn',{attrs:{"icon-color":"primary","icon":_vm.icons.dropdown}})]},proxy:true}],null,true)},[_c('v-divider')],1),_c('v-expansion-panel-content',[(shoppingList.positions.length > 0)?_c('v-list',_vm._l((shoppingList.positions),function(position){return _c('ShoppingListPosition',{key:position.id,attrs:{"position":position}})}),1):_c('span',{staticClass:"d-block text-center pa-3 caption"},[_vm._v(" "+_vm._s(_vm.$t('shoppingListPosition.terms.noPositions'))+" ")])],1)],1)}),1)],1)],1),(_vm.nameDialog.isLoaded)?_c('ShoppingListUpdateDialogName',{attrs:{"open":_vm.nameDialog.isOpen,"shopping-list":_vm.shoppingListInternal},on:{"cancel":function($event){return _vm.nameDialog.close()},"save":function($event){return _vm.nameDialog.close()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }