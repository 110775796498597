































import Vue from 'vue';
import Icon from "@/plugins/icons";
import {Component, Prop} from "vue-property-decorator";
import AsiCard from "@/components/common/AsiCard.vue";
import {IAsiConfirmDialog} from "@/components/common/IAsiConfirmDialog";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiTextFieldSimple, AsiBtn, AsiCard},
})
export default class AsiConfirmDialog extends Vue implements IAsiConfirmDialog {

	@Prop({type: Boolean, default: false})
	public secure!: boolean;

	@Prop({type: String, default: null})
	public secureWord!: string;

	@Prop({type: String, default: 'success'})
	public yesButtonColor!: string;

	@Prop({type: String, default: 'error'})
	public noButtonColor!: string;

	@Prop({default: null})
	public yesButtonLabel!: string | TranslateResult | null;

	@Prop({default: null})
	public noButtonLabel!: string | TranslateResult | null;

	@Prop({type: Boolean, default: false})
	public persistent!: boolean;

	private icons = Icon;

	private dialog = false;
	private resolve: ((result: boolean) => void) | null = null;
	private reject: (() => void) | null = null;

	private title: string | TranslateResult | null = null;
	private message: string | TranslateResult | null = null;
	private icon: string | null = null;

	private secureInput: string | null = null;

	private get yesLabelFinal(): string | TranslateResult {
		return this.yesButtonLabel ?? this.$t('ui.yes');
	}

	private get noLabelFinal(): string | TranslateResult {
		return this.noButtonLabel ?? this.$t('ui.no');
	}

	private get secureWordFinal(): string {
		return this.secureWord ?? this.$t('ui.yes');
	}

	private get secureApproved(): boolean {
		return !this.secure || this.secureInput?.toLowerCase() === this.secureWordFinal.toLowerCase();
	}

	public openDialog(title: string | TranslateResult | null = null, message: string | TranslateResult | null = null, icon: string = Icon.confirm): Promise<boolean> {
		this.dialog = true;
		this.title = title ?? this.$t('confirm.title');
		this.message = message ?? this.$t('confirm.message');
		this.icon = icon;
		this.secureInput = null;

		return new Promise((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;
		});
	}

	private agree(): void {
		if (!this.secureApproved) return;

		if (null !== this.resolve) {
			this.resolve(true);
		}
		this.dialog = false;
	}

	private cancel(): void {
		if (null !== this.resolve) {
			this.resolve(false);
		}
		this.dialog = false;
	}

}
