


























































import {Component, Emit, Prop} from "vue-property-decorator";
import {IUserShop} from "@/models/user/UserShopModels";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiDialogAvatarUpload from "@/components/common/AsiDialogAvatarUpload.vue";
import DialogHandler from "@/components/common/DialogHandler";
import UserHelper from "@/models/user/UserHelper";
import {AttachmentMediaSize, UserState} from "@/helpers/constants";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import {mixins} from "vue-class-component";
import RightChecks from "@/mixins/RightChecks.vue";
import UserUpdateDialogUsername from "@/components/user/UserUpdateDialogUsername.vue";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
import UserUpdateDialogPassword from "@/components/user/UserUpdateDialogPassword.vue";
import AsiConfirmDialogDefinition from "@/helpers/AsiConfirmDialogDefinition";
import Snackbar from "@/helpers/Snackbar";
import AsiChip from "@/components/common/AsiChip.vue";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

@Component({
	components: {
		AsiChip,
		UserUpdateDialogPassword,
		AsiConfirmDialog, UserUpdateDialogUsername, AsiAvatar, AsiDialogAvatarUpload, AsiCard, AsiBtn
	}
})
export default class UserHeader extends mixins(RightChecks, ResponsiveChecks) {

	@Prop({required: true})
	public user!: IUserShop | null;

	@Prop({type: Boolean, default: false})
	public isCustomerManager!: boolean;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private showAvatarActions: boolean = false;
	private avatarDialog: DialogHandler = new DialogHandler();
	private usernameDialog: DialogHandler = new DialogHandler();
	private passwordDialog: DialogHandler = new DialogHandler();

	private get fullName(): string {
		return UserHelper.fullName(this.user) ?? '?';
	}

	private get avatarUrl(): string | null {
		return this.user === null ? null : UserHelper.avatarUrl(this.user, AttachmentMediaSize.l);
	}

	private get isOwnProfile(): boolean {
		return this.user?.id === this.$store.getters['user/userId'];
	}

	private get canManageUser(): boolean {
		return this.rMasterData || (this.rCustomerManager && this.user?.customer.id === this.$store.getters['user/modelCustomer']?.id);
	}

	private get isUserUnconfirmed(): boolean {
		return this.user !== null && this.user.state === UserState.created;
	}

	@Emit('change')
	public change(): void {
		return;
	}

	private requestResetPassword(): void {
		if (this.user === null || !this.canManageUser) return;

		const confirm = this.$refs.confirm as unknown as AsiConfirmDialogDefinition;
		confirm.openDialog().then((res: boolean) => {
			if (!res || this.user === null) return;

			this.loading = true;
			this.$userServiceShop.resetPassword(this.user.username)
				.then(this.change)
				.catch(() => Snackbar.updateError())
				.finally(() => this.loading = false);
		});
	}

	private performResendConfirmation(): void {
		if (this.user === null || !this.canManageUser) return;

		const confirm = this.$refs.confirm as unknown as AsiConfirmDialogDefinition;
		confirm.openDialog().then((res: boolean) => {
			if (!res || this.user === null) return;

			this.loading = true;
			this.$authService.resendRegistrationConfirmation(this.user.username)
				.then(() => Snackbar.show(this.$t('user.terms.resendConfirmationSuccess'), 'success'))
				.catch(() => Snackbar.updateError())
				.finally(() => this.loading = false);
		});
	}

}
