













import {Component, Vue} from 'vue-property-decorator';
import LegalTermsAndConditions from "@/components/legal/LegalTermsAndConditions.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import ShopView from "@/components/layout/ShopView.vue";
import PrivacyContent from "@/views/shop/legal/PrivacyContent.vue";

@Component({
	components: {PrivacyContent, ShopView, AsiCard, LegalTermsAndConditions}
})
export default class Privacy extends Vue {

}
