






















import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import AsiSelectSimple from "@/components/common/AsiSelectSimple";

@Component({
	components: {AsiSelectSimple}
})
export default class ItemListPagination extends Vue {
	@Prop({type: Number, required: true})
	public totalItems!: number;

	@Prop({type: Object, required: true})
	public tableOptions!: AsiListTableOptions;

	@Prop({type: Array, required: true})
	public perPageOptions!: number[];

	private get pageTextFirstItem(): string {
		let firstItem = 1;
		if (this.tableOptions.page === 1) {
			firstItem = 1;
		} else {
			firstItem = ((this.tableOptions.page-1) * this.tableOptions.itemsPerPage) + 1;
		}
		return firstItem.toString();
	}

	private get pageTextLastItem(): string {
		return (this.tableOptions.itemsPerPage * this.tableOptions.page > this.totalItems
			? this.totalItems
			: this.tableOptions.itemsPerPage * this.tableOptions.page)
			.toString();
	}
}
