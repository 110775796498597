




















import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import LocaleHelper from "@/helpers/LocaleHelper";
import CategoryHelper from "@/models/category/CategoryHelper";
import {Dictionary} from "vue-router/types/router";

@Component
export default class AsiLocaleSwitcher extends Vue {

	@Prop({type: Boolean, default: false})
	public dark!: boolean;

	private get activeLocale(): string {
		return this.$i18n.locale;
	}

	private get availableLocales(): { value: string; text: string, abbreviation: string }[] {
		return this.$i18n.availableLocales.map(locale => {
			return {
				value: locale,
				text: this.$t(`locale.${locale}`).toString(),
				abbreviation: locale.substring(0, 2).toLocaleUpperCase(),
			};
		});
	}

	private switchLocale(locale: { value: string }) {
		const lang = LocaleHelper.extractLanguage(locale.value);
		if (lang !== this.$route.params.language) {
			//update the language parameter in the current route's params
			var updatedParams : Dictionary<string> = {...this.$route.params, language: lang};

			if (this.$route.params.canonical !== undefined) {
				const category = this.$store.getters['category/categoryByCanonical'](this.$route.params.canonical);
				const newCanonical = CategoryHelper.currentCanonical(category.canonical, lang);
				if (newCanonical != this.$route.params.canonical) {
					updatedParams = {...this.$route.params, language: lang, canonical: newCanonical};
				}
			}

			//navigate to the updated URL
			this.$router.push({params: updatedParams});
		}

	}

}
