










import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import Icon from "@/plugins/icons";

@Component
export default class AsiStepperStep extends Vue {

	@Prop({type: [String, Number], required: true})
	public step!: number | string;

	@Prop({type: Boolean, default: false})
	public editable!: boolean;

	@Prop({type: Boolean, default: false})
	public complete!: boolean;

	private icons = Icon;

}
