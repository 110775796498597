



















import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
import UserCardPersonalData from "@/components/user/UserCardPersonalData.vue";
import UserCardName from "@/components/user/UserCardName.vue";
import RightChecks from "@/mixins/RightChecks.vue";
import {mixins} from "vue-class-component";
import UserCardState from "@/components/user/admin/UserCardState.vue";
import UserCardRights from "@/components/user/UserCardRights.vue";
import {Permissions, Roles} from "@/helpers/constants";
import Snackbar from "@/helpers/Snackbar";

@Component({
	components: {
		UserCardRights,
		UserCardState, UserCardName, UserCardPersonalData, AsiSkeletonCards, AsiContentContainer
	}
})
export default class UserTabRights extends mixins(RightChecks) {

	@Prop({type: String, required: true})
	public id!: string;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	private readonly relevantRoles = [Roles.roleAdmin, Roles.roleMasterData, Roles.roleMetaData, Roles.roleFinance];
	private loadingRoles: boolean = false;
	private userRoles: Roles[] = [];
	private readonly relevantPermissions = [Permissions.rightBackendUser, Permissions.rightCustomerManager];
	private loadingPermissions: boolean = false;
	private userPermissions: Permissions[] = [];

	private get readonly(): boolean {
		return !this.rAdmin;
	}

	@Watch('id', {immediate: true})
	private onIdChanged(): void {
		this.loadRoles();
		this.loadPermissions();
	}

	@Watch('loadingRoles')
	@Watch('loadingPermissions')
	private onLoadingRolesChanged(): void {
		const isLoading = this.loadingRoles || this.loadingPermissions;
		if (this.loading !== isLoading) this.$emit('update:loading', isLoading);
	}

	@Emit('change')
	public change(): void {
		return;
	}

	private loadRoles(): void {
		this.loadingRoles = true;
		this.$userServiceShop.roles(this.id)
			.then(data => this.userRoles = data)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loadingRoles = false);
	}

	private loadPermissions(): void {
		this.loadingPermissions = true;
		this.$userServiceShop.permissions(this.id)
			.then(data => this.userPermissions = data)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loadingPermissions = false);
	}

}
