export enum Routes {
	home = 'Home',

	users = 'Users',
	user = 'User',
	login = 'Login',
	registration = 'Registration',
	registrationConfirm = 'RegistrationConfirm',
	contact = 'Contact',
	customers = 'Customers',
	customer = 'Customer',
	categories = 'Categories',
	category = 'Category',
	items = 'Items',
	item = 'Item',
	cart = 'Cart',
	carts = 'Carts',
	order = 'Order',
	orders = 'Orders',
	sampleOrder = 'SampleOrder',
	checkout = 'Checkout',
	faq = 'Faq',
	privacy = 'Privacy',
	termsAndConditions = 'TermsAndConditions',
	legalNotice = 'LegalNotice',

	azureAdConfig = 'AzureAdConfig',
	azureAdConfigs = 'AzureAdConfigs',

	adminHome = 'AdminHome',
	adminUsers = 'AdminUsers',
	adminUser = 'AdminUser',
	adminCustomers = 'AdminCustomers',
	adminCustomer = 'AdminCustomer',
	adminCategories = 'AdminCategories',
	adminCategory = 'AdminCategory',
	adminItems = 'AdminItems',
	adminItem = 'AdminItem',
	adminCarts = 'AdminCarts',
	adminCart = 'AdminCart',
	adminShoppingLists = 'AdminShoppingLists',
	adminShoppingList = 'AdminShoppingList',
	adminOrders = 'AdminOrders',
	adminOrder = 'AdminOrder',
	adminSettings = 'AdminSettings',

	notFound = 'NotFound',
	catchAll = 'CatchAll',
	debug = 'Debug',
}

export enum AuthType {
	password = 'Password',
	azureAd = 'AzureAD',
	emailNotConfirmed = 'EmailNotConfirmed',
}

export enum Roles {
	roleAdmin = 'Role.Admin',
	roleMasterData = 'Role.MasterData',
	roleMetaData = 'Role.MetaData',
	roleFinance = 'Role.Finance',

	roleCustomerBusiness = 'Role.CustomerBusiness',
	roleCustomerPrivate = 'Role.CustomerPrivate',
}

export enum Permissions {
	rightAdmin = 'Permission.Admin',
	rightMasterData = 'Permission.MasterData',
	rightMetaData = 'Permission.MetaData',
	rightFinance = 'Permission.Finance',

	rightCustomerBusiness = 'Permission.CustomerBusiness',
	rightCustomerPrivate = 'Permission.CustomerPrivate',

	rightBackendUser = 'Permission.BackendUser',
	rightCustomerManager = 'Permission.CustomerManager',
}

export const PerPageOptions = [10, 15, 20, 25, 30, 50, 100];

export enum Gender {
	male = 0,
	female = 10,
	divers = 20
}

export enum Salutation {
	mr = 0,
	ms = 10,
	other = 20
}

export enum AddressTitle {
	dr = 0,
	prof = 10,
	ba = 20,
	bsc = 30,
	ma = 40,
	msc = 50,
}

export enum ItemType {
	shopItem = 10,
	linkItem = 20,
}

export enum AddressType {
	addressBusiness = 0,
	addressPrivate = 10,
}

export enum UserState {
	created = 0,
	active = 10,
	archived = 90,
}

export enum CustomerState {
	active = 0,
	archived = 90,
}

export enum CategoryState {
	active = 0,
	archived = 90,
}

export enum CategoryView {
	cardView = 0,
	tableView = 10,
}

export enum ItemState {
	active = 0,
	archived = 90,
}

export enum OrderPaymentState {
	orderPaymentUnpaid = 10,
	orderPaymentPaid = 20,
}

export enum OrderShipmentState {
	orderShipmentNotShipped = 10,
	orderShipmentShipped = 20,
}

export enum OrderExportState {
	orderExportWaiting = 0,
	orderExportReady = 10,
	orderExportExporting = 20,
	orderExportExported = 30,
	orderExportFailed = 40,
}

export enum DeliveryMethod {
	deliveryMethodPickup = 10,
	deliveryMethodDelivery = 20,
}

export enum OrderExportFailReason {
	exportFailUnknown = 10,
	exportFailMissingCustomerExternalId = 20,
	exportFailMissingPaymentTypeExternalId = 30,
	exportFailMissingShipmentTypeExternalId = 40,
}

export enum ParamPaymentState {
	initialize = 'initialize',
	success = 'success',
	failure = 'failure',
}

export enum ItemAttributeVisibility {
	never = 0,
	detailViewOnly = 1,
	largeView = 2,
	mediumView = 4,
	smallView = 8,
}

export enum ItemAttributeFilterVisibility {
	never = 0,
	alwaysCollapsed = 1,
	expandedLarge = 2,
	expandedMedium = 4,
	expandedSmall = 8,
}

export enum CustomerType {
	business = 0,
	private = 10,
}

export enum AttachmentMediaSize {
	orig = 0,
	s = 10,
	m = 20,
	l = 30,
}

export enum UnitOfMeasure {
	unitCan = 0, // "Dose"
	unitKilogram = 10, // "Kilogramm"
	unitSquareMeter = 20, // "m2"
	unitMeter = 30, // "m"
	unitMillimeter = 40, // "Millimeter"
	unitPiece = 50, // "Stück"
	unitCanister = 60, // "Kannister"
	unitBox = 70, // "Box"
	unitAssortment = 80, // "Mischung"
	unitPackage = 90, // "Paket"
	unitPallet = 100, // "Palett"
	unitRoll = 120, // "Rolle"
	unitBar = 130, // "Stange"
	unitRoundel = 140, // "Rondelle"
	unitCoil = 150, // "Spule"
	unitTube = 160, // "Rohr"
	unitSet = 170, // "Set"
	unitLiter = 180, // "Liter"
	unitBag = 190, // "Sack"
	unitHour = 200, // "Stunden"
}

export enum StockStatus {
	stockNone = 0,
	stockLow = 10,
	stockMedium = 20,
	stockHigh = 30,
	stockNoInformation = 40
}

export enum ItemAttribute {
	avatarAttachmentId = 'avatarAttachmentId',
	price = 'price',

	stockStatus = 'stockStatus',
	isTopSeller = 'isTopSeller',
	canOrderSample = 'canOrderSample',


	grossWeight = 'grossWeight',
	length = 'length',
	width = 'width',
	height = 'height',

	material = 'material',
	color = 'color',
	type = 'type',
	rebate = 'rebate',
	rebateSpace = 'rebateSpace',
	grooveWidth = 'grooveWidth',
	grooveDepth = 'grooveDepth',
	hardnessType = 'hardnessType',
	hardness = 'hardness',
	properties = 'properties',
	density = 'density',
	connectionType = 'connectionType',
	surfaceTreatment = 'surfaceTreatment',
	tolerance = 'tolerance',
	fireProtectionStandard = 'fireProtectionStandard',
	foodStandard = 'foodStandard',
	otherStandard = 'otherStandard',
	fixture = 'fixture',
	wrenchSize = 'wrenchSize',
	thread = 'thread',
	threadLength = 'threadLength',
	diameterMm = 'diameterMm',
	applicationRange = 'applicationRange',
	resistance = 'resistance',
	system = 'system',
	glassThickness = 'glassThickness',
	innerDiameter = 'innerDiameter',
	outerDiameter = 'outerDiameter',
	ringDiameter = 'ringDiameter',
	crossSection = 'crossSection',
	assemblyGroup = 'assemblyGroup',
}

export enum MailType {
	// order
	mailTypeOrderConfirmed = 10,
	mailTypeOrderShipped = 20,
	mailTypeOrderReadyForPickup = 30,

	// user
	mailTypePasswordReset = 100,
	mailTypeConfirmation = 110,

	// sample order
	mailTypeNewSampleOrder = 300,

	// contact form
	mailTypeContactForm = 400,
	mailTypeContactFormConfirmation = 410,
}

export enum FrameAssemblyType {
	frame = 10,
	cFrame = 20,
	uFrame = 30,
	corner = 40,
}

export enum FrameAssemblyInstallation {
	frame = 10,
	wing = 20,
}

export enum FrameAssemblyMeasuringPoint {
	rebate = 10,
	light = 20,
}
