














































import Vue from 'vue';
import {Component} from "vue-property-decorator";
import NavGroup from "@/helpers/NavGroup";
import NavEntry from "@/helpers/NavEntry";
import Icon from "@/plugins/icons";
import {Routes} from "@/helpers/constants";

@Component
export default class PrimaryNavigation extends Vue {

	private navGroups: NavGroup[] = [];

	public created(): void {
		this.navGroups = [
			this.createGlobalGroup(),
			this.createSalesGroup(),
			this.createMasterDataGroup(),
			this.createCustomerDataGroup(),
			this.createSettingsGroup(),
		].filter(g => g.entries.length > 0);
	}

	private createGlobalGroup(): NavGroup {
		const group = new NavGroup();
		group.add(new NavEntry(this.$t('pageTitles.admin.home'), Icon.home, {name: Routes.adminHome}, true));
		return group;
	}

	private createSalesGroup(): NavGroup {
		const group = new NavGroup();
		group.add(new NavEntry(this.$t('order.plural'), Icon.orders, {name: Routes.adminOrders}, false));
		group.add(new NavEntry(this.$t('cart.plural'), Icon.carts, {name: Routes.adminCarts}, false));
		group.add(new NavEntry(this.$t('shoppingList.plural'), Icon.shoppingList, {name: Routes.adminShoppingLists}));
		return group;
	}

	private createMasterDataGroup(): NavGroup {
		const group = new NavGroup();
		group.add(new NavEntry(this.$t('category.plural'), Icon.categories, {name: Routes.adminCategories}, false));
		group.add(new NavEntry(this.$t('item.plural'), Icon.items, {name: Routes.adminItems}, false));
		return group;
	}

	private createCustomerDataGroup(): NavGroup {
		const group = new NavGroup();
		group.add(new NavEntry(this.$t('customer.plural'), Icon.customers, {name: Routes.adminCustomers}, false));
		group.add(new NavEntry(this.$t('user.plural'), Icon.users, {name: Routes.adminUsers}, false));
		return group;
	}

	private createSettingsGroup(): NavGroup {
		const group = new NavGroup();
		group.add(new NavEntry(this.$t('pageTitles.admin.settings'), Icon.settings, {name: Routes.adminSettings}, true));
		return group;
	}

}
