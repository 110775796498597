













import Vue from 'vue';
import {Component, Emit, Prop} from 'vue-property-decorator';
import AsiDialog from "@/components/common/AsiDialog.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";

@Component({
	components: {AsiBtn, AsiDialog}
})
export default class OrderPaymentAssertDialog extends Vue {

	@Prop({type: String, required: true})
	public id!: string;

	@Prop({type: Boolean, default: false})
	public open!: boolean;

	private icons = Icon;

	@Emit('cancel')
	public cancel(): void {
		return;
	}

}
