











import Vue from 'vue';
import {Component} from "vue-property-decorator";
import ShopView from "@/components/layout/ShopView.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import OrderList from "@/components/order/shop/OrderList.vue";

@Component({
	components: {OrderList, AsiCard, ShopView}
})
export default class Orders extends Vue {

}
