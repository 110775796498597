
















import Vue from 'vue';
import {Component, Prop, Watch} from "vue-property-decorator";
import Icon from "@/plugins/icons";

@Component
export default class VideoCard extends Vue {

	@Prop({type: Boolean, default: false})
	public autoplay!: boolean;

	@Prop({type: String, required: true})
	public source!: string;

	@Prop({type: String, default: ''})
	public thumbnailSrc!: string;

	@Prop({type: Boolean, default: false})
	public stopVideo!: boolean;

	private icons = Icon;

	private videoIcon = this.icons.videoPlay;
	private displayVideoControls = true;

	public mounted(): void {
		if (this.autoplay) {
			const video = this.$refs.video as HTMLVideoElement;
			this.playVideo(video);
		}
	}

	private playPause(): void {
		const video = this.$refs.video as HTMLVideoElement;
		if (video.paused) {
			this.playVideo(video);
		} else {
			this.pauseVideo(video);
		}
	}

	private playVideo(video: HTMLVideoElement): void {
		var promise = video.play();
		if (promise !== undefined) {
			promise.then(() => {
				this.videoIcon = this.icons.videoPause;
				this.displayVideoControls = false;
			});
		}
	}

	private pauseVideo(video: HTMLVideoElement): void {
		video.pause();
		this.videoIcon = this.icons.videoPlay;
		this.displayVideoControls = true;
	}

	@Watch('source')
	private onSourceChanged(): void {
	    const video = this.$refs.video as HTMLVideoElement;
		video.load();
	}

	@Watch('stopVideo')
	private onStopVideoChanged(): void {
		const video = this.$refs.video as HTMLVideoElement;
		if (!video.paused) {
			this.pauseVideo(video);
		}
	}
}
