

















import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import {ValidationRule} from "@/declarations/ValidationRule";
import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
import AsiTextField from "@/components/common/AsiTextField";
import CustomerRules from "@/models/customer/CustomerRules";

@Component({
	components: {AsiTextField, AsiDialogFormLayout}
})
export default class CustomerUpdateDialogName extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public customer!: ICustomerAdmin;

	private name: string | null = null;

	// noinspection JSMethodCanBeStatic
	private get rules(): ValidationRule[] {
		return CustomerRules.nameRules;
	}

	@Watch('customer.name', {immediate: true})
	private onCustomerNameChanged(value: string): void {
		this.name = value;
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.name = this.customer.name;
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private requestSave(): void {
		if (this.name === null) return;

		this.loading = true;
		this.$customerServiceAdmin.updateName(this.customer.id, this.name)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

}
