


















































import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
import Icon from "@/plugins/icons";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import DialogHandler from "@/components/common/DialogHandler";
import AsiTextField from "@/components/common/AsiTextField";
import OrderHelper from "@/helpers/OrderHelper";
import AsiCheckbox from "@/components/common/AsiCheckbox";
import OrderPaymentRedirectionDialog from "@/components/order/shop/OrderPaymentRedirectionDialog.vue";

@Component({
	components: {
		OrderPaymentRedirectionDialog,
		AsiCheckbox,
		AsiTextField, AsiBtn, AsiCard, AsiContentContainer, AsiPageTitle
	}
})
export default class Debug extends Vue {

	private icons = Icon;
	private paymentDialog: DialogHandler = new DialogHandler();
	private paymentUrl: string | null = 'https://asinfotrack.ch';
	private paymentTimeout: number | string = 0;
	private paymentOrderId: string | null = null;
	private paymentNewTab: boolean = false;

	private orderWithUrl(): void {
		if (this.paymentOrderId === null || this.paymentUrl === null) return;
		this.$router.push(OrderHelper.detailRouteWithPaymentUrl(this.paymentOrderId, this.paymentUrl));
	}

}
