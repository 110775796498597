






































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogFormLayout from "../../common/AsiDialogFormLayout.vue";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import Snackbar from '@/helpers/Snackbar';
import AsiTextField from "@/components/common/AsiTextField";
import PromotionUpdate from "@/models/promotion/PromotionUpdate";
import {IPromotionAdminListEntry} from "@/models/promotion/PromotionAdminModels";
import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
import ItemAutocompleteInput from "@/components/item/admin/ItemAutocompleteInput.vue";

@Component({
	components: {
		ItemAutocompleteInput,
		AsiDatePickerCombined, AsiTextField, AsiDialogFormLayout
	}
})
export default class PromotionUpdateDialog extends AsiDialogForm {

	@Prop({type: String, required: true})
	public id!: string;

	private model: PromotionUpdate | null = null;
	private detailModel: IPromotionAdminListEntry | null = null;

	private get currencyCode(): string {
		//TODO: maybe later replace with configurable currency
		return 'CHF';
	}

	private setPromotionInternal(): void {
		if (this.detailModel === null) {
			this.model = null;
			return;
		}

		if (this.model === null) {
			this.model = new PromotionUpdate(this.detailModel);
			this.valid = true;
		} else {
			this.model.setDetailModel(this.detailModel);
		}
	}

	private requestSave(): void {
		if (this.model === null) return;

		this.loading = true;
		this.$promotionServiceAdmin.update(this.id, this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

	private loadPromotion(): void {
		this.loading = true;
		this.$promotionServiceAdmin.promotion(this.id)
			.then(model => {
				this.detailModel = model;
				this.setPromotionInternal();
			})
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loading = false);
	}


	@Watch('id', {immediate: true})
	private onIdChanged(): void {
		this.loadPromotion();
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.setPromotionInternal();
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

}
