



























import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import ItemCard from "@/components/item/shop/ItemCard.vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import {UnitOfMeasure} from "@/helpers/constants";
import {IItemPriceShopSimple} from "@/models/item-price/ItemPriceModelsShop";
import ItemListPagination from "@/components/item/shop/ItemListPagination.vue";

@Component({
	components: {ItemListPagination, ItemCard}
})
export default class ItemListViewCards extends Vue {

	@Prop({type: Array, required: true})
	public items!: IItemShopListEntry[];

	@Prop({type: Number, required: true})
	public totalItems!: number;

	@Prop({type: Object, required: true})
	public tableOptions!: AsiListTableOptions;

	@Prop({type: Array, required: true})
	public perPageOptions!: number[];

	@Prop({default: null})
	public showAttributeCallback!: ((column: string) => boolean) | null;

	@Emit('itemSelected')
	public itemSelected(model: IItemShopListEntry): IItemShopListEntry {
		return model;
	}

	@Emit('showAddToCart')
	public showAddToCart(item: { id: string, unit: UnitOfMeasure, prices: IItemPriceShopSimple[] }): { id: string, unit: UnitOfMeasure, prices: IItemPriceShopSimple[] } {
		return item;
	}

}
