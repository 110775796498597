import { render, staticRenderFns } from "./AsiAvatar.vue?vue&type=template&id=d9cdb996&scoped=true"
import script from "./AsiAvatar.vue?vue&type=script&lang=ts"
export * from "./AsiAvatar.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9cdb996",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VIcon,VImg})
