

































import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import AsiCard from "@/components/common/AsiCard.vue";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
import CategoryHelper from "@/models/category/CategoryHelper";
import {AttachmentMediaSize} from "@/helpers/constants";
import {RawLocation} from "vue-router";

@Component({
	components: {AsiCard}
})
export default class CategoryFeaturedCard extends Vue {

	@Prop({type: String, required: true})
	public id!: string;

	private showMore: boolean = false;

	private get category(): ICategoryShopListEntry | null {
		return this.$store.getters['category/categoryById'](this.id);
	}

	private get imageStyles(): object {
		return {
			'box-sizing': 'border-box',
			'border-bottom-color': this.categoryColor,
			'background-image': 'linear-gradient(45deg, #00000044, transparent)',
			'background-color': this.categoryColor,
		};
	}

	private get categoryColor(): string {
		return this.category === null ? 'grey' : CategoryHelper.colorHierarchical(this.category) ?? 'grey';
	}

	private get avatarStyle(): object {
		return {
			'width': this.showMore ? '15%' : '45%',
		};
	}

	private get nameTranslated(): string | null {
		return this.category === null
			? null
			: TranslatedValueHelper.get(this.category.name, this.$i18n.locale);
	}

	private get descriptionTranslated(): string | null {
		return this.category === null || this.category.description === null || this.category.description === undefined
			? null
			: TranslatedValueHelper.get(this.category.description, this.$i18n.locale);
	}

	private get avatarUrl(): string | null {
		return this.category === null ? null : CategoryHelper.avatarUrl(this.category, AttachmentMediaSize.m);
	}

	private get detailRoute(): RawLocation | null {
		return this.category === null ? null : CategoryHelper.detailRoute(CategoryHelper.currentCanonical(this.category.canonical));
	}

	private showCategory(): void {
		if (this.category === null || this.$router.currentRoute.params.canonical === CategoryHelper.currentCanonical(this.category.canonical) || this.detailRoute === null) {
			return;
		}
		this.$router.push(this.detailRoute);
	}
}
