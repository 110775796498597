













































import {Component, Emit, Prop} from "vue-property-decorator";
import {ICustomerShop, ICustomerShopSimple} from "@/models/customer/CustomerShopModels";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import Icon from "@/plugins/icons";
import AsiDialogAvatarUpload from "@/components/common/AsiDialogAvatarUpload.vue";
import DialogHandler from "@/components/common/DialogHandler";
import {AttachmentMediaSize} from "@/helpers/constants";
import {IUserShop} from "@/models/user/UserShopModels";
import CustomerHelper from "@/models/customer/CustomerHelper";
import CustomerTypeChip from "@/components/customer/CustomerTypeChip.vue";
import CustomerUpdateDialogPhone from "@/components/customer/admin/CustomerUpdateDialogPhone.vue";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

@Component({
	components: {CustomerUpdateDialogPhone, CustomerTypeChip, AsiDialogAvatarUpload, AsiAvatar, AsiBtn, AsiCard}
})
export default class CustomerHeader extends mixins(ResponsiveChecks) {

	@Prop({required: true})
	public customer!: ICustomerShop | null;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private showAvatarActions: boolean = false;
	private showPhoneActions: boolean = false;
	private avatarDialog: DialogHandler = new DialogHandler();
	private phoneDialog: DialogHandler = new DialogHandler();

	private get customerFromStore(): ICustomerShopSimple | null {
		const userModel = this.$store.getters['user/model'] as IUserShop | null;
		return userModel?.customer ?? null;
	}

	private get avatarUrl(): string | null {
		return this.customerFromStore === null ? null : CustomerHelper.avatarUrl(this.customerFromStore, AttachmentMediaSize.l);
	}


	@Emit('change')
	public change(): void {
		return;
	}

}
