


























import {Component, Prop, Watch} from "vue-property-decorator";
import {IUserShop} from "@/models/user/UserShopModels";
import ShopView from "@/components/layout/ShopView.vue";
import Icon from "@/plugins/icons";
import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
import UserCardPersonalData from "@/components/user/UserCardPersonalData.vue";
import UserCardName from "@/components/user/UserCardName.vue";
import {ICustomerShopSimple} from "@/models/customer/CustomerShopModels";
import RightChecks from "@/mixins/RightChecks.vue";
import {mixins} from "vue-class-component";
import UserHeader from "@/components/user/shop/UserHeader.vue";
import Snackbar from "@/helpers/Snackbar";
import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
import UserCardRights from "@/components/user/UserCardRights.vue";
import {Permissions, Roles} from "@/helpers/constants";
import UserCardContact from "@/components/user/UserCardContact.vue";

@Component({
	components: {
		UserCardContact,
		UserCardRights,
		AsiSkeletonCards,
		UserHeader,
		UserCardName,
		UserCardPersonalData,
		AsiContentContainer, ShopView
	}
})
export default class User extends mixins(RightChecks) {

	@Prop({type: String, required: true})
	public id!: string;

	private icons = Icon;
	private userInternal: IUserShop | null = null;
	private userRoles: Roles[] = [];
	private userPermissions: Permissions[] = [];
	private loadingUser: boolean = false;
	private loadingPermissions: boolean = false;

	private get loading(): boolean {
		return this.loadingUser || this.loadingPermissions;
	}

	private get user(): IUserShop | null {
		return this.isOwnProfile ? this.$store.getters['user/model'] : this.userInternal;
	}

	/**
	 * Checks if the user currently logged in is customer manager of the user looked at
	 * @private
	 */
	private get isCustomerManagerOfUser(): boolean {
		const customer = this.$store.getters['user/modelCustomer'] as ICustomerShopSimple | null;
		return this.rCustomerManager && this.userInternal?.customer.id === customer?.id;
	}

	/**
	 * Checks if the user looked at has customer manager rights
	 * @private
	 */
	private get isCustomerManager(): boolean {
		return this.userPermissions.includes(Permissions.rightCustomerManager);
	}

	private get isOwnProfile(): boolean {
		return (this.userInternal?.id ?? this.id) === this.$store.getters['user/userId'];
	}

	private get readonly(): boolean {
		return !this.rMasterData
			&& !this.isCustomerManagerOfUser
			&& !this.isOwnProfile;
	}

	private get relevantPermissions(): Permissions[] {
		return [Permissions.rightCustomerManager];
	}

	@Watch('id', {immediate: true})
	private onIdChanged(): void {
		this.loadUser();

		if (this.rCustomerManager) {
			this.loadPermissions();
		}
	}

	private userChanged(): void {
		if (this.isOwnProfile) {
			this.$store.dispatch('user/loadLoggedInUserData');
		} else {
			this.loadUser();
		}
	}

	private rightsChanged(): void {
		if (this.rCustomerManager) {
			this.loadPermissions();
		}
	}

	private loadUser(): void {
		if (this.id === null) return;
		this.loadingUser = true;
		this.$userServiceShop.user(this.id)
			.then(model => this.userInternal = model)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loadingUser = false);
	}

	private loadPermissions(): void {
		this.loadingPermissions = true;
		this.$userServiceShop.permissions(this.id)
			.then(data => this.userPermissions = data)
			.catch(() => Snackbar.loadingError())
			.finally(() => this.loadingPermissions = false);
	}

}
