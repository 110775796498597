var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AsiListTableLayout',{attrs:{"filter-text":_vm.filterModel.fulltext,"has-filter":_vm.hasFilter,"hide-toolbar":_vm.hideToolbar},on:{"clearFilterText":function($event){return _vm.filterTextChanged(null)},"clearSearch":_vm.clearSearch,"update:filterText":_vm.filterTextChanged},scopedSlots:_vm._u([{key:"advanced-filters",fn:function(){return [_c('AsiSelectSimple',{attrs:{"items":_vm.shipmentStateOptions,"label":_vm.$t('order.shipmentState'),"placeholder":_vm.$t('order.shipmentState'),"multiple":"","clearable":""},model:{value:(_vm.filterModel.shipmentStates),callback:function ($$v) {_vm.$set(_vm.filterModel, "shipmentStates", $$v)},expression:"filterModel.shipmentStates"}}),_c('AsiSelectSimple',{attrs:{"items":_vm.paymentStateOptions,"label":_vm.$t('order.paymentState'),"placeholder":_vm.$t('order.paymentState'),"multiple":"","clearable":""},model:{value:(_vm.filterModel.paymentStates),callback:function ($$v) {_vm.$set(_vm.filterModel, "paymentStates", $$v)},expression:"filterModel.paymentStates"}}),_c('AsiDatePickerCombined',{attrs:{"label":_vm.$t('order.createdAt'),"clearable":"","range":"","simple":""},model:{value:(_vm.createdAtDateRange),callback:function ($$v) {_vm.createdAtDateRange=$$v},expression:"createdAtDateRange"}})]},proxy:true}])},[_c('AsiContentContainer',{attrs:{"no-bottom-margin":""}},[_c('v-data-table',{attrs:{"footer-props":{itemsPerPageOptions: _vm.perPageOptions},"headers":_vm.createHeaders(),"items":_vm.items,"loading":_vm.loadingInternal,"options":_vm.tableOptions,"server-items-length":_vm.total,"item-key":"id","multi-sort":""},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.openModelDetailPage},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(item.createdAt), 'short'))+" "+_vm._s(_vm.$d(new Date(item.createdAt), 'timeShort'))+" ")])]}},{key:"item.paymentState",fn:function(ref){
var item = ref.item;
return [_c('OrderPaymentStateChip',{attrs:{"order":item}})]}},{key:"item.shipmentState",fn:function(ref){
var item = ref.item;
return [_c('OrderShipmentStateChip',{attrs:{"order":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('AsiBtn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.reorder(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('order.terms.reorder'))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }