















import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import Icon from "@/plugins/icons";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import {IShoppingListPositionAdminSimple} from "@/models/shopping-list-position/ShoppingListPositionAdminModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import i18n from "@/plugins/i18n";

@Component({
	components: {AsiCardDetailEntry, AsiCardDetail}
})
export default class ShoppingListCardPositions extends Vue {

	@Prop({type: Array, required: true})
	public positions!: IShoppingListPositionAdminSimple[];

	private icons = Icon;

	private itemName(position: IShoppingListPositionAdminSimple): string {
		return TranslatedValueHelper.get(position.item.name, i18n.locale, true) ?? '?';
	}
}
