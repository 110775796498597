










import Vue from "vue";
import {Component, Emit, Prop} from "vue-property-decorator";
import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
import Icon from "@/plugins/icons";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import CustomerUpdateDialogNotes from "@/components/customer/admin/CustomerUpdateDialogNotes.vue";
import DialogHandler from "@/components/common/DialogHandler";
import AsiBtn from "@/components/common/AsiBtn.vue";

@Component({
	components: {AsiBtn, CustomerUpdateDialogNotes, AsiCardDetailEntry, AsiCardDetail}
})
export default class CustomerCardNotes extends Vue {

	@Prop({type: Object, required: true})
	public customer!: ICustomerAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	@Emit('change')
	public change(): void {
		return;
	}

}
