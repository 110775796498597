

















import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import UserUpdateName from "@/models/user/UserUpdateName";
import {IUserShop} from "@/models/user/UserShopModels";
import {IUserAdmin} from "@/models/user/UserAdminModels";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import AsiTextField from "@/components/common/AsiTextField";
import {ValidationRule} from "@/declarations/ValidationRule";
import UserRules from "@/models/user/UserRules";

@Component({
	components: {AsiTextField, AsiDialogFormLayout, AsiDialogForm}
})
export default class UserUpdateDialogUsername extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public user!: IUserShop | IUserAdmin;

	private model: UserUpdateName | null = null;
	private username: string | null = null;

	public created(): void {
		this.setUserInternal();
	}

	// noinspection JSMethodCanBeStatic
	private get usernameRules(): ValidationRule[] {
		return UserRules.usernameRules;
	}

	@Watch('user', {deep: true})
	private onUserChanged(): void {
		this.setUserInternal();
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.setUserInternal();
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private setUserInternal(): void {
		this.username = this.user.username;
	}

	private requestSave(): void {
		if (this.username === null) return;

		this.loading = true;
		this.$userServiceShop.updateUsername(this.user.id, this.username)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

}
