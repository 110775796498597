















































	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import {Component, Emit} from "vue-property-decorator";
	import ShipmentTypeCreate from "../../../models/shipment-type/ShipmentTypeCreate";
	import TranslatedValueFormPart from "@/components/translated-value/admin/TranslatedValueFormPart.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";
	import {DeliveryMethod} from "@/helpers/constants";
import EnumHelper from "@/helpers/EnumHelper";

	@Component({
		components: {AsiRadioGroup, AsiCheckbox, AsiTextField, TranslatedValueFormPart, AsiDialogFormLayout}
	})
	export default class ShipmentTypeCreateDialog extends AsiDialogForm {

		private model: ShipmentTypeCreate = new ShipmentTypeCreate();

		private get currencyCode(): string {
			//TODO: maybe later replace with configurable currency
			return 'CHF';
		}

		private get deliveryMethods(): {text: string, value: string | number}[] {
			return EnumHelper.toSelectItems(DeliveryMethod, true);
		}

		private requestSave(): void {
			if (!this.valid) return;

			this.loading = true;
			this.$shipmentTypeServiceAdmin.create(this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([this.model], form);
			return event;
		}

	}
