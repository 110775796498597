





import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";

@Component
export default class AsiSkeletonCard extends Vue {

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

}
