

















































import {Component, Prop} from "vue-property-decorator";
import Icon from "@/plugins/icons";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

@Component({
	components: {AsiAvatar, AsiFadeTransition, AsiBtn}
})
export default class AsiToolbar extends mixins(ResponsiveChecks) {

	@Prop({type: String, default: null})
	public title!: string | null;

	@Prop({type: String, default: null})
	public subtitle!: string | null;

	@Prop({type: String, default: null})
	public icon!: string | null;

	@Prop({type: Number, default: 40})
	public iconSize!: number;

	@Prop({type: String, default: null})
	public avatarUrl!: string | null;

	@Prop({type: String, default: null})
	public avatarIconColor!: string | null;

	@Prop({type: String, default: 'transparent'})
	public avatarBgColor!: string | null;

	@Prop({type: Boolean, default: false})
	public showExpanderToggleButton!: boolean;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	@Prop({type: Boolean, default: false})
	public dense!: boolean;

	@Prop({type: String, default: 'grey lighten-3'})
	public color!: string;

	@Prop({type: Boolean, default: false})
	public dark!: boolean;

	protected expanded: boolean = false;
	private icons = Icon;

	private get mobileMode(): boolean {
		return this.$vuetify.breakpoint.smAndDown;
	}

	private get hasPrependButton(): boolean {
		return !!this.$slots['prependButton'];
	}

	private get hasContentRight(): boolean {
		return !!this.$slots['content-right'];
	}

	private get hasContentExpander(): boolean {
		return !!this.$slots['content-expander'];
	}

	private get hasSubtitle(): boolean {
		return !!this.$slots['subtitle'] || !!this.$scopedSlots['subtitle'];
	}

}
