


















import {Component} from "vue-property-decorator";
import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import CategoryHelper from "@/models/category/CategoryHelper";
import AsiBtn from "@/components/common/AsiBtn.vue";
import CategoryCard from "@/components/category/shop/CategoryCard.vue";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import {RawLocation} from "vue-router";

@Component({
	components: {CategoryCard, AsiBtn}
})
export default class RootCategoryNavigation extends mixins(ResponsiveChecks) {

	private get rootCategories(): ICategoryShopListEntry[] {
		return this.$store.getters['category/rootCategories'];
	}

	private get activeRootCategory(): string {
		return this.$store.state.category.activeRootCategory;
	}

	private categoryColor(category: ICategoryShopListEntry): string {
		return CategoryHelper.colorHierarchical(category) ?? 'primary';
	}

	private categoryNameTranslated(category: ICategoryShopListEntry): string | null {
		return TranslatedValueHelper.get(category.name, this.$i18n.locale);
	}

	private categoryDetailRoute(category: ICategoryShopListEntry): RawLocation {
		return CategoryHelper.detailRoute(CategoryHelper.currentCanonical(category.canonical));
	}

	private showCategory(category: ICategoryShopListEntry): void {
		this.$store.commit('category/setActiveRootCategory', category.id);
		if (this.$router.currentRoute.params.id === category.id) return;
		this.$router.push(this.categoryDetailRoute(category));
	}
}
