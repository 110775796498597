





























import Vue from 'vue';
import {Component, Emit, Prop} from 'vue-property-decorator';
import Icon from "@/plugins/icons";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";
import DialogHandler from "@/components/common/DialogHandler";
import GlobalConfigUpdateDialogTrimmingCost
	from "@/components/global-config/admin/GlobalConfigUpdateDialogTrimmingCost.vue";

@Component({
	components: {GlobalConfigUpdateDialogTrimmingCost, AsiCardDetailEntry, AsiCardDetail}
})
export default class GlobalConfigCardTrimmingCost extends Vue {

	@Prop({type: Object, required: true})
	public globalConfig!: IGlobalConfigAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	@Emit('change')
	public change(): void {
		return;
	}

}
