










import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import AsiCardDetail from "../../common/AsiCardDetail.vue";
import {IItemAdmin} from "@/models/item/ItemAdminModels";
import Icon from "../../../plugins/icons";
import DialogHandler from "../../common/DialogHandler";
import ItemPriceTable from "../ItemPriceTable.vue";

@Component({
	components: {ItemPriceTable, AsiCardDetail}
})
export default class ItemCardPrices extends Vue {
	@Prop({type: Object, required: true})
	public item!: IItemAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	private get hasPrices(): boolean {
		return this.item.prices.length > 0;
	}

	@Emit('change')
	public change(): void {
		return;
	}
}
