










	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import ItemCard from "@/components/item/shop/ItemCard.vue";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import ItemListFilterShop from "@/models/item/ItemListFilterShop";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

	@Component({
		components: {ItemCard}
	})
	export default class ItemRelatedItemsList extends mixins(ResponsiveChecks) {
        @Prop({type: String, required: true})
        public itemId!: string;

		private relatedItems: IItemShopListEntry[] = [];
		private relatedItemsFilter = new ItemListFilterShop();

		private loadRelatedItems(): void {
			this.$itemServiceShop.items(this.relatedItemsFilter, null)
				.then(res => {
					this.relatedItems = [];
					this.relatedItems = res.data;
					this.noRelatedItems(this.relatedItems.length === 0);
				});
		}

		@Watch('itemId', {immediate: true})
		private onItemIdChanged(value: string): void {
			this.relatedItemsFilter.relatedToItemIds = [];
			this.relatedItemsFilter.relatedToItemIds.push(value);
			this.loadRelatedItems();
		}

		@Emit('noRelatedItems')
		public noRelatedItems(value: boolean): boolean {
			return value;
		}
	}
