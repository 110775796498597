
























































import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import AsiDialog from "./AsiDialog.vue";
import Icon from "@/plugins/icons";
import Snackbar from "@/helpers/Snackbar";
import IModel from "@/models/IModel";
import IImageGalleryService from "@/services/IImageGalleryService";
import AttachmentUpload from "@/models/attachment/AttachmentUpload";
import AttachmentCreate from "@/models/attachment/AttachmentCreate";
import VueI18n from "vue-i18n";
import AsiFileInputSimple from "@/components/common/AsiFileInputSimple";
import AttachmentHelper from "@/models/attachment/AttachmentHelper";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiFileInputSimple, AsiDialog}
})
export default class ImageGalleryUploadDialog extends Vue {

	@Prop({required: true})
	public service!: IImageGalleryService;

	@Prop({type: Object, required: true})
	public model!: IModel;

	@Prop({type: Boolean, required: true})
	public open!: boolean;

	@Prop({type: String, default: null})
	public title!: string | null;

	@Prop({type: String, default: null})
	public subtitle!: string | null;

	@Prop({type: String, default: Icon.avatar})
	public icon!: string;

	@Prop({type: String, default: "image/png, image/jpeg, image/jpg"})
	public accept!: string;

	private icons = Icon;
	private loadingInternal: number = 0;
	private selectedFiles: File[] = [];
	private uploads: AttachmentUpload[] = [];
	private failedUploads: number[] = [];
	private successfulUploads: number[] = [];

	private get titleFinal(): string | TranslateResult {
		return this.title ?? this.$t('ui.terms.uploadGalleryImages');
	}

	private get allowedMimeTypes(): string[] {
		return this.accept.split(/\s*,\s*/);
	}

	@Emit('cancel')
	public cancel(): number {
		return this.successfulUploads.length;
	}

	@Emit('uploaded')
	public uploaded(): void {
		return;
	}

	public paste(event: Event): void {
		//@ts-ignore
		if (event.clipboardData === undefined) return;
		const ce = event as ClipboardEvent;

		//items
		const items = ce.clipboardData?.items;
		if (items !== undefined && items.length > 0) {
			for (const item of items) {
				if (item.kind !== 'file') continue;
				const f = item.getAsFile();
				if (f === null) continue;
				this.performUpload(f);
			}
		}
	}

	private selectedFilesChanged(): void {
		this.selectedFiles.forEach(f => this.performUpload(f));
		this.selectedFiles = [];
	}

	private filesDropped(event: DragEvent): void {
		event.preventDefault();
		if (event.dataTransfer === null) return;

		if (event.dataTransfer.items) {
			for (let i = 0; i < event.dataTransfer.items.length; i++) {
				if (event.dataTransfer.items[i].kind === 'file') {
					const file = event.dataTransfer.items[i].getAsFile();
					if (file !== null) {
						this.performUpload(file);
					}
				}
			}
		} else {
			for (let i = 0; i < event.dataTransfer.files.length; i++) {
				this.performUpload(event.dataTransfer.files[i]);
			}
		}
	}

	private performUpload(file: File): void {
		if (!this.allowedMimeTypes.includes(file.type)) {
			console.error('mime type not allowed', file.name, file.type);
			return;
		}

		const model = new AttachmentCreate();
		model.model = this.model;
		model.file = file;
		const upload = new AttachmentUpload(model);
		this.uploads.unshift(upload);

		this.loadingInternal++;
		this.service.uploadGalleryImage(upload.model, (event: ProgressEvent): void => {
			upload.progress = Math.round((event.loaded * 100) / event.total);
		})
			.then(() => {
				this.loadingInternal--;
				this.uploaded();
				this.successfulUploads.push(upload.identifier);
			})
			.catch(err => {
				Snackbar.uploadError(err);
				this.failedUploads.push(upload.identifier);
			});
	}

	// noinspection JSMethodCanBeStatic
	private fileIcon(file: File): string {
		return AttachmentHelper.iconByMimeType(file.type);
	}

	// noinspection JSMethodCanBeStatic
	private fileMeta(file: File): string {
		return [
			AttachmentHelper.fileSizeFormatted(file.size),
			file.type,
		].join(', ');
	}

}
