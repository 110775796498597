

























































































import {Component, Prop, Watch} from "vue-property-decorator";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import ItemPriceTable from "@/components/item/ItemPriceTable.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import AsiBtn from "@/components/common/AsiBtn.vue";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import Icon from "@/plugins/icons";
import CartSelectInput from "@/components/cart/shop/CartSelectInput.vue";
import Snackbar from "@/helpers/Snackbar";
import GalleryDetail from "@/components/gallery/GalleryDetail.vue";
import {IAttachment} from "@/models/attachment/AttachmentModels";
import GalleryPreview from "@/components/gallery/GalleryPreview.vue";
import ItemQuantitySelectionWithPrice from "@/components/item/shop/ItemQuantitySelectionWithPrice.vue";
import ItemAlertStockStatus from "@/components/item/ItemAlertStockStatus.vue";
import ItemChipNumber from "@/components/item/ItemChipNumber.vue";
import ItemChipAltNumber from "@/components/item/ItemChipAltNumber.vue";
import ShoppingListButton from "@/components/shopping-list/shop/ShoppingListButton.vue";
import AsiAlert from "@/components/common/AsiAlert.vue";
import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";
import i18n from "@/plugins/i18n";
import {TranslateResult} from "vue-i18n";
import EnumHelper from "@/helpers/EnumHelper";
import {StockStatus, UnitOfMeasure} from "@/helpers/constants";

@Component({
	components: {
		AsiAlert,
		ShoppingListButton,
		ItemChipAltNumber,
		ItemChipNumber,
		ItemAlertStockStatus,
		ItemQuantitySelectionWithPrice,
		GalleryPreview,
		GalleryDetail,
		CartSelectInput,
		AsiBtn, AsiCard, ItemPriceTable
	}
})
export default class ItemCardBasicData extends mixins(ResponsiveChecks) {

	@Prop({type: Object, default: null})
	public item!: IItemShopListEntry | null;

	private icons = Icon;
	private quantity: number = 1;
	private showPriceTable: boolean = false;

	private selectedCartId: string | null = null;
	private selectedImageId: string | null = null;
	private currentQuantity: number = 0;

	public created(): void {
		this.selectedCartId = this.$store.state.cart.activeCartId;
	}

	private get hasPrices(): boolean {
		return (this.item?.prices.length ?? 0) > 0;
	}

	private get numCarts(): number {
		return this.$store.state.cart.carts.length;
	}

	private get nameTranslated(): string | null {
		return this.item === null ? null : TranslatedValueHelper.get(this.item.name, this.$i18n.locale);
	}

	private get selectedImage(): IAttachment | null {
		return this.item?.galleryAttachments.find(i => i.id === this.selectedImageId) ?? null;
	}

	private get hasImage(): boolean {
		return (this.item?.galleryAttachments.length ?? 0) > 0;
	}

	private get isLoggedIn(): boolean {
		return this.$store.getters['user/isLoggedIn'];
	}

	private get canSampleOrder(): boolean | undefined {
		return this.item?.canOrderSample;
	}

	private get isItemAlreadyInSampleOrderCart(): boolean {
		return this.$store.getters["sampleOrder/getItemById"](this.item?.id) !== null;
	}

	private get categoryAvatarImage(): IAttachment | null {
		return this.item === null || this.item.category.avatarAttachmentId === null
			? null
			: this.item.category.galleryAttachments.find(i => i.id === this.item?.category.avatarAttachmentId) ?? null;
	}

	private get globalConfig(): IGlobalConfigAdmin | null {
		return this.$store.state.globalConfig.modelAdmin;
	}

	private get trimmingCostsInfo(): TranslateResult {
		const unit = this.item?.salesUnitOfMeasure !== undefined ? EnumHelper.textFromValue(UnitOfMeasure, this.item?.salesUnitOfMeasure, true) : '';
		return i18n.t('item.terms.trimmingCosts', {value: this.globalConfig?.trimmingCostThreshold, unit: unit, price: this.globalConfig?.trimmingCostPrice?.currency.currencyCode + ' ' + this.globalConfig?.trimmingCostPrice?.amount});
	}

	private get showExternalStockQuantity(): boolean {
		return (StockStatus.stockNoInformation !== this.item?.externalStockStatus && StockStatus.stockNone !== this.item?.externalStockStatus)
			&& (this.item?.stockQuantity === null || this.currentQuantity > (this.item?.stockQuantity ?? 0));
	}

	@Watch('item', {deep: true})
	private onItemChanged(): void {
		this.selectedImageId = this.item?.avatarAttachmentId ?? null;
	}

	private addToCart(): void {
		if (this.item !== null) {
			this.$store.dispatch('cart/addPosition', {
				item: this.item,
				quantity: this.quantity,
				cartId: this.selectedCartId,
			})
				.then(() => this.$store.commit('cart/setExpanded', true))
				.catch(() => Snackbar.createError());
		}
	}

	private addToSampleOrderCart(): void {
		if (this.item !== null) {
			this.$store.dispatch('sampleOrder/add', this.item);
		}
	}

	private quantityChanged(currentQuantity: number) {
		this.currentQuantity = currentQuantity;
	}
}
