import { render, staticRenderFns } from "./OrderCardPositions.vue?vue&type=template&id=e80a8dae&scoped=true"
import script from "./OrderCardPositions.vue?vue&type=script&lang=ts"
export * from "./OrderCardPositions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e80a8dae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VBtnToggle,VDataTable,VDivider})
