










































import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import Icon from "@/plugins/icons";
import CustomerTypeChip from "@/components/customer/CustomerTypeChip.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import DialogHandler from "@/components/common/DialogHandler";
import CustomerUpdateDialogState from "@/components/customer/admin/CustomerUpdateDialogState.vue";
import CustomerUpdateDialogType from "@/components/customer/admin/CustomerUpdateDialogType.vue";
import CustomerUpdateDialogName from "@/components/customer/admin/CustomerUpdateDialogName.vue";
import CustomerStateChip from "@/components/customer/CustomerStateChip.vue";
import CustomerUpdateDialogPhone from "@/components/customer/admin/CustomerUpdateDialogPhone.vue";

@Component({
	components: {
		CustomerUpdateDialogPhone,
		CustomerStateChip,
		CustomerUpdateDialogName,
		CustomerUpdateDialogType,
		CustomerUpdateDialogState, AsiBtn, CustomerTypeChip, AsiCardDetail, AsiCardDetailEntry
	}
})
export default class CustomerCardBasicData extends Vue {

	@Prop({type: Object, required: true})
	public customer!: ICustomerAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	@Prop({type: Boolean, default: false})
	public nameDisabled!: boolean;

	@Prop({type: Boolean, default: false})
	public typeDisabled!: boolean;

	@Prop({type: Boolean, default: false})
	public stateDisabled!: boolean;

	private icons = Icon;
	private nameDialog: DialogHandler = new DialogHandler();
	private stateDialog: DialogHandler = new DialogHandler();
	private typeDialog: DialogHandler = new DialogHandler();
	private phoneDialog: DialogHandler = new DialogHandler();

	private call(nr: string | null): void {
		if (nr !== null) {
			const finalNumber = nr.replace(/\s/g, '');
			window.location.href = 'tel:' + finalNumber;
		}
	}

	@Emit('change')
	public change(): void {
		return;
	}

}
