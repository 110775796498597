






























import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import ItemQuantitySelection from "@/components/item/shop/ItemQuantitySelection.vue";
import CartPositionCustomerReferenceInput from "@/components/cart-position/CartPositionCustomerReferenceInput.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import CartTotalEntries from "@/components/cart/shop/CartTotalEntries.vue";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import Icon from "@/plugins/icons";
import ItemHelper from "@/models/item/ItemHelper";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import {TranslateResult} from "vue-i18n";
import {IItemShopSimple} from "@/models/item/ItemShopModels";

@Component({
	components: {AsiAvatar, CartTotalEntries, AsiBtn, CartPositionCustomerReferenceInput, ItemQuantitySelection}
})
export default class SampleOrderCart extends Vue {

	@Prop({type: Array, required: false})
	public itemsModel!: IItemShopSimple[] | undefined

	@Prop({type: Boolean, required: false, default: true})
	public canDelete!: boolean | undefined

	private icons = Icon;

	public get items(): IItemShopSimple[] {
		if (this.itemsModel !== undefined) {
			return this.itemsModel;
		}
		return this.$store.state.sampleOrder.items;
	}

	private showItem(item: IItemShopSimple): void {
		if (this.$router.resolve(ItemHelper.detailRoute(item.itemNumber)).route.path === this.$route.path) return;
		this.$router.push(ItemHelper.detailRoute(item.itemNumber));
	}

	private itemTitle(item: IItemShopSimple): string | TranslateResult | null {
		return TranslatedValueHelper.get(item.name, this.$i18n.locale);
	}

	private removePosition(item: IItemShopSimple): void {
		this.$store.dispatch('sampleOrder/remove', item);
	}

}
