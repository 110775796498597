




























import {Component} from 'vue-property-decorator';
import ContactForm from "@/components/contact-form/ContactForm.vue";
import ShopView from "@/components/layout/ShopView.vue";
import SampleOrderForm from "@/components/sample-order/shop/SampleOrderForm.vue";
import Snackbar from "@/helpers/Snackbar";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import Icon from "@/plugins/icons";
import AddressHelper from "@/helpers/AddressHelper";
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import ContactFormSummary from "@/models/contact/ContactFormModels";
import ContactFormCreate from "@/models/contact/ContactFormCreate";
import AsiBtn from "@/components/common/AsiBtn.vue";
import RoutesHelper from "@/helpers/RoutesHelper";

@Component({
	components: {AsiBtn, AsiCardDetail, AsiCardDetailEntry, SampleOrderForm, ShopView, ContactForm}
})
export default class Contact extends mixins(ResponsiveChecks) {
	private icons = Icon;
	public model: ContactFormCreate | null = new ContactFormCreate();
	public confirmationModel!: ContactFormSummary;
	public valid = false;
	private isFormSubmitted = false;

	private sendContactForm() {
		this.$contactFormService.sendContactForm(this.prepareDataForPost())
			.then(() => {
				this.prepareConfirmationData();
				this.reset();
				this.isFormSubmitted = true;
			})
			.catch((reason) => {
				console.log(reason);
				Snackbar.sendError();
			})
			.finally();
	}

	private prepareDataForPost(): ContactFormCreate {
		if (this.model === null) {
			return new ContactFormCreate();
		}
		this.model.locale = this.$i18n.locale;
		return this.model;
	}

	private addressLines(model: ContactFormSummary | null): string[] {
		if (model !== null) {
			return AddressHelper.addressLinesContactForm(model);
		}
		return [];
	}

	private reset(): void {
		if (this.model === null) {
			return;
		}
		this.model.reset();
		this.valid = false;
	}

	private prepareConfirmationData() {
		if (this.model === null) {
			return;
		}
		this.confirmationModel = new ContactFormSummary(this.model);
	}

	private showHome(): void {
		this.$router.push(RoutesHelper.homeShopRoute());
	}
}
