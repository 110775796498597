import { render, staticRenderFns } from "./ItemListPagination.vue?vue&type=template&id=5fc1a1e2&scoped=true"
import script from "./ItemListPagination.vue?vue&type=script&lang=ts"
export * from "./ItemListPagination.vue?vue&type=script&lang=ts"
import style0 from "./ItemListPagination.vue?vue&type=style&index=0&id=5fc1a1e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc1a1e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VDivider,VPagination})
