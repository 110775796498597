











































import {Component, Emit, Prop} from "vue-property-decorator";
import AsiListTableHeader from "../common/AsiListTableHeader";
import {IAttachment, IAttachmentFile, IAttachmentListEntry} from "@/models/attachment/AttachmentModels";
import AsiListTable from "@/components/common/AsiListTable.vue";
import AttachmentListFilter from "@/models/attachment/AttachmentListFilter";
import {AttachmentMediaSize} from "@/helpers/constants";
import AttachmentHelper from "@/models/attachment/AttachmentHelper";
import Icon from "@/plugins/icons";
import AsiListTableLayout from "@/components/common/AsiListTableLayout.vue";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
import {IItemAdmin} from "@/models/item/ItemAdminModels";
import Snackbar from "@/helpers/Snackbar";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import AttachmentUpdateDialog from "@/components/attachment/AttachmentUpdateDialog.vue";
import DialogHandler from "@/components/common/DialogHandler";
import AttachmentPreviewDialog from "@/components/attachment/AttachmentPreviewDialog.vue";

@Component({
	components: {AttachmentPreviewDialog, AttachmentUpdateDialog, AsiAvatar, AsiConfirmDialog, AsiListTableLayout}
})
export default class AttachmentList extends AsiListTable<IAttachmentListEntry, AttachmentListFilter> {

	@Prop({required: true})
	public item!: IItemAdmin | null;

	@Prop({type: Boolean, default: false})
	public showActions!: boolean;

	private icons = Icon;
	private loadingDelete: boolean = false;
	private updateAttachment: IItemAdmin | null = null;
	private updateDialog: DialogHandler = new DialogHandler(() => this.updateAttachment = null);

	private previewDialog: DialogHandler = new DialogHandler(() => this.previewAttachment = null);
	private previewAttachment: IAttachment | null = null;

	public createHeaders(): AsiListTableHeader[] {
		const ret: AsiListTableHeader[] = [
			new AsiListTableHeader('', 'avatar', false, false, 'center', '4rem'),
			new AsiListTableHeader(this.$t('ui.terms.name'), 'name', true, true, 'start'),
			new AsiListTableHeader(this.$t('ui.terms.fileName'), 'filename', true, true, 'start'),
			new AsiListTableHeader(this.$t('attachment.mimeType'), 'mimeType', true, true, 'start', '15%'),
			new AsiListTableHeader(this.$t('attachment.fileSize'), 'files', true, true, 'end', '15%'),
		];

		if (this.showActions) {
			ret.push(
				new AsiListTableHeader('', 'actions', false, false, 'end', '8rem'),
			);
		}

		return ret;
	}

	private fileSize(attachment: IAttachment): string {
		const origFile: IAttachmentFile | null = attachment.files.find(a => a.mediaSize === AttachmentMediaSize.orig) ?? null;
		return origFile !== null ? AttachmentHelper.fileSizeFormatted(origFile.fileSize) : '';
	}

	private icon(attachment: IAttachment): string {
		return AttachmentHelper.icon(attachment);
	}

	private canPreview(attachment: IAttachment): boolean {
		return AttachmentHelper.isImageAttachment(attachment);
	}

	private preview(attachment: IAttachment): void {
		this.previewAttachment = attachment;
		this.previewDialog.open();
	}

	private performDelete(entry: IAttachmentListEntry): void {
		const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
		confirm.openDialog().then((res: boolean) => {
			if (!res) return;

			const id = entry.id;
			this.loadingDelete = true;
			if (this.item === null) return;
			this.$itemServiceAdmin.deleteAdditionalAttachment(this.item.id, entry.id)
				.then(() => {
					this.deleted(id);
				})
				.catch(() => Snackbar.deleteError())
				.finally(() => this.loadingDelete = false);
		});
	}

	@Emit('changed')
	public changed(id: string): string {
		return id;
	}

	@Emit('deleted')
	public deleted(id: string): string {
		return id;
	}
}
