


















































import {Component, Emit, Prop} from "vue-property-decorator";
import AsiAvatar from "@/components/common/AsiAvatar.vue";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;
import {mixins} from "vue-class-component";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

@Component({
	components: {AsiAvatar}
})
export default class AsiCard extends mixins(ResponsiveChecks) {

	@Prop({type: String, default: ''})
	public titleBgColor!: string | null;

	@Prop({type: String, default: 'grey lighten-5'})
	public actionBgColor!: string | null;

	@Prop({default: ''})
	public title!: string | TranslateResult;

	@Prop({type: Boolean, default: false})
	public wrapTitle!: boolean;

	@Prop({type: Boolean, default: false})
	public titleDense!: boolean;

	@Prop()
	public subtitle!: string;

	@Prop({type: Boolean, default: false})
	public wrapSubtitle!: boolean;

	@Prop({type: String})
	public icon!: string;

	@Prop({type: String, default: 'white'})
	public iconColor!: string;

	@Prop({type: String, default: ''})
	public iconBgColor!: string;

	@Prop({type: Number, default: 40})
	public avatarSize!: number | null;

	@Prop({type: String, default: null})
	public imageUrl!: string | null;

	@Prop({type: Boolean, default: false})
	public highlighted!: boolean;

	@Prop({type: Boolean, default: false})
	public loading!: boolean;

	@Prop({type: Boolean, default: false})
	public unwrapped!: boolean;

	@Prop({type: Boolean, default: false})
	public noBottomMargin!: boolean;

	@Prop({type: Boolean, default: false})
	public flat!: boolean;

	@Prop({type: Boolean, default: false})
	public tile!: boolean;

	@Prop({type: String, default: null})
	public loaderColor!: string | null;

	@Prop({type: Number, default: null})
	public loaderProgress!: number | null;

	private get titleClasses(): string {
		let ret = this.titleBg;
		if (!this.tile) {
			if (!this.hasTopContent) ret = ret + ' rounded-t';
			if (!this.hasContent) ret = ret + ' rounded-b';
		}
		return ret;
	}

	private get titleBg(): string {
		if (this.titleBgColor === null || this.titleBgColor.length === 0) {
			return this.highlighted ? 'primary' : 'grey lighten-4';
		}

		return this.titleBgColor;
	}

	private get iconBg(): string {
		if (this.iconBgColor === null || this.iconBgColor.length === 0) {
			return this.highlighted ? 'secondary' : 'grey';
		}

		return this.iconBgColor;
	}

	private get loadingValue(): string | null {
		return this.loading ? 'secondary' : null;
	}

	protected get hasContent(): boolean {
		return (this.$slots.default ?? []).length > 0;
	}

	private get hasTopContent(): boolean {
		return !!this.$slots['top-content'];
	}

	@Emit('click')
	public click(event: Event): Event {
		return event;
	}

	private get hasClickListener(): boolean {
		return !!this.$listeners.click;
	}

}
