

















import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import Icon from "../../../plugins/icons";
import DialogHandler from "../../common/DialogHandler";
import {IItemAdmin} from "@/models/item/ItemAdminModels";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCardDetailEntryTranslatedValue from "@/components/common/AsiCardDetailEntryTranslatedValue.vue";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import ItemUpdateDialogBasicData from "@/components/item/admin/ItemUpdateDialogBasicData.vue";
import {ItemType} from "@/helpers/constants";
@Component({
	components: {ItemUpdateDialogBasicData, AsiCardDetailEntry, AsiCardDetailEntryTranslatedValue, AsiCardDetail}
})
export default class ItemCardBasicData extends Vue {

	private itemType = ItemType;

	@Prop({type: Object, required: true})
	public item!: IItemAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	@Emit('change')
	public change(): void {
		return;
	}

}
