








































import {Component, Prop, Watch} from 'vue-property-decorator';
import {ICartShopListEntry} from "@/models/cart/CartShopModels";
import {ICustomerShopSimple} from "@/models/customer/CustomerShopModels";
import Snackbar from "@/helpers/Snackbar";
import AsiStepper from "@/components/common/AsiStepper.vue";
import AsiStepperStep from "@/components/common/AsiStepperStep.vue";
import AsiStepperContent from "@/components/common/AsiStepperContent.vue";
import VueI18n from "vue-i18n";
import Icon from "@/plugins/icons";
import OrderHelper from "@/helpers/OrderHelper";
import {IUserShop} from "@/models/user/UserShopModels";
import {CustomerType} from "@/helpers/constants";
import RightChecks from "@/mixins/RightChecks.vue";
import {mixins} from "vue-class-component";
import CheckoutWizardStepAddresses from "@/components/checkout/steps/CheckoutWizardStepAddresses.vue";
import CheckoutWizardStepShipment from "@/components/checkout/steps/CheckoutWizardStepShipment.vue";
import CheckoutWizardStepPayment from "@/components/checkout/steps/CheckoutWizardStepPayment.vue";
import CheckoutWizardStepMisc from "@/components/checkout/steps/CheckoutWizardStepMisc.vue";
import CheckoutWizardStepSummary from "@/components/checkout/steps/CheckoutWizardStepSummary.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import CheckoutWizardStepAddressesGuest from "@/components/checkout/steps/CheckoutWizardStepAddressesGuest.vue";
import Gtm, {itemShopSimpleToGtmItem} from "@/plugins/gtm";
import TotalHelper, {CurrencyAmount} from "@/helpers/TotalHelper";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {
		CheckoutWizardStepAddressesGuest,
		AsiCard,
		CheckoutWizardStepSummary,
		CheckoutWizardStepMisc,
		CheckoutWizardStepPayment,
		CheckoutWizardStepShipment,
		CheckoutWizardStepAddresses,
		AsiStepperContent,
		AsiStepperStep, AsiStepper
	}
})
export default class CheckoutWizard extends mixins(RightChecks) {

	@Prop({type: String, required: true})
	public id!: string;

	private readonly stepAddresses: number = 1;
	private readonly stepShipment: number = 2;
	private readonly stepPayment: number = 3;
	private readonly stepMisc: number = 4;
	private readonly stepSummary: number = 5;
	private readonly allSteps: number[] = [1, 2, 3, 4, 5];

	private icons = Icon;

	private step: number = 1;
	private visitedSteps: number[] = [];
	private loadingSteps: number[] = [];

	private get cart(): ICartShopListEntry | null {
		return this.$store.getters['cart/cartById'](this.id);
	}

	private get user(): IUserShop | null {
		return this.$store.getters['user/model'];
	}

	private get isBusinessAccount(): boolean {
		return this.user !== null && this.user.customer.type === CustomerType.business;
	}

	private get customer(): ICustomerShopSimple | null {
		return this.$store.getters['user/modelCustomer'];
	}

	public mounted(): void {
		const cart = this.cart;
		if (cart === null) {
			return;
		}
		TotalHelper.totalInclVat(cart, true)
			.forEach(total => {
				Gtm.beginCheckout({
					currency: total.currency.currencyCode,
					value: total.amount,
					items: cart.positions.map(p => itemShopSimpleToGtmItem(p.item, p.price?.amount ?? 0, p.quantity))
				});
			});

	}

	@Watch('step', {immediate: true})
	private onStepChanged(value: number): void {
		if (!this.isStepVisited(value)) {
			this.visitedSteps.push(value);
		}
	}

	private isStepVisited(step: number): boolean {
		return this.visitedSteps.includes(step);
	}

	private isStepEditable(step: number): boolean {
		if (this.isStepVisited(step)) return true;

		const indexBefore = this.allSteps.indexOf(step) - 1;
		return indexBefore < 0 ? false : this.isStepComplete(this.allSteps[indexBefore]);
	}

	private isStepComplete(step: number): boolean {
		if (this.cart === null || !this.isStepVisited(step)) {
			return false;
		}

		switch (step) {
			case this.stepAddresses:
				if (this.user !== null) {
					return this.cart.customerShippingAddress !== null && this.cart.customerBillingAddress !== null;
				} else {
					return this.cart.oneTimeShippingAddress !== null && this.cart.oneTimeBillingAddress !== null;
				}
			case this.stepShipment:
				return this.cart.shipmentType !== null;
			case this.stepPayment:
				return this.cart.paymentType !== null;
			case this.stepMisc:
			case this.stepSummary:
				return true;
			default:
				return false;
		}
	}

	private isStepLoading(step: number): boolean {
		return this.loadingSteps.includes(step);
	}

	private setStepLoading(step: number, loading: boolean): void {
		if (!loading) {
			this.loadingSteps = this.loadingSteps.filter(s => s !== step);
			return;
		}

		if (!this.isStepLoading(step)) {
			this.loadingSteps.push(step);
		}
	}

	private stepLabel(step: number): string | TranslateResult {
		switch (step) {
			case this.stepAddresses:
				return this.$t('address.plural');
			case this.stepShipment:
				return this.$t('shipmentType.singular');
			case this.stepPayment:
				return this.$t('paymentType.singular');
			case this.stepMisc:
				return this.$t('cart.checkout.additionalData');
			case this.stepSummary:
				return this.$t('cart.checkout.summary');
			default:
				return '?';
		}
	}

	private performOrder(): void {
		this.setStepLoading(this.stepSummary, true);

		const cartToCheckout: ICartShopListEntry = this.$store.getters["cart/cartById"](this.id);
		this.$cartServiceShop.checkout(this.id)
			.then(response => {

				const totalsExcl = TotalHelper.totalExclVat(cartToCheckout, false);
				const totalsIncl = TotalHelper.totalInclVat(cartToCheckout, false);

				const sumTotals = (carry: number, b: CurrencyAmount) => carry + b.amount;

				Gtm.purchase({
					currency: totalsExcl[0].currency.currencyCode,
					tax: totalsIncl.reduce(sumTotals, 0) - totalsExcl.reduce(sumTotals, 0),
					value: totalsExcl.reduce(sumTotals, 0),
					shipping: cartToCheckout.shipmentType?.postage.amount ?? 0,
					transaction_id: response.orderId,
					items: cartToCheckout.positions.map(p => itemShopSimpleToGtmItem(p.item, p.price?.amount ?? 0, p.quantity))
				});

				if (this.user === null) this.$store.commit('cart/setActiveCartId', null);
				this.$store.dispatch('cart/loadCarts');
				const route = response.redirectUrl === null
					? OrderHelper.detailRoute(response.orderId)
					: OrderHelper.detailRouteWithPaymentUrl(response.orderId, response.redirectUrl);
				this.$router.push(route);
			})
			.catch(() => Snackbar.createError())
			.finally(() => this.setStepLoading(this.stepSummary, false));
	}
}
