




















import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import EnumHelper from "@/helpers/EnumHelper";
import AsiChip from "@/components/common/AsiChip.vue";
import {IOrderAdminSimple} from "@/models/order/OrderAdminModels";
import {IOrderShopSimple} from "@/models/order/OrderShopModels";
import OrderHelper from "@/helpers/OrderHelper";
import {OrderPaymentState} from "@/helpers/constants";
import Icon from "@/plugins/icons";

@Component({
	components: {AsiChip}
})
export default class OrderPaymentStateChip extends Vue {

	@Prop({type: Object, required: true})
	public order!: IOrderAdminSimple | IOrderShopSimple;

	@Prop({type: Boolean, default: false})
	public expanded!: boolean;

	private icons = Icon;

	private stateLabel(paymentState: OrderPaymentState): string|null {
		return EnumHelper.textFromValue(OrderPaymentState, paymentState, true);
	}
	private stateIcon(paymentState: OrderPaymentState): string {
		return OrderHelper.paymentStateIcon(paymentState);
	}
	private stateColor(paymentState: OrderPaymentState): string {
		return OrderHelper.paymentStateColor(paymentState);
	}

	private get states(): OrderPaymentState[] {
		return EnumHelper.toSelectItems(OrderPaymentState, true).map(s => s.value) as OrderPaymentState[];
	}

}
