





















import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
import Icon from "@/plugins/icons";
import DialogHandler from "@/components/common/DialogHandler";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCardDetailEntryTranslatedValue from "@/components/common/AsiCardDetailEntryTranslatedValue.vue";
import CategoryUpdateDialogBasicData from "@/components/category/admin/CategoryUpdateDialogBasicData.vue";
import EnumHelper from "@/helpers/EnumHelper";
import {CategoryView} from "@/helpers/constants";

@Component({
	components: {
		CategoryUpdateDialogBasicData,
		AsiCardDetailEntryTranslatedValue, AsiCardDetail, AsiCardDetailEntry
	}
})
export default class CategoryCardBasicData extends Vue {

	@Prop({type: Object, required: true})
	public category!: ICategoryAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	private get viewValue(): string | null {
		return EnumHelper.textFromValue(CategoryView, this.category.view, true);
	}

	@Emit('change')
	public change(): void {
		return;
	}

}
