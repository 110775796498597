
import Vue from 'vue';
import {Component} from "vue-property-decorator";

@Component
export default class ResponsiveChecks extends Vue {

	protected get sMobile(): boolean {
		return this.$vuetify.breakpoint.smAndDown;
	}

	protected get sSemiMobile(): boolean {
		return this.$vuetify.breakpoint.mdAndUp && this.$vuetify.breakpoint.lgAndDown;
	}

	protected get sDesktop(): boolean {
		return this.$vuetify.breakpoint.xl;
	}

}
