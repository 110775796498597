import { render, staticRenderFns } from "./ItemAddToCartButton.vue?vue&type=template&id=1387a73e&scoped=true"
import script from "./ItemAddToCartButton.vue?vue&type=script&lang=ts"
export * from "./ItemAddToCartButton.vue?vue&type=script&lang=ts"
import style0 from "./ItemAddToCartButton.vue?vue&type=style&index=0&id=1387a73e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1387a73e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VFadeTransition,VHover,VIcon,VOverlay})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
