





































import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import Icon from "@/plugins/icons";
import VueI18n from "vue-i18n";
import ItemHelper from "@/models/item/ItemHelper";
import AsiCard from "@/components/common/AsiCard.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiFadeTransition, AsiBtn, AsiCard}
})
export default class ItemAttributeFilter extends Vue {

	@Prop({type: String, required: true})
	public attribute!: string;

	@Prop({required: true})
	public valueFormatted!: string | null | TranslateResult;

	@Prop({type: Boolean, required: true})
	public filterActive!: boolean;

	@Prop({type: Boolean, default: false})
	public showIcon!: boolean;

	@Prop({type: Boolean, default: false})
	public closeOnContentClick!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private icons = Icon;

	private get label(): string | TranslateResult | null {
		return ItemHelper.attributeLabel(this.attribute);
	}

	private get icon(): string {
		return ItemHelper.attributeIcon(this.attribute);
	}

	@Emit('clear')
	public clear(): void {
		return;
	}

}
