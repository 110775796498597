
































































import {Component, Emit} from "vue-property-decorator";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import ItemCreate from "@/models/item/ItemCreate";
import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
import AsiSelect from "@/components/common/AsiSelect";
import {ItemType} from "@/helpers/constants";
import EnumHelper from "@/helpers/EnumHelper";
import AsiTextField from "@/components/common/AsiTextField";
import AsiRadioGroup from "@/components/common/AsiRadioGroup";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import i18n from "@/plugins/i18n";
import Snackbar from "@/helpers/Snackbar";
import StringHelper from "@/helpers/StringHelper";
import TranslatedValueFormPart from "@/components/translated-value/admin/TranslatedValueFormPart.vue";

@Component({
	components: {TranslatedValueFormPart, AsiRadioGroup, AsiTextField, AsiSelect, AsiDialogFormLayout, AsiDialogForm}
})
export default class ItemCreateDialog extends AsiDialogForm {

	private model: ItemCreate = new ItemCreate();
	private categoryId = '';

	private itemType = ItemType;
	private categories: { text: string; value: number | string }[] = [];

	private get itemTypes(): { text: string; value: number | string }[] {
		return EnumHelper.toSelectItems(ItemType, true);
	}

	public created(): void {
		this.loadCategories();
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		const form = this.$refs.form as HTMLFormElement;
		this.reset([this.model], form);
		return event;
	}

	@Emit('save')
	public save(id: string): string {
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		this.model = new ItemCreate();
		return id;
	}

	private loadCategories(): void {
		this.loading = true;
		this.$categoryServiceAdmin.categories(null, null)
			.then(res => {
				this.categories = res.data.map(c => ({ text: TranslatedValueHelper.get(c.name, i18n.locale, true) ?? '?', value: c.id }));
			})
			.finally(() => this.loading = false);
	}

	private requestSave(): void {
		if (!this.valid || StringHelper.isEmpty(this.categoryId)) return;

		this.loading = true;
		this.$categoryServiceAdmin.createItem(this.categoryId, this.model)
			.then(res => {
				this.save(res.id);
			})
			.catch(() => Snackbar.createError())
			.finally(() => this.loading = false);
	}
}
