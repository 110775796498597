















































import {Component} from "vue-property-decorator";
import RightChecks from "@/mixins/RightChecks.vue";
import {mixins} from "vue-class-component";
import CartContent from "@/components/cart/shop/CartContent.vue";
import AsiMenuItem from "@/components/common/AsiMenuItem.vue";
import Icon from "@/plugins/icons";
import AsiBtn from "@/components/common/AsiBtn.vue";
import AsiCard from "@/components/common/AsiCard.vue";
import CartHelper from "@/helpers/CartHelper";
import VueI18n from "vue-i18n";
import {Routes} from "@/helpers/constants";
import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
import Snackbar from "@/helpers/Snackbar";
import {ICartShopListEntry} from "@/models/cart/CartShopModels";
import TranslateResult = VueI18n.TranslateResult;

@Component({
	components: {AsiConfirmDialog, AsiCard, AsiBtn, AsiMenuItem, CartContent}
})
export default class CartDrawer extends mixins(RightChecks) {

	private icons = Icon;
	private menu: boolean = false;

	private get expanded(): boolean {
		return this.$store.state.cart.expanded;
	}

	// noinspection JSUnusedLocalSymbols
	private set expanded(expanded: boolean) {
		this.$store.commit('cart/setExpanded', expanded);
	}

	private get cart(): ICartShopListEntry | null {
		return this.$store.getters['cart/activeCart'];
	}

	private get cartLabel(): string | TranslateResult {
		return CartHelper.label(this.cart);
	}

	private get numCarts(): number {
		return this.$store.state.cart.carts.length;
	}

	private showCarts(): void {
		if (!this.rLoggedIn || this.$router.currentRoute.name === Routes.carts) return;
		this.$router.push({name: Routes.carts});
	}

	private activatePrev(): void {
		const prev = this.$store.getters['cart/prevCart'] as ICartShopListEntry | null;
		if (prev === null) return;
		this.$store.commit('cart/setActiveCartId', prev.id);
	}

	private activateNext(): void {
		const next = this.$store.getters['cart/nextCart'] as ICartShopListEntry | null;
		if (next === null) return;
		this.$store.commit('cart/setActiveCartId', next.id);
	}

	private clearCart(): void {
		const cart = this.cart;
		if (cart === null || cart.positions.length === 0) return;

		const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
		confirm.openDialog().then((res: boolean) => {
			if (!res) return;
			this.$store.dispatch('cart/clearCart', cart.id).catch(() => Snackbar.updateError());
		});
	}

	private deleteCart(): void {
		const cart = this.cart;
		if (cart === null) return;

		const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
		confirm.openDialog().then((res: boolean) => {
			if (!res) return;
			this.$store.dispatch('cart/deleteCart', cart.id).catch(() => Snackbar.deleteError());
		});
	}

}
