





























import {Component, Emit, Prop} from "vue-property-decorator";
import AsiDialogFormLayout from "../../common/AsiDialogFormLayout.vue";
import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
import AsiTextField from "@/components/common/AsiTextField";
import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
import ItemAutocompleteInput from "@/components/item/admin/ItemAutocompleteInput.vue";
import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";
import GlobalConfigUpdateTrimmingCost from "@/models/global-config/GlobalConfigUpdateTrimmingCost";

@Component({
	components: {
		ItemAutocompleteInput,
		AsiDatePickerCombined, AsiTextField, AsiDialogFormLayout
	}
})
export default class GlobalConfigUpdateDialogTrimmingCost extends AsiDialogForm {

	@Prop({type: Object, required: true})
	public globalConfig!: IGlobalConfigAdmin;

	private model: GlobalConfigUpdateTrimmingCost | null = null;

	public created(): void {
	    this.setGlobalConfigInternal();
	}

	// noinspection JSMethodCanBeStatic
	private get currencyCode(): string {
		return 'CHF';
	}

	private setGlobalConfigInternal(): void {
		if (this.globalConfig === null) {
			this.model = null;
			return;
		}

		if (this.model === null) {
			this.model = new GlobalConfigUpdateTrimmingCost(this.globalConfig);
			this.valid = true;
		} else {
			this.model.setDetailModel(this.globalConfig);
		}
	}

	private requestSave(): void {
		if (this.model === null) return;

		this.loading = true;
		this.$globalConfigServiceAdmin.updateTrimmingCost(this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.setGlobalConfigInternal();
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

}
