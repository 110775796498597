import { render, staticRenderFns } from "./CustomerBusinessCard.vue?vue&type=template&id=67672c02&scoped=true"
import script from "./CustomerBusinessCard.vue?vue&type=script&lang=ts"
export * from "./CustomerBusinessCard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67672c02",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VChip } from 'vuetify/lib/components/VChip';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBadge,VChip,VMenu})
