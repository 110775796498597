






import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import EnumHelper from "@/helpers/EnumHelper";
import {OrderExportState} from "@/helpers/constants";
import AsiChip from "@/components/common/AsiChip.vue";
import {IOrderAdminListEntry} from "@/models/order/OrderAdminModels";
import OrderHelper from "@/helpers/OrderHelper";

@Component({
	components: {AsiChip}
})
export default class OrderExportStateChip extends Vue {

	@Prop({type: Object, required: true})
	public order!: IOrderAdminListEntry;

	// noinspection JSMethodCanBeStatic
	private get label(): string | null {
		return EnumHelper.textFromValue(OrderExportState, this.order.exportState, true);
	}

	// noinspection JSMethodCanBeStatic
	private get icon(): string {
		return OrderHelper.exportStateIcon(this.order);
	}

	// noinspection JSMethodCanBeStatic
	private get color(): string {
		return OrderHelper.exportStateColor(this.order);
	}

}
