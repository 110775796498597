




























import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import AsiDialogForm from "../../common/AsiDialogForm.vue";
import AsiDialogFormLayout from "../../common/AsiDialogFormLayout.vue";
import AsiTextField from "../../common/AsiTextField";
import {IItemAdmin} from "@/models/item/ItemAdminModels";
import ItemUpdateMetaData from "@/models/item/ItemUpdateMetaData";

@Component({
	components: {AsiTextField, AsiDialogFormLayout}
})
export default class ItemUpdateDialogMeta extends AsiDialogForm {
	@Prop({type: Object, required: true})
	public item!: IItemAdmin;

	private model: ItemUpdateMetaData | null = null;

	@Watch('item', {deep: true, immediate: true})
	private onItemChanged(): void {
		this.setItemInternal();
	}

	@Emit('save')
	public save(): void {
		return;
	}

	@Emit('cancel')
	public cancel(event: Event): Event {
		this.setItemInternal();
		const form = this.$refs.form as HTMLFormElement;
		this.reset([], form);
		return event;
	}

	private setItemInternal(): void {
		if (this.model === null) {
			this.model = new ItemUpdateMetaData(this.item);
			this.valid = true;
		} else {
			this.model.setDetailModel(this.item);
		}
	}

	private requestSave(): void {
		if (this.model === null) return;

		this.loading = true;
		this.$itemServiceAdmin.updateMetaData(this.item.id, this.model)
			.then(this.save)
			.catch(this.handleErrors)
			.finally(() => this.loading = false);
	}
}
