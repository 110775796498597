























	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import ItemAddToCart from "@/components/item/shop/ItemAddToCart.vue";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import EnumHelper from "@/helpers/EnumHelper";
	import {AttachmentMediaSize, ItemAttribute} from "@/helpers/constants";
	import ItemHelper from "@/models/item/ItemHelper";
	import {TranslateResult} from "vue-i18n";
	import ItemAlertStockStatus from "@/components/item/ItemAlertStockStatus.vue";
	import {Tenant} from "@/models/ui/Tenant";
	import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
	import i18n from "@/plugins/i18n";

	@Component({
		components: {ItemAlertStockStatus, ItemAddToCart}
	})
	export default class ItemListViewTableMobileEntry extends Vue {
        @Prop({type: Object, required: false})
        public item!: IItemShopListEntry;

		@Prop({default: null})
		public showAttributeCallback!: ((column: string) => boolean) | null;

		private stockType = '';

		private get nameTranslated(): string | null {
			return this.item === null ? null : TranslatedValueHelper.get(this.item.name, this.$i18n.locale);
		}

		public get itemAttributes(): {key: TranslateResult, value: string | number}[] {
			let ret: {key: TranslateResult, value: string | number}[] = [];

			EnumHelper
				.toArray(ItemAttribute)
				.filter(attr => !ItemHelper.isBaseAttribute(attr.value as string))
				.forEach(attr => {
					if (this.showAttributeCallback !== null ? this.showAttributeCallback(attr.key) : true) {
						let value = this.item[attr.key as keyof IItemShopListEntry];
						if (typeof value === 'object') {
							value = TranslatedValueHelper.get(value as ITranslatedValue, i18n.locale);
						}

						let element = {
							key: ItemHelper.attributeLabel(attr.key),
							//@ts-ignore
							value: value as string | number
						};
						ret.push(element);
					}
				});
			return ret;
		}

		private get avatarUrl(): string | null {
			return ItemHelper.avatarUrl(this.item, AttachmentMediaSize.m);
		}

		// noinspection JSUnusedLocalSymbols
		private get imageStyles(): object {
			switch (this.$isTenant) {
				case Tenant.herzog:
					return {
						'background-image': 'linear-gradient(45deg, #00000044, transparent)',
						'background-color': ItemHelper.colorHierarchical(this.item) ?? undefined,
					};
				case Tenant.mk:
					return {
						'background-color': '#FFFFFF',
					};
				default:
					return {};
			}
		}

		private stockTypeChanged(type: string): void {
			this.stockType = type;
		}
	}
