











import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import {IUserShop} from "@/models/user/UserShopModels";
import {IUserAdmin} from "@/models/user/UserAdminModels";
import Icon from "@/plugins/icons";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import UserUpdateDialogPersonalData from "@/components/user/UserUpdateDialogPersonalData.vue";
import DialogHandler from "@/components/common/DialogHandler";

@Component({
	components: {UserUpdateDialogPersonalData, AsiCardDetailEntry, AsiCardDetail}
})
export default class UserCardPersonalData extends Vue {

	@Prop({type: Object, required: true})
	public user!: IUserShop | IUserAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	@Emit('change')
	public change(): void {
		return;
	}

}
