




























import {Component, Prop} from "vue-property-decorator";
import {IItemShop} from "@/models/item/ItemShopModels";
import {IAttachment, IAttachmentFile} from "@/models/attachment/AttachmentModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import AttachmentHelper from "@/models/attachment/AttachmentHelper";
import {AttachmentMediaSize} from "@/helpers/constants";
import AsiCard from "@/components/common/AsiCard.vue";
import FileHelper from "@/helpers/FileHelper";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";
import AttachmentPreviewDialog from "@/components/attachment/AttachmentPreviewDialog.vue";
import DialogHandler from "@/components/common/DialogHandler";
import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
import {mixins} from "vue-class-component";

@Component({
	components: {AttachmentPreviewDialog, AsiBtn, AsiCard}
})
export default class ItemAttachments extends mixins(ResponsiveChecks) {

	@Prop({type: Object, required: true})
	public item!: IItemShop;

	private icons = Icon;
	private previewDialog: DialogHandler = new DialogHandler(() => this.previewAttachment = null);
	private previewAttachment: IAttachment | null = null;

	private subtitle(attachment: IAttachment): string | null {
		const origFile: IAttachmentFile | null = attachment.files.find(a => a.mediaSize === AttachmentMediaSize.orig) ?? null;
		if (origFile === null) return null;
		const ret: string[] = [];

		if (this.nameTranslated(attachment) === null) {
			ret.push(attachment.filename);
		}

		const ext: string | null = FileHelper.extensionFromFilename(attachment.filename)?.toUpperCase() ?? null;
		if (ext !== null) ret.push(ext);

		ret.push(AttachmentHelper.fileSizeFormatted(origFile.fileSize));

		return ret.join(', ');
	}

	private nameTranslated(attachment: IAttachment): string | null {
		return attachment.name === null ? null : TranslatedValueHelper.get(attachment.name, this.$i18n.locale, false);
	}

	private nameFinal(attachment: IAttachment): string | null {
		if (attachment.name === null) return attachment.filename;
		return this.nameTranslated(attachment) ?? attachment.filename;
	}

	// noinspection JSMethodCanBeStatic
	private canPreview(attachment: IAttachment): boolean {
		return AttachmentHelper.isImageAttachment(attachment);
	}

	// noinspection JSMethodCanBeStatic
	private icon(attachment: IAttachment): string {
		return AttachmentHelper.icon(attachment);
	}

	private preview(attachment: IAttachment): void {
		this.previewAttachment = attachment;
		this.previewDialog.open();
	}

	private download(attachment: IAttachment): void {
		AttachmentHelper.download(attachment);
	}

}
