








import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
import Icon from "@/plugins/icons";
import OrderList from "@/components/order/admin/OrderList.vue";

@Component({
	components: {OrderList, AsiPageTitle}
})
export default class Orders extends Vue {

	private icons = Icon;

}
