
































import Vue from 'vue';
import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
import AsiDialog from "@/components/common/AsiDialog.vue";
import AsiBtn from "@/components/common/AsiBtn.vue";
import Icon from "@/plugins/icons";

@Component({
	components: {AsiBtn, AsiDialog}
})
export default class OrderPaymentRedirectionDialog extends Vue {

	@Prop({type: String, required: true})
	public url!: string;

	@Prop({type: Boolean, default: false})
	public open!: boolean;

	@Prop({type: Number, default: 0})
	public redirectionTimeoutSeconds!: number;

	@Prop({type: Boolean, default: false})
	public newTab!: boolean;

	private icons = Icon;
	private interval: any = null;
	private secondsLeft: number = 0;

	@Watch('open', {immediate: true})
	private onOpenChanged(value: boolean): void {
		clearInterval(this.interval);
		this.secondsLeft = this.redirectionTimeoutSeconds;

		if (value && this.redirectionTimeoutSeconds > 0) {
			this.interval = setInterval(() => {
				this.secondsLeft--;
				if (this.secondsLeft === 0) {
					this.performRedirect();
					this.cancel();
				}
			}, 1000);
		}
	}

	@Emit('cancel')
	public cancel(): void {
		clearInterval(this.interval);
	}

	private performRedirect(): void {
		clearInterval(this.interval);
		if (this.newTab) {
			const w = window.open(this.url, '_blank');
			if (w !== null) w.focus();
		} else {
			window.location.href = this.url;
		}
	}

}
