




















import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";
import Icon from "../../../plugins/icons";
import DialogHandler from "../../common/DialogHandler";
import {IItemAdmin} from "@/models/item/ItemAdminModels";
import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
import AsiCardDetailEntryTranslatedValue from "@/components/common/AsiCardDetailEntryTranslatedValue.vue";
import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
import EnumHelper from "@/helpers/EnumHelper";
import {UnitOfMeasure} from "@/helpers/constants";
import ItemUpdateDialogSalesData from "@/components/item/admin/ItemUpdateDialogSalesData.vue";
import AsiChip from "@/components/common/AsiChip.vue";

@Component({
	components: {
		AsiChip,
		ItemUpdateDialogSalesData, AsiCardDetailEntry, AsiCardDetailEntryTranslatedValue, AsiCardDetail
	}
})
export default class ItemCardSalesData extends Vue {

	@Prop({type: Object, required: true})
	public item!: IItemAdmin;

	@Prop({type: Boolean, default: false})
	public readonly!: boolean;

	@Prop({type: Boolean, default: false})
	public disabled!: boolean;

	private icons = Icon;
	private updateDialog: DialogHandler = new DialogHandler();

	private unitName(unit: string): string | null {
		return EnumHelper.textFromValue(UnitOfMeasure, unit, true);
	}

	@Emit('change')
	public change(): void {
		return;
	}

}
